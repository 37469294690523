import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HotToastModule } from '@ngneat/hot-toast';
import { DataTablesModule } from 'angular-datatables';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { BaseFormDirective } from './directives/base-form/base-form.directive';
import { ApiInterceptorInterceptor } from './interceptors/api-interceptor.interceptor';

@NgModule({
  declarations: [AppComponent, NotFoundComponent, BaseFormDirective],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule,
    AppRoutingModule,
    DataTablesModule,
    BrowserAnimationsModule,
    ComponentsModule,
    HotToastModule.forRoot({
      reverseOrder: true,
      dismissible: true,
      autoClose: true,
    }),
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: ApiInterceptorInterceptor,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
