import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { TimingOption } from 'src/app/common/enums';

@Component({
  selector: 'app-timing-options-picker',
  templateUrl: './timing-options-picker.component.html',
  styleUrls: ['./timing-options-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimingOptionsPickerComponent implements OnChanges {
  TimingOption = TimingOption;
  activeOption: {
    type: TimingOption;
    value?: string;
  } = this.getDefaultTimingOption();

  @Input() value?: {
    type: TimingOption;
    value?: number;
  };

  @Output() update: EventEmitter<{
    type: TimingOption;
    value?: number;
  }> = new EventEmitter();

  constructor() {}

  ngOnChanges({ value }: SimpleChanges): void {
    if (value?.currentValue) {
      this.activeOption = { ...value.currentValue };
    }
  }

  get timingOptions() {
    return [
      {
        label: 'show immediately',
        value: TimingOption.SHOW_IMMEDIATELY,
      },
      {
        label:
          this.activeOption.type === TimingOption.SHOW_AFTER
            ? 'show after'
            : 'show after time delay',
        value: TimingOption.SHOW_AFTER,
      },
    ];
  }

  get selectedLabel() {
    if (
      this.activeOption.type === TimingOption.SHOW_AFTER &&
      this.value?.value !== undefined
    ) {
      return `show after ${this.value.value} second${
        this.value.value === 1 ? '' : 's'
      }`;
    }
    return this.timingOptions.find(o => o.value === this.activeOption.type)
      ?.label;
  }

  private getDefaultTimingOption() {
    return {
      type: TimingOption.SHOW_IMMEDIATELY,
    };
  }

  hideDropdown() {
    if (
      !this.activeOption.value &&
      this.activeOption.type === TimingOption.SHOW_AFTER
    ) {
      this.activeOption.type = TimingOption.SHOW_IMMEDIATELY;
    }

    this.update.emit({
      type: this.activeOption.type,
      value: this.activeOption.value
        ? Number(this.activeOption.value)
        : undefined,
    });

    this.activeOption = this.getDefaultTimingOption();
  }

  setTimingOption(timingOption: TimingOption) {
    this.activeOption.type = timingOption;
  }

  trackByOption(index: number, option: { label: string; value: TimingOption }) {
    return option.value;
  }
}
