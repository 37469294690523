<h5 class="title mb-1" *ngIf="title">{{ title }}</h5>
<div
  class="uploader flex items-center gap-2.5"
  (click)="fileInput.click()"
  [attr.test]="isUploading"
  [attr.currentFile]="currentFile">
  <ng-container
    *ngIf="
      currentFile !== null && currentFile.url !== '' && !isUploading;
      else uploader
    ">
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.83301 1.66663H2.99967C2.55765 1.66663 2.13372 1.84222 1.82116 2.15478C1.5086 2.46734 1.33301 2.89127 1.33301 3.33329V16.6666C1.33301 17.1087 1.5086 17.5326 1.82116 17.8451C2.13372 18.1577 2.55765 18.3333 2.99967 18.3333H12.9997C13.4417 18.3333 13.8656 18.1577 14.1782 17.8451C14.4907 17.5326 14.6663 17.1087 14.6663 16.6666V7.49996M8.83301 1.66663L14.6663 7.49996M8.83301 1.66663V7.49996H14.6663"
        stroke="#636B75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>

    <div>
      <p>{{ currentFile.name }}</p>
      <p class="survey-edit-asset-area-uploader-text text-left">
        {{ currentFile.size }}
      </p>
    </div>
  </ng-container>
  <ng-template #uploader>
    <button
      type="button"
      class="survey-edit-asset-area-uploader flex items-center gap-3">
      <svg
        *ngIf="currentFile != null && !isUploading; else defaultIcon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.5 11.5V14.8333C16.5 15.2754 16.3244 15.6993 16.0118 16.0118C15.6993 16.3244 15.2754 16.5 14.8333 16.5H3.16667C2.72464 16.5 2.30072 16.3244 1.98816 16.0118C1.67559 15.6993 1.5 15.2754 1.5 14.8333V11.5M13.1667 5.66667L9 1.5M9 1.5L4.83333 5.66667M9 1.5V11.5"
          stroke="#636B75"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <ng-template #defaultIcon>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0003 1.66675V5.00008M10.0003 15.0001V18.3334M4.10866 4.10841L6.46699 6.46675M13.5337 13.5334L15.892 15.8917M1.66699 10.0001H5.00033M15.0003 10.0001H18.3337M4.10866 15.8917L6.46699 13.5334M13.5337 6.46675L15.892 4.10841"
            stroke="#636B75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </ng-template>
      <div>
        <p *ngIf="label" class="text-left">{{ label }}</p>
        <p
          class="survey-edit-asset-area-uploader-text text-left"
          [ngClass]="label ? 'custom-sm-txt' : ''">
          {{ uploadedFile?.name ?? description }}
        </p>
        <ng-container *ngIf="isUploading">
          <p class="survey-edit-asset-area-uploader-text text-left">
            <ng-container *ngIf="isProcessing; else progress">
              Upload finished - processing data...
            </ng-container>
            <ng-template #progress>
              {{ uploadingFileSizeTitle }} - {{ uploadingProgress }}%
            </ng-template>
          </p>
        </ng-container>
      </div>
    </button>
  </ng-template>
</div>

<input
  #fileInput
  hidden
  type="file"
  accept="{{ type.join(',') }}"
  (click)="clearFileInput($event)"
  (change)="handleUploadOperation($event)" />
