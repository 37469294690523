import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-hidden-content',
  templateUrl: './hidden-content.component.html',
  styleUrls: ['./hidden-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenContentComponent {
  isOpened = false;

  @Input() label?: string;

  constructor() {}

  toggle() {
    this.isOpened = !this.isOpened;
  }
}
