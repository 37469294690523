export enum CohortType {
  MIXPANEL = 'mixpanel',
  SLACK = 'slack',
  SEGMENT_SOURCE = 'segment_source',
  SEGMENT_DES = 'segment_destination',
  ZAPIER = 'zapier',
  AMPLITUDE = 'amplitude',
  GOOGLE_SHEET = 'google_sheet',
  BRAZE = 'braze',
  ITERABLE = 'iterable',
  HEAP = 'heap',
  HUB_SPOT = 'hub_spot',
  EMAIL = 'email',
  MANUAL = 'manual',
  DYNAMIC = 'dynamic',
}
