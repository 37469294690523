import {
  animate,
  style,
  state,
  trigger,
  transition,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      transition('* => show', [animate('300ms')]),
      transition('show <=> hide', [animate('300ms')]),
    ]),
  ],
})
export class ModalComponent implements OnChanges {
  private timeout: NodeJS.Timeout | null = null;

  public appearanceOpenSub = new BehaviorSubject(false);
  public animationOpenSub = new BehaviorSubject(false);

  @Input() width = '400px';
  @Input() opened = false;
  @Input() modalTitle?: string;
  @Input() usePadding = true;

  @Output() hide = new EventEmitter();

  constructor() {}

  ngOnChanges({ opened }: SimpleChanges) {
    if (opened?.currentValue !== undefined) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      const isOpened = opened.currentValue;

      if (isOpened) {
        this.appearanceOpenSub.next(true);
      } else {
        this.timeout = setTimeout(() => {
          this.appearanceOpenSub.next(false);
        }, 400);
      }

      setTimeout(() => {
        this.animationOpenSub.next(isOpened);
      }, 0);
    }
  }

  public handleClick(event: MouseEvent) {
    event.stopPropagation();
  }

  public handleClose() {
    this.animationOpenSub.next(false);

    setTimeout(() => {
      this.hide.emit();
    }, 300);
  }
}
