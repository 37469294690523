import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from 'src/environments/environment';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { ACCOUNT, LOGIN_URL, SIGNUP, SECRET_PAGE } from './constants/routes';
import { LoginGuard } from './guards/login/login.guard';
import { SessionGuard } from './guards/session/session.guard';

const routes: Routes = [
  {
    path: LOGIN_URL.substr(1),
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: SIGNUP.substr(1),
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./modules/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: ACCOUNT.substr(1),
    loadChildren: () =>
      import('./modules/account/account.module').then(m => m.AccountModule),
  },
  {
    path: SECRET_PAGE.substr(1),
    loadChildren: () =>
      import('src/app/modules/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: '',
    canActivate: [SessionGuard],
    loadChildren: () =>
      import('./modules/private-access/private-access.module').then(
        m => m.PrivateAccessModule
      ),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash ? true : false,
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
