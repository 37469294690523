<div class="w-full flex flex-col gap-2.5">
  <ng-container
    *ngFor="
      let filter of triggerFilters;
      let i = index;
      trackBy: trackByFilter
    ">
    <div class="flex flex-wrap items-center gap-2">
      <p class="text">if</p>
      <ng-container [ngSwitch]="filter.filterType">
        <app-event-filter
          *ngSwitchCase="FilterType.Event"
          [allowAddingNewEvents]="true"
          [hideEventFilterType]="true"
          [event]="toEventFilterType(filter)"
          (update)="updateFilter($event)"
          (remove)="removeFilter(filter.id, i)"
          (finish)="applyFilter(filter.id, $event)"></app-event-filter>
        <app-page-filter
          *ngSwitchCase="FilterType.Page"
          [filter]="filter"
          (update)="updateFilter($event)"
          (remove)="removeFilter(filter.id, i)"
          (finish)="applyFilter(filter.id, $event)"></app-page-filter>
      </ng-container>
      <p class="text">then</p>
      <app-timing-options-picker
        [value]="filter.timingOption"
        (update)="setTimingOption($event, i)"></app-timing-options-picker>
    </div>
  </ng-container>
  <app-add-filter-button
    buttonTitle="Add condition"
    dropdownTitle="TRIGGERS"
    [filters]="allowedFilters"
    (add)="addFilter($event)">
  </app-add-filter-button>
</div>
