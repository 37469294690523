import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SURVEYS } from 'src/app/constants/routes';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  public SURVEYS = SURVEYS;

  constructor(public sessionService: SessionService) {}
}
