import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

import { FilterType } from '../enums';

@Component({
  selector: 'app-add-filter-button',
  templateUrl: './add-filter-button.component.html',
  styleUrls: ['./add-filter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFilterButtonComponent {
  FilterType = FilterType;

  @Input() buttonTitle = 'Add filter';
  @Input() dropdownTitle?: string;
  @Input() filters: {
    title: string;
    description?: string;
    type: FilterType;
  }[] = [];

  @Output() add: EventEmitter<FilterType> = new EventEmitter();

  constructor() {}
}
