<app-sidepanel [isOpen]="isOpened" (hide)="hide.emit()">
  <form
    ngNativeValidate
    #form="ngForm"
    class="side-panel p-5"
    (submit)="handleSubmit(form)">
    <div class="flex items-center justify-between mb-5">
      <h3 class="side-panel-title">{{ title }}</h3>
      <div class="flex items-center gap-2.5">
        <app-button
          width="80px"
          color="primary"
          variant="link"
          (press)="hide.emit()"
          >Cancel</app-button
        >
        <app-button
          [showLoader]="isSaving"
          width="80px"
          variant="filled"
          buttonType="submit"
          color="primary">
          {{ isSaving ? 'Saving...' : 'Save' }}
        </app-button>
      </div>
    </div>
    <div class="flex flex-col gap-2.5">
      <ng-content></ng-content>
      <div *ngIf="allowDelete && delete.observers.length" class="mt-2.5">
        <app-hidden-content label="Danger zone">
          <app-button
            color="secondary"
            variant="filled"
            [showLoader]="isDeleting"
            (press)="delete.emit()">
            <ng-container *ngIf="isDeleting; else content"
              >Deleting...</ng-container
            >
            <ng-template #content>
              <app-icon-delete size="12px"></app-icon-delete>
              <span class="side-panel-text side-panel-text-danger ml-1.5">
                Delete {{ deleteButtonName }}
              </span>
            </ng-template>
          </app-button>
        </app-hidden-content>
      </div>
    </div>
  </form>
</app-sidepanel>
