<div class="flex items-center gap-2">
  <div
    [class.checkbox-disabled]="disabled"
    class="checkbox checkbox-{{
      checked ? 'checked' : 'unchecked'
    }} flex items-center justify-center">
    <app-icon-check *ngIf="checked"></app-icon-check>
    <input
      [id]="id"
      class="checkbox-hidden"
      [name]="name"
      [required]="required"
      [checked]="checked"
      type="checkbox"
      (change)="handleChange($event)" />
  </div>
  <label
    *ngIf="label"
    class="checkbox-label"
    [for]="id"
    [style.color]="labelColor"
    >{{ label }}</label
  >
</div>
