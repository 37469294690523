import { FilterCondition } from 'src/app/common/enums';

export const FILTER_CONDITIONS = {
  [FilterCondition.Is]: 'is',
  [FilterCondition.IsIn]: 'is in',
  [FilterCondition.IsNotIn]: 'is not in',
  [FilterCondition.IsNot]: 'is not',
  [FilterCondition.Between]: 'between',
  [FilterCondition.IsEqualTo]: 'is equal to',
  [FilterCondition.IsNotEqualTo]: 'is not equal to',
  [FilterCondition.IsLessThan]: 'is less than',
  [FilterCondition.IsMoreThan]: 'is more than',
  [FilterCondition.IsAtLeast]: 'is at least',
  [FilterCondition.IsAtMost]: 'is at most',
  [FilterCondition.IncludesOneOrMoreOf]: 'includes one or more of',
  [FilterCondition.IncludesAllOf]: 'includes all of',
  [FilterCondition.ExcludesOneOrMoreOf]: 'excludes one or more of',
  [FilterCondition.ExcludesAllOf]: 'excludes all of',
  [FilterCondition.Contains]: 'contains',
  [FilterCondition.DoesNotContain]: 'does not contain',
  [FilterCondition.StartsWith]: 'starts with',
  [FilterCondition.EndsWith]: 'ends with',
  [FilterCondition.HasAnyValue]: 'has any value',
  [FilterCondition.IsUnknown]: 'is unknown',
  [FilterCondition.Exactly]: 'exactly',
  [FilterCondition.MoreThan]: 'more than',
  [FilterCondition.LessThan]: 'less than',
  [FilterCondition.Before]: 'before',
  [FilterCondition.On]: 'on',
  [FilterCondition.After]: 'after',
};

export const PAGE_FILTER_OPTIONS = [
  {
    label: 'Page Loaded',
    icon: '<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.8333 0.333252H2.16667C1.24167 0.333252 0.5 1.08325 0.5 1.99992V11.9999C0.5 12.9166 1.24167 13.6666 2.16667 13.6666H13.8333C14.75 13.6666 15.5 12.9166 15.5 11.9999V1.99992C15.5 1.08325 14.7583 0.333252 13.8333 0.333252ZM13.8333 11.9999H2.16667V3.66659H13.8333V11.9999Z" fill="#636B75"/></svg>',
    value: 'page_loaded',
  },
  {
    label: 'Exit Intent',
    icon: '<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.8333 0.333252H2.16667C1.24167 0.333252 0.5 1.08325 0.5 1.99992V11.9999C0.5 12.9166 1.24167 13.6666 2.16667 13.6666H13.8333C14.75 13.6666 15.5 12.9166 15.5 11.9999V1.99992C15.5 1.08325 14.7583 0.333252 13.8333 0.333252ZM13.8333 11.9999H2.16667V3.66659H13.8333V11.9999Z" fill="#636B75"/></svg>',
    value: 'exit_intent',
  },
];
