import { Component, Injector, Input } from '@angular/core';

import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  SubscriptionStatus,
  SubscriptionType,
  PlanType,
} from 'src/app/common/enums';
import { UPDATE_ACTION } from 'src/app/constants/constants';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { SessionService } from 'src/app/services/session/session.service';
import {
  StripeService,
  StripePlan,
} from 'src/app/services/stripe/stripe.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UserSubscriptionService } from 'src/app/services/user-subscription/user-subscription.service';
import { Account } from 'src/app/types/projects';
import { environment } from 'src/environments/environment';

declare const window: Window & {
  Intercom: any;
};

@Component({
  selector: 'app-plans-form',
  templateUrl: './plans-form.component.html',
  styleUrls: ['./plans-form.component.scss'],
})
export class PlansFormComponent extends BaseDirective {
  isFailedModalOpened = false;
  isLoading = true;
  isPaymentButtonVisible = true;
  isWaitingForPayment = false;
  SubscriptionType = SubscriptionType;
  accountPlan: StripePlan | null = null;
  activeAccountId: string | null = null;
  account: Account | null = null;
  selectedPlan: StripePlan | null = null;
  marketingLink = `${environment.marketing_site}/pricing`;
  activeSubscriptionType = SubscriptionType.Annual;
  plans: StripePlan[] = [];

  @Input() upgrade = false;

  constructor(
    public stripeService: StripeService,
    public accountsService: AccountsService,
    public userSubscriptionService: UserSubscriptionService,
    private sessionService: SessionService,
    private toastService: ToastService,
    injector: Injector
  ) {
    super(injector);

    super.addSubscription(
      this.accountsService.accounts$.subscribe(() => {
        this.setAccount();
      })
    );

    super.addSubscription(
      this.stripeService.plans$.subscribe(plans => {
        this.plans = plans;
      })
    );

    super.addSubscription(
      this.sessionService.activeAccountId$.subscribe(activeAccountId => {
        if (this.activeAccountId !== activeAccountId) {
          if (this.activeAccountId) {
            this.accountPlan = null;
            this.togglePlanType(SubscriptionType.Annual);
          }

          this.activeAccountId = activeAccountId;

          this.stripeService.setStripePlan(null);
          this.getCustomerId();
          this.loadPlans();
        }
      })
    );

    super.addSubscription(
      this.stripeService.accountStripePlan$.subscribe(accountStripePlan => {
        this.accountPlan = accountStripePlan;
      })
    );
  }

  get activePlanIndex() {
    return this.selectedPlan?.id
      ? this.plans.findIndex(plan => plan.id === this.selectedPlan?.id)
      : -1;
  }

  get isNewPlanActive() {
    if (this.account) {
      const { subscription_details } = this.account;

      if (this.accountsService.isSubscriptionActive) {
        return subscription_details?.product_id
          ? this.plans.some(plan => plan.id === subscription_details.product_id)
          : true;
      }
      return true;
    }
    return false;
  }

  get accountPlanSubscriptionType() {
    return this.accountPlan
      ? (Object.keys(this.accountPlan.price).find(
          key =>
            this.accountPlan!.price[key]!.id ===
            this.account!.subscription_details!.price_id
        ) as SubscriptionType)
      : null;
  }

  get plan() {
    return this.selectedPlan ?? this.accountPlan;
  }

  get savedAmount() {
    if (this.selectedPlan) {
      return (
        (((this.selectedPlan.price[SubscriptionType.Monthly]?.amount ?? 0) -
          (this.selectedPlan.price[SubscriptionType.Annual]?.amount ?? 0) /
            12) /
          100) *
        12
      );
    } else if (this.accountPlan) {
      return (
        (this.accountPlan.price[SubscriptionType.Monthly]?.amount ?? 0) * 12 -
        (this.accountPlan.price[SubscriptionType.Annual]?.amount ?? 0)
      );
    }
    return undefined;
  }

  private async loadPaymentMethods() {
    if (
      this.activeAccountId &&
      this.plan?.price[this.activeSubscriptionType].id
    ) {
      return this.stripeService.getPaymentMethods(
        this.activeAccountId,
        this.plan.price[this.activeSubscriptionType].id
      );
    }
    return null;
  }

  private async getCustomerId() {
    if (this.activeAccountId) {
      this.stripeService.getCustomerIdByAccountId(this.activeAccountId);
    }
  }

  private setIsPaymentButtonVisible() {
    const accountPriceId = this.account?.subscription_details?.price_id;
    const selectedPriceId =
      this.selectedPlan?.price[this.activeSubscriptionType]?.id;
    const areSubscriptionsEqual =
      this.accountPlanSubscriptionType === this.activeSubscriptionType;
    const arePricesEqual = selectedPriceId === accountPriceId;

    this.isPaymentButtonVisible = !(
      !this.upgrade &&
      (this.isNewPlanActive ? arePricesEqual : areSubscriptionsEqual)
    );
  }

  private async loadPlans() {
    this.isLoading = true;

    try {
      await this.stripeService.loadPlans(this.activeAccountId);

      await this.setAccount();
    } catch {
      this.setIsPaymentButtonVisible();
    } finally {
      this.isLoading = false;
    }
  }

  private async setAccount() {
    const accounts = await lastValueFrom(
      this.accountsService.accounts$.pipe(take(1))
    );

    this.account =
      accounts.find(a => a.account_id === this.activeAccountId) ?? null;
    this.isFailedModalOpened =
      this.account?.subscription_details?.status ===
      SubscriptionStatus.PaymentFailed;

    if (this.accountsService.isSubscriptionActive) {
      await this.stripeService.loadPlanById(
        this.account!.subscription_details!.product_id!
      );

      if (this.accountPlanSubscriptionType) {
        this.togglePlanType(this.accountPlanSubscriptionType);
      }
    }

    if (this.plans.length) {
      if (this.upgrade) {
        const defaultPlanType = await lastValueFrom(
          this.userSubscriptionService.defaultPlanType$.pipe(take(1))
        );

        this.setPlan(
          (defaultPlanType &&
            this.plans.find(
              p => p.name.toLowerCase() === defaultPlanType.toLowerCase()
            )) ||
            this.plans[this.plans.length - 1]
        );
      } else if (this.account && this.accountsService.isSubscriptionActive) {
        const plan =
          this.account.subscription_details &&
          this.plans.find(
            plan => plan.id === this.account!.subscription_details!.product_id
          );
        if (plan) {
          this.setPlan(plan);
        }
      } else {
        this.setPlan(this.plans[0]);
      }
    }
  }

  private async goToPaymentPage() {
    if (
      this.activeAccountId &&
      this.plan?.price[this.activeSubscriptionType]?.id
    ) {
      const data = {
        redirect_url: window.location.href,
        account_id: this.activeAccountId,
        price_id: this.plan.price[this.activeSubscriptionType].id,
      };

      try {
        if (
          this.accountsService.isSubscriptionActive &&
          !this.isNewPlanActive &&
          this.account?.subscription_details?.product_id
        ) {
          const { result } =
            await this.userSubscriptionService.loadSubscriptionPlansByProductId(
              this.account.subscription_details.product_id
            );

          const plan = result.find(
            plan => plan.subscription === this.activeSubscriptionType
          );

          if (plan) {
            data.price_id = plan.price_id;
          }
        }

        const { result } = await this.stripeService.createSession(data);

        if (result) {
          window.location.href = result.payment_url;
        }
      } catch (error) {
        this.toastService.parseErrorAndShow(error);
      }
    }
  }

  private async payWithoutCheckout() {
    if (
      this.activeAccountId &&
      this.plan?.price[this.activeSubscriptionType]?.id
    ) {
      const { result } = await this.stripeService.subscribeWithoutCheckout(
        this.activeAccountId,
        this.plan.price[this.activeSubscriptionType].id,
        this.isNewPlanActive
          ? (this.plan.name.toLowerCase() as PlanType)
          : PlanType.Custom
      );

      await this.accountsService.updateActiveAccount(result);

      await this.setAccount();
    }
  }

  async getUserStripeDashboardUrl() {
    const canUpdate =
      await this.accountsService.hasAccessToAction(UPDATE_ACTION);

    if (this.activeAccountId && canUpdate) {
      try {
        const { result } =
          await this.userSubscriptionService.getUserDasboardUrl(
            this.activeAccountId,
            window.location.href
          );

        window.location.href = result.url;
      } catch (error) {
        this.toastService.parseErrorAndShow(error);
      }
    } else {
      this.toastService.showError(
        'Based on your role, you do not have access to this action.'
      );
    }
  }

  showIntercom() {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      window.alert("You can't use Intercom in this mode");
    }
  }

  async pay() {
    this.isWaitingForPayment = true;

    try {
      if (this.activeAccountId) {
        const payment = await this.loadPaymentMethods();

        if (payment?.canDoWithoutChekoutSubscription) {
          await this.payWithoutCheckout();
        } else if (payment?.checkout) {
          await this.goToPaymentPage();
        } else {
          this.toastService.parseErrorAndShow('Unknown payment error.');
        }

        this.userSubscriptionService.hideUpdatePlanModal();
      }
    } catch (error) {
      this.toastService.parseErrorAndShow(error);
    } finally {
      this.isWaitingForPayment = false;
    }
  }

  setPlan(plan: StripePlan) {
    this.selectedPlan = plan;

    this.setIsPaymentButtonVisible();
  }

  togglePlanType(planType: SubscriptionType) {
    this.activeSubscriptionType = planType;

    this.setIsPaymentButtonVisible();
  }

  trackByPlan(index: number, plan: StripePlan) {
    return plan.id;
  }

  hideFailedModal() {
    this.isFailedModalOpened = false;
  }
}
