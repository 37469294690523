import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleSelectComponent {
  @Input() dropdownWidth?: string = '196px';
  @Input() value?: string | number;
  @Input() elements: {
    label: string;
    value: string | number;
  }[] = [];

  @Output() update: EventEmitter<string | number> = new EventEmitter();

  constructor() {}

  get selectedLabel() {
    return this.elements.find(element => element.value === this.value)?.label;
  }

  public onChange(model: { label: string; value: string | number }) {
    this.update.emit(model.value);
  }

  public trackByFn(
    index: number,
    element: { label: string; value: string | number }
  ) {
    return element.value;
  }
}
