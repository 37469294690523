<app-select
  class="w-full"
  title="User"
  placeholder="Choose cohort"
  [required]="true"
  [useSearch]="true"
  [isLoading]="isLoading"
  [selected]="selected ? [selected] : []"
  [elements]="(cohortsList$ | async) || []"
  (update)="select($event)">
  <ng-content></ng-content>
</app-select>
