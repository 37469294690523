<svg
  #icon
  class="cursor-pointer"
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.16797 4.83335H9.83464V6.50002H8.16797V4.83335ZM8.16797 8.16669H9.83464V13.1667H8.16797V8.16669ZM9.0013 0.666687C4.4013 0.666687 0.667969 4.40002 0.667969 9.00002C0.667969 13.6 4.4013 17.3334 9.0013 17.3334C13.6013 17.3334 17.3346 13.6 17.3346 9.00002C17.3346 4.40002 13.6013 0.666687 9.0013 0.666687ZM9.0013 15.6667C5.3263 15.6667 2.33464 12.675 2.33464 9.00002C2.33464 5.32502 5.3263 2.33335 9.0013 2.33335C12.6763 2.33335 15.668 5.32502 15.668 9.00002C15.668 12.675 12.6763 15.6667 9.0013 15.6667Z"
    fill="#2D4EFF" />
</svg>

<div
  #tooltip
  role="tooltip"
  class="tooltip-content inline-block absolute invisible z-10 shadow-sm opacity-0 transition-opacity duration-300 properties-tooltip"
  [style.width]="width">
  <h5 *ngIf="title" class="tooltip-content-title mb-2">{{ title }}</h5>
  <ng-content></ng-content>
</div>
