import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { SubscriptionStatus, UserType } from 'src/app/common/enums';
import {
  API_URL_ACCOUNTS_PREFIX,
  API_URL_ACCOUNT_INFO_PREFIX,
} from 'src/app/constants/constants';
import { GET_PROJECTS } from 'src/app/endpoints/endpoints';
import { OneflowStorageService } from 'src/app/services/oneflow-storage/oneflow-storage.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { SessionService } from 'src/app/services/session/session.service';
import { AccountsProjectsResponse, Account } from 'src/app/types/projects';

export const PAID_STATUSES = [
  SubscriptionStatus.Open,
  SubscriptionStatus.Active,
  SubscriptionStatus.Paid,
  SubscriptionStatus.Complete,
];

export type OrganizationAccount = {
  account_id: string;
  company_size: string | null;
  language: string | null;
  created_on: number;
  name: string;
  trial_start: number;
  usage_summary: {
    [key: string]: {
      details: {
        key: string;
        name: string;
        description: string;
        sort_key: number;
        default_value: boolean | number;
        type: 'boolean' | 'number';
      };
      quota: boolean | number;
      usage?: number;
    };
  };
};

export type AccountInfo = {
  account_id: string;
  account_user_id: string;
  created_on: number;
  role: UserType;
  updated_on: number;
};

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends ApiCommonHandler {
  private accountsSub: BehaviorSubject<Account[]> = new BehaviorSubject(
    [] as Account[]
  );
  private accountInfoSub: BehaviorSubject<AccountInfo | null> =
    new BehaviorSubject(null);
  protected organizationAccountSub: BehaviorSubject<OrganizationAccount | null> =
    new BehaviorSubject(null);

  public organizationAccount$ = this.organizationAccountSub.asObservable();
  public accountInfo$ = this.accountInfoSub.asObservable();
  public accounts$ = this.accountsSub.asObservable();

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private oneflowStorageService: OneflowStorageService,
    private permissionService: PermissionService
  ) {
    super();
  }

  get isSubscriptionActive() {
    return this.account?.subscription_details?.status
      ? PAID_STATUSES.includes(this.account?.subscription_details?.status)
      : false;
  }

  get account() {
    const accoundId = this.sessionService.getActiveAccount();

    return (accoundId && this.getAccountById(accoundId)) || ({} as Account);
  }

  getAccountsList() {
    return this.accountsSub.value;
  }

  getAccounts(): Observable<AccountsProjectsResponse> {
    return this.http.get<AccountsProjectsResponse>(
      `${this.getAPIFullUrlByName(GET_PROJECTS)}`
    );
  }

  getAccountById(id: string) {
    return this.accountsSub.value.find(account => account.account_id === id);
  }

  getAccountByProjectId(id: string) {
    return this.accountsSub.value.find(account =>
      account.projects.find(p => p.project_id === id)
    );
  }

  public async updateActiveAccount(account: Account) {
    this.accountsSub.next(
      this.accountsSub.value.map(a =>
        a.account_id === account.account_id ? account : a
      )
    );

    await this.loadOrganizationAccountDetails(account.account_id);
  }

  public updateAccount(data) {
    if (
      this.sessionService.getActiveProject() !== data.project_id ||
      this.sessionService.getActiveAccount() !== data.account_id
    ) {
      const account = this.getAccountById(data.account_id);
      if (account) {
        this.oneflowStorageService.getStorageData(account, data.project_id);
      }
      this.sessionService.setActiveAccount(data.account_id);
      this.sessionService.setActiveProject(data.project_id);
    }
  }

  public setAccounts(accounts: Account[]) {
    this.accountsSub.next(accounts);
  }

  public async getAccountInfo(account_id) {
    const { result } = await lastValueFrom(
      this.http.get<{ result: AccountInfo }>(
        this.getAPIFullUrlByName(
          `${API_URL_ACCOUNTS_PREFIX}/${account_id}/${API_URL_ACCOUNT_INFO_PREFIX}/${account_id}`
        )
      )
    );

    this.accountInfoSub.next(result);

    return result;
  }

  public getAccountInfoUsingUserID(user_id) {
    return this.http.get(
      this.getAPIFullUrlByName(`${API_URL_ACCOUNTS_PREFIX}/user/${user_id}`)
    );
  }

  public clearAccountsData() {
    this.accountsSub.next([]);
    this.organizationAccountSub.next(null);
  }

  public updateAccountLanguage(accountId: string, language: string | null) {
    this.permissionService.validateCreateAndUpdateOperation();

    return lastValueFrom(
      this.http.put<AccountsProjectsResponse>(
        `${this.getAPIFullUrlByName(GET_PROJECTS)}${accountId}`,
        { language }
      )
    );
  }

  public async hasAccessToAction(action: string) {
    const accountInfo = await this.accountInfoSub.value;

    if (accountInfo) {
      if (accountInfo.role === UserType.VIEWER) {
        return false;
      } else if (accountInfo.role === UserType.EDITOR) {
        return action !== 'delete';
      } else {
        return true;
      }
    }
    return false;
  }

  async loadOrganizationAccountDetails(account_id): Promise<void> {
    const { result } = await lastValueFrom(
      this.http.get<{
        result: OrganizationAccount;
        message: string;
        success: boolean;
      }>(this.getAPIFullUrlByName(`${GET_PROJECTS}${account_id}`))
    );

    this.organizationAccountSub.next(result);
  }

  updateAccountDetails(account_id, data): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.put(
      this.getAPIFullUrlByName(`${GET_PROJECTS}${account_id}`),
      data
    );
  }

  setAccountDetails(account: OrganizationAccount) {
    this.organizationAccountSub.next(account);
  }

  getAccountDetails() {
    return this.organizationAccountSub.value;
  }

  hasAccessTo(entitlement: string) {
    return !!this.organizationAccountSub.value?.usage_summary?.[entitlement]
      ?.quota;
  }
}
