import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-segment-element',
  templateUrl: './segment-element.component.html',
  styleUrls: ['./segment-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentElementComponent {
  @Input() active = false;
  @Input() disabled: boolean = false;

  constructor() {}
}
