<svg
  width="15"
  height="15"
  viewBox="0 0 15 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.9375 9.375C7.83598 9.375 9.375 7.83598 9.375 5.9375C9.375 4.03902 7.83598 2.5 5.9375 2.5C4.03902 2.5 2.5 4.03902 2.5 5.9375C2.5 7.83598 4.03902 9.375 5.9375 9.375Z"
    stroke="black"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M9.0625 12.5C10.961 12.5 12.5 10.961 12.5 9.0625C12.5 7.16402 10.961 5.625 9.0625 5.625C7.16402 5.625 5.625 7.16402 5.625 9.0625C5.625 10.961 7.16402 12.5 9.0625 12.5Z"
    stroke="black"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
