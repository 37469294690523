import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { logout } from 'src/app/common/utils';
import { AUTH_TOKEN } from 'src/app/constants/constants';
import { LOGIN_URL } from 'src/app/constants/routes';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { OneflowStorageService } from 'src/app/services/oneflow-storage/oneflow-storage.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SocketClientService } from 'src/app/services/socket/socket-client.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({ providedIn: 'root' })
export class ApiInterceptorInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    private router: Router,
    public domsantinzier: DomSanitizer,
    private accountsService: AccountsService,
    private OneflowStorageService: OneflowStorageService,
    private segmentService: SegmentService,
    private socket: SocketClientService,
    private sessionService: SessionService,
    private userService: UserService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.storage.getItem(AUTH_TOKEN);

    if (token && !request.headers.has('Authorization')) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    return next.handle(request).pipe(
      map(event => {
        return event;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          logout(
            this.accountsService,
            this.storage,
            this.OneflowStorageService,
            this.segmentService,
            this.socket,
            this.sessionService,
            this.userService
          );

          this.router.navigateByUrl(LOGIN_URL);

          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
