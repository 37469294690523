import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appNumberValidation][ngModel],[appNumberValidation][FormControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NumberValidationDirective,
      multi: true,
    },
  ],
})
export class NumberValidationDirective {
  @Input() public optional: boolean = false;
  constructor() {}
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (this.optional)
      return !control.value || control.value === ''
        ? null
        : Number.isInteger(parseInt(control.value))
        ? null
        : { numberError: true };
    else
      return Number.isInteger(parseInt(control.value))
        ? null
        : { numberError: true };
  }
}
