<app-select
  class="w-full"
  title="Trait"
  placeholder="Find trait"
  [required]="true"
  [useSearch]="true"
  [isLoading]="isLoading"
  [loadMoreLoader]="loadMoreLoader"
  [selected]="
    selected?.value
      ? [getTraitValue(selected!.value!, selected!.isDefaultTrait!)]
      : []
  "
  [elements]="(traitsList$ | async) || []"
  (update)="select($event)"
  (handleSearch)="setSearchString($event)"
  (handleKeyPress)="allowAddingCustom && handleKeyPress($event)"
  (loadMore)="!allTraitsLoaded && loadMoreTraits()">
  <ng-container
    topElement
    *ngIf="allowAddingCustom && (searchString$ | async); let searchString">
    <span
      *ngIf="searchString !== selectedLabel"
      class="block w-full"
      (click)="addCustom()">
      Use: "{{ searchString }}"
    </span>
  </ng-container>
</app-select>
