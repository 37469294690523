import {
  Component,
  ChangeDetectionStrategy,
  Injector,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { openFilterById } from 'src/app/common/utils';
import { BaseDirective } from 'src/app/directives/base/base.directive';

import { FilterType, FilterOperator } from '../enums';
import {
  CohortFilter,
  TraitFilter,
  Filter,
  EventFilter,
  FilterGroupFilter,
  EventPropertyFilter,
} from '../types';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent extends BaseDirective implements OnChanges {
  private invalidFilters: { [key: string]: boolean } = {};

  allowedFilters = [
    {
      title: 'Trait',
      type: FilterType.Trait,
    },
    {
      title: 'Event',
      type: FilterType.Event,
    },
    {
      title: 'Cohort',
      type: FilterType.Cohort,
    },
    {
      title: 'Filter Group',
      description: 'A group to nest more filters',
      type: FilterType.FilterGroup,
    },
  ];
  allowedFilterGroupFilters = [
    {
      title: 'Cohort',
      type: FilterType.Cohort,
    },
    {
      title: 'Event',
      type: FilterType.Event,
    },
    {
      title: 'Trait',
      type: FilterType.Trait,
    },
  ];
  operator?: FilterOperator;
  FilterType = FilterType;
  filters: Filter[] = [];

  @Input() filter?: {
    operator?: FilterOperator;
    filters: Filter[];
  };

  @Output() update: EventEmitter<{
    operator?: FilterOperator;
    filters: Filter[];
  }> = new EventEmitter();
  @Output() updateValidationStatus: EventEmitter<boolean> = new EventEmitter();

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnChanges({ filter }: SimpleChanges): void {
    if (filter?.currentValue) {
      this.filters = [...filter.currentValue.filters];

      if (filter.currentValue.filters.length > 1) {
        this.operator = filter.currentValue.operator;
      } else {
        this.operator = undefined;
      }
    }
  }

  private updateValidation() {
    this.updateValidationStatus.emit(
      Object.keys(this.invalidFilters).every(key => !this.invalidFilters[key])
    );
  }

  private updateFilters() {
    this.update.emit({
      operator: this.operator,
      filters: this.filters,
    });
    this.updateValidation();
  }

  activateFilters(id: string, isFilterValid: boolean) {
    this.invalidFilters[id] = !isFilterValid;

    this.updateFilters();
  }

  trackByFilter(index: number, filter: Filter) {
    return filter.id;
  }

  addFilter(filterType: FilterType) {
    const filter: Filter = {
      id: uuidv4(),
      filterType,
    };

    this.filters.push(filter);

    if (!this.operator && this.filters.length > 1) {
      this.operator = FilterOperator.AND;
    }

    openFilterById(filter.id);
  }

  updateOperator(operator: FilterOperator) {
    this.operator = operator;

    this.updateFilters();
  }

  removeFilter(id: string, index: number) {
    delete this.invalidFilters[id];

    this.filters = this.filters.filter((f, i) => i !== index);

    if (this.filters.length === 1) {
      this.operator = undefined;
    }

    this.updateFilters();
  }

  updateFilter(updatedFilter: Filter) {
    this.filters = this.filters.map(filter =>
      filter.id === updatedFilter.id ? updatedFilter : filter
    );
  }

  toTraitFilterType(filter: Filter) {
    return filter as TraitFilter;
  }

  toEventPropertyFilterType(filter: Filter) {
    return filter as EventPropertyFilter;
  }

  toCohortFilterType(filter: Filter) {
    return filter as CohortFilter;
  }

  toEventFilterType(filter: Filter) {
    return filter as EventFilter;
  }

  toFilterGroupFilterType(filter: Filter) {
    return filter as FilterGroupFilter;
  }
}
