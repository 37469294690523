<svg
  [attr.width]="size"
  [attr.height]="size"
  attr.viewBox="0 0 {{ 24 }} {{ 24 }}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12 5V19"
    [attr.stroke]="color"
    [attr.stroke-width]="border"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M5 12H19"
    [attr.stroke]="color"
    [attr.stroke-width]="border"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
