import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-slack',
  templateUrl: './icon-slack.component.html',
  styleUrls: ['./icon-slack.component.scss'],
})
export class IconSlackComponent {
  @Input() color = '#F9FAFB';

  constructor() {}
}
