/* eslint-disable prettier/prettier */
import { EventEmitter, Injectable } from '@angular/core';

import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { ProjectSettingService } from 'src/app/services/project-setting/project-setting.service';
import { SmartAudienceService } from 'src/app/services/smart-audience/smart-audience.service';
import { SocketClientService } from 'src/app/services/socket/socket-client.service';
import { SurveyQuestionService } from 'src/app/services/survey-question/survey-question.service';
import { Account, ProjectPushNotification } from 'src/app/types/projects';
import { SurveyGroup } from 'src/app/types/surveys-list';

@Injectable({
  providedIn: 'root',
})
export class OneflowStorageService {
  private activeProjectId: string | null = null;

  private waitingForTestConnectionSub: BehaviorSubject<null | boolean> =
    new BehaviorSubject(null);

  private sandboxOptionsSub: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private surveysListSub: BehaviorSubject<SurveyGroup[]> = new BehaviorSubject(
    []
  );
  private surveysListLoadingSub = new BehaviorSubject(true);

  public project_setting: any = null;
  public current_active_project: any;
  public static survey_reload_data: EventEmitter<any> = new EventEmitter();
  public projectCohortsSub: EventEmitter<any> = new EventEmitter();
  public plan_fetched = false;
  public project_events_list: Array<any> | null = null;
  public userCustomProperties: Array<any> | null = null;
  public project_events_list_all: Array<{ _id: string }> | null = null;

  public waitingForTestConnection$ =
    this.waitingForTestConnectionSub.asObservable();
  public sandboxOptions$ = this.sandboxOptionsSub.asObservable();
  public surveysList$ = this.surveysListSub.asObservable();
  public surveysListLoading$ = this.surveysListLoadingSub.asObservable();
  public project_cohorts_list: Array<any> | null = null;

  constructor(
    private surveyService: SurveyQuestionService,
    private projectSetting: ProjectSettingService,
    private socket: SocketClientService,
    private smartAudienceService: SmartAudienceService
  ) {
    OneflowStorageService.survey_reload_data.subscribe(res => {
      this.setSurveysList([]);

      this.updateSurvey();
      // this.getProjectSetting();
    });

    SocketClientService.user_custom_properties.subscribe(list => {
      this.userCustomProperties = list;
    });

    SocketClientService.project_events_list.subscribe(list => {
      this.project_events_list = list;
    });

    this.projectSetting.pushNotificationConfig$.subscribe(
      (config: ProjectPushNotification[]) => {
        if (this.project_setting && config.length > 0) {
          this.project_setting.push_notification_enabled = true;
        }
      }
    );
  }
  getPorjectEventsList() {
    return this.project_events_list;
  }

  async getProjectCohorts(project_id) {
    try {
      let data = await lastValueFrom(
        this.projectSetting.getProjectUserCohorts(project_id)
      );
      this.project_cohorts_list = [];
      if (data) {
        data.result = data.result.map(({ name }) => name);
        this.project_cohorts_list = data.result;
      }
      this.projectCohortsSub.emit(this.project_cohorts_list);
    } catch (e) {
      this.project_cohorts_list = [];
      this.projectCohortsSub.emit(this.project_cohorts_list);
    }
  }

  setSurveysList(list: SurveyGroup[]) {
    this.surveysListSub.next(list);
  }

  setProjectAnalytics(waiting: boolean | null) {
    this.waitingForTestConnectionSub.next(waiting);
  }

  updateSurvey() {
    this.getSurvey();
  }

  async getSurvey() {
    this.surveysListLoadingSub.next(true);

    try {
      const { surveys, project_analytics } = await lastValueFrom(
        this.surveyService.getSurveys(this.activeProjectId)
      );

      this.setProjectAnalytics(project_analytics);
      this.setSurveysList(surveys);
    } finally {
      this.surveysListLoadingSub.next(false);
    }
  }

  async getProjectEvents(project_id) {
    const events =
      await this.smartAudienceService.getAudienceDataEvents(project_id);

    this.project_events_list_all = events;
  }

  projectEvents() {
    return this.project_events_list_all;
  }

  triggerEventSessionSocket(event_name) {
    this.socket.getEventSessions(this.activeProjectId, event_name);
  }

  triggerUserPropertySocket(isAdvanceFilter = true) {
    if (isAdvanceFilter === false) {
      this.socket.triggerUserCustomPropertiesEvent({
        project_id: this.activeProjectId,
      });
    }

    this.getProjectCohorts(this.activeProjectId);
  }

  getUserCustomProperties() {
    return this.userCustomProperties;
  }

  /*================USER CURRENT SUBSCRIPTION PLAN END======*/
  /*===========*/
  triggerEventListEvent({ filter_start_time = null, filter_end_time = null }) {
    if (this.activeProjectId) {
      this.socket.eventsList(
        this.activeProjectId,
        filter_start_time,
        filter_end_time
      );
    }
  }
  /*=========SETTING STORAGE DATA===============*/
  getStorageData(account: Account, projectId: string) {
    this.activeProjectId = projectId;

    this.setSurveysList([]);
    this.socket.sdkIntegratedEvent(this.activeProjectId);
    this.getProjectEvents(this.activeProjectId);
    this.getProjectCohorts(this.activeProjectId);
    this.project_events_list = null;
    this.getSurvey();
    this.getProjectSetting(account.account_id);
    this.current_active_project = account;
    // console.log(data,"=============>>>>>>")
  }

  getProjectSettingData() {
    return this.project_setting;
  }
  setProjectSettingData(data) {
    this.project_setting = data;
  }
  /*==============PROJECT SETTING GET ===================*/
  getProjectSetting(accountId: string) {
    this.projectSetting
      .getProjectSurveyAppearance(accountId, this.activeProjectId)
      .subscribe(data => {
        this.project_setting = data.result;
        this.sandboxOptionsSub.next(
          this.project_setting.sandbox
            ? this.project_setting.sandbox.active
            : false
        );
      });
  }
  updateSandboxOption(option: boolean) {
    this.sandboxOptionsSub.next(option);
  }

  isPushNotificationEnabled() {
    return this.project_setting.push_notification_enabled;
  }

  DestoryStorageData() {
    this.setProjectAnalytics(null);
    this.setSurveysList([]);
    this.project_events_list = null;
  }

  DestoryStorageDataLogout() {
    this.setProjectAnalytics(null);
    this.setSurveysList([]);
    this.project_setting = null;
    this.userCustomProperties = null;
    this.project_events_list = null;
  }
}
