<a
  *ngIf="routerLink || href; else buttonElement"
  class="link link-{{ variant }}"
  [routerLink]="routerLink"
  [href]="href"
  [target]="routerLink ? '_self' : '_blank'">
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</a>

<ng-template #buttonElement>
  <button
    type="button"
    class="link link-{{ variant }} text-{{ align }}"
    (click)="press.emit($event)">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </button>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
