import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject, of, lastValueFrom } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { CohortType } from 'src/app/common/enums';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { PromiseService } from 'src/app/services/promise/promise.service';
import { UserFilter } from 'src/app/types';

export enum DeviceType {
  MOBILE = 'Mobile',
  TABLET = 'Tablet',
  LAPTOP = 'Laptop',
  DESKTOP = 'Desktop',
}

export type Cohort = {
  _id: string;
  name: string;
  source: CohortType;
  filter?: UserFilter;
  created_by: string;
  project_id: string;
  description: string | null;
};

export type ProjectUser = {
  _id: string;
  last_surveyed: {
    created_at?: number;
    updated_at?: number;
  } | null;
  responses: {
    count: number;
  };
  traits: {
    [key: string]: any;
    name?: string;
    email?: string;
  } | null;
  updated_at: number;
  user_id: string;
};

export type UserEvent = {
  _id: string | null;
  data: {
    created_at: number;
    deleted: boolean;
    deleted_at: number | null;
    event: string;
    event_date: string;
    project_id: string;
    properties: {
      [key: string]: any;
    };
    schema_version: number;
    timestamp: number;
    updated_at: number;
    user_id: string;
    __v: number;
    _id: string;
  }[];
};

export type Survey = {
  _id: string;
  survey_id: string;
  survey_response_id: string;
  created: number;
  survey: {
    _id: string;
    icon?: string;
    name: string;
  };
};

export type ProjectUserInfo = {
  context: {
    app: {
      version: string;
      build: string;
      _id: string;
    };
    device: {
      manufacturer: string;
      model: string;
      _id: string;
    };
    library: {
      name: string;
      version: string;
      _id: string;
    };
    location: {
      country: string | null;
      region: string | null;
      city: string | null;
      _id: string;
    };
    network: {
      carrier: string;
      wifi: boolean;
      _id: string;
    };
    os: {
      name: string;
      version: string;
      _id: string;
    };
    screen: {
      width: number;
      height: number;
      type: DeviceType;
      _id: string;
    };
  };
  created_at: number;
  survey_list: Survey[];
  traits: {
    [key: string]: any;
    name?: string;
    email?: string;
  } | null;
  segments: {
    account_id: string;
    field_name: string;
    field_value: string;
    project_id: string;
  }[];
  updated_at: number;
  user_id: string;
  _id: string;
};

type GetUsersWithFilterBody = {
  limit: number;
  project_id: string;
  offset: number;
  search?: string;
  cohort_id?: string;
  filters?: UserFilter;
};

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ApiCommonHandler {
  private usersToNewManualCohort: string[] = [];
  private loadUsersSubject$: Subject<GetUsersWithFilterBody> = new Subject();
  private updateCohortModalSub: BehaviorSubject<{
    isOpened: boolean;
    cohort?: Cohort;
  }> = new BehaviorSubject({ isOpened: false });
  private deleteCohortModalSub: BehaviorSubject<{
    isOpened: boolean;
    cohort?: Cohort;
  }> = new BehaviorSubject({ isOpened: false });
  private isManualCohortModalOpenedSub = new BehaviorSubject(false);
  private isImportUsersModalOpenedSub = new BehaviorSubject(false);
  private cohortsSub: BehaviorSubject<Cohort[]> = new BehaviorSubject([]);
  private usersSub: BehaviorSubject<{
    isLoading: boolean;
    data: ProjectUser[];
    skip: number;
    take: number;
    filtered: number;
    total: number;
  }> = new BehaviorSubject({
    isLoading: false,
    data: [],
    skip: 0,
    take: 50,
    filtered: 0,
    total: 0,
  });

  public updateCohortModal$ = this.updateCohortModalSub.asObservable();
  public deleteCohortModal$ = this.deleteCohortModalSub.asObservable();
  public isImportUsersModalOpened$ =
    this.isImportUsersModalOpenedSub.asObservable();
  public isManualCohortModalOpened$ =
    this.isManualCohortModalOpenedSub.asObservable();
  public cohorts$ = this.cohortsSub.asObservable();
  public users$ = this.usersSub.asObservable();

  constructor(
    private http: HttpClient,
    private promiseService: PromiseService,
    private permissionService: PermissionService
  ) {
    super();

    this.runUsersSubject();
  }

  private runUsersSubject() {
    this.loadUsersSubject$
      .pipe(
        switchMap(body =>
          this.http.post<{
            message: string;
            result: {
              allCount: number;
              filteredCount: number;
              users: ProjectUser[];
            };
            success: number;
          }>(this.getAPIFullUrlByName('project/filter-v2'), body)
        ),
        catchError(() => {
          this.usersSub.next({
            ...this.usersSub.value,
            isLoading: false,
          });
          return of(null);
        })
      )
      .subscribe(
        (
          res: {
            result: {
              allCount: number;
              filteredCount: number;
              users: ProjectUser[];
            };
          } | null
        ) => {
          if (res) {
            this.usersSub.next({
              ...this.usersSub.value,
              isLoading: false,
              data: [...this.usersSub.value.data, ...res.result.users],
              filtered: res.result.filteredCount,
              total: res.result.allCount,
            });
          } else if (this.loadUsersSubject$.observers.length === 0) {
            this.loadUsersSubject$.complete();
            this.loadUsersSubject$ = new Subject();

            this.runUsersSubject();
          }
        }
      );
  }

  public getAllCohorts() {
    return this.cohortsSub.value;
  }

  public updateUsersSkipCount() {
    this.usersSub.next({
      ...this.usersSub.value,
      skip: this.usersSub.value.skip + this.usersSub.value.take,
    });
  }

  public getProjectUsers(params: {
    limit: number;
    project_id: string;
    offset: number;
    search?: string;
    cohort_id?: string;
  }) {
    return lastValueFrom(
      this.http.get<{
        message: string;
        result: {
          allCount: number;
          filteredCount: number;
          users: ProjectUser[];
        };
        success: number;
      }>(this.getAPIFullUrlByName('project/search'), { params })
    );
  }

  public getProjectUsersWithFilter(body: {
    project_id: string;
    search?: string;
    cohort_id?: string;
    filters?: UserFilter;
  }) {
    this.usersSub.next({
      ...this.usersSub.value,
      isLoading: true,
      skip: this.usersSub.value.skip,
      take: this.usersSub.value.take,
    });

    this.loadUsersSubject$.next({
      ...body,
      limit: this.usersSub.value.take,
      offset: this.usersSub.value.skip,
    });
  }

  public deleteProjectUsers(ids: string[]) {
    this.permissionService.validateCreateUpdateAndDeleteOperation();

    return lastValueFrom(
      this.http.put<{
        message: string;
        success: number;
      }>(this.getAPIFullUrlByName(`project/user`), { ids })
    );
  }

  public getProjectUserInfo(projectId: string, userId: string) {
    return lastValueFrom(
      this.http.get<{
        message: string;
        result: ProjectUserInfo;
        success: number;
      }>(this.getAPIFullUrlByName(`project/users/${projectId}/${userId}`))
    );
  }

  public async getProjectUserEvents(
    projectId: string,
    userId: string,
    offset: number,
    search?: string
  ) {
    try {
      const { result } = await lastValueFrom(
        this.http.get<{
          message: string;
          result: { events: UserEvent[] };
          success: number;
        }>(
          this.getAPIFullUrlByName(
            `/events/${projectId}/${userId}?offset=${offset}${
              search ? `&search=${search}` : ''
            }`
          )
        )
      );

      return result.events;
    } catch {
      return [];
    }
  }

  public async getCohortsByUserId(projectId: string, userId: string) {
    const { result } = await lastValueFrom(
      this.http.get<{
        message: string;
        result: {
          _id: string;
          name: string;
          members: {
            created_at: number;
            user_id: string;
          };
        }[];
        success: number;
      }>(
        this.getAPIFullUrlByName(`cohorts/user-cohorts/${projectId}/${userId}`)
      )
    );

    return result.map(cohort => ({
      name: cohort.name,
      _id: cohort._id,
      createdAt: cohort.members.created_at,
    }));
  }

  public downloadUsers(
    projectId: string,
    params: {
      only_displayed_columns: boolean;
      search?: string;
      cohort_id?: string;
    }
  ) {
    return lastValueFrom(
      this.http.post<{
        message: string;
        result: {
          url: string;
        };
        success: number;
      }>(this.getAPIFullUrlByName(`project/download/${projectId}`), params)
    );
  }

  public async addManualCohort(model: {
    project_id: string;
    name: string;
    description?: string;
  }) {
    this.permissionService.validateCreateAndUpdateOperation();

    const res = await lastValueFrom(
      this.http.post<{
        message: string;
        result: Cohort;
        success: number;
      }>(this.getAPIFullUrlByName('cohorts'), {
        ...model,
        source: CohortType.MANUAL,
      })
    );

    if (this.usersToNewManualCohort.length) {
      await this.addMembersToCohort(
        res.result._id,
        this.usersToNewManualCohort
      );
    }

    this.cohortsSub.next([res.result, ...this.cohortsSub.value]);

    return res;
  }

  public async addDynamicCohort(model: {
    project_id: string;
    name: string;
    filter: UserFilter;
    description?: string;
  }) {
    this.permissionService.validateCreateAndUpdateOperation();

    const res = await lastValueFrom(
      this.http.post<{
        message: string;
        result: Cohort;
        success: number;
      }>(this.getAPIFullUrlByName('cohorts'), {
        ...model,
        source: CohortType.DYNAMIC,
      })
    );

    this.cohortsSub.next([res.result, ...this.cohortsSub.value]);

    return res;
  }

  public async updateCohort({
    id,
    ...model
  }: {
    id: string;
    filter?: UserFilter;
    project_id?: string;
    name?: string;
    description?: string;
  }) {
    this.permissionService.validateCreateAndUpdateOperation();

    const res = await lastValueFrom(
      this.http.put<{
        message: string;
        result: Cohort;
        success: number;
      }>(this.getAPIFullUrlByName(`cohorts/${id}`), model)
    );

    this.cohortsSub.next(
      this.cohortsSub.value.map(c => (c._id === id ? { ...c, ...model } : c))
    );

    return res;
  }

  public async deleteCohort(id: string) {
    this.permissionService.validateCreateUpdateAndDeleteOperation();

    const res = await lastValueFrom(
      this.http.delete<{
        message: string;
        success: number;
      }>(this.getAPIFullUrlByName(`cohorts/${id}`))
    );

    this.cohortsSub.next(
      this.cohortsSub.value.filter(cohort => cohort._id !== id)
    );

    return res;
  }

  public updateUserTraits(id: string, traits: { [key: string]: any }) {
    this.permissionService.validateCreateAndUpdateOperation();

    return lastValueFrom(
      this.http.put<{
        message: string;
        success: number;
      }>(this.getAPIFullUrlByName(`project/user/traits/${id}`), { traits })
    );
  }

  public async loadCohorts(projectId: string) {
    try {
      const { result } = await this.promiseService.share(
        `usersService.loadCohorts.${projectId}`,
        () => {
          return lastValueFrom(
            this.http.get<{
              message: string;
              result: Cohort[];
              success: number;
            }>(this.getAPIFullUrlByName(`cohorts?project_id=${projectId}`))
          );
        }
      );

      this.cohortsSub.next(result);
    } catch (error) {
      console.error(error);
    }
  }

  public getCohorts() {
    return this.cohortsSub.value;
  }

  public addMembersToCohort(id: string, members: string[]) {
    this.permissionService.validateCreateAndUpdateOperation();

    return lastValueFrom(
      this.http.post<{
        message: string;
        result: Cohort[];
        success: number;
      }>(this.getAPIFullUrlByName(`cohorts/add-members/${id}`), { members })
    );
  }

  public removeMembersFromCohort(id: string, members: string[]) {
    this.permissionService.validateCreateUpdateAndDeleteOperation();

    return lastValueFrom(
      this.http.post<{
        message: string;
        result: Cohort[];
        success: number;
      }>(this.getAPIFullUrlByName(`cohorts/remove-members/${id}`), { members })
    );
  }

  public uploadCsv(file: File) {
    this.permissionService.validateCreateAndUpdateOperation();

    const formData = new FormData();

    formData.set('file', file);

    return lastValueFrom(
      this.http.post<{
        success: number;
        message: string;
        result: string;
      }>(this.getAPIFullUrlByName(`assets/users`), formData)
    );
  }

  public getProjectUserTraits(projectId: string) {
    return this.promiseService.share(
      `usersService.getProjectUserTraits.${projectId}`,
      () => {
        return lastValueFrom(
          this.http.get<{
            result: {
              user_properties: { [key: string]: (string | number | boolean)[] };
            };
          }>(this.getAPIFullUrlByName(`project/user/properties/${projectId}`))
        );
      }
    );
  }

  public importUsers(model: {
    file_url: string;
    user_id_column: string;
    no_header: boolean;
    is_create: boolean;
    project_id: string;
    cohort_id?: string;
  }) {
    return lastValueFrom(
      this.http.post<{
        success: number;
        message: string;
      }>(this.getAPIFullUrlByName(`project/upload`), model)
    );
  }

  public resetUsers() {
    this.usersSub.next({
      isLoading: false,
      data: [],
      skip: 0,
      take: 50,
      total: 0,
      filtered: 0,
    });
  }

  public showManualCohortModal(ids?: string[]) {
    if (ids) {
      this.usersToNewManualCohort = ids;
    }
    this.isManualCohortModalOpenedSub.next(true);
  }

  public hideManualCohortModal() {
    if (this.usersToNewManualCohort.length) {
      this.usersToNewManualCohort = [];
    }
    this.isManualCohortModalOpenedSub.next(false);
  }

  public showImportUsersModal() {
    this.isImportUsersModalOpenedSub.next(true);
  }

  public hideImportUsersModal() {
    this.isImportUsersModalOpenedSub.next(false);
  }

  public showDeleteCohortModal(cohort: Cohort) {
    this.deleteCohortModalSub.next({ cohort, isOpened: true });
  }

  public hideDeleteCohortModal() {
    this.deleteCohortModalSub.next({ isOpened: false });
  }

  public showUpdateCohortModal(cohort: Cohort) {
    this.updateCohortModalSub.next({ cohort, isOpened: true });
  }

  public hideUpdateCohortModal() {
    this.updateCohortModalSub.next({ isOpened: false });
  }
}
