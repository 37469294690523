import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { UserType } from 'src/app/common/enums';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private isPermissionsModalOpenedSub = new BehaviorSubject(false);
  private userRoleSub = new BehaviorSubject<UserType>(UserType.VIEWER);

  public isPermissionsModalOpened$ =
    this.isPermissionsModalOpenedSub.asObservable();
  public userRole$ = this.userRoleSub.asObservable();

  constructor() {}

  get canCreateUpdateAndDelete(): boolean {
    return (
      this.userRoleSub.value === UserType.ADMIN ||
      this.userRoleSub.value === UserType.OWNER
    );
  }

  get canCreateAndUpdate(): boolean {
    return (
      this.userRoleSub.value === UserType.ADMIN ||
      this.userRoleSub.value === UserType.EDITOR ||
      this.userRoleSub.value === UserType.OWNER
    );
  }

  public getUserRole(): UserType {
    return this.userRoleSub.value;
  }

  public setUserRole(role: UserType): void {
    this.userRoleSub.next(role);
  }

  public showPermissionsModal() {
    this.isPermissionsModalOpenedSub.next(true);
  }

  public hidePermissionsModal() {
    this.isPermissionsModalOpenedSub.next(false);
  }

  public validateCreateUpdateAndDeleteOperation() {
    if (this.canCreateUpdateAndDelete) {
      return true;
    }

    this.showPermissionsModal();

    throw Error('You do not have permission to perform this action');
  }

  public validateCreateAndUpdateOperation() {
    if (this.canCreateAndUpdate) {
      return true;
    }

    this.showPermissionsModal();

    throw Error('You do not have permission to perform this action');
  }
}
