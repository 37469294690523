import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { SubscriptionType, PlanType } from 'src/app/common/enums';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { Account } from 'src/app/types/projects';

type PaymentMethod = {
  checkout: boolean;
  canDoWithoutChekoutSubscription: boolean;
  customer_id: string;
};

export type StripePlan = {
  id: string;
  name: string;
  description: string;
  price: {
    [key: string]: {
      id: string;
      amount: number;
    };
  };
};

@Injectable({
  providedIn: 'root',
})
export class StripeService extends ApiCommonHandler {
  private customerIdSub: BehaviorSubject<string | null> = new BehaviorSubject(
    null
  );
  private accountStripePlanSub: BehaviorSubject<StripePlan | null> =
    new BehaviorSubject(null);
  private plansSub: BehaviorSubject<StripePlan[]> = new BehaviorSubject([]);

  public customerId$ = this.customerIdSub.asObservable();
  public accountStripePlan$ = this.accountStripePlanSub.asObservable();
  public plans$ = this.plansSub.asObservable();

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService
  ) {
    super();
  }

  checkout(body: {
    subscription_type: SubscriptionType;
    account_id: string;
    price_id: string;
    plan_id: string;
    success_url: string;
    cancel_url: string;
  }): Promise<{ result: { payment_url: string } }> {
    this.permissionService.validateCreateAndUpdateOperation();

    return lastValueFrom(
      this.http.post<{ result: { payment_url: string } }>(
        this.getAPIFullUrlByName('stripe/checkout'),
        body
      )
    );
  }

  createSession(body: {
    account_id: string;
    price_id: string;
    redirect_url: string;
  }): Promise<{ result: { payment_url: string } }> {
    this.permissionService.validateCreateAndUpdateOperation();

    return lastValueFrom(
      this.http.post<{ result: { payment_url: string } }>(
        this.getAPIFullUrlByName('stripe/createSession'),
        body
      )
    );
  }

  async loadPlans(account_id: string | null) {
    if (!this.plansSub.value.length) {
      const { result } = await lastValueFrom(
        this.http.get<{
          result: StripePlan[];
        }>(this.getAPIFullUrlByName(`stripe/plans?account_id=${account_id}`))
      );

      this.plansSub.next(result);
    }
  }

  async loadPlanById(id: string) {
    if (this.accountStripePlanSub.value?.id !== id) {
      const { result } = await lastValueFrom(
        this.http.get<{
          result: StripePlan;
        }>(this.getAPIFullUrlByName(`stripe/plans/${id}`))
      );

      this.setStripePlan(result);
    }
  }

  setStripePlan(plan: StripePlan | null) {
    this.accountStripePlanSub.next(plan);
  }

  async getCustomerIdByAccountId(accountId: string) {
    try {
      const { result } = await lastValueFrom(
        this.http.get<{
          result: string;
        }>(this.getAPIFullUrlByName(`stripe/account/${accountId}`))
      );

      this.customerIdSub.next(result);
    } catch {
      this.customerIdSub.next(null);
    }
  }

  async subscribeWithoutCheckout(
    accountId: string,
    priceId: string,
    planType: PlanType
  ) {
    this.permissionService.validateCreateAndUpdateOperation();

    return lastValueFrom(
      this.http.post<{
        result: Account;
      }>(this.getAPIFullUrlByName(`stripe/subscribeWithOutCheckout`), {
        account_id: accountId,
        price_id: priceId,
        plan_type: planType,
      })
    );
  }

  async getPaymentMethods(accountId: string, priceId: string) {
    try {
      const { result } = await lastValueFrom(
        this.http.post<{
          result: PaymentMethod;
        }>(
          this.getAPIFullUrlByName(
            `stripe/checkIfUserHaveDefaultPaymentMethod`
          ),
          { account_id: accountId, price_id: priceId }
        )
      );

      return result;
    } catch {
      return null;
    }
  }
}
