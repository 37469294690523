import { Component } from '@angular/core';

@Component({
  selector: 'app-icon-edit-alert',
  templateUrl: './icon-edit-alert.component.html',
  styleUrls: ['./icon-edit-alert.component.scss'],
})
export class IconEditAlertComponent {
  constructor() {}
}
