import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { PromiseService } from 'src/app/services/promise/promise.service';

type Event = {
  event: string;
};

type Session = {
  _id: string;
  user_id: string;
  'context.location.city': string | null;
  'context.location.country': string | null;
  'context.network.carrier': string | null;
  'context.network.wifi': string | null;
  'context.os.name': string | null;
  'context.os.version': string | null;
  'context.screen.width': string | null;
};

@Injectable({
  providedIn: 'root',
})
export class SmartAudienceService extends ApiCommonHandler {
  constructor(
    private http: HttpClient,
    private promiseService: PromiseService
  ) {
    super();
  }

  async getAudienceDataEvents(projectId: string) {
    const { result } = await this.promiseService.share(
      `smartAudienceService.getAudienceDataEvents.${projectId}`,
      () => {
        return lastValueFrom(
          this.http.post<{
            result: {
              e: Event[];
            };
          }>(this.getAPIFullUrlByName('survey/smart-audience-data'), {
            type: 'event',
            project_id: projectId,
          })
        );
      }
    );

    return (result.e as Event[]).map(({ event }) => ({ _id: event }));
  }

  async getAudienceDataSessions(projectId: string) {
    const { result } = await this.promiseService.share(
      `smartAudienceService.getAudienceDataSessions.${projectId}`,
      () => {
        return lastValueFrom(
          this.http.post<{
            result: {
              e: Session[];
            };
          }>(this.getAPIFullUrlByName('survey/smart-audience-data'), {
            type: 'session',
            project_id: projectId,
          })
        );
      }
    );

    return ([result.e] as unknown as Session[]).map(item => ({
      _id: item._id,
      user_id: item.user_id,
      c: {
        w: item['context.network.wifi'],
      },
      v: item['context.app.version'],
      b: item['context.app.build'],
      l: {
        c: item['context.location.city'],
        cc: item['context.location.country'],
      },
      d: {
        o: item['context.os.name'],
        v: item['context.os.version'],
        w: item['context.screen.width'],
        c: item['context.network.carrier'],
      },
    }));
  }
}
