import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-clipboard',
  templateUrl: './icon-clipboard.component.html',
  styleUrls: ['./icon-clipboard.component.scss'],
})
export class IconClipboardComponent {
  @Input() color: 'primary' | 'grey' | 'light-grey' = 'primary';
  @Input() size = 14;

  constructor() {}
}
