export enum FilterType {
  Trait = 'trait',
  Event = 'event',
  EventProperty = 'event_property',
  Cohort = 'cohort',
  FilterGroup = 'filter_group',
  Page = 'page',
  Mobile = 'mobile',
  PageProperty = 'page_property',
}

export enum LabelFilterType {
  Cohort = 'cohort',
  Event = 'event',
  Trait = 'trait',
  EventProperty = 'event_property',
  Page = 'page',
  PageProperty = 'page_property',
}

export enum FilterOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum EventFilterType {
  COUNT = 'count',
  FIRST_OCCURRED = 'first-occurred',
  LAST_OCCURRED = 'last-occurred',
}

export enum EventCountTimeRange {
  ALL_TIME = 'all_time',
  IN_THE_LAST = 'in_the_last',
  MORE_THAN = 'more_than',
  BETWEEN = 'between',
}

export enum EventCountTimeRangeFormat {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}
