import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-icon-settings',
  templateUrl: './icon-settings.component.html',
  styleUrls: ['./icon-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSettingsComponent {
  @Input() active = false;

  constructor() {}
}
