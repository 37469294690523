import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerComponent implements OnInit, OnDestroy {
  @Input() opened = false;

  @Output() public handleEmoji: EventEmitter<{ char: string; name: string }> =
    new EventEmitter();
  @Output() public handleClose = new EventEmitter();

  constructor() {
    this.close = this.close.bind(this);
  }

  ngOnInit(): void {
    document.body.addEventListener('click', this.close);
  }

  ngOnDestroy() {
    document.body.removeEventListener('click', this.close);
  }

  private close() {
    this.handleClose.emit();
  }

  public addEmoji(data: {
    $event: PointerEvent;
    emoji: { id: string; native: string; unified: string; keywords: string[] };
  }) {
    this.handleEmoji.emit({
      name: data.emoji.keywords[0],
      char: data.emoji.native,
    });
  }
}
