import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-close',
  templateUrl: './icon-close.component.html',
  styleUrls: ['./icon-close.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCloseComponent {
  @Input() color = '#1E2024';
  @Input() size = '12px';

  constructor() {}
}
