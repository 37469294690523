<div
  class="no-data ml-auto mr-auto w-max flex flex-col items-center justify-center">
  <div class="no-data-icon flex items-center justify-center">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 19L15.5001 15.5M18 9.5C18 14.1944 14.1944 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5Z"
        stroke="#2F54EB"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </div>
  <h4 class="no-data-title mt-4">{{ title }}</h4>
  <p class="no-data-message mt-1 mb-6">
    {{ description }}
  </p>
  <div class="flex items-center gap-3">
    <ng-content select="[action]"></ng-content>
    <a
      *ngIf="addButton"
      [routerLink]="addButton.link"
      [queryParams]="addButton.queryParams">
      <app-button size="m" width="100%" type="button">
        <div
          class="w-max h-full flex items-center justify-center button-content">
          <svg
            class="sidebar-navigation-button-icon"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.8327 6.83341H6.83268V11.8334H5.16602V6.83341H0.166016V5.16675H5.16602V0.166748H6.83268V5.16675H11.8327V6.83341Z"
              fill="white" />
          </svg>
          <span>
            <span class="ml-2">{{ addButton.title }}</span>
          </span>
        </div>
      </app-button>
    </a>
  </div>
</div>
