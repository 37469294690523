<app-select
  class="w-full"
  title="Event property"
  placeholder="Find event property"
  [required]="true"
  [useSearch]="true"
  [isLoading]="isLoading"
  [selected]="selected ? [selected] : []"
  [elements]="(propertiesListSub | async) || []"
  (update)="select($event)"
  (loadMore)="!allEventPropertiesLoaded && loadMoreEventProperties()">
</app-select>
