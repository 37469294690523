import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Directive({
  selector: '[appInviteTeamEmail]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: InviteTeamEmailDirective,
      multi: true,
    },
  ],
})
export class InviteTeamEmailDirective {
  @Input() user_id: any;
  constructor(
    private api: ApiService,
    private storage: StorageService
  ) {}
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!this.user_id) {
      this.user_id = this.storage.getItem('ONE_FLOW_USER_ID');
    }
    return this.api.validateInviteEmail(control.value, this.user_id).pipe(
      map((res: any) => {
        return res
          ? { inviteEmailExists: true, message: 'Email already invited' }
          : null;
      }),
      catchError(err => {
        return of(null);
      })
    );
  }
}
