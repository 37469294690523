import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  SimpleChanges,
  Injector,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { DataType, FilterCondition } from 'src/app/common/enums';
import {
  DATA_TYPE_ICONS,
  DEFAULT_TRAITS,
  FILTER_CONDITIONS,
} from 'src/app/constants';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { ProjectService } from 'src/app/services/project/project.service';
import { SessionService } from 'src/app/services/session/session.service';

import { LabelFilterType } from '../../enums';
import { TraitFilter } from '../../types';
import { generateAnswersAsLables } from '../../utils';

@Component({
  selector: 'app-trait-filter',
  templateUrl: './trait-filter.component.html',
  styleUrls: ['./trait-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TraitFilterComponent extends BaseDirective implements OnChanges {
  traitModel$: BehaviorSubject<TraitFilter | null> = new BehaviorSubject(null);
  FILTER_CONDITIONS = FILTER_CONDITIONS;
  dropdownActive = false;
  DataType = DataType;
  LabelFilterType = LabelFilterType;
  FilterCondition = FilterCondition;
  traits: {
    trait: string;
    data_type: DataType;
  }[] = [];
  answerValues: string[] = [];

  @Input() trait?: TraitFilter | null = null;

  @Output() update: EventEmitter<TraitFilter> = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() finish: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private projectService: ProjectService,
    injector: Injector
  ) {
    super(injector);

    super.addSubscription(
      this.sessionService.activeProjectId$.subscribe(async projectId => {
        if (projectId) {
          this.traits = await this.projectService.getTraits({
            projectId,
            offset: 0,
            search: '',
          });

          this.updateTraitModel();
        }
      })
    );
  }

  ngOnChanges({ trait }: SimpleChanges) {
    if (trait?.currentValue) {
      this.updateTraitModel();
      this.answerValues = generateAnswersAsLables(
        this.traitModel$.value?.answers
      );
    }
  }

  get isFilterInvalid() {
    if (!this.traitModel$.value) {
      return true;
    }

    const { value, condition, answers = [] } = this.traitModel$.value;

    if (!value) {
      return true;
    }

    if (
      condition === FilterCondition.HasAnyValue ||
      condition === FilterCondition.IsUnknown
    ) {
      return false;
    }

    return answers.length === 0;
  }

  get conditionTitle() {
    if (!this.traitModel$.value) {
      return '';
    }

    const { condition } = this.traitModel$.value;

    return condition && FILTER_CONDITIONS[condition];
  }

  get valuePostfix() {
    if (!this.traitModel$.value) {
      return '';
    }

    const { traitType, answers = [] } = this.traitModel$.value;

    if (
      traitType === DataType.DATE &&
      answers.length &&
      !Number.isNaN(+answers[0])
    ) {
      return ' days ago';
    }
    return '';
  }

  private updateTraitModel() {
    if (!this.trait) {
      return;
    }

    let { icon, label, traitType, value, isDefaultTrait } = this.trait;

    if (!icon) {
      if (isDefaultTrait) {
        const defaultTrait = DEFAULT_TRAITS.find(t => t.value === value);

        if (!traitType) {
          traitType = defaultTrait?.type;
        }

        label = defaultTrait?.label;
      } else if (!traitType) {
        traitType = this.traits.find(t => t.trait === value)?.data_type;
      }

      icon = traitType && DATA_TYPE_ICONS[traitType];
    }

    this.traitModel$.next({
      ...this.trait,
      icon,
      label,
      traitType,
    });
  }

  setTrait(model: {
    type: DataType;
    icon: string;
    isDefaultTrait: boolean;
    label: string;
    value: string;
  }) {
    if (this.traitModel$.value) {
      this.update.emit({
        ...this.traitModel$.value,
        traitType: model.type,
        label: model.label,
        value: model.value,
        icon: model.icon,
        isDefaultTrait: model.isDefaultTrait,
        answers: [],
      });
    }
  }

  showDropdown() {
    this.dropdownActive = true;
  }

  hideDropdown() {
    this.dropdownActive = false;

    this.finish.emit(!this.isFilterInvalid);
  }

  setCondition(condition?: FilterCondition, answer?: string) {
    if (this.traitModel$.value) {
      this.update.emit({
        ...this.traitModel$.value,
        condition,
        answers: answer ? [answer] : [],
      });
    }
  }

  setAnswer(answer?: string) {
    if (this.traitModel$.value) {
      this.update.emit({
        ...this.traitModel$.value,
        answers: answer ? [answer] : [],
      });
    }
  }

  setAnswers(answers: string[]) {
    if (this.traitModel$.value) {
      this.update.emit({
        ...this.traitModel$.value,
        answers,
      });
    }
  }

  setDateValue(model: { condition?: FilterCondition; value?: string }) {
    if (this.traitModel$.value) {
      this.update.emit({
        ...this.traitModel$.value,
        condition: model.condition,
        answers: model.value ? [model.value] : [],
      });
    }
  }

  trackByAnswerValue(index: number, answer: string) {
    return `${index}-${answer}`;
  }
}
