<aside
  *ngIf="!expanded && !(focusedSub | async) ? 'hide' : 'show'; let animation"
  class="sidebar"
  [class.sidebar-expanded]="expanded || (focusedSub | async)"
  (mouseenter)="mouseEnterSidebar()"
  (mouseleave)="mouseLeaveSidebar()">
  <nav class="sidebar-navigation">
    <a
      class="sidebar-navigation-button"
      [routerLink]="
        '/' + (sessionService.activeProjectId$ | async) + TEMPLATES
      ">
      <app-button style="height: 50px" size="m" width="100%" class="w-full">
        <div
          class="w-max h-full flex items-center justify-center button-content">
          <svg
            class="sidebar-navigation-button-icon"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.8327 6.83341H6.83268V11.8334H5.16602V6.83341H0.166016V5.16675H5.16602V0.166748H6.83268V5.16675H11.8327V6.83341Z"
              fill="white" />
          </svg>
          <span [@visible]="animation">
            <span *ngIf="expanded || (focusedSub | async)" class="ml-2">
              Create Survey
            </span>
          </span>
        </div>
      </app-button>
    </a>
    <ng-container *ngFor="let route of routes">
      <a
        *ngIf="route.routerLink; else externalLink"
        class="sidebar-navigation-element flex items-center gap-6"
        [class.sidebar-navigation-element-active]="
          activeTitle
            ? activeTitle === route.title
            : isActive(route.routerLink, route.routerLinkActiveOptions.exact)
        "
        [routerLink]="route.routerLink"
        (click)="setActiveTitle(route.title)">
        <app-icon
          class="sidebar-navigation-icon"
          [active]="
            activeTitle
              ? activeTitle === route.title
              : isActive(route.routerLink, route.routerLinkActiveOptions.exact)
          "
          [icon]="route.icon">
        </app-icon>
        <span [@visible]="animation">{{ route.title }}</span>
      </a>
      <ng-template #externalLink>
        <a
          *ngIf="route.href"
          class="sidebar-navigation-element flex items-center gap-6"
          [href]="route.href"
          [target]="route.target">
          <app-icon
            class="sidebar-navigation-icon"
            [active]="isActive(route.href, false)"
            [icon]="route.icon"></app-icon>
          <span [@visible]="animation">{{ route.title }}</span>
        </a>
      </ng-template>
      <hr
        *ngIf="route.withBottomBorder"
        class="sidebar-navigation-element-with-bottom-border" />
    </ng-container>
  </nav>
  <button
    (click)="toggleExpand()"
    class="pointer sidebar-navigation-element flex items-center gap-6">
    <svg
      class="sidebar-navigation-icon sidebar-collapse-button"
      [class.sidebar-collapse-button-expanded]="expanded"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7566 13.7375L10.0191 8L15.7566 2.2625L13.9941 0.5L6.49414 8L13.9941 15.5L15.7566 13.7375ZM0.244141 0.5H2.74414V15.5H0.244141V0.5Z"
        fill="#454545" />
    </svg>
    <span [@visible]="animation">{{ expanded ? 'Collapse' : 'Expand' }}</span>
  </button>
</aside>

<div
  class="sidebar-navigation-hidden"
  [class.sidebar-navigation-hidden-expanded]="expanded"></div>
