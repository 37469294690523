<svg
  width="14"
  height="14"
  viewBox="0 0 14 14"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M11.7041 2.29183C10.4957 1.0835 8.8374 0.333496 6.99574 0.333496C3.3124 0.333496 0.337402 3.31683 0.337402 7.00016C0.337402 10.6835 3.3124 13.6668 6.99574 13.6668C10.1041 13.6668 12.6957 11.5418 13.4374 8.66683H11.7041C11.0207 10.6085 9.17074 12.0002 6.99574 12.0002C4.2374 12.0002 1.99574 9.7585 1.99574 7.00016C1.99574 4.24183 4.2374 2.00016 6.99574 2.00016C8.37907 2.00016 9.6124 2.57516 10.5124 3.4835L7.82907 6.16683H13.6624V0.333496L11.7041 2.29183Z"
    fill="#2F54EB" />
</svg>
