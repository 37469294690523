import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import {
  API_URL_GET_USER_DETAILS,
  API_URL_USER_FORGET_EMAIL_PREFIX,
  API_URL_USER_PREFIX,
} from 'src/app/constants/constants';
import { PermissionService } from 'src/app/services/permission/permission.service';

export type User = {
  account_user_id: string;
  current_user: {
    email: string;
    id: string;
    name?: string;
    created_on?: number;
  };
  email: string;
  first_name: string;
  google_id: string;
  last_name: string | null;
  logon_type: string;
  phone_number: string | null;
};

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiCommonHandler {
  protected userSub: BehaviorSubject<User | null> = new BehaviorSubject(null);

  public user$ = this.userSub.asObservable();

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService
  ) {
    super();
  }

  public clearUserDetails() {
    this.userSub.next(null);
  }

  public async loadUserDetails(account_id?: string): Promise<void> {
    const { result } = await lastValueFrom(
      this.http.get<{
        result: User;
        message: string;
        success: boolean;
      }>(
        this.getAPIFullUrlByName(
          `${API_URL_GET_USER_DETAILS}/${account_id ?? ''}`
        )
      )
    );

    this.userSub.next(result);
  }

  public updateUserDetails(account_user_id: string, data): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.put(
      this.getAPIFullUrlByName(
        `${API_URL_GET_USER_DETAILS}/${account_user_id}`
      ),
      data
    );
  }

  public getProjectUserIdByAnalyticId(analyticUserId: string) {
    return lastValueFrom(
      this.http.get<{ result: { system_id: string } }>(
        this.getAPIFullUrlByName(`project_users/${analyticUserId}/id`)
      )
    );
  }

  public sendforgetMail(email: string): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(
        `${API_URL_USER_PREFIX}/${API_URL_USER_FORGET_EMAIL_PREFIX}/${email}`
      )
    );
  }

  public validateToken(token): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(
        `${API_URL_USER_PREFIX}/${API_URL_USER_FORGET_EMAIL_PREFIX}/valid/${token}`
      )
    );
  }

  public reset(token, password): Observable<any> {
    return this.http.post(
      this.getAPIFullUrlByName(
        `${API_URL_USER_PREFIX}/${API_URL_USER_FORGET_EMAIL_PREFIX}/reset/${token}`
      ),
      { password }
    );
  }

  public getUser() {
    return this.userSub.value;
  }

  public setUserDetails(firstName: string, email: string) {
    if (this.userSub.value) {
      this.userSub.next({
        ...this.userSub.value,
        first_name: firstName,
        email,
      });
    }
  }
}
