<div
  (click)="toggle()"
  class="flex items-center gap-2 cursor-pointer max-w-max"
  [class.mb-2]="isOpened">
  <app-icon-chevron
    color="#636B75"
    [side]="isOpened ? 'top' : 'bottom'"></app-icon-chevron>
  <p class="text">{{ label }}</p>
</div>
<div *ngIf="isOpened">
  <ng-content></ng-content>
</div>
