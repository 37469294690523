import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { SETTINGS_PLAN_AND_USAGE, SURVEYS } from 'src/app/constants/routes';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-result-expire',
  templateUrl: './result-expire.component.html',
  styleUrls: ['./result-expire.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultExpireComponent extends BaseDirective {
  activeProjectId: string | null = null;
  SETTINGS_PLAN_AND_USAGE = SETTINGS_PLAN_AND_USAGE;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    injector: Injector
  ) {
    super(injector);

    super.addSubscription(
      this.sessionService.activeProjectId$.subscribe(activeProjectId => {
        if (this.activeProjectId && this.activeProjectId !== activeProjectId) {
          this.router.navigate([activeProjectId, SURVEYS.substr(1)]);
        }

        this.activeProjectId = activeProjectId;
      })
    );
  }
}
