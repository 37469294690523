import { Component, Injector } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router';

import { SIDEBAR_POSITION } from 'src/app/constants/constants';
import {
  SURVEYS,
  SETTINGS,
  SDK_TEAMMATES_AFTER_LOGIN,
  USERS,
  TEMPLATES,
  INTEGRATIONS,
  ANNOUNCEMENTS,
} from 'src/app/constants/routes';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { SessionService } from 'src/app/services/session/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent extends BaseDirective {
  activeProjectId: string | null = null;
  expanded = false;
  usePadding = true;
  routes: {
    title: string;
    icon: string;
    routerLinkActiveOptions: { exact: boolean };
    withBottomBorder?: boolean;
    href?: string;
    target?: string;
    routerLink?: string;
  }[] = [];
  SURVEYS = SURVEYS;
  loadingRouteConfig = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private accountsService: AccountsService,
    private sessionService: SessionService,
    private router: Router,
    injector: Injector
  ) {
    super(injector);

    const expanded = localStorage.getItem(SIDEBAR_POSITION);

    if (expanded) {
      this.expanded = !!+expanded;
    }

    super.addSubscription(
      this.sessionService.activeProjectId$.subscribe(activeProjectId => {
        this.activeProjectId = activeProjectId;

        const accountId = this.sessionService.getActiveAccount();
        const accounts = this.accountsService.getAccountsList();

        const account = accounts.find(a => a.account_id === accountId);

        this.routes = [
          {
            title: 'Surveys',
            routerLink: `/${activeProjectId}${SURVEYS}`,
            icon: 'surveys',
            routerLinkActiveOptions: { exact: false },
          },
        ];

        if (account?.entitlements.announcements) {
          this.routes.push({
            title: 'Announcements',
            routerLink: `/${activeProjectId}${ANNOUNCEMENTS}`,
            icon: 'volume-up',
            routerLinkActiveOptions: { exact: false },
          });
        }

        this.routes = [
          ...this.routes,
          {
            title: 'Templates',
            routerLink: `/${activeProjectId}${TEMPLATES}`,
            icon: 'bulb',
            routerLinkActiveOptions: { exact: false },
          },
          {
            title: 'Users',
            routerLink: `/${activeProjectId}${USERS}`,
            icon: 'users',
            routerLinkActiveOptions: { exact: false },
          },
          {
            title: 'Integrations',
            routerLink: `/${activeProjectId}${INTEGRATIONS}`,
            icon: 'integrations',
            withBottomBorder: true,
            routerLinkActiveOptions: { exact: true },
          },
          {
            title: 'Setup Guide',
            routerLink: `/${activeProjectId}${SDK_TEAMMATES_AFTER_LOGIN}`,
            icon: 'setup',
            routerLinkActiveOptions: { exact: true },
          },
          {
            title: 'Documentation',
            href: environment.documentation_route,
            target: '_blank',
            icon: 'documentation',
            routerLinkActiveOptions: { exact: true },
          },
          {
            title: 'Settings',
            routerLink: `/${activeProjectId}${SETTINGS}`,
            icon: 'settings',
            routerLinkActiveOptions: { exact: false },
          },
        ];
      })
    );

    super.addSubscription(
      this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
        }
      })
    );

    super.addSubscription(
      this.activatedRoute.data.subscribe(({ usePadding = true }) => {
        this.usePadding = usePadding;
      })
    );
  }

  public toggle(expanded: boolean) {
    this.expanded = expanded;

    localStorage.setItem(SIDEBAR_POSITION, `${+this.expanded}`);
  }
}
