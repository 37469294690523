import { Injectable } from '@angular/core';

import SegmentPlugin from '@analytics/segment';
import Analytics, { AnalyticsInstance } from 'analytics';
import { BehaviorSubject } from 'rxjs';

import { SessionService } from 'src/app/services/session/session.service';
import {
  PageEvent,
  TrackEvents,
  UserGroups,
  UserIndentify,
} from 'src/app/types/segment';
import { environment } from 'src/environments/environment';

export enum SegmentLogType {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class SegmentService {
  private eventsQueue: TrackEvents[] = [];
  private areIdentifyAndGroupCalledSub = new BehaviorSubject(false);
  private analytics: AnalyticsInstance | null = null;
  private defaultErrorMessage = 'Unable to load segment API';

  areIdentifyAndGroupCalled$ = this.areIdentifyAndGroupCalledSub.asObservable();

  constructor(private sessionService: SessionService) {
    try {
      this.analytics = Analytics({
        app: '1flow-web-app',
        version: environment.segment_version,
        plugins: [
          SegmentPlugin({
            writeKey: environment.segment_api_key,
          }),
        ],
      });
    } catch (err) {
      this.log(SegmentLogType.ERROR, this.defaultErrorMessage);
    }
  }

  setAreIdentifyAndGroupCalled(areCalled: boolean) {
    this.areIdentifyAndGroupCalledSub.next(areCalled);

    if (this.areIdentifyAndGroupCalledSub.value) {
      this.eventsQueue.forEach(data => {
        this.addEvent(data);
      });
    }
  }

  addUser(data: UserIndentify) {
    this.analytics?.identify(data.user_id, data.user_data);
  }

  addGroup(data: UserGroups) {
    (this.analytics?.plugins as any)?.segment.group(
      data.group_id,
      data.group_data
    );
  }

  addEvent(data: TrackEvents) {
    if (this.areIdentifyAndGroupCalledSub.value) {
      const accountId =
        data.event_data['company_id'] ?? this.sessionService.getActiveAccount();
      if (accountId) {
        /* add company_id field to every event call because this is required for group-level analytics. */
        data.event_data['company_id'] = accountId;
        this.analytics?.track(data.event_name, data.event_data);
      }
    } else {
      this.eventsQueue.push(data);
    }
  }

  addPage(data: PageEvent) {
    this.analytics?.page(
      { name: data.page_name },
      data.page_extra_params ?? {}
    );
  }

  log(type: SegmentLogType, message: string) {
    if (!environment.useHash) {
      switch (type) {
        case SegmentLogType.ERROR:
          console.error('Error: ', message);
          return;
        case SegmentLogType.INFO:
          console.log('Info: ', message);
          return;
        case SegmentLogType.WARN:
          console.warn('Warn: ', message);
      }
    }
  }
}
