export enum SurveyQuestion {
  ONE_FIVE_RATING = 'rating-numerical',
  NPS = 'nps',
  MULTIPLE_CHOISE = 'mcq',
  CHECKBOX = 'checkbox',
  FIVE_STAR_RATING = 'rating-5-star',
  EMOJI_RATING = 'rating-emojis',
  SHORT_TEXT = 'short-text',
  LONG_TEXT = 'text',
  WELCOME = 'welcome-screen',
  END = 'end-screen',
}

export enum SurveyStatus {
  DRAFT = 'draft',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}

export enum SurveyEmoji {
  DISAPPOINTED = 'frowning_face',
  WORRIED = 'slightly_frowning_face',
  NEUTRAL_FACE = 'neutral_face',
  SLIGHTLY_SMILING_FACE = 'slightly_smiling_face',
  SMILE = 'smiling_face_with_smiling_eyes',
}

export enum SurveyMediaContentType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum SurveyMediaSourceType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum SurveyMediaAction {
  UPLOAD = 1,
  LINK,
  DELETE,
}

export enum AudienceType {
  FIRST_SEEN = 'first-seen',
  LAST_SEEN = 'last-seen',
  COUNTRY = 'country',
  CITY = 'city',
  OS = 'os',
  BROWSER = 'browser',
  APP_VERSION = 'app-version',
  WI_FI = 'wi-fi',
  CUSTOMER_USER_PROPERTY = 'custom_user_property',
  EVENTS = 'event',
  COHORT = 'cohort',
}

export enum SurveyResultsViewType {
  SUMMARY = 'summary',
  RESPONSES = 'responses',
}
