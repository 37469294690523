<app-dropdown
  *ngIf="traitModel$ | async; let traitModel"
  placement="bottom-start"
  [appendToBody]="true"
  [useScrollToElement]="true"
  (openDropdown)="showDropdown()"
  (closeDropdown)="hideDropdown()">
  <app-label-filter
    button
    [id]="traitModel?.id"
    [showError]="isFilterInvalid"
    [tooltip]="
      traitModel?.value
        ? 'Please enter the missing value'
        : 'Please select a trait'
    "
    [active]="dropdownActive"
    [icon]="traitModel?.icon"
    [type]="LabelFilterType.Trait"
    (remove)="remove.emit()">
    <ng-container *ngIf="traitModel?.label; else placeholder">
      <b>{{ traitModel?.label }}</b>
      {{ conditionTitle }}
      <b *ngFor="let answer of answerValues; trackBy: trackByAnswerValue">{{
        answer
      }}</b>
      <b>{{ valuePostfix }}</b>
    </ng-container>
    <ng-template #placeholder>Select a trait</ng-template>
  </app-label-filter>
  <div dropdown class="dropdown flex flex-col gap-4">
    <app-trait-select
      [selected]="traitModel || null"
      [isOpen]="dropdownActive"
      (setTraitType)="setTrait($event)"></app-trait-select>
    <ng-container *ngIf="traitModel && traitModel.answers">
      <ng-container [ngSwitch]="traitModel.traitType">
        <div *ngSwitchCase="DataType.ARRAY" class="flex flex-col gap-1">
          <app-array-input
            [values]="traitModel.answers"
            [active]="
              traitModel.condition === FilterCondition.IncludesOneOrMoreOf
            "
            [condition]="FilterCondition.IncludesOneOrMoreOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-array-input
            [values]="traitModel.answers"
            [active]="traitModel.condition === FilterCondition.IncludesAllOf"
            [condition]="FilterCondition.IncludesAllOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-array-input
            [values]="traitModel.answers"
            [active]="
              traitModel.condition === FilterCondition.ExcludesOneOrMoreOf
            "
            [condition]="FilterCondition.ExcludesOneOrMoreOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-array-input
            [values]="traitModel.answers"
            [active]="traitModel.condition === FilterCondition.ExcludesAllOf"
            [condition]="FilterCondition.ExcludesAllOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-radio
            [active]="traitModel.condition === FilterCondition.HasAnyValue"
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="traitModel.condition === FilterCondition.IsUnknown"
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <div *ngSwitchCase="DataType.BOOLEAN" class="flex flex-col gap-1">
          <app-radio
            [active]="
              traitModel.condition === FilterCondition.Is &&
              traitModel?.answers?.[0]?.toString() === 'true'
            "
            (update)="setCondition(FilterCondition.Is, 'true')">
            {{ FILTER_CONDITIONS[FilterCondition.Is] }} true
          </app-radio>
          <app-radio
            [active]="
              traitModel.condition === FilterCondition.Is &&
              traitModel?.answers?.[0]?.toString() === 'false'
            "
            (update)="setCondition(FilterCondition.Is, 'false')">
            {{ FILTER_CONDITIONS[FilterCondition.Is] }} false
          </app-radio>
          <app-radio
            [active]="traitModel.condition === FilterCondition.HasAnyValue"
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="traitModel.condition === FilterCondition.IsUnknown"
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <div *ngSwitchCase="DataType.STRING" class="flex flex-col gap-1">
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.Is"
              (update)="setCondition(FilterCondition.Is)">
              {{ FILTER_CONDITIONS[FilterCondition.Is] }}
            </app-radio>
            <app-trait-properties-select
              *ngIf="traitModel.condition === FilterCondition.Is"
              [selected]="traitModel.answers"
              [trait]="traitModel?.value"
              [isDefaultTrait]="traitModel?.isDefaultTrait || false"
              (update)="setAnswers($event)">
            </app-trait-properties-select>
            <!-- <app-select
              *ngIf="traitModel.condition === FilterCondition.Is"
              class="block ml-6"
              placeholder="Enter value"
              [multiple]="true"
              [required]="true"
              [useSearch]="true"
              [selected]="traitModel.answers"
              [elements]="userProperties"
              (update)="handleTraitSelect($event)"
            >
            </app-select> -->
            <!-- <app-labels-picker
              *ngIf="traitModel.condition === FilterCondition.Is"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [selected]="traitModel.answers"
              (update)="setAnswers($event)">
            </app-labels-picker> -->
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.IsNot"
              (update)="setCondition(FilterCondition.IsNot)">
              {{ FILTER_CONDITIONS[FilterCondition.IsNot] }}
            </app-radio>
            <app-trait-properties-select
              *ngIf="traitModel.condition === FilterCondition.IsNot"
              [selected]="traitModel.answers"
              [trait]="traitModel?.value"
              [isDefaultTrait]="traitModel?.isDefaultTrait || false"
              (update)="setAnswers($event)">
            </app-trait-properties-select>
            <!-- <app-labels-picker
              *ngIf="traitModel.condition === FilterCondition.IsNot"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [selected]="traitModel.answers"
              (update)="setAnswers($event)">
            </app-labels-picker> -->
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.Contains"
              (update)="setCondition(FilterCondition.Contains)">
              {{ FILTER_CONDITIONS[FilterCondition.Contains] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.Contains"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.DoesNotContain"
              (update)="setCondition(FilterCondition.DoesNotContain)">
              {{ FILTER_CONDITIONS[FilterCondition.DoesNotContain] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.DoesNotContain"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.StartsWith"
              (update)="setCondition(FilterCondition.StartsWith)">
              {{ FILTER_CONDITIONS[FilterCondition.StartsWith] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.StartsWith"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.EndsWith"
              (update)="setCondition(FilterCondition.EndsWith)">
              {{ FILTER_CONDITIONS[FilterCondition.EndsWith] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.EndsWith"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <app-radio
            [active]="traitModel.condition === FilterCondition.HasAnyValue"
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="traitModel.condition === FilterCondition.IsUnknown"
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <div *ngSwitchCase="DataType.NUMBER" class="flex flex-col gap-1">
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.IsEqualTo"
              (update)="setCondition(FilterCondition.IsEqualTo)">
              {{ FILTER_CONDITIONS[FilterCondition.IsEqualTo] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.IsEqualTo"
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.IsNotEqualTo"
              (update)="setCondition(FilterCondition.IsNotEqualTo)">
              {{ FILTER_CONDITIONS[FilterCondition.IsNotEqualTo] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.IsNotEqualTo"
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.IsLessThan"
              (update)="setCondition(FilterCondition.IsLessThan)">
              {{ FILTER_CONDITIONS[FilterCondition.IsLessThan] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.IsLessThan"
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.IsMoreThan"
              (update)="setCondition(FilterCondition.IsMoreThan)">
              {{ FILTER_CONDITIONS[FilterCondition.IsMoreThan] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.IsMoreThan"
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.IsAtLeast"
              (update)="setCondition(FilterCondition.IsAtLeast)">
              {{ FILTER_CONDITIONS[FilterCondition.IsAtLeast] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.IsAtLeast"
              class="block ml-6"
              placeholder="Enter number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="traitModel.condition === FilterCondition.IsAtMost"
              (update)="setCondition(FilterCondition.IsAtMost)">
              {{ FILTER_CONDITIONS[FilterCondition.IsAtMost] }}
            </app-radio>
            <app-input
              *ngIf="traitModel.condition === FilterCondition.IsAtMost"
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="traitModel.answers[0]"
              (focusOut)="setAnswer(traitModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <app-radio
            [active]="traitModel.condition === FilterCondition.HasAnyValue"
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="traitModel.condition === FilterCondition.IsUnknown"
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <app-date-filter-section
          *ngSwitchCase="DataType.DATE"
          [value]="traitModel?.answers?.[0]"
          [condition]="traitModel.condition"
          (update)="setDateValue($event)">
        </app-date-filter-section>
      </ng-container>
    </ng-container>
  </div>
</app-dropdown>
