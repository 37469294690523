<svg
  width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M11.75 20.5H14.25V18H11.75V20.5ZM13 0.5C6.1 0.5 0.5 6.1 0.5 13C0.5 19.9 6.1 25.5 13 25.5C19.9 25.5 25.5 19.9 25.5 13C25.5 6.1 19.9 0.5 13 0.5ZM13 23C7.4875 23 3 18.5125 3 13C3 7.4875 7.4875 3 13 3C18.5125 3 23 7.4875 23 13C23 18.5125 18.5125 23 13 23ZM13 5.5C10.2375 5.5 8 7.7375 8 10.5H10.5C10.5 9.125 11.625 8 13 8C14.375 8 15.5 9.125 15.5 10.5C15.5 13 11.75 12.6875 11.75 16.75H14.25C14.25 13.9375 18 13.625 18 10.5C18 7.7375 15.7625 5.5 13 5.5Z"
    [attr.fill]="active ? '#2D4EFF' : '#454545'" />
</svg>
