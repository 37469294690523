<app-dropdown placement="bottom-start" [closeDrowdownOnClick]="true">
  <button
    button
    type="button"
    class="simple-select-header flex gap-2 items-center justify-between">
    <span>{{ selectedLabel }}</span>
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.825 0.912598L5 4.72926L1.175 0.912598L0 2.0876L5 7.0876L10 2.0876L8.825 0.912598Z"
        fill="#2D4EFF" />
    </svg>
  </button>
  <ul dropdown [style.width]="dropdownWidth">
    <li
      *ngFor="let element of elements; index as i; trackBy: trackByFn"
      class="simple-select-dropdown-element"
      (click)="onChange(element)">
      <div class="flex items-center gap-2.5">
        <span class="block truncate">
          {{ element.label }}
        </span>
      </div>
      <svg
        *ngIf="element.value === value"
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="#1E2024"
        aria-hidden="true">
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd" />
      </svg>
    </li>
  </ul>
</app-dropdown>
