import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { openFilterById } from 'src/app/common/utils';

import { FilterType, FilterOperator } from '../enums';
import {
  Filter,
  TraitFilter,
  CohortFilter,
  EventFilter,
  FilterGroupFilter,
  EventPropertyFilter,
} from '../types';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterGroupComponent implements OnChanges {
  private invalidFilters: { [key: string]: boolean } = {};

  FilterType = FilterType;

  @Input() eventName?: string;
  @Input() filterGroup: FilterGroupFilter | null = null;
  @Input() allowedFilters: {
    title: string;
    description?: string;
    type: FilterType;
  }[] = [];

  @Output() activateFilters: EventEmitter<boolean> = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() update: EventEmitter<FilterGroupFilter> = new EventEmitter();

  constructor() {}

  ngOnChanges({ filterGroup }: SimpleChanges) {
    const group = filterGroup?.currentValue;

    if (group && !group.filters) {
      this.update.emit({ ...group, filters: [] });
    } else if (group.filters?.length > 1 && !group.operator) {
      this.update.emit({
        ...group,
        operator: FilterOperator.AND,
      });
    } else if (group.filters?.length === 1 && group.operator) {
      this.update.emit({
        ...group,
        operator: undefined,
      });
    }
  }

  private updateValidation() {
    this.activateFilters.emit(
      Object.keys(this.invalidFilters).every(key => !this.invalidFilters[key])
    );
  }

  trackByFilter(index: number, filter: Filter) {
    return filter.id;
  }

  handleFinish(id: string, isFilterValid: boolean) {
    this.invalidFilters[id] = !isFilterValid;

    this.updateValidation();
  }

  addFilter(filterType: FilterType) {
    if (this.filterGroup) {
      const id = uuidv4();

      this.update.emit({
        ...this.filterGroup,
        filters: [
          ...this.filterGroup.filters,
          {
            id,
            filterType,
          },
        ],
      });

      openFilterById(id);
    }
  }

  updateOperator(operator: FilterOperator) {
    if (this.filterGroup) {
      this.update.emit({
        ...this.filterGroup,
        operator,
      });

      this.updateValidation();
    }
  }

  removeFilter(id: string, index: number) {
    delete this.invalidFilters[id];

    if (this.filterGroup) {
      const filters = this.filterGroup.filters.filter((f, i) => i !== index);
      this.update.emit({
        ...this.filterGroup,
        operator: filters.length > 1 ? this.filterGroup.operator : undefined,
        filters,
      });
      this.updateValidation();
    }
  }

  updateFilter(filter: Filter) {
    if (this.filterGroup) {
      this.update.emit({
        ...this.filterGroup,
        filters: this.filterGroup.filters.map(f =>
          f.id === filter.id ? filter : f
        ),
      });
    }
  }

  toEventPropertyFilterType(filter: Filter) {
    return filter as EventPropertyFilter;
  }

  toTraitFilterType(filter: Filter) {
    return filter as TraitFilter;
  }

  toCohortFilterType(filter: Filter) {
    return filter as CohortFilter;
  }

  toEventFilterType(filter: Filter) {
    return filter as EventFilter;
  }
}
