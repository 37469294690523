import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HotToastService } from '@ngneat/hot-toast';

const DEFAULT_STYLES = {
  fontSize: '14px',
  fontWeight: 500,
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toast: HotToastService) {}

  parseErrorAndShow = (
    error: HttpErrorResponse | Error | unknown,
    duration = 5000
  ) => {
    let message = '';

    if (error instanceof HttpErrorResponse) {
      message = error.error?.message;
    } else {
      message = (error as Error).message;
    }

    this.showError(message || 'Something went wrong', duration);
  };

  public showError(message: string, duration = 5000) {
    this.toast.error(message, { duration, style: DEFAULT_STYLES });
  }

  public showSuccess(message: string, duration = 5000) {
    this.toast.success(message, { duration, style: DEFAULT_STYLES });
  }
}
