<div
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  *ngIf="show">
  <div
    class="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"></div>

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      (click)="confirmation(false)"
      >&#8203;</span
    >

    <div
      class="relative inline-block align-bottom bg-white rounded-lg w-444 h-238 pl-pr-32 pt-pb-32 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle">
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <img src="assets/images/icon2.png" alt="icon" class="icon3" />
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-8 sm:text-left">
          <h3
            class="text-lg leading-6 font-semibold text-gray-900"
            id="modal-title">
            Delete team members
          </h3>
          <div class="mt-2">
            <p class="heading">Are you sure you want to delete this user?</p>
          </div>
        </div>
      </div>
      <div
        class="mt-8 sm:mt-8 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          (click)="confirmation(false)"
          class="mt-3 w-full inline-flex justify-center rounded-md border bg-color-gray border-gray-300 shadow-sm px-4 py-2 bg- text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:mt-0 sm:col-start-1 sm:text-sm">
          No, cancel
        </button>
        <button
          type="button"
          (click)="confirmation(true)"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-color text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
          Yes, delete now
        </button>
      </div>
    </div>
  </div>
</div>
