import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-side-panel-with-delete',
  templateUrl: './side-panel-with-delete.component.html',
  styleUrls: ['./side-panel-with-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelWithDeleteComponent {
  @Input() isOpened = false;
  @Input() isDeleting = false;
  @Input() isSaving = false;
  @Input() allowDelete = false;
  @Input() title?: string;
  @Input() deleteButtonName?: string;

  @Output() hide = new EventEmitter();
  @Output() save: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  constructor() {}

  public handleSubmit({ valid, form }: NgForm) {
    if (valid) {
      this.save.emit();
    } else {
      form.markAllAsTouched();
    }
  }
}
