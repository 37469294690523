<div class="relative">
  <button #button>
    <ng-content select="[button]"></ng-content>
  </button>

  <ng-container *ngIf="appendToBody; else templateRef"></ng-container>

  <ng-template #templateRef>
    <div
      #dropdown
      class="dropdown hidden mt-1"
      tabindex="-1"
      (click)="handleDropdownClick()">
      <ng-content select="[dropdown]"></ng-content>
    </div>
  </ng-template>
</div>
