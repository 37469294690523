import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import {
  API_URL_ACCOUNTS_PREFIX,
  API_URL_CREATE_PROJECTS_PREFIX,
  API_URL_GET_PROJECT_BY_ACCOUNT_ID,
  GET_PROJECT_API_KEY,
} from 'src/app/constants/constants';
import { VALIDATE_PROJECT_APIKEY } from 'src/app/endpoints/endpoints';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { UserFilter } from 'src/app/types';
import { ProjectKey } from 'src/app/types/projects';

@Injectable({
  providedIn: 'root',
})
export class ProjectCreateService extends ApiCommonHandler {
  private getEventsCountSubject$: Subject<string | null> = new Subject();
  projectsKeysSub: BehaviorSubject<{ [key: string]: ProjectKey[] }> =
    new BehaviorSubject({});

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService
  ) {
    super();
  }

  get projectsKeys$(): Observable<{ [key: string]: ProjectKey[] }> {
    return this.projectsKeysSub.asObservable();
  }

  addProject(data, account_id: string | null): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.post(
      this.getAPIFullUrlByName(
        API_URL_ACCOUNTS_PREFIX +
          '/' +
          account_id +
          '/' +
          API_URL_CREATE_PROJECTS_PREFIX
      ),
      data
    );
  }

  getProject(project_id, account_id: string | null): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(
        API_URL_ACCOUNTS_PREFIX +
          '/' +
          account_id +
          '/' +
          API_URL_CREATE_PROJECTS_PREFIX +
          '/' +
          project_id
      )
    );
  }

  getProjectUsingAccountId(account_id): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(
        `${API_URL_GET_PROJECT_BY_ACCOUNT_ID}/${account_id}`
      )
    );
  }

  async getProjectEventsById(body: {
    projectId: string;
    filterType:
      | 'all'
      | 'today'
      | 'last-7-days'
      | 'month-to-date'
      | 'last-30-days'
      | 'last-6-months'
      | 'last-12-months'
      | 'custom';
    events: string[];
    surveyId: string;
    startTimestamp?: number;
    endTimestamp?: number;
    filters: UserFilter;
  }) {
    const { result } = await lastValueFrom(
      this.http.post<{
        result: {
          count: number;
          list: {
            _id: string;
            project_id: string;
            user_id: string;
            event: string;
            properties: {
              time_zone: string;
              entry_time: number;
              url: string;
              survey_id: string;
            };
            timestamp: number;
            created_at: number;
            updated_at: number;
            deleted_at: number | null;
            deleted: boolean;
            schema_version: number;
            __v: number;
          }[];
        };
      }>(this.getAPIFullUrlByName(`events/${body.projectId}`), {
        filter_type: body.filterType,
        events: body.events,
        survey_id: body.surveyId,
        start_timestamp: body.startTimestamp,
        end_timestamp: body.endTimestamp,
        filters: body.filters,
      })
    );

    return result;
  }

  async loadProjectKeysById(project_id: string) {
    const res = await lastValueFrom(this.getKey(project_id));

    const projectsKeys = await lastValueFrom(
      this.projectsKeysSub.pipe(take(1))
    );

    projectsKeys[project_id] = res.result;

    this.projectsKeysSub.next(projectsKeys);
  }

  getKey(project_id: string | null): Observable<{ result: ProjectKey[] }> {
    return this.http.get<{ result: ProjectKey[] }>(
      this.getAPIFullUrlByName(GET_PROJECT_API_KEY + '/' + project_id)
    );
  }

  validateProjectKeyName(
    project_id: string | null,
    key_name: string
  ): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(
        `${VALIDATE_PROJECT_APIKEY}/${project_id}/${key_name}`
      )
    );
  }

  addKey(
    project_id: string | null,
    key_name: string,
    key_mode = 'prod'
  ): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.post(
      this.getAPIFullUrlByName(GET_PROJECT_API_KEY + '/' + project_id),
      { key_name, key_mode }
    );
  }

  removeKey(id: string): Observable<any> {
    this.permissionService.validateCreateUpdateAndDeleteOperation();

    return this.http.delete(
      this.getAPIFullUrlByName(`${GET_PROJECT_API_KEY}/${id}`)
    );
  }
}
