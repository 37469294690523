<ng-container
  *ngIf="
    key &&
      additionalRule &&
      (this.accountsService.organizationAccount$ | async)?.usage_summary?.[key]
        ?.quota;
    else notAllowed
  ">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #notAllowed>
  <app-tooltip content="Upgrade now to unlock this feature" placement="top">
    <div (keydown)="handleKeyDown($event)" (mousedown)="handleClick($event)">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </app-tooltip>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
