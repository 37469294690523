import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent {
  @Input() colspan = 0;
  @Input() spinnerHeight = '500px';
  @Input() isLoading = false;
  @Input() showActions = false;

  constructor() {}
}
