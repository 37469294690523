<div
  *ngIf="appearanceOpenSub | async"
  class="side-panel fixed left-0 top-0"
  role="dialog"
  aria-modal="true">
  <div
    [@showHide]="(animationOpenSub | async) ? 'show' : 'hide'"
    class="side-panel-background fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div
    (click)="hide.emit()"
    class="side-panel-content fixed inset-0 overflow-hidden"
    [class.cursor-pointer]="this.hide.observers.length">
    <div class="absolute inset-0 overflow-hidden">
      <div
        class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full h-full pl-10">
        <div
          (click)="$event.stopPropagation()"
          class="relative pointer-events-auto w-screen h-full max-w-max cursor-default">
          <div
            [@openClose]="(animationOpenSub | async) ? 'open' : 'closed'"
            class="fixed top-0 flex h-full overflow-y-auto flex-col divide-y divide-gray-200 bg-white shadow-xl">
            <div class="h-max">
              <ng-content></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
