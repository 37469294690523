<app-dropdown
  placement="bottom-start"
  [appendToBody]="true"
  [useScrollToElement]="true"
  (openDropdown)="showDropdown()"
  (closeDropdown)="hideDropdown()">
  <app-label-filter
    button
    [id]="filter?.id"
    [showError]="isFilterInvalid"
    [tooltip]="
      filter?.value
        ? 'Please enter the missing value'
        : 'Please select an event'
    "
    [active]="dropdownActive"
    [type]="LabelFilterType.Page"
    (remove)="remove.emit()">
    <ng-container *ngIf="filter?.label; else placeholder">
      <b>{{ filter?.label }}</b>
      <ng-container *ngIf="filter?.where?.filters?.length">
        where
        <ng-container
          *ngFor="let whereFilter of filter?.where?.filters; let i = index">
          <ng-container
            *ngIf="
              whereFilter.filterType === FilterType.FilterGroup &&
                toFilterGroupFilterType(whereFilter);
              let filterGroupFilter;
              else: defaultWhereLabel
            ">
            (<ng-container
              *ngFor="
                let f of filterGroupFilter.filters;
                let filterGroupFilterIndex = index
              ">
              <b>{{ f.label }}</b>
              {{ getFilterConditionLabel(f.condition) }}
              <b>{{ getPagePropertyLabel(f) }}</b>
              <ng-container
                *ngIf="
                  filterGroupFilterIndex < filterGroupFilter.filters.length - 1
                ">
                {{ filterGroupFilter.operator?.toLowerCase() }}
              </ng-container> </ng-container
            >)
          </ng-container>
          <ng-template #defaultWhereLabel>
            <ng-container
              *ngIf="
                toPagePropertyFilterType(whereFilter);
                let eventPropertyFilter
              ">
              <b>{{ eventPropertyFilter.label }}</b>
              {{ getFilterConditionLabel(eventPropertyFilter.condition) }}
              <b>{{ getPagePropertyLabel(whereFilter) }} </b>
              <ng-container
                *ngIf="filter && i < (filter.where?.filters?.length ?? 0) - 1">
                {{ filter.where?.operator?.toLowerCase() }}
              </ng-container>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #placeholder>Select a page trigger</ng-template>
  </app-label-filter>
  <div dropdown class="dropdown flex flex-col gap-4">
    <app-select
      title="Page"
      placeholder="Find page trigger"
      [useSearch]="true"
      [required]="true"
      [selected]="filter ? [filter.value] : []"
      [elements]="pageConditions"
      (update)="setPageCondition($event)">
    </app-select>
    <app-page-filter-where
      *ngIf="filter"
      [filter]="filter.where ?? null"
      (update)="updateWhere($event)"></app-page-filter-where>
  </div>
</app-dropdown>
