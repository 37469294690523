import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-arrow',
  templateUrl: './icon-arrow.component.html',
  styleUrls: ['./icon-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconArrowComponent {
  @Input() color = '#636B75';
  @Input() size = 14;
  @Input() side: 'left' | 'right' | 'bottom' | 'top' = 'left';

  constructor() {}
}
