<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12.0834 8.33317C11.3917 8.33317 10.8334 7.77484 10.8334 7.08317V2.9165C10.8334 2.22484 11.3917 1.6665 12.0834 1.6665C12.7751 1.6665 13.3334 2.22484 13.3334 2.9165V7.08317C13.3334 7.77484 12.7751 8.33317 12.0834 8.33317Z"
    fill="white" />
  <path
    d="M17.0834 8.33317H15.8334V7.08317C15.8334 6.3915 16.3917 5.83317 17.0834 5.83317C17.7751 5.83317 18.3334 6.3915 18.3334 7.08317C18.3334 7.77484 17.7751 8.33317 17.0834 8.33317Z"
    fill="white" />
  <path
    d="M7.91675 11.6665C8.60842 11.6665 9.16675 12.2248 9.16675 12.9165V17.0832C9.16675 17.7748 8.60842 18.3332 7.91675 18.3332C7.22508 18.3332 6.66675 17.7748 6.66675 17.0832V12.9165C6.66675 12.2248 7.22508 11.6665 7.91675 11.6665Z"
    fill="white" />
  <path
    d="M2.91675 11.6665H4.16675V12.9165C4.16675 13.6082 3.60841 14.1665 2.91675 14.1665C2.22508 14.1665 1.66675 13.6082 1.66675 12.9165C1.66675 12.2248 2.22508 11.6665 2.91675 11.6665Z"
    fill="white" />
  <path
    d="M11.6667 12.0832C11.6667 11.3915 12.2251 10.8332 12.9167 10.8332H17.0834C17.7751 10.8332 18.3334 11.3915 18.3334 12.0832C18.3334 12.7748 17.7751 13.3332 17.0834 13.3332H12.9167C12.2251 13.3332 11.6667 12.7748 11.6667 12.0832Z"
    fill="white" />
  <path
    d="M12.9167 15.8332H11.6667V17.0832C11.6667 17.7748 12.2251 18.3332 12.9167 18.3332C13.6084 18.3332 14.1667 17.7748 14.1667 17.0832C14.1667 16.3915 13.6084 15.8332 12.9167 15.8332Z"
    fill="white" />
  <path
    d="M8.33342 7.9165C8.33342 7.22484 7.77508 6.6665 7.08342 6.6665H2.91675C2.22508 6.6665 1.66675 7.22484 1.66675 7.9165C1.66675 8.60817 2.22508 9.1665 2.91675 9.1665H7.08342C7.77508 9.1665 8.33342 8.60817 8.33342 7.9165Z"
    fill="white" />
  <path
    d="M7.08342 4.1665H8.33342V2.9165C8.33342 2.22484 7.77508 1.6665 7.08342 1.6665C6.39175 1.6665 5.83342 2.22484 5.83342 2.9165C5.83342 3.60817 6.39175 4.1665 7.08342 4.1665Z"
    fill="white" />
  <path
    d="M12.0834 8.33317C11.3917 8.33317 10.8334 7.77484 10.8334 7.08317V2.9165C10.8334 2.22484 11.3917 1.6665 12.0834 1.6665C12.7751 1.6665 13.3334 2.22484 13.3334 2.9165V7.08317C13.3334 7.77484 12.7751 8.33317 12.0834 8.33317Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M17.0834 8.33317H15.8334V7.08317C15.8334 6.3915 16.3917 5.83317 17.0834 5.83317C17.7751 5.83317 18.3334 6.3915 18.3334 7.08317C18.3334 7.77484 17.7751 8.33317 17.0834 8.33317Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M7.91675 11.6665C8.60842 11.6665 9.16675 12.2248 9.16675 12.9165V17.0832C9.16675 17.7748 8.60842 18.3332 7.91675 18.3332C7.22508 18.3332 6.66675 17.7748 6.66675 17.0832V12.9165C6.66675 12.2248 7.22508 11.6665 7.91675 11.6665Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M2.91675 11.6665H4.16675V12.9165C4.16675 13.6082 3.60841 14.1665 2.91675 14.1665C2.22508 14.1665 1.66675 13.6082 1.66675 12.9165C1.66675 12.2248 2.22508 11.6665 2.91675 11.6665Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M11.6667 12.0832C11.6667 11.3915 12.2251 10.8332 12.9167 10.8332H17.0834C17.7751 10.8332 18.3334 11.3915 18.3334 12.0832C18.3334 12.7748 17.7751 13.3332 17.0834 13.3332H12.9167C12.2251 13.3332 11.6667 12.7748 11.6667 12.0832Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M12.9167 15.8332H11.6667V17.0832C11.6667 17.7748 12.2251 18.3332 12.9167 18.3332C13.6084 18.3332 14.1667 17.7748 14.1667 17.0832C14.1667 16.3915 13.6084 15.8332 12.9167 15.8332Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M8.33342 7.9165C8.33342 7.22484 7.77508 6.6665 7.08342 6.6665H2.91675C2.22508 6.6665 1.66675 7.22484 1.66675 7.9165C1.66675 8.60817 2.22508 9.1665 2.91675 9.1665H7.08342C7.77508 9.1665 8.33342 8.60817 8.33342 7.9165Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M7.08342 4.1665H8.33342V2.9165C8.33342 2.22484 7.77508 1.6665 7.08342 1.6665C6.39175 1.6665 5.83342 2.22484 5.83342 2.9165C5.83342 3.60817 6.39175 4.1665 7.08342 4.1665Z"
    [attr.stroke]="color"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
