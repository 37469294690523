<!--(click)="handleClick.emit()"-->
<app-dropdown>
  <button
    button
    #downloadBtn
    [disabled]="isDownloading"
    type="button"
    class="download-button relative inline-flex bg-transparent align-items-center justify-start px-4 p-t-3 py-2 text-sm font-medium text-black"
    aria-expanded="true"
    aria-haspopup="true">
    {{ isDownloading ? 'Preparing download...' : 'Download CSV' }}
  </button>

  <div dropdown class="download-dropdown">
    <button
      *ngFor="let btn of dropDownButtonItem"
      class="text-left block p-2.5 text-sm dn-op"
      (click)="handleClick.emit(btn.id); downloadBtn.click()">
      {{ btn.name }}
    </button>
  </div>
</app-dropdown>
