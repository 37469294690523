import {
  API_URL_SURVEY_PREFIX,
  API_URL_USER_PREFIX,
  API_URL_ACCOUNTS_PREFIX,
  API_URL_EMAIL_SUBCRIPTION_PREFIX,
  API_URL_SURVEY_RESPONSE_PREFIX,
  API_URL_PROJECT_SETTING_PREFIX,
  API_URL_ADD_UPDATE_SURVEY_KEYUP,
  API_URL_VALIDATE_PROJECT_KEY_NAME_PREFIX,
  API_URL_PROJECT_WEBHOOK_PREFIX,
  API_URL_GET_PROJECT_EVENTS,
  API_URL_INVITE_TEAM_MEMBERS,
} from '../constants/constants';

export const signup = 'user';
export const signup_new_flow = 'accounts-new-signup-flow';
export const login = `${API_URL_USER_PREFIX}/login`;
export const SIGNUP_WITH_CHECKOUT = `${API_URL_USER_PREFIX}/sign-up/checkout`;
export const SIGNUP_WITHOUT_CHECKOUT = `${API_URL_USER_PREFIX}/sign-up/no-checkout`;
export const VALIDATE_EMAIL_INVITE = `${API_URL_INVITE_TEAM_MEMBERS}/validateInviteEmail`;
export const VALIDATE_PROJECT_APIKEY = `${API_URL_VALIDATE_PROJECT_KEY_NAME_PREFIX}/validate`;
export const survery_create = `${API_URL_SURVEY_PREFIX}/`;
export const GET_PROJECTS = `${API_URL_ACCOUNTS_PREFIX}/`;
export const GET_SURVEYS = `${API_URL_SURVEY_PREFIX}/web`;
export const GENERATE_EMAIL_SURVEY_TEMPLATE = `${API_URL_SURVEY_PREFIX}/email`;
export const SUBSCRIPTION = `${API_URL_EMAIL_SUBCRIPTION_PREFIX}`;
export const SURVEY_RESPONSE = `${API_URL_SURVEY_RESPONSE_PREFIX}`;
export const PROJECT_SETTING = `${API_URL_PROJECT_SETTING_PREFIX}`;
export const PROJECT_WEBHOOK = `${API_URL_PROJECT_WEBHOOK_PREFIX}`;
export const GET_PROJECT_DETAIL = `${API_URL_ACCOUNTS_PREFIX}/project`;
export const ADD_UPDATE_SURVEY_KEYUP = `${API_URL_ADD_UPDATE_SURVEY_KEYUP}`;
export const GET_PROJECT_EVENTS = `${API_URL_GET_PROJECT_EVENTS}`;

export const MTU_USAGE_SERVERLESS_API_URL =
  'https://us-west-2.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/1flow-wslxs/service/MTU_usage/incoming_webhook/mtu_usage';
export const MTU_USAGE_SERVERLESS_API_URL_DEVELOPMENT =
  'https://eu-west-1.aws.webhooks.mongodb-realm.com/api/client/v2.0/app/oneflow-development-cqsjv/service/mtu/incoming_webhook/mtu_usage';
