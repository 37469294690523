import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent {
  @Input() show!: Boolean;
  @Input() text: string = '';
  @Output() ModalAction: EventEmitter<any> = new EventEmitter();

  constructor() {}

  confirmation(action: boolean) {
    this.ModalAction.emit(action);
  }
}
