<div
  *ngIf="
    !(
      key &&
      (this.accountsService.organizationAccount$ | async)?.usage_summary?.[key]
        ?.quota
    );
    else content
  "
  class="label">
  PRO
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
