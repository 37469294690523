<header class="header py-2 px-5 flex items-center">
  <nav class="w-full">
    <div class="mx-auto">
      <div class="flex items-center justify-between">
        <ng-content></ng-content>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <div class="flex items-center gap-3 relative">
            <a
              *ngIf="
                !accountsService.account.subscription_details ||
                !accountsService.isSubscriptionActive
              "
              class="header-subscribe-button"
              [routerLink]="'/' + activeProjectId + SETTINGS_PLAN_AND_USAGE">
              <ng-container *ngIf="totalDays > 0; else subscribe">
                <span class="p-1"
                  >{{ totalDays }} trial day{{
                    totalDays > 1 ? 's' : ''
                  }}
                  left</span
                >
              </ng-container>
            </a>

            <div class="relative text-left">
              <button
                #helpMenuButton
                type="button"
                class="header-icon flex items-center justify-center"
                aria-expanded="true"
                aria-haspopup="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>

              <div
                #helpMenuDropdown
                class="header-dropdown header-dropdown-with-padding hidden"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button2"
                tabindex="-1">
                <div class="py-1" role="none">
                  <a
                    (click)="closeHelpMenu()"
                    href="{{ documentation_route }}"
                    target="_blank"
                    class="header-dropdown-element"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0">
                    Developer Documentation
                  </a>
                  <a
                    (click)="closeHelpMenu()"
                    href="mailto:{{ supportEmail }}"
                    class="header-dropdown-element"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-1">
                    Contact Support
                  </a>
                </div>
              </div>
            </div>

            <div class="relative text-left">
              <button
                #settingsMenuButton
                type="button"
                class="header-icon flex items-center justify-center"
                aria-expanded="true"
                aria-haspopup="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </button>

              <div
                #settingsMenuDropdown
                class="header-dropdown header-dropdown-with-padding hidden"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button4"
                tabindex="-1">
                <div class="py-1" role="none">
                  <a
                    (click)="closeSettingsMenu()"
                    [routerLink]="'/' + activeProjectId + PROJECT_SETTING"
                    class="header-dropdown-element"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0">
                    Settings
                  </a>
                  <a
                    (click)="closeSettingsMenu()"
                    [routerLink]="'/' + activeProjectId + SETTINGS_ORGANIZATION"
                    [queryParams]="{ invite: true }"
                    class="header-dropdown-element"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-1">
                    Invite teammates
                  </a>
                  <button
                    (click)="closeSettingsMenu()"
                    class="header-dropdown-element"
                    role="menuitem"
                    tabindex="-1"
                    (click)="onLogout()">
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="header-divider ml-3"></div>

          <div class="relative inline-block text-left ml-3">
            <button
              #projectMenuButton
              type="button"
              class="header-project-picker flex items-center justify-center"
              aria-expanded="true"
              aria-haspopup="true">
              {{ projectNameSub | async }}
              <app-icon-chevron></app-icon-chevron>
            </button>

            <div
              #projectMenuDropdown
              data-index="1"
              class="header-dropdown hidden"
              role="menu"
              aria-orientation="vertical"
              tabindex="-1">
              <div class="header-dropdown-scrollable">
                <div
                  *ngFor="let account of (accountsListSub | async) || []"
                  class="header-dropdown-block">
                  <h5 class="header-dropdown-block-title">
                    {{ account.name }}
                  </h5>
                  <button
                    *ngFor="let project of account.projects"
                    (click)="
                      changeProject(account.account_id, project.project_id)
                    "
                    class="header-dropdown-element"
                    [class.header-dropdown-element-active]="
                      project.project_id == activeProjectId
                    "
                    role="menuitem"
                    tabindex="-1">
                    {{ project.name }}
                  </button>
                </div>
              </div>
              <a
                [routerLink]="CREATE_PROJECT"
                class="header-add-button flex items-center justify-center gap-1"
                role="menuitem"
                tabindex="-1">
                <app-icon-plus></app-icon-plus>
                Add new project
              </a>
            </div>
          </div>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <button
            type="button"
            (click)="openMobileNav()"
            class="bg-white inline-flex items-center justify-center px-2 py-1 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            aria-controls="mobile-menu"
            aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <!--
              Heroicon name: outline/menu
  
              Menu open: "hidden", Menu closed: "block"
            -->
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <!--
              Heroicon name: outline/x
  
              Menu open: "block", Menu closed: "hidden"
            -->
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="sm:hidden" id="mobile-menu">
      <div
        id="mySidenav"
        class="sidenav header-sidenav-activedenav"
        [class.header-sidenav-active]="mobileMenuOpened">
        <a href="javascript:void(0)" (click)="closeMobileNav()" class="closebtn"
          >&times;</a
        >
        <a href="#">HELP</a>
        <a
          href="{{ documentation_route }}"
          target="_blank"
          class="text-gray-700 block px-4 py-2 ml-5 text-sm"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          >Developer Documentation</a
        >
        <a
          href="mailto:{{ supportEmail }}"
          class="text-gray-700 block px-4 py-2 ml-5 text-sm"
          role="menuitem"
          tabindex="-1"
          id="menu-item-1"
          >Contact Support</a
        >

        <a href="#" class="mt-5">SETTINGS</a>
        <a
          href="javascript:void(0)"
          class="text-gray-700 block px-4 py-2 ml-5 text-sm"
          [routerLink]="'/' + activeProjectId + PROJECT_SETTING"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          >Settings</a
        >
        <a
          href="javascript:void(0)"
          class="text-gray-700 block px-4 py-2 ml-5 text-sm"
          [routerLink]="'/' + activeProjectId + SETTINGS_ORGANIZATION"
          [queryParams]="{ invite: true }"
          role="menuitem"
          tabindex="-1"
          id="menu-item-1"
          >Invite teammates</a
        >
        <a
          href="javascript:void(0)"
          class="text-gray-700 block px-4 py-2 ml-5 text-sm"
          role="menuitem"
          tabindex="-1"
          (click)="onLogout()"
          >Log out</a
        >
        <button
          #mobileProjectMenuButton
          type="button"
          class="inline-flex justify-start ml-5 w-full rounded-md capitalize proj-font px-4 py-2 mt-5 text-gray-500 hover:bg-gray-50"
          id="menu-button11"
          aria-expanded="true"
          aria-haspopup="true">
          {{ projectNameSub | async }}
          <!-- Heroicon name: solid/chevron-down -->
          <svg
            class="-mr-1 ml-2 h-5 w-5 justify-center align-items-center mt-1 mt-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd" />
          </svg>
        </button>

        <div
          #mobileProjectMenuDropdown
          class="toggle z-40 origin-top-right absolute left-2 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button12"
          tabindex="-1">
          <div class="py-1" role="none" data-index="1">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <a
              href="javascript:void(0)"
              [routerLink]="CREATE_PROJECT"
              class="text-gray-700 block px-4 py-2 text-sm"
              role="menuitem"
              tabindex="-1"
              >Add New Project</a
            >
            <!--a href="javascript:void(0)" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">SkyBox</a-->
            <ng-container
              *ngFor="let account of (accountsListSub | async) || []">
              <a
                href="javascript:void(0)"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                *ngFor="let project of account.projects">
                <ng-container
                  *ngIf="project.name.length > 24; else normalNameMobile"
                  >{{ project.name.substring(0, 21) + '...' }}</ng-container
                >
                <ng-template #normalNameMobile>{{ project.name }}</ng-template>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>

<ng-template #subscribe>
  <span class="p-1"> Subscribe Now </span>
</ng-template>
