import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-editable-survey-name',
  templateUrl: './editable-survey-name.component.html',
  styleUrls: ['./editable-survey-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableSurveyNameComponent {
  @Input() defaultName: string | null = null;
  @Input() icon?: string | null;
  @Input() color?: string;
  @Input() name?: string | null;
  @Input() editModeActive = false;

  @Output() edit: EventEmitter<string> = new EventEmitter();
  @Output() pressLink: EventEmitter<void> = new EventEmitter();

  constructor() {}

  handleBlur(event: FocusEvent) {
    if (event.target instanceof HTMLInputElement) {
      this.edit.emit(event.target.value);
    }
  }
}
