<app-dropdown [closeDrowdownOnClick]="true">
  <app-button button #buttonElement variant="link">
    <div class="button flex items-center gap-2">
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.66671 5.66668H5.66671V9.66668H4.33337V5.66668H0.333374V4.33334H4.33337V0.333344H5.66671V4.33334H9.66671V5.66668Z"
          fill="#2F54EB" />
      </svg>
      {{ buttonTitle }}
    </div>
  </app-button>
  <div dropdown class="dropdown">
    <div
      (click)="$event.stopPropagation()"
      class="dropdown-header dropdown-row">
      {{ dropdownTitle }}
    </div>
    <button
      *ngFor="let filter of filters"
      class="dropdown-variant dropdown-row flex gap-2.5"
      (click)="add.emit(filter.type)">
      <div
        [ngSwitch]="filter.type"
        class="flex items-center justify-center h-5 w-5">
        <svg
          *ngSwitchCase="FilterType.Trait"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7 1.99992C7.91667 1.99992 8.66667 2.74992 8.66667 3.66659C8.66667 4.58325 7.91667 5.33325 7 5.33325C6.08333 5.33325 5.33333 4.58325 5.33333 3.66659C5.33333 2.74992 6.08333 1.99992 7 1.99992ZM7 10.3333C9.25 10.3333 11.8333 11.4083 12 11.9999H2C2.19167 11.3999 4.75833 10.3333 7 10.3333ZM7 0.333252C5.15833 0.333252 3.66667 1.82492 3.66667 3.66659C3.66667 5.50825 5.15833 6.99992 7 6.99992C8.84167 6.99992 10.3333 5.50825 10.3333 3.66659C10.3333 1.82492 8.84167 0.333252 7 0.333252ZM7 8.66658C4.775 8.66658 0.333334 9.78325 0.333334 11.9999V13.6666H13.6667V11.9999C13.6667 9.78325 9.225 8.66658 7 8.66658Z"
            fill="#1E2024" />
        </svg>
        <svg
          *ngSwitchCase="FilterType.Event"
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.16289 15.5H3.32955L4.16289 9.66667H1.24622C0.512885 9.66667 0.971219 9.04167 0.987885 9.01667C2.06289 7.11667 3.67955 4.28333 5.83789 0.5H6.67122L5.83789 6.33333H8.76289C9.09622 6.33333 9.27955 6.49167 9.09622 6.88333C5.80455 12.625 4.16289 15.5 4.16289 15.5Z"
            fill="#1E2024" />
        </svg>
        <svg
          *ngSwitchCase="FilterType.EventProperty"
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.16289 15.5H3.32955L4.16289 9.66667H1.24622C0.512885 9.66667 0.971219 9.04167 0.987885 9.01667C2.06289 7.11667 3.67955 4.28333 5.83789 0.5H6.67122L5.83789 6.33333H8.76289C9.09622 6.33333 9.27955 6.49167 9.09622 6.88333C5.80455 12.625 4.16289 15.5 4.16289 15.5Z"
            fill="#1E2024" />
        </svg>
        <svg
          *ngSwitchCase="FilterType.Cohort"
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.8917 7.94159C15.0333 8.71659 15.8333 9.76659 15.8333 11.1666V13.6666H19.1667V11.1666C19.1667 9.34992 16.1917 8.27492 13.8917 7.94159Z"
            fill="#0E0F11" />
          <path
            d="M12.5 6.99992C14.3417 6.99992 15.8333 5.50825 15.8333 3.66659C15.8333 1.82492 14.3417 0.333252 12.5 0.333252C12.1083 0.333252 11.7417 0.416585 11.3917 0.533252C12.0833 1.39158 12.5 2.48325 12.5 3.66659C12.5 4.84992 12.0833 5.94159 11.3917 6.79992C11.7417 6.91659 12.1083 6.99992 12.5 6.99992Z"
            fill="#0E0F11" />
          <path
            d="M7.5 6.99992C9.34167 6.99992 10.8333 5.50825 10.8333 3.66659C10.8333 1.82492 9.34167 0.333252 7.5 0.333252C5.65833 0.333252 4.16667 1.82492 4.16667 3.66659C4.16667 5.50825 5.65833 6.99992 7.5 6.99992ZM7.5 1.99992C8.41667 1.99992 9.16667 2.74992 9.16667 3.66659C9.16667 4.58325 8.41667 5.33325 7.5 5.33325C6.58333 5.33325 5.83333 4.58325 5.83333 3.66659C5.83333 2.74992 6.58333 1.99992 7.5 1.99992Z"
            fill="#0E0F11" />
          <path
            d="M7.5 7.83325C5.275 7.83325 0.833334 8.94992 0.833334 11.1666V13.6666H14.1667V11.1666C14.1667 8.94992 9.725 7.83325 7.5 7.83325ZM12.5 11.9999H2.5V11.1749C2.66667 10.5749 5.25 9.49992 7.5 9.49992C9.75 9.49992 12.3333 10.5749 12.5 11.1666V11.9999Z"
            fill="#0E0F11" />
        </svg>
        <svg
          *ngSwitchCase="FilterType.FilterGroup"
          class="mt-0.5"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.6667 2.33341V12.3334H5.66667V2.33341H15.6667ZM15.6667 0.666748H5.66667C4.75 0.666748 4 1.41675 4 2.33341V12.3334C4 13.2501 4.75 14.0001 5.66667 14.0001H15.6667C16.5833 14.0001 17.3333 13.2501 17.3333 12.3334V2.33341C17.3333 1.41675 16.5833 0.666748 15.6667 0.666748ZM9.39167 10.6667L6.5 7.75008L7.66667 6.57508L9.39167 8.30841L13.6667 4.00008L14.8333 5.17508L9.39167 10.6667ZM2.33333 4.00008H0.666666V15.6667C0.666666 16.5834 1.41667 17.3334 2.33333 17.3334H14V15.6667H2.33333V4.00008Z"
            fill="#1E2024" />
        </svg>
        <svg
          *ngSwitchCase="FilterType.Page"
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.8333 0.333008H2.16667C1.24167 0.333008 0.5 1.08301 0.5 1.99967V11.9997C0.5 12.9163 1.24167 13.6663 2.16667 13.6663H13.8333C14.75 13.6663 15.5 12.9163 15.5 11.9997V1.99967C15.5 1.08301 14.7583 0.333008 13.8333 0.333008ZM13.8333 11.9997H2.16667V3.66634H13.8333V11.9997Z"
            fill="#1E2024" />
        </svg>
        <svg
          *ngSwitchCase="FilterType.PageProperty"
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.8333 0.333008H2.16667C1.24167 0.333008 0.5 1.08301 0.5 1.99967V11.9997C0.5 12.9163 1.24167 13.6663 2.16667 13.6663H13.8333C14.75 13.6663 15.5 12.9163 15.5 11.9997V1.99967C15.5 1.08301 14.7583 0.333008 13.8333 0.333008ZM13.8333 11.9997H2.16667V3.66634H13.8333V11.9997Z"
            fill="#1E2024" />
        </svg>
        <svg
          *ngSwitchCase="FilterType.Mobile"
          width="12"
          height="20"
          viewBox="0 0 12 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.33331 0.833008H2.66665C1.51665 0.833008 0.583313 1.76634 0.583313 2.91634V17.083C0.583313 18.233 1.51665 19.1663 2.66665 19.1663H9.33331C10.4833 19.1663 11.4166 18.233 11.4166 17.083V2.91634C11.4166 1.76634 10.4833 0.833008 9.33331 0.833008ZM5.99998 18.333C5.30831 18.333 4.74998 17.7747 4.74998 17.083C4.74998 16.3913 5.30831 15.833 5.99998 15.833C6.69165 15.833 7.24998 16.3913 7.24998 17.083C7.24998 17.7747 6.69165 18.333 5.99998 18.333ZM9.74998 14.9997H2.24998V3.33301H9.74998V14.9997Z"
            fill="#1E2024" />
        </svg>
      </div>
      <div>
        <h6 class="dropdown-variant-title">{{ filter.title }}</h6>
        <p *ngIf="filter.description" class="dropdown-variant-description">
          {{ filter.description }}
        </p>
      </div>
    </button>
  </div>
</app-dropdown>
