<div (click)="$event.stopPropagation()">
  <div class="mt-2 mb-2">
    <div class="relative">
      <div
        class="date-picker-header flex items-center justify-between"
        [class.date-picker-header-active]="isOpened">
        <input type="hidden" name="date" x-ref="date" />
        <input
          type="text"
          readonly
          (click)="toggleDatepickerPopup()"
          placeholder="Select date"
          [value]="modifiedDate" />
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.6665 2.49992H14.8332V0.833252H13.1665V2.49992H4.83317V0.833252H3.1665V2.49992H2.33317C1.4165 2.49992 0.666504 3.24992 0.666504 4.16658V17.4999C0.666504 18.4166 1.4165 19.1666 2.33317 19.1666H15.6665C16.5832 19.1666 17.3332 18.4166 17.3332 17.4999V4.16658C17.3332 3.24992 16.5832 2.49992 15.6665 2.49992ZM15.6665 17.4999H2.33317V8.33325H15.6665V17.4999ZM15.6665 6.66658H2.33317V4.16658H15.6665V6.66658Z"
            fill="#1E2024" />
        </svg>
      </div>

      <div
        *ngIf="isOpened"
        class="date-picker-dropdown bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0">
        <div class="flex justify-between items-center mb-2">
          <div>
            <span class="text-lg font-bold text-gray-800">{{
              MONTH_NAMES[month]
            }}</span>
            <span class="ml-1 text-lg text-gray-600 font-normal">{{
              year
            }}</span>
          </div>
          <div>
            <button
              type="button"
              class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
              (click)="subtractMonth(month)">
              <svg
                class="h-6 w-6 text-gray-500 inline-flex"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button
              type="button"
              class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
              (click)="addDays(month)">
              <svg
                class="h-6 w-6 text-gray-500 inline-flex"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>

        <div class="flex flex-wrap mb-3 -mx-1">
          <div style="width: 14.26%" class="px-1" *ngFor="let day of WEEK_DAYS">
            <div class="text-gray-800 font-medium text-center text-xs">
              {{ day }}
            </div>
          </div>
        </div>

        <div class="flex flex-wrap -mx-1">
          <div
            *ngFor="let blankday of blankDays"
            style="width: 14.28%"
            class="text-center border p-1 border-transparent text-sm"></div>
          <ng-container *ngIf="daysOfTheMonth.length">
            <div
              style="width: 14.28%"
              class="px-1 mb-1"
              *ngFor="let day of daysOfTheMonth; index as dateIndex"
              :key="dateIndex">
              <div
                class="ddd cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100"
                [ngClass]="[
                  isDateActive(day)
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-700 hover:bg-blue-200'
                ]"
                (click)="setDate(day)">
                {{ day }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
