<svg
  *ngIf="active; else inactive"
  width="24"
  height="28"
  viewBox="0 0 24 28"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M17.625 0.25H2.625C1.25 0.25 0.125 1.375 0.125 2.75V20.25H2.625V2.75H17.625V0.25ZM16.375 5.25L23.875 12.75V25.25C23.875 26.625 22.75 27.75 21.375 27.75H7.6125C6.2375 27.75 5.125 26.625 5.125 25.25L5.1375 7.75C5.1375 6.375 6.25 5.25 7.625 5.25H16.375ZM15.125 14H22L15.125 7.125V14Z"
    fill="#2D4EFF" />
</svg>

<ng-template #inactive>
  <svg
    width="24"
    height="28"
    viewBox="0 0 24 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.625 0.25H2.625C1.25 0.25 0.125 1.375 0.125 2.75V20.25H2.625V2.75H17.625V0.25ZM16.375 5.25H7.625C6.25 5.25 5.1375 6.375 5.1375 7.75L5.125 25.25C5.125 26.625 6.2375 27.75 7.6125 27.75H21.375C22.75 27.75 23.875 26.625 23.875 25.25V12.75L16.375 5.25ZM7.625 25.25V7.75H15.125V14H21.375V25.25H7.625Z"
      fill="#454545" />
  </svg>
</ng-template>
