import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-icon-edit',
  templateUrl: './icon-edit.component.html',
  styleUrls: ['./icon-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconEditComponent {
  @Input() color = '#1E2024';

  constructor() {}
}
