<div class="relative w-full">
  <div *ngIf="title" class="flex items-center justify-between mb-1">
    <h4 class="picker-title">{{ title }}</h4>
    <ng-content></ng-content>
  </div>
  <button type="button" class="picker-header flex items-center justify-between">
    <div
      *ngIf="selected.length; else placeholderBlock"
      (click)="searchElement?.nativeElement?.focus()"
      class="cursor-text w-full">
      <div
        #pickerHeader
        class="w-full h-full flex flex-wrap items-center gap-1">
        <span class="picker-header-label" *ngFor="let element of selected">
          {{ element }}
          <app-icon-close
            class="cursor-pointer"
            (click)="handleDelete($event, element)"
            size="8px"></app-icon-close>
        </span>
        <input
          #search
          [required]="required"
          [autofocus]="autofocus"
          [(ngModel)]="searchString"
          (keyup)="handleChange($event)"
          (blur)="handleBlur()"
          class="picker-header-search picker-header-text picker-header-text-value"
          type="text" />
      </div>
    </div>
  </button>
</div>

<ng-template #placeholderBlock>
  <input
    #search
    [required]="required"
    [autofocus]="autofocus"
    [placeholder]="placeholder"
    [(ngModel)]="searchString"
    (keyup)="handleChange($event)"
    (blur)="handleBlur()"
    class="picker-header-search picker-header-text picker-header-text-value"
    type="text" />
</ng-template>
