import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable, BehaviorSubject, Subscription, lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { DownloadCriteria } from 'src/app/components/download-button/download-button.component';
import {
  API_URL_SURVEY_PREFIX,
  API_URL_SURVEY_STATUS_PREFIX,
} from 'src/app/constants/constants';
import {
  ADD_UPDATE_SURVEY_KEYUP,
  GENERATE_EMAIL_SURVEY_TEMPLATE,
  GET_SURVEYS,
} from 'src/app/endpoints/endpoints';
import { PermissionService } from 'src/app/services/permission/permission.service';
import {
  RatingTextType,
  InputType,
  Screen,
  Survey,
  SurveyScreenMediaOperation,
} from 'src/app/types/survey';

export type MediaOperation = {
  screenId: string;
  operation: SurveyScreenMediaOperation;
};
export type Translation = { [key: string]: { [language: string]: any } };

@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionService extends ApiCommonHandler {
  public static defaultLanguage = 'en-US';

  private surveyScreenMediaOperationsSub: BehaviorSubject<MediaOperation[]> =
    new BehaviorSubject([]);
  private currentSurveySub: BehaviorSubject<Survey | null> =
    new BehaviorSubject(null);
  private languageSub: BehaviorSubject<string> = new BehaviorSubject(
    SurveyQuestionService.defaultLanguage
  );
  private translationSub: BehaviorSubject<Translation | null> =
    new BehaviorSubject(null);

  public currentSurvey$ = this.currentSurveySub.asObservable();
  public language$ = this.languageSub.asObservable();
  public translation$ = this.translationSub.asObservable();
  public detectSurveyScreenErrors: EventEmitter<boolean> = new EventEmitter();
  public surveyScreenMediaOperations$ =
    this.surveyScreenMediaOperationsSub.asObservable();

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService
  ) {
    super();
  }

  public async translate(
    text: string,
    language = this.languageSub.value
  ): Promise<string> {
    if (this.translationSub.value && language) {
      return (
        this.translationSub.value[text]?.[language] ||
        this.translationSub.value[text]?.default ||
        text
      );
    } else {
      let translationSub: Subscription | null = null;

      const successPromise = new Promise(resolve => {
        translationSub = this.translationSub.subscribe(translation => {
          if (translation && language) {
            translationSub?.unsubscribe();

            resolve(
              translation[text]?.[language] ||
                translation[text]?.default ||
                text
            );
          }
        });
      });

      const declinePromise = new Promise(resolve => {
        setTimeout(() => {
          translationSub?.unsubscribe();

          resolve(text);
        }, 30000);
      });

      return (await Promise.race([
        successPromise,
        declinePromise,
      ])) as Promise<string>;
    }
  }

  public setLanguage(language: string) {
    this.languageSub.next(language);
  }

  public setCurrentSurvey(survey: Survey | null) {
    this.currentSurveySub.next(survey);
  }

  async loadTranslations() {
    const translations = (await lastValueFrom(
      this.http.get('assets/localization/default-translation.json')
    )) as { [key: string]: string }[];
    const translationObject = {};

    translations.map(translation => {
      translationObject[translation.default] = translation;
    });

    this.translationSub.next(translationObject);
  }

  updateSurvey(data: Object, surveyId?: string) {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.put(
      this.getAPIFullUrlByName(API_URL_SURVEY_PREFIX + '/' + surveyId),
      data
    );
  }

  getSurveys(project_id: string | null, survey_id?: string): Observable<any> {
    const url = `${this.getAPIFullUrlByName(GET_SURVEYS + '/' + project_id)}/${
      survey_id || ''
    }`;
    return this.http.get<any>(url);
  }

  generateEmailSurveyTemplate(survey_id?: string): Observable<any> {
    const url = `${this.getAPIFullUrlByName(
      GENERATE_EMAIL_SURVEY_TEMPLATE
    )}/${survey_id}`;
    return this.http.get<any>(url);
  }

  addAndUpdateSingleScreen(
    data,
    project_id
  ): Observable<{ message: string; result: Survey }> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.post<{ message: string; result: any }>(
      this.getAPIFullUrlByName(
        `${API_URL_SURVEY_PREFIX}/${ADD_UPDATE_SURVEY_KEYUP}/${project_id}`
      ),
      data
    );
  }

  deleteSurvey(survey_id: string | undefined): Observable<any> {
    this.permissionService.validateCreateUpdateAndDeleteOperation();

    return this.http.delete(
      this.getAPIFullUrlByName(`${API_URL_SURVEY_PREFIX}/${survey_id}`)
    );
  }

  getCSVfileData(
    projectId: string,
    surveyId: string,
    filters: any[],
    criteria: DownloadCriteria
  ): Observable<any> {
    return this.http.post(
      this.getAPIFullUrlByName(`survey-response/export/csv`),
      { project_id: projectId, survey_id: surveyId, filters, criteria }
    );
  }

  changeStatus(survey_id: string | undefined, status: string): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.get(
      this.getAPIFullUrlByName(
        `/${API_URL_SURVEY_PREFIX}/${API_URL_SURVEY_STATUS_PREFIX}/${survey_id}/${status}`
      )
    );
  }

  async generateScreenModel(
    type: InputType,
    language = this.languageSub.value,
    id?: string,
    readonly = false
  ) {
    let title = '';
    let message = '';

    if (readonly) {
      switch (type) {
        case InputType.WELCOME:
          {
            title = await this.translate('A compelling headline…', language);
            message = await this.translate(
              'This will help us improve your experience.',
              language
            );
          }
          break;

        case InputType.END:
          {
            title = await this.translate('Thank you!', language);
            message = await this.translate(
              'Your answer has been recorded.',
              language
            );
          }
          break;

        default: {
          title = await this.translate('Enter your question here', language);
          message = await this.translate(
            'This will help us improve your experience.',
            language
          );
        }
      }
    } else {
      switch (type) {
        case InputType.WELCOME:
          message = await this.translate(
            'This will help us improve your experience.',
            language
          );
          break;

        case InputType.END:
          title = await this.translate('Thank you!', language);
          message = await this.translate(
            'Your answer has been recorded.',
            language
          );
          break;

        default:
          message = await this.translate(
            'This will help us improve your experience.',
            language
          );
      }
    }

    const buttonTitle = await this.translate(
      type === InputType.WELCOME ? 'Continue' : 'Submit',
      language
    );
    const ratingMinText = await this.translate('Not likely at all', language);
    const ratingMaxText = await this.translate('Extremely likely', language);
    const ratingText = {
      [RatingTextType.ZeroSecond]: await this.translate(
        'Please select an option',
        language
      ),
      [RatingTextType.OneSecond]: await this.translate(
        'Very dissatisfied',
        language
      ),
      [RatingTextType.TwoSecond]: await this.translate(
        'Somewhat dissatisfied',
        language
      ),
      [RatingTextType.ThreeSecond]: await this.translate(
        'Not dissatisfied nor satisfied',
        language
      ),
      [RatingTextType.FourSecond]: await this.translate(
        'Somewhat satisfied',
        language
      ),
      [RatingTextType.FiveSecond]: await this.translate(
        'Very satisfied',
        language
      ),
    };

    const screen = new Screen(
      type,
      title,
      message,
      buttonTitle,
      ratingText,
      ratingMinText,
      ratingMaxText,
      id
    );

    return screen;
  }

  addMediaOperation(operation: MediaOperation) {
    const operations = [...this.surveyScreenMediaOperationsSub.value];

    const index = operations.findIndex(o => o.screenId === operation.screenId);

    if (index !== -1) {
      operations.splice(index, 1, operation);
    } else {
      operations.push(operation);
    }

    this.surveyScreenMediaOperationsSub.next(operations);
  }

  deleteMediaOperation(screenId: string) {
    this.surveyScreenMediaOperationsSub.next(
      this.surveyScreenMediaOperationsSub.value.filter(
        o => o.screenId !== screenId
      )
    );
  }

  getMediaOperations() {
    return this.surveyScreenMediaOperationsSub.value;
  }

  getMediaOperationByScreenId(screenId: string) {
    return (
      this.surveyScreenMediaOperationsSub.value.find(
        operation => operation.screenId === screenId
      ) ?? null
    );
  }

  clearMediaOperations() {
    this.surveyScreenMediaOperationsSub.next([]);
  }
}
