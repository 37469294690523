import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-icon-users',
  templateUrl: './icon-users.component.html',
  styleUrls: ['./icon-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconUsersComponent {
  @Input() active = false;

  constructor() {}
}
