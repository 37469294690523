<app-dropdown
  placement="bottom-start"
  [appendToBody]="true"
  [useScrollToElement]="true"
  (openDropdown)="showDropdown()"
  (closeDropdown)="hideDropdown()">
  <app-label-filter
    button
    tooltip="Please select a cohort"
    [id]="cohort?.id"
    [showError]="isFilterInvalid"
    [active]="dropdownActive"
    [icon]="cohort?.icon"
    [type]="LabelFilterType.Cohort"
    (remove)="remove.emit()">
    <ng-container *ngIf="cohort?.label; else placeholder">
      <b>User</b>
      {{ conditionTitle }}
      <b>{{ cohort?.label }}</b>
    </ng-container>
    <ng-template #placeholder>Select a cohort</ng-template>
  </app-label-filter>
  <div dropdown class="dropdown flex flex-col gap-4">
    <app-cohort-select
      [selected]="cohort?.value"
      (setCohort)="setCohort($event)">
      <app-simple-select
        class="block ml-4"
        [value]="cohort?.condition"
        [elements]="operators"
        (update)="setCondition($event)">
      </app-simple-select>
    </app-cohort-select>
  </div>
</app-dropdown>
