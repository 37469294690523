import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxMaskModule } from 'ngx-mask';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { DirectivesModule } from 'src/app/modules/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import { ArrayInputComponent } from './array-input/array-input.component';
import { ButtonComponent } from './button/button.component';
import { CalenderComponent } from './calender/calender.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CohortSelectComponent } from './cohort-select/cohort-select.component';
import { ColorInputComponent } from './color-input/color-input.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ContainerComponent } from './container/container.component';
import { CopyComponent } from './copy/copy.component';
import { DataLogicTooltipComponent } from './data-logic-tooltip/data-logic-tooltip.component';
import { DeleteComponent } from './delete/delete.component';
import { DescriptionComponent } from './description/description.component';
import { DividerComponent } from './divider/divider.component';
import { DotsMenuComponent } from './dots-menu/dots-menu.component';
import { DotsMenuRenameRemoveComponent } from './dots-menu-rename-remove/dots-menu-rename-remove.component';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EditableSurveyNameComponent } from './editable-survey-name/editable-survey-name.component';
import { EmojiByNameComponent } from './emoji-by-name/emoji-by-name.component';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { ErrorGeneratorComponent } from './error-generator/error-generator.component';
import { EventPropertySelectComponent } from './event-property-select/event-property-select.component';
import { FeatureAccessWrapperComponent } from './feature-access-wrapper/feature-access-wrapper.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FilterLabelComponent } from './filter-label/filter-label.component';
import { AddFilterButtonComponent } from './filters/add-filter-button/add-filter-button.component';
import { CohortFilterComponent } from './filters/cohort-filter/cohort-filter.component';
import { DateFilterSectionComponent } from './filters/date-filter-section/date-filter-section.component';
import { EventFilterComponent } from './filters/event/event-filter/event-filter.component';
import { EventFilterWhereComponent } from './filters/event/event-filter-where/event-filter-where.component';
import { EventPropertyFilterComponent } from './filters/event/event-property-filter/event-property-filter.component';
import { EventPropertyValuesSelectComponent } from './filters/event/event-property-values-select/event-property-values-select.component';
import { FilterGroupComponent } from './filters/filter-group/filter-group.component';
import { FilterTooltipComponent } from './filters/filter-tooltip/filter-tooltip.component';
import { FilterWhereLabelComponent } from './filters/filter-where-label/filter-where-label.component';
import { FiltersComponent } from './filters/filters/filters.component';
import { LabelFilterComponent } from './filters/label-filter/label-filter.component';
import { PageFilterComponent } from './filters/page/page-filter/page-filter.component';
import { PageFilterGroupComponent } from './filters/page/page-filter-group/page-filter-group.component';
import { PageFilterWhereComponent } from './filters/page/page-filter-where/page-filter-where.component';
import { PagePropertyFilterComponent } from './filters/page/page-property-filter/page-property-filter.component';
import { PagePropertyValuesSelectComponent } from './filters/page/page-property-values-select/page-property-values-select.component';
import { RelationshipSwitcherComponent } from './filters/relationship-switcher/relationship-switcher.component';
import { TraitFilterComponent } from './filters/trait/trait-filter/trait-filter.component';
import { TraitPropertiesSelectComponent } from './filters/trait/trait-properties-select/trait-properties-select.component';
import { TriggerFilterComponent } from './filters/trigger-filter/trigger-filter.component';
import { HeaderComponent } from './header/header.component';
import { HiddenContentComponent } from './hidden-content/hidden-content.component';
import { IconComponent } from './icons/icon/icon.component';
import { IconArrowComponent } from './icons/icon-arrow/icon-arrow.component';
import { IconBulbComponent } from './icons/icon-bulb/icon-bulb.component';
import { IconCheckComponent } from './icons/icon-check/icon-check.component';
import { IconChevronComponent } from './icons/icon-chevron/icon-chevron.component';
import { IconClipboardComponent } from './icons/icon-clipboard/icon-clipboard.component';
import { IconClockComponent } from './icons/icon-clock/icon-clock.component';
import { IconCloseComponent } from './icons/icon-close/icon-close.component';
import { IconDangerCircleComponent } from './icons/icon-danger-circle/icon-danger-circle.component';
import { IconDeleteComponent } from './icons/icon-delete/icon-delete.component';
import { IconDeleteAlertComponent } from './icons/icon-delete-alert/icon-delete-alert.component';
import { IconDocumentationComponent } from './icons/icon-documentation/icon-documentation.component';
import { IconDotsComponent } from './icons/icon-dots/icon-dots.component';
import { IconEditComponent } from './icons/icon-edit/icon-edit.component';
import { IconEditAlertComponent } from './icons/icon-edit-alert/icon-edit-alert.component';
import { IconEventComponent } from './icons/icon-event/icon-event.component';
import { IconEventsComponent } from './icons/icon-events/icon-events.component';
import { IconIntegrationsComponent } from './icons/icon-integrations/icon-integrations.component';
import { IconLinkComponent } from './icons/icon-link/icon-link.component';
import { IconPlusComponent } from './icons/icon-plus/icon-plus.component';
import { IconRefreshComponent } from './icons/icon-refresh/icon-refresh.component';
import { IconSegmentComponent } from './icons/icon-segment/icon-segment.component';
import { IconSettingsComponent } from './icons/icon-settings/icon-settings.component';
import { IconSetupComponent } from './icons/icon-setup/icon-setup.component';
import { IconSlackComponent } from './icons/icon-slack/icon-slack.component';
import { IconSurveysComponent } from './icons/icon-surveys/icon-surveys.component';
import { IconUsersComponent } from './icons/icon-users/icon-users.component';
import { IconVolumeUpComponent } from './icons/icon-volume-up/icon-volume-up.component';
import { InlineTooltipComponent } from './inline-tooltip/inline-tooltip.component';
import { InputComponent } from './input/input.component';
import { LabelsPickerComponent } from './labels-picker/labels-picker.component';
import { LightLayoutComponent } from './light-layout/light-layout.component';
import { LinkComponent } from './link/link.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { MaintenanceBannerComponent } from './maintenance-banner/maintenance-banner.component';
import { ModalComponent } from './modal/modal.component';
import { NoTableDataPlaceholderComponent } from './no-table-data-placeholder/no-table-data-placeholder.component';
import { PlansFormComponent } from './plans-form/plans-form.component';
import { ProLabelComponent } from './pro-label/pro-label.component';
import { QuestionSelectComponent } from './question-select/question-select.component';
import { RadioComponent } from './radio/radio.component';
import { RandomAvatarComponent } from './random-avatar/random-avatar.component';
import { ResultExpireComponent } from './result-expire/result-expire.component';
import { SearchComponent } from './search/search.component';
import { SegmentElementComponent } from './segment/segment-element/segment-element.component';
import { SegmentComponent } from './segment/segment.component';
import { SelectComponent } from './select/select.component';
import { SidePanelWithDeleteComponent } from './side-panel-with-delete/side-panel-with-delete.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidepanelComponent } from './sidepanel/sidepanel.component';
import { SimpleSelectComponent } from './simple-select/simple-select.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SquareArrowLinkComponent } from './square-arrow-link/square-arrow-link.component';
import { StatusLabelComponent } from './status-label/status-label.component';
import { SurveyAvatarComponent } from './survey-avatar/survey-avatar.component';
import { SwitchComponent } from './switch/switch.component';
import { TableComponent } from './table/table.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimingOptionsPickerComponent } from './timing-options-picker/timing-options-picker.component';
import { TitleComponent } from './title/title.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TraitSelectComponent } from './trait-select/trait-select.component';
import { UpdatePlanModalComponent } from './update-plan-modal/update-plan-modal.component';
import { VerticalListComponent } from './vertical-list/vertical-list.component';
import { VerticalListElementComponent } from './vertical-list-element/vertical-list-element.component';

@NgModule({
  declarations: [
    ColorPickerComponent,
    HeaderComponent,
    SidebarComponent,
    ErrorGeneratorComponent,
    MaintenanceBannerComponent,
    MainLayoutComponent,
    IconSurveysComponent,
    IconComponent,
    IconDocumentationComponent,
    IconSettingsComponent,
    IconSetupComponent,
    IconUsersComponent,
    IconEventsComponent,
    IconBulbComponent,
    ContainerComponent,
    IconPlusComponent,
    IconClipboardComponent,
    IconArrowComponent,
    IconSegmentComponent,
    DownloadButtonComponent,
    IconCloseComponent,
    LightLayoutComponent,
    IconChevronComponent,
    IconIntegrationsComponent,
    IconDotsComponent,
    IconEditComponent,
    IconDeleteComponent,
    IconCheckComponent,
    IconDangerCircleComponent,
    SpinnerComponent,
    RandomAvatarComponent,
    RandomAvatarComponent,
    SwitchComponent,
    SelectComponent,
    TitleComponent,
    DescriptionComponent,
    DotsMenuComponent,
    SidepanelComponent,
    CheckboxComponent,
    InputComponent,
    ButtonComponent,
    SearchComponent,
    QuestionSelectComponent,
    DeleteComponent,
    InlineTooltipComponent,
    IconSlackComponent,
    ModalComponent,
    IconEditAlertComponent,
    IconDeleteAlertComponent,
    LabelsPickerComponent,
    IconRefreshComponent,
    TextareaComponent,
    EmojiByNameComponent,
    IconClockComponent,
    FilterLabelComponent,
    IconLinkComponent,
    IconClockComponent,
    LinkComponent,
    IconEventComponent,
    TooltipComponent,
    CopyComponent,
    SquareArrowLinkComponent,
    SidePanelWithDeleteComponent,
    DropdownComponent,
    VerticalListComponent,
    VerticalListElementComponent,
    DividerComponent,
    TableComponent,
    DataLogicTooltipComponent,
    FiltersComponent,
    AddFilterButtonComponent,
    RelationshipSwitcherComponent,
    LabelFilterComponent,
    FilterGroupComponent,
    TraitFilterComponent,
    TraitSelectComponent,
    RadioComponent,
    CalenderComponent,
    PlansFormComponent,
    UpdatePlanModalComponent,
    ProLabelComponent,
    ResultExpireComponent,
    FeatureAccessWrapperComponent,
    CohortFilterComponent,
    CohortSelectComponent,
    SimpleSelectComponent,
    EventFilterComponent,
    SegmentComponent,
    DateFilterSectionComponent,
    EventFilterWhereComponent,
    RadioComponent,
    ArrayInputComponent,
    TraitPropertiesSelectComponent,
    EventPropertyFilterComponent,
    EventPropertySelectComponent,
    EventPropertyValuesSelectComponent,
    FilterTooltipComponent,
    DotsMenuRenameRemoveComponent,
    IconVolumeUpComponent,
    NoTableDataPlaceholderComponent,
    SurveyAvatarComponent,
    StatusLabelComponent,
    EditableSurveyNameComponent,
    SegmentElementComponent,
    EmojiPickerComponent,
    ColorInputComponent,
    PageFilterComponent,
    PageFilterWhereComponent,
    FilterWhereLabelComponent,
    PagePropertyFilterComponent,
    TimingOptionsPickerComponent,
    PageFilterGroupComponent,
    PagePropertyValuesSelectComponent,
    TriggerFilterComponent,
    HiddenContentComponent,
    FileUploaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PipesModule,
    DirectivesModule,
    PickerModule,
    NgxMaskModule.forRoot(),
    VirtualScrollerModule,
    NgxTippyModule,
  ],
  exports: [
    ColorPickerComponent,
    HeaderComponent,
    SidebarComponent,
    ErrorGeneratorComponent,
    MaintenanceBannerComponent,
    MainLayoutComponent,
    ContainerComponent,
    IconSurveysComponent,
    IconComponent,
    IconDocumentationComponent,
    IconSettingsComponent,
    IconSetupComponent,
    IconUsersComponent,
    IconEventsComponent,
    IconPlusComponent,
    IconArrowComponent,
    IconSegmentComponent,
    IconClipboardComponent,
    DownloadButtonComponent,
    IconClipboardComponent,
    IconChevronComponent,
    IconIntegrationsComponent,
    IconDotsComponent,
    IconEditComponent,
    IconDeleteComponent,
    IconCheckComponent,
    IconBulbComponent,
    IconCloseComponent,
    IconDangerCircleComponent,
    LightLayoutComponent,
    SpinnerComponent,
    RandomAvatarComponent,
    RandomAvatarComponent,
    SwitchComponent,
    SelectComponent,
    TitleComponent,
    DescriptionComponent,
    DotsMenuComponent,
    SidepanelComponent,
    CheckboxComponent,
    InputComponent,
    ButtonComponent,
    SearchComponent,
    QuestionSelectComponent,
    DeleteComponent,
    InlineTooltipComponent,
    IconSlackComponent,
    ModalComponent,
    IconEditAlertComponent,
    IconDeleteAlertComponent,
    LabelsPickerComponent,
    IconRefreshComponent,
    TextareaComponent,
    EmojiByNameComponent,
    TooltipComponent,
    CopyComponent,
    SquareArrowLinkComponent,
    SidePanelWithDeleteComponent,
    FilterLabelComponent,
    IconEventComponent,
    IconLinkComponent,
    IconClockComponent,
    LinkComponent,
    DropdownComponent,
    VerticalListComponent,
    VerticalListElementComponent,
    DividerComponent,
    TableComponent,
    DataLogicTooltipComponent,
    FiltersComponent,
    TraitSelectComponent,
    RadioComponent,
    CalenderComponent,
    PlansFormComponent,
    UpdatePlanModalComponent,
    ProLabelComponent,
    ResultExpireComponent,
    FeatureAccessWrapperComponent,
    CohortSelectComponent,
    SimpleSelectComponent,
    SegmentComponent,
    RadioComponent,
    EventPropertySelectComponent,
    DotsMenuRenameRemoveComponent,
    IconVolumeUpComponent,
    NoTableDataPlaceholderComponent,
    SurveyAvatarComponent,
    StatusLabelComponent,
    EditableSurveyNameComponent,
    SegmentElementComponent,
    EmojiPickerComponent,
    ColorInputComponent,
    AddFilterButtonComponent,
    PageFilterComponent,
    TimingOptionsPickerComponent,
    EventFilterComponent,
    ArrayInputComponent,
    TriggerFilterComponent,
    HiddenContentComponent,
    FileUploaderComponent,
  ],
  providers: [],
})
export class ComponentsModule {}
