import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

import { LabelFilterType } from '../enums';

@Component({
  selector: 'app-label-filter',
  templateUrl: './label-filter.component.html',
  styleUrls: ['./label-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelFilterComponent {
  Type = LabelFilterType;

  @Input() id?: string;
  @Input() tooltip?: string;
  @Input() showError = false;
  @Input() active = false;
  @Input() type?: LabelFilterType;
  @Input() icon?: string;

  @Output() remove = new EventEmitter();

  constructor() {}

  removeFilter(event: Event) {
    event.stopPropagation();

    this.remove.emit();
  }
}
