<app-header>
  <a [routerLink]="'/' + activeProjectId + SURVEYS">
    <img
      class="logo"
      width="103px"
      height="24px"
      src="assets/icons/1flow-logo-dark.svg"
      alt="1flow logo" />
  </a>
</app-header>
<div class="flex">
  <div class="hidden sm:block">
    <app-sidebar
      [expanded]="expanded"
      [routes]="routes"
      (toggle)="toggle($event)"></app-sidebar>
  </div>
  <div
    class="content-wrapper"
    [class.z-0]="loadingRouteConfig"
    [class.content-wrapper-expanded]="expanded">
    <main
      class="content fs-6 d-flex flex-column flex-column-fluid"
      [class.p-8]="usePadding"
      id="kt_content">
      <div class="content-spacer">
        <div
          *ngIf="loadingRouteConfig; else content"
          class="content-spinner-wrapper full-w full-h flex items-center justify-center">
          <app-spinner></app-spinner>
        </div>
      </div>
    </main>
  </div>
</div>

<ng-template #content>
  <router-outlet></router-outlet>
</ng-template>
