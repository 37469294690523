<div class="radio flex items-center">
  <input
    #radio
    class="radio-input"
    type="radio"
    [disabled]="disabled"
    [checked]="active"
    (change)="update.emit()" />
  <p
    class="radio-label pl-2"
    [class.radio-label-active]="active"
    [class.radio-label-disabled]="disabled"
    (click)="radio.click()">
    <ng-content></ng-content>
  </p>
</div>
