export const events = {
  account_created: 'Account Created',
  company_added: 'Company Added',
  project_added: 'Project Added',
  sdk_integrated: 'SDK Integrated',
  teammate_invite: 'Teammate Invited',
  invitation_accepted: 'Invitation Accepted',
  survey_created: 'Survey Created',
  survey_published: 'Survey Published',
  survey_stopped: 'Survey Stopped',
  survey_resumed: 'Survey Resumed',
  logged_in: 'Logged In',
  logged_out: 'Logged Out',
  screen_added: 'Screen Added',
  screen_deleted: 'Screen Deleted',
  screen_type_changes: 'Screen Type Changed',
  screen_content_edited: 'Screen Content Edited',
  sharable_link_copied: 'Sharable Link Copied',
  survey_reported_viewed: 'Survey Report Viewed',
  csv_download_started: 'CSV Download Started',
  webhook_enabled: 'Webhook Enabled',
  api_key_added: 'API Key Added',
  template_saved: 'Template Saved',
  template_edited: 'Template Edited',
  template_deleted: 'Template Deleted',
  template_duplicate: 'Template Duplicated',
  trigger_added: 'Trigger Added',
  audience_rule_added: 'Audience Rule Added',
  user_profile_viewed: 'User Profile Viewed',
  cohort_created: 'Cohort Created',
  integration_connected: 'Integration Connected',
  media_added: 'Media Added',
  media_removed: 'Media Removed',
  ai_survey_generation_started: 'AI Survey Generation Started',
};
