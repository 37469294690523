import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-volume-up',
  templateUrl: './icon-volume-up.component.html',
  styleUrls: ['./icon-volume-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconVolumeUpComponent {
  @Input() active = false;

  constructor() {}
}
