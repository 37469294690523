import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import Pickr from '@simonwep/pickr';

export type ColorPicker = {
  default_color: string;
  default_opacity: number;
  current_color: string;
  current_opacity: number;
  default_color_rgba?: string;
  current_color_rgba?: string;
};

export type ColorPickerOutput = {
  current_color: string;
  current_color_opacity: number;
  event: string;
  current_color_rgba?: string;
};

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  public picker?: Pickr;

  @Input() colorPicker?: ColorPicker;
  @Input() useParentElementAsButton = false;

  @Output() handleEvents: EventEmitter<ColorPickerOutput> = new EventEmitter();

  @ViewChild('pickrContainer', { static: false })
  pickrContainerElement?: ElementRef<HTMLDivElement>;

  constructor() {}

  ngAfterViewInit() {
    this.initColorPicker();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colorPicker?.currentValue) {
      if (changes.colorPicker.firstChange) {
        this.updateColorPicker();
      } else {
        if (
          Object.keys(changes.colorPicker.currentValue).some(
            key =>
              changes.colorPicker.currentValue[key] !==
              changes.colorPicker.previousValue[key]
          )
        ) {
          this.updateColorPicker();
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.picker) {
      this.picker.destroyAndRemove?.();
    }
  }

  updateColorPicker() {
    const color = this.colorPicker?.current_color;

    if (this.picker && color) {
      this.picker.setColor(`#${color}`, true);
    }
  }

  initColorPicker() {
    const color =
      this.colorPicker?.current_color || this.colorPicker?.default_color;

    if (this.pickrContainerElement?.nativeElement) {
      try {
        this.picker = Pickr.create({
          el: this.pickrContainerElement?.nativeElement,
          theme: 'nano', // or 'monolith', or 'nano'
          default: `#${color}`,
          comparison: false,
          swatches: [
            'rgba(244, 67, 54, 1)',
            'rgba(233, 30, 99, 1)',
            'rgba(156, 39, 176, 1)',
            'rgba(103, 58, 183, 1)',
            'rgba(63, 81, 181, 1)',
            'rgba(33, 150, 243, 1)',
            'rgba(3, 169, 244, 1)',
            'rgba(0, 188, 212, 1)',
            'rgba(0, 150, 136, 1)',
            'rgba(76, 175, 80, 1)',
            'rgba(139, 195, 74, 1)',
            'rgba(205, 220, 57, 1)',
            'rgba(255, 235, 59, 1)',
            'rgba(255, 193, 7, 1)',
          ],
          useAsButton: this.useParentElementAsButton,
          components: {
            // Main components
            preview: false,
            opacity: true,
            hue: true,
            // Input / output Options
            interaction: {
              hex: false,
              rgba: false,
              hsla: false,
              hsva: false,
              cmyk: false,
              input: true,
              clear: true,
              save: true,
            },
          },
        });

        this.picker
          .on('init', () => {
            this.updateColorPicker();
          })
          .on('clear', () => {
            if (this.colorPicker) {
              this.handleEvents.emit({
                current_color: this.colorPicker.default_color,
                current_color_opacity: this.colorPicker.default_opacity,
                current_color_rgba: this.colorPicker.default_color_rgba,
                event: 'clear',
              });
            }

            this.picker?.hide();
          })
          .on('save', color => {
            if (this.colorPicker) {
              const [r, g, b, a] = color.toRGBA();
              const defaultColorRgba = `rgba(${r}, ${g}, ${b}, ${a})`;
              const defaultColor = color.toHEXA().toString().replace('#', '');
              const defaultOpacity = parseInt((color.a * 100).toFixed(0));

              if (this.colorPicker.current_color !== defaultColor) {
                this.handleEvents.emit({
                  current_color: defaultColor,
                  current_color_opacity: defaultOpacity,
                  current_color_rgba: defaultColorRgba,
                  event: 'save',
                });
              }

              setTimeout(() => {
                this.picker?.hide();
              }, 0);
            }
          })
          .on('hide', () => {
            const color = this.colorPicker?.current_color;

            if (this.picker && color) {
              this.picker.setColor(`#${color}`, true);
            }
          });
      } catch (error: any) {
        console.error('COLOR PICKER COMPONENTS', error?.message);
      }
    }
  }
}
