import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-icon-plus',
  templateUrl: './icon-plus.component.html',
  styleUrls: ['./icon-plus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPlusComponent {
  @Input() color = '#50555C';
  @Input() size = 24;
  @Input() border = 1;

  constructor() {}
}
