import { Injectable, EventEmitter } from '@angular/core';

import { io, Socket } from 'socket.io-client';

import { AUTH_TOKEN } from 'src/app/constants/constants';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UsersService } from 'src/app/services/users/users.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketClientService {
  public prefix = '1flow_';
  public static sdk_integration_success: EventEmitter<any> = new EventEmitter();
  public static project_events_list: EventEmitter<any> = new EventEmitter();
  public static current_filter_event_sessions: EventEmitter<any> =
    new EventEmitter();
  public static user_custom_properties: EventEmitter<any> = new EventEmitter();
  public socket: Socket | null = null;
  public connected: boolean = false;
  constructor(
    private storage: StorageService,
    private usersService: UsersService
  ) {}

  init = async (user_id = '') => {
    this.connected = true;
    const token = this.storage.getItem(AUTH_TOKEN);
    this.socket = io(`${environment.socket_url}`, {
      autoConnect: true,
      query: {
        authorization: token,
        user_id,
      },
      closeOnBeforeunload: true,
      reconnection: true,
      timeout: 10000,
      reconnectionDelay: 3000,
      forceNew: true,
      transports: ['websocket', 'polling'],
    });
    this.socketListeners(user_id);
  };

  socketListeners = user_id => {
    // console.log("====here calling init", this.socket?.connected);
    if (this.socket?.connected) {
      this.socket?.disconnect();
    }
    this.socket?.on('connect', () => {
      this.connected = this.socket?.connected || false;

      this.socket?.on('joinedRoom', this.joinedRoom);
      this.socket?.on('getAccountDetails', this.getAccountDetails);
      // this.socket?.on("checkSDKIntergrated", this.checkSDKIntergrated)
      this.socket?.on('sdk_integrated_event', this.sdkIntegratedReceive);
      this.socket?.on('eventsList', this.getEventsList);
      this.socket?.on('getEventSessions', this.recieveEventSession);
      this.socket?.on(
        'receivedUserCustomProperties',
        this.receivedUserCustomProperties
      );
    });
  };

  disconnect = () => {
    if (this.socket?.connected) {
      this.socket?.disconnect();
    }
  };
  join = (room_id: string) => {
    this.socket?.emit('join', { room_id });
  };

  joinedRoom = ({ message, user_id }) => {
    console.log('User_id');
  };

  getAccountDetails = ({ _id, name }) => {
    console.info(name, _id);
  };
  /*=====FOR SDK INTEGRATED EVENT  SOCKET START====*/
  sdkIntegratedEvent(project_id) {
    this.socket?.emit('sdk_integrated_event_be', { project_id });
  }

  sdkIntegratedReceive({ message }) {
    console.log('SDK INTEGRATED', message);
    SocketClientService.sdk_integration_success.emit(true);
  }

  /*=====FOR SDK INTEGRATED EVENT  SOCKET END====*/

  /*=====FOR SDK TEST CONNECTION  SOCKET START====*/
  checkIntegration({ user_id }) {
    this.socket?.emit('checkSDKIntergrated', { user_id });
  }

  // checkSDKIntergrated({ success }) {
  //   console.log('checkSDKIntergrated', success)
  //   SocketClientService.sdk_integration_success.emit(success)
  // }

  eventsList(
    project_id: string,
    filter_start_time: number | null = null,
    filter_end_time: number | null = null
  ) {
    this.socket?.emit('eventsList', {
      project_id,
      filter_start_time,
      filter_end_time,
    });
  }

  getEventsList({ eventsList }) {
    SocketClientService.project_events_list.emit(eventsList);
  }

  getEventSessions(project_id: string | null, event_name: string) {
    this.socket?.emit('getEventSessions', { project_id, event_name });
  }

  recieveEventSession({ _id, s }) {
    SocketClientService.current_filter_event_sessions.emit([{ _id, s }]);
  }

  /*=====FOR SDK TEST CONNECTION  SOCKET END====*/

  /*=====FOR GET USER CUSTOM PROPERTIES ====*/

  async triggerUserCustomPropertiesEvent({ project_id }) {
    if (project_id) {
      try {
        const { result } =
          await this.usersService.getProjectUserTraits(project_id);

        SocketClientService.user_custom_properties.emit(result.user_properties);
      } catch {
        SocketClientService.user_custom_properties.emit([]);
      }
    }
  }

  receivedUserCustomProperties({ user_proertires }) {
    SocketClientService.user_custom_properties.emit(user_proertires);
  }
  /*=====FOR GET USER CUSTOM PROPERTIES END ====*/
}
