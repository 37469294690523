import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { DataType } from 'src/app/common/enums';
import { PromiseService } from 'src/app/services/promise/promise.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends ApiCommonHandler {
  constructor(
    private http: HttpClient,
    private promiseService: PromiseService
  ) {
    super();
  }

  async getTraitValues({
    search,
    ...model
  }: {
    projectId: string;
    isDefaultTrait: boolean;
    limit: number;
    offset: number;
    trait: string;
    search?: string;
  }) {
    const { result } = await lastValueFrom(
      this.http.post<{ result: { trait_value: string[] } }>(
        this.getAPIFullUrlByName(
          `project/filter-v2-details${search ? `?search=${search}` : ''}`
        ),
        {
          project_id: model.projectId,
          isDefaultTrait: model.isDefaultTrait,
          limit: model.limit,
          offset: model.offset,
          trait: model.trait,
          type: 'trait_value',
        }
      )
    );

    return result.trait_value
      .filter(value => value)
      .map(value => value.replace(/"/g, ''));
  }

  async getTraits(model: {
    projectId: string;
    offset: number | 0;
    search?: string;
    type?: string;
  }) {
    const { result } = await this.promiseService.share(
      `projectService.getTraits.${JSON.stringify(model)}`,
      () => {
        return lastValueFrom(
          this.http.post<{
            result: { traits: { trait: string; data_type: DataType }[] };
          }>(
            this.getAPIFullUrlByName(
              `project/filter-v2-details${
                model.search ? `?search=${model.search}` : ''
              }`
            ),
            {
              project_id: model.projectId,
              limit: 10,
              offset: model.offset,
              type: model.type ? model.type : 'trait',
            }
          )
        );
      }
    );

    return result.traits;
  }

  async getEventProperties(model: {
    projectId: string;
    limit: number;
    offset: number;
    event_name?: string;
  }) {
    const { result } = await this.promiseService.share(
      `projectService.getEventProperties.${JSON.stringify(model)}`,
      () => {
        return lastValueFrom(
          this.http.post<{
            result: { properties: { property: string; data_type: DataType }[] };
          }>(this.getAPIFullUrlByName('project/filter-v2-details'), {
            project_id: model.projectId,
            limit: model.limit,
            offset: model.offset,
            event_name: model.event_name,
            type: 'property',
          })
        );
      }
    );

    return result.properties;
  }

  async getEventPropertyValues({
    search,
    ...model
  }: {
    projectId: string;
    limit: number;
    offset: number;
    property: string;
    search?: string;
    eventName?: string;
  }) {
    const { result } = await lastValueFrom(
      this.http.post<{
        result: { property_value: string[] };
      }>(
        this.getAPIFullUrlByName(
          `project/filter-v2-details${search ? `?search=${search}` : ''}`
        ),
        {
          project_id: model.projectId,
          limit: model.limit,
          offset: model.offset,
          property: model.property,
          event_name: model.eventName,
          type: 'property_value',
        }
      )
    );

    return result.property_value
      .filter(value => value)
      .map(value => value.replace(/"/g, ''));
  }
}
