import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { UserType } from 'src/app/common/enums';
import {
  API_URL_INVITE_CHECK_TEAM_MEMBERS,
  API_URL_INVITE_TEAM_DETAILS_MEMBERS,
  API_URL_INVITE_TEAM_MEMBERS,
} from 'src/app/constants/constants';
import { events } from 'src/app/constants/segment-event-list';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AccountsProjectsResponse } from 'src/app/types/projects';
import { Roles } from 'src/app/types/team-member';

export type AcceptAccount = {
  account_name: string;
  email: string;
  id: string;
  role: UserType;
  username: string;
};

export type TeamMember = {
  email: string;
  _id: string;
  role: Roles | null;
  account_id: string | null;
  accept_invite: boolean;
  created_on: number;
  deleted: boolean;
  deleted_on: number | null;
  schema_version: number;
  sent_invite: boolean;
  updated_on: number;
  user_details: {
    created_on: number;
    deleted: boolean;
    deleted_on: number | null;
    email: string;
    first_name: string;
    google_id: string;
    last_name: string;
    logon_type: string;
    password: string;
    phone_number: string;
    schema_version: number;
    step: string;
    t_user_id: string;
    updated_on: number;
  }[];
  user_id: string;
};

@Injectable({
  providedIn: 'root',
})
export class TeamMemberService extends ApiCommonHandler {
  invitesSub: BehaviorSubject<TeamMember[]> = new BehaviorSubject(
    [] as TeamMember[]
  );

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private accountsService: AccountsService,
    private toastService: ToastService,
    private segmentService: SegmentService,
    private permissionService: PermissionService
  ) {
    super();
  }

  get invites$(): Observable<TeamMember[]> {
    return this.invitesSub.asObservable();
  }

  setID(result: AcceptAccount) {
    const data = {
      expire_at: moment().add(30, 'minutes').format('x'),
      data: result,
    };
    this.storage.setSessionData('invite_token', JSON.stringify(data));
  }

  getID = () => {
    const json = this.storage.getSessionData('invite_token');

    if (!json) {
      return null;
    }

    try {
      const accountModel: {
        expire_at: string;
        data: AcceptAccount;
      } | null = JSON.parse(json);

      if (!accountModel) {
        return null;
      }

      const ex = accountModel?.expire_at;

      if (ex > moment().format('x')) {
        return accountModel;
      } else {
        this.storage.removeSessionData('invite_token');

        return null;
      }
    } catch {
      return null;
    }
  };

  inviteAcceoptTrigger = account_id => {
    let data: any = this.storage.getSessionData('invite_token');

    if (data !== '' && data !== null) {
      data = JSON.parse(data);
      let ex = data?.expire_at;
      let token = data?.data.id;
      if (ex > moment().format('x')) {
        this.checkInvite(token, account_id).subscribe(
          d => {
            this.acceptInvite(token).subscribe(res => {
              this.toastService.showSuccess(res.message);
              let data = res.result.data;
              this.segmentService.addEvent({
                event_name: events.invitation_accepted,
                event_data: data,
              });
              this.accountsService
                .getAccounts()
                .subscribe((res: AccountsProjectsResponse) => {
                  this.accountsService.setAccounts(res.result);
                  const account = this.accountsService.getAccountById(
                    d.result.account_id
                  );

                  this.accountsService.updateAccount({
                    account_id: d.result.account_id,
                    project_id: account?.projects[0]?.project_id,
                  });
                });
              this.storage.removeSessionData('invite_token');
            });
          },
          error => {
            if (
              error instanceof HttpErrorResponse &&
              error.status === HttpStatusCode.BadRequest
            ) {
              this.storage.removeSessionData('invite_token');
            }
          }
        );
      } else {
        this.storage.removeSessionData('invite_token');
      }
    }
  };

  sendInvite(data: any): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.post(
      this.getAPIFullUrlByName(API_URL_INVITE_TEAM_MEMBERS),
      data
    );
  }
  deleteInvite(invite_id): Observable<any> {
    return this.http.delete(
      this.getAPIFullUrlByName(`${API_URL_INVITE_TEAM_MEMBERS}/${invite_id}`)
    );
  }

  updateTeamMates(invite_id, role): Observable<any> {
    return this.http.post(
      this.getAPIFullUrlByName(`${API_URL_INVITE_TEAM_MEMBERS}/${invite_id}`),
      { role }
    );
  }
  acceptInvite(invite_id): Observable<any> {
    return this.http.put(
      this.getAPIFullUrlByName(`${API_URL_INVITE_TEAM_MEMBERS}/${invite_id}`),
      {}
    );
  }

  checkInvite(invite_id: string, account_id): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(
        `${API_URL_INVITE_TEAM_MEMBERS}${API_URL_INVITE_CHECK_TEAM_MEMBERS}/${invite_id}/${account_id}`
      )
    );
  }

  getAcceptInvite(invite_id) {
    return lastValueFrom(
      this.http.get<{
        result: AcceptAccount;
      }>(
        this.getAPIFullUrlByName(
          `${API_URL_INVITE_TEAM_DETAILS_MEMBERS}/${invite_id}`
        )
      )
    );
  }

  async loadInvites(account_id: string) {
    const { result } = await lastValueFrom(this.getAcceptInvites(account_id));

    this.invitesSub.next(result);
  }

  getAcceptInvites(account_id): Observable<{ result: TeamMember[] }> {
    return this.http.get<{ result: TeamMember[] }>(
      this.getAPIFullUrlByName(`${API_URL_INVITE_TEAM_MEMBERS}/${account_id}`)
    );
  }
}
