import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateAsAgoPipe } from './date-as-ago/date-as-ago.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';

@NgModule({
  declarations: [DateAsAgoPipe, SafeHtmlPipe, SafeUrlPipe],
  imports: [CommonModule],
  exports: [DateAsAgoPipe, SafeHtmlPipe, SafeUrlPipe],
})
export class PipesModule {}
