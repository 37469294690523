import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { FilterCondition } from 'src/app/common/enums';
import { FILTER_CONDITIONS } from 'src/app/constants';

import { LabelFilterType } from '../enums';
import { CohortFilter } from '../types';

@Component({
  selector: 'app-cohort-filter',
  templateUrl: './cohort-filter.component.html',
  styleUrls: ['./cohort-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CohortFilterComponent implements OnChanges {
  dropdownActive = false;
  LabelFilterType = LabelFilterType;
  operators = [
    {
      label: 'is in',
      value: FilterCondition.IsIn,
    },
    {
      label: 'is not in',
      value: FilterCondition.IsNotIn,
    },
  ];

  @Input() cohort: CohortFilter | null = null;

  @Output() update: EventEmitter<CohortFilter> = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() finish: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  get conditionTitle() {
    return this.cohort?.condition !== undefined
      ? FILTER_CONDITIONS[this.cohort.condition]
      : undefined;
  }

  get isFilterInvalid() {
    return !this.cohort?.label;
  }

  ngOnChanges({ cohort }: SimpleChanges) {
    if (cohort?.currentValue && !cohort.currentValue.condition) {
      this.setCondition(FilterCondition.IsIn);
    }
  }

  setCohort(model: { icon: string; label: string; value: string }) {
    if (this.cohort) {
      this.update.emit({
        ...this.cohort,
        label: model.label,
        value: model.value,
        icon: model.icon,
      });
    }
  }

  showDropdown() {
    this.dropdownActive = true;
  }

  hideDropdown() {
    this.dropdownActive = false;

    this.finish.emit(!this.isFilterInvalid);
  }

  setCondition(value: string | number) {
    if (this.cohort) {
      this.update.emit({
        ...this.cohort,
        condition: value as FilterCondition,
      });
    }
  }
}
