import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  @Input() autofocus = false;

  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    if (this.autofocus) {
      requestAnimationFrame(() => {
        this.host.nativeElement.focus();
      });
    }
  }
}
