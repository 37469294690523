import { Component, Injector } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router';

import { BaseDirective } from 'src/app/directives/base/base.directive';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-light-layout',
  templateUrl: './light-layout.component.html',
  styleUrls: ['./light-layout.component.scss'],
})
export class LightLayoutComponent extends BaseDirective {
  link: { url: string; title: string } | null = null;
  loadingRouteConfig = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    injector: Injector
  ) {
    super(injector);

    super.addSubscription(
      this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
        }
      })
    );

    super.addSubscription(
      this.activatedRoute.data.subscribe(data => {
        const link = { ...data.link };

        if (link.useProjectIdPrefix) {
          link.url = `/${this.sessionService.getActiveProject()}${link.url}`;
        }

        this.link = link;
      })
    );
  }
}
