<div>
  <div #button>
    <ng-content></ng-content>
  </div>
  <div
    #dropdown
    class="dropdown origin-top-right absolute hidden right-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button2"
    tabindex="-1">
    <div class="py-1" role="none">
      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
      <button
        type="button"
        (click)="handleSelect(InputType.WELCOME)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          class="icon icon-tabler icon-tabler-square-5 mr-2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.68602 3.30002L2.32602 5.66002C0.112682 7.87335 0.112682 11.46 2.32602 13.6734C4.53935 15.8867 8.12602 15.8867 10.3393 13.6734L14.346 9.66669C14.9927 9.02002 14.9927 7.96002 14.346 7.30669C14.266 7.22669 14.1793 7.15335 14.086 7.09335L14.346 6.83335C14.9927 6.18669 14.9927 5.12669 14.346 4.47335C14.2393 4.36669 14.1127 4.27335 13.986 4.20002C14.2527 3.58669 14.1393 2.85335 13.6393 2.35335C13.0593 1.77335 12.1593 1.71335 11.506 2.16669C11.4393 2.06669 11.366 1.97335 11.2793 1.88669C10.6327 1.24002 9.57268 1.24002 8.91935 1.88669L7.24602 3.56002C7.18602 3.46669 7.11268 3.38002 7.03268 3.30002C6.38602 2.65335 5.33268 2.65335 4.68602 3.30002ZM5.62602 4.24669C5.75935 4.11335 5.96601 4.11335 6.09935 4.24669C6.23268 4.38002 6.23268 4.58669 6.09935 4.72002L3.97935 6.84002C4.75935 7.62002 4.75935 8.88669 3.97935 9.66669L4.91935 10.6067C5.88602 9.64002 6.13268 8.22669 5.66602 7.03335L9.86602 2.83335C9.99935 2.70002 10.206 2.70002 10.3393 2.83335C10.4727 2.96669 10.4727 3.17335 10.3393 3.30669L7.27268 6.37335L8.21268 7.31335L12.2193 3.30669C12.3527 3.17335 12.5593 3.17335 12.6927 3.30669C12.826 3.44002 12.826 3.64669 12.6927 3.78002L8.68602 7.78669L9.62601 8.72669L12.926 5.42669C13.0593 5.29335 13.266 5.29335 13.3993 5.42669C13.5327 5.56002 13.5327 5.76669 13.3993 5.90002L9.62601 9.67335L10.566 10.6134L12.926 8.25335C13.0593 8.12002 13.266 8.12002 13.3993 8.25335C13.5327 8.38669 13.5327 8.59335 13.3993 8.72669L9.39935 12.7334C7.70602 14.4267 4.96602 14.4267 3.27268 12.7334C1.57935 11.04 1.57935 8.30002 3.27268 6.60669L5.62602 4.24669ZM15.3327 11.3334C15.3327 13.54 13.5393 15.3334 11.3327 15.3334V14.3334C12.986 14.3334 14.3327 12.9867 14.3327 11.3334H15.3327ZM0.666016 4.66669C0.666016 2.46002 2.45935 0.666687 4.66602 0.666687V1.66669C3.01268 1.66669 1.66602 3.01335 1.66602 4.66669H0.666016Z"
            fill="#2C3E50" />
        </svg>
        Welcome Screen
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.RATING_NUMERICAL)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-square-5 mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 16h2a2 2 0 1 0 0 -4h-2v-4h4" />
          <rect x="4" y="4" width="16" height="16" rx="2" />
        </svg>
        1-5 Rating
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.NPS)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-ruler-2 mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M17 3l4 4l-14 14l-4 -4z" />
          <path d="M16 7l-1.5 -1.5" />
          <path d="M13 10l-1.5 -1.5" />
          <path d="M10 13l-1.5 -1.5" />
          <path d="M7 16l-1.5 -1.5" />
        </svg>
        NPS
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.MCQ)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-list mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="9" y1="6" x2="20" y2="6" />
          <line x1="9" y1="12" x2="20" y2="12" />
          <line x1="9" y1="18" x2="20" y2="18" />
          <line x1="5" y1="6" x2="5" y2="6.01" />
          <line x1="5" y1="12" x2="5" y2="12.01" />
          <line x1="5" y1="18" x2="5" y2="18.01" />
        </svg>
        Multiple Choice
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.CHECKBOX)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-list-check mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />
          <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />
          <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />
          <line x1="11" y1="6" x2="20" y2="6" />
          <line x1="11" y1="12" x2="20" y2="12" />
          <line x1="11" y1="18" x2="20" y2="18" />
        </svg>
        Checkbox
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.RATING_FIVE_STAR)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-star mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
        </svg>
        5-Star Rating
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.RATING_EMOJI)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-mood-happy mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="9" y1="9" x2="9.01" y2="9" />
          <line x1="15" y1="9" x2="15.01" y2="9" />
          <path d="M8 13a4 4 0 1 0 8 0m0 0h-8" />
        </svg>
        Emoji Rating
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.SHORT_TEXT)"
        class="text-gray-700 group hover:bg-gray-100 flex items-center px-6 py-3 text-sm font-semibold w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-forms mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 3a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3" />
          <path d="M6 3a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3" />
          <path d="M13 7h7a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-7" />
          <path d="M5 7h-1a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h1" />
          <path d="M17 12h.01" />
          <path d="M13 12h.01" />
        </svg>
        Short Text
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.TEXT)"
        class="text-gray-700 group hover:bg-gray-100 flex items-center px-6 py-3 text-sm font-semibold w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-6">
        <svg
          class="icon icon-tabler icon-tabler-forms mr-2"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#2c3e50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.25 8.64258V16.25C16.25 16.7473 16.0525 17.2242 15.7008 17.5758C15.3492 17.9275 14.8723 18.125 14.375 18.125H5.625C5.12772 18.125 4.65081 17.9275 4.29917 17.5758C3.94754 17.2242 3.75 16.7473 3.75 16.25V3.75C3.75 3.25272 3.94754 2.77581 4.29917 2.42417C4.65081 2.07254 5.12772 1.875 5.625 1.875H9.48242C9.81383 1.87505 10.1316 2.0067 10.366 2.24102L15.884 7.75898C16.1183 7.99335 16.2499 8.31117 16.25 8.64258Z"
            stroke="#2C3E50"
            stroke-linejoin="round" />
          <path
            d="M10 2.1875V6.875C10 7.20652 10.1317 7.52446 10.3661 7.75888C10.6005 7.9933 10.9185 8.125 11.25 8.125H15.9375M6.875 11.25H13.125M6.875 14.375H13.125"
            stroke="#2C3E50"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        Long Text
      </button>
      <button
        type="button"
        (click)="handleSelect(InputType.END)"
        class="text-gray-700 group flex items-center px-6 py-3 text-sm font-semibold hover:bg-gray-100 w-full"
        role="menuitem"
        tabindex="-1"
        id="menu-item-6">
        <svg
          width="20"
          height="20"
          class="icon icon-tabler icon-tabler-forms mr-2"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5 3.875V2.3125C11.5 1.8981 11.3354 1.50067 11.0424 1.20765C10.7493 0.91462 10.3519 0.75 9.9375 0.75H2.4375C2.0231 0.75 1.62567 0.91462 1.33265 1.20765C1.03962 1.50067 0.875 1.8981 0.875 2.3125V11.6875C0.875 12.1019 1.03962 12.4993 1.33265 12.7924C1.62567 13.0854 2.0231 13.25 2.4375 13.25H9.9375C10.3519 13.25 10.7493 13.0854 11.0424 12.7924C11.3354 12.4993 11.5 12.1019 11.5 11.6875V10.125M14 3.875L17.125 7L14 10.125M6.46094 7H17.125"
            stroke="#2C3E50"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        End Screen
      </button>
    </div>
  </div>
</div>
