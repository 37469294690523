import { Component } from '@angular/core';

@Component({
  selector: 'app-icon-dots',
  templateUrl: './icon-dots.component.html',
  styleUrls: ['./icon-dots.component.scss'],
})
export class IconDotsComponent {
  constructor() {}
}
