export const colors = [
  {
    label: 'light-yellow',
    color_code: '#FAECA3',
  },
  {
    label: 'light-green',
    color_code: '#91ECC0',
  },
  {
    label: 'light-voilet',
    color_code: '#AFB0F3',
  },
  {
    label: 'light-blue',
    color_code: '#76B3FC',
  },
  {
    label: 'light-pink',
    color_code: '#FFB1D9',
  },
  {
    label: 'light-orange',
    color_code: '#F09973',
  },
  {
    label: 'light-green-parrot',
    color_code: '#D2FC7A',
  },
  {
    label: 'dark-yellow',
    color_code: '#FAE264',
  },
  {
    label: 'dark-voilet',
    color_code: '#7485DF',
  },
];
