<app-header>
  <a
    *ngIf="link; else default"
    class="link flex items-center gap-1.5"
    [routerLink]="link.url">
    <app-icon-arrow color="#636B75" [size]="20"></app-icon-arrow>
    {{ link.title }}
  </a>
</app-header>
<main class="content fs-6 d-flex flex-column flex-column-fluid" id="kt_content">
  <div class="content-spacer">
    <router-outlet></router-outlet>
    <div
      *ngIf="loadingRouteConfig"
      class="content-spinner-wrapper full-w full-h flex items-center justify-center">
      <app-spinner></app-spinner>
    </div>
  </div>
</main>

<ng-template #default>
  <div></div>
</ng-template>
