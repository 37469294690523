import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  Injector,
} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { SIDEBAR_FOCUSED } from 'src/app/constants/constants';
import { TEMPLATES } from 'src/app/constants/routes';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('visible', [
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      state(
        'hide',
        style({
          opacity: '0',
        })
      ),
      transition('hide => show', animate('300ms 100ms linear')),
      transition('show => hide', animate('300ms linear')),
    ]),
  ],
})
export class SidebarComponent extends BaseDirective {
  activeTitle: string | null = null;

  focusedSub = new BehaviorSubject(false);
  TEMPLATES = TEMPLATES;

  @Input() expanded = true;
  @Input() routes: {
    title: string;
    icon: string;
    routerLinkActiveOptions: { exact: boolean };
    target?: string;
    href?: string;
    routerLink?: string;
    withBottomBorder?: boolean;
  }[] = [];

  @Output() toggle = new EventEmitter();

  constructor(
    public sessionService: SessionService,
    private router: Router,
    injector: Injector
  ) {
    super(injector);

    if (localStorage.getItem(SIDEBAR_FOCUSED) === '1') {
      this.focusedSub.next(true);
    }

    super.addSubscription(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          if (this.focusedSub.value) {
            localStorage.setItem(SIDEBAR_FOCUSED, '1');
          }
        }

        if (event instanceof NavigationEnd) {
          localStorage.removeItem(SIDEBAR_FOCUSED);
        }
      })
    );
  }

  setActiveTitle(title: string) {
    this.activeTitle = title;
  }

  isActive(path: string, exact: boolean): boolean {
    return this.router.isActive(path, exact);
  }

  toggleExpand() {
    this.toggle.emit(!this.expanded);

    if (this.expanded) {
      this.focusedSub.next(false);
    }
  }

  mouseEnterSidebar() {
    if (!this.expanded) {
      requestAnimationFrame(() => {
        this.focusedSub.next(true);
      });
    }
  }

  mouseLeaveSidebar() {
    if (!this.expanded) {
      localStorage.removeItem(SIDEBAR_FOCUSED);
      this.focusedSub.next(false);
    }
  }
}
