<svg
  *ngIf="active; else inactive"
  width="18"
  height="24"
  viewBox="0 0 18 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.00162 0.5C7.58162 0.5 7.15162 0.54 6.72162 0.61C3.91162 1.11 1.64162 3.36 1.12162 6.16C0.64162 8.77 1.60162 11.17 3.34162 12.72C3.77162 13.1 4.00162 13.63 4.00162 14.19C4.00162 15.71 4.00162 18.5 4.00162 18.5H6.28162C6.63162 19.1 7.26162 19.5 8.00162 19.5C8.74162 19.5 9.38162 19.1 9.72162 18.5H12.0016V14.19C12.0016 13.64 12.2216 13.1 12.6416 12.73C14.0916 11.45 15.0016 9.58 15.0016 7.5C15.0016 3.63 11.8716 0.5 8.00162 0.5ZM10.0016 16.5H6.00162V15.5H10.0016V16.5ZM10.0016 14.5H6.00162V13.5H10.0016V14.5ZM8.50162 8.91V11.5H7.50162V8.91L5.67162 7.09L6.38162 6.38L8.00162 8L9.62162 6.38L10.3316 7.09L8.50162 8.91Z"
    fill="#2D4EFF" />
</svg>

<ng-template #inactive>
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.0021 0.125C8.4271 0.125 7.8396 0.175 7.2521 0.3C3.8021 0.9625 1.0521 3.75 0.402103 7.2C-0.197896 10.4625 1.0021 13.4625 3.1771 15.4C3.7146 15.875 4.0021 16.5375 4.0021 17.2375V20.125C4.0021 21.5 5.1271 22.625 6.5021 22.625H6.8521C7.2896 23.375 8.0771 23.875 9.0021 23.875C9.9271 23.875 10.7271 23.375 11.1521 22.625H11.5021C12.8771 22.625 14.0021 21.5 14.0021 20.125V17.2375C14.0021 16.55 14.2771 15.875 14.8021 15.4125C16.6146 13.8125 17.7521 11.475 17.7521 8.875C17.7521 4.0375 13.8396 0.125 9.0021 0.125ZM11.5021 17.625H6.5021V16.375H11.5021V17.625ZM6.5021 20.125V18.875H11.5021V20.125H6.5021ZM13.1396 13.55C13.0271 13.65 12.9396 13.775 12.8396 13.875H5.1521C5.0521 13.7625 4.9646 13.6375 4.8521 13.5375C3.2021 12.0625 2.4646 9.8625 2.8646 7.6625C3.3146 5.2375 5.3146 3.225 7.7271 2.75C8.1521 2.6625 8.5771 2.625 9.0021 2.625C12.4521 2.625 15.2521 5.425 15.2521 8.875C15.2521 10.6625 14.4896 12.3625 13.1396 13.55Z"
      fill="#454545" />
    <path d="M9.6271 10.125H8.3771V13.875H9.6271V10.125Z" fill="#454545" />
    <path
      d="M6.97647 7.46715L6.0926 8.35102L8.74422 11.0026L9.6281 10.1188L6.97647 7.46715Z"
      fill="#454545" />
    <path
      d="M8.37835 10.1315L9.26223 11.0154L11.9138 8.36373L11.03 7.47985L8.37835 10.1315Z"
      fill="#454545" />
  </svg>
</ng-template>
