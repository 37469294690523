import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-icon-documentation',
  templateUrl: './icon-documentation.component.html',
  styleUrls: ['./icon-documentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDocumentationComponent {
  @Input() active = false;

  constructor() {}
}
