import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

import { FilterOperator } from '../enums';

@Component({
  selector: 'app-relationship-switcher',
  templateUrl: './relationship-switcher.component.html',
  styleUrls: ['./relationship-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelationshipSwitcherComponent {
  FilterOperator = FilterOperator;

  @Input() active?: FilterOperator;

  @Output() toggle: EventEmitter<FilterOperator> = new EventEmitter();

  constructor() {}
}
