import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorInputComponent implements OnChanges {
  colorPicker?: {
    default_color: string;
    default_opacity: number;
    current_color: string;
    current_opacity: number;
  };
  hasError = false;

  @Input() required = false;
  @Input() color = '';
  @Input() opacity = 100;
  @Input() placeholder?: string = '';
  @Input() title?: string;
  @Input() description?: string;

  @Output() update: EventEmitter<{ color: string; opacity: number }> =
    new EventEmitter();

  constructor() {}

  ngOnChanges({ color, opacity }: SimpleChanges): void {
    if (color?.currentValue || opacity?.currentValue) {
      const color = this.color.replace('#', '');
      this.colorPicker = {
        default_color: color,
        default_opacity: this.opacity,
        current_color: color,
        current_opacity: this.opacity,
      };
    }
  }

  private setOpacityToHexColor(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

    return color + _opacity.toString(16).toUpperCase();
  }

  handleBlur(event: FocusEvent) {
    if (
      this.required &&
      event.target instanceof HTMLInputElement &&
      !event.target.value
    ) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }
  }

  updateColor(event: FocusEvent) {
    if (event.target instanceof HTMLInputElement) {
      let color = event.target.value;

      if (!color.includes('#')) {
        color = `#${color}`;
      }

      const valid = /^#[0-9a-f]{3,6}$/i.test(color);

      if (valid) {
        this.update.emit({
          color,
          opacity: this.opacity,
        });
      } else {
        event.target.value = this.color.replace('#', '');
      }
    }
  }

  updateOpacity({ target }: FocusEvent) {
    if (target instanceof HTMLInputElement) {
      const opacity = (target.value && +target.value.replace('%', '')) || 0;

      this.update.emit({
        opacity,
        color: this.color,
      });
    }
  }

  handleColorPicker(data: {
    current_color: string;
    current_color_opacity: number;
  }) {
    this.update.emit({
      color: `#${data.current_color}`,
      opacity: data.current_color_opacity,
    });
  }
}
