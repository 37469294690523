// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sharedSurveyBaseUrl: 'https://s.1flow.app/development/testing/shared/survey',
  web_app_base_url: 'https://dev.1flow.app/#',
  marketing_site: 'https://dev.marketing.1flow.app',
  useHash: true,
  cdnUrl: 'https://cdn-development.1flow.ai',
  stripe: {
    stripeMode: false,
  },
  integrationsApi: 'https://dev-integrations.1flow.app/v2',
  assetUrl: '/',
  admin_api_url: 'https://dev-dashboard-api.1flow.app/v1/admin/2021-06-15',
  base_url: 'https://dev-dashboard-api.1flow.app/api/v1/2021-06-15/',
  socket_url: 'https://dev.1flow.app',
  server_assets_url: 'http://localhost:8080/public/',
  firebaseConfig: {
    apiKey: 'AIzaSyAdp14UT3i2Em1KsbNHNuDZsmo8JjagkNU',
    authDomain: 'flow-7171f.firebaseapp.com',
    projectId: 'flow-7171f',
    storageBucket: 'flow-7171f.appspot.com',
    messagingSenderId: '1046672871029',
    appId: '1:1046672871029:web:b27b0761e6ad79495cb23c',
    measurementId: 'G-QGDVG38R3C',
  },
  googleProjectId: 'project-1046672871029',
  support_email: 'support@1flow.ai',
  admin_email: 'kai@skybluevpn.co',
  documentation_route: 'https://1flow.app/docs',
  js_documentation_route:
    'https://1flow.app/docs/javascript-92dad081f708402ea061c71f29ac2a0b',
  npm_documentation_route:
    'https://1flow.app/docs/node-js-427ff2d906264b75b9f2dde1b24ead06',
  segment_api_key: 'jauTRqLEVDs1BU4DUavrsNQ5MYZcLIyA',
  segment_version: '4.15.3',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
