import { Component } from '@angular/core';

import { UserType } from 'src/app/common/enums';
import { toUnixTimestamp } from 'src/app/common/utils';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { SessionService } from 'src/app/services/session/session.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

declare const window: Window & {
  Intercom: any;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'web-portal';
  public UserType = UserType;

  constructor(
    public permissionService: PermissionService,
    private sessionService: SessionService,
    private accountsService: AccountsService,
    private userService: UserService,
    private segmentService: SegmentService
  ) {
    if (environment.useHash && !window.location.hash) {
      window.location.href = `${window.location.origin}/#${window.location.pathname}${window.location.search}`;
    }

    this.sessionService.activeAccountId$.subscribe(async activeAccountId => {
      if (activeAccountId) {
        await Promise.all([
          this.userService.loadUserDetails(),
          this.accountsService.loadOrganizationAccountDetails(activeAccountId),
        ]);

        this.setAccountRole(activeAccountId);

        const account = this.accountsService.getAccountDetails();
        const user = this.userService.getUser();

        if (user && account) {
          this.segmentService.addUser({
            user_id: user.account_user_id,
            user_data: {
              name: user.first_name,
              email: user.email,
              company_name: account.name,
              company_id: account.account_id,
              created_at: toUnixTimestamp(account.created_on),
            },
          });

          this.segmentService.addGroup({
            group_id: account.account_id,
            group_data: {
              email: user.email,
              name: account.name,
              created_at: toUnixTimestamp(account.created_on),
              trial_start: toUnixTimestamp(account.trial_start),
            },
          });

          this.segmentService.setAreIdentifyAndGroupCalled(true);

          if (window.Intercom) {
            window.Intercom('boot', {
              email: user.email,
              name: user.first_name,
              user_id: user.current_user.id,
            });
          }
        }
      }
    });
  }

  private async setAccountRole(accountId: string) {
    const accountInfo = await this.accountsService.getAccountInfo(accountId);

    this.permissionService.setUserRole(accountInfo.role);
  }

  contactSupport() {
    if (window.Intercom) {
      window.Intercom('show');
    } else {
      window.alert("You can't use Intercom in this mode");
    }
  }
}
