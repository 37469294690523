<app-filter-tooltip
  [visible]="showError && !!tooltip && !active"
  [tooltip]="tooltip">
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</app-filter-tooltip>

<ng-template #content>
  <div [id]="id" class="label-filter flex" [class.label-filter-active]="active">
    <div
      class="label-filter-info p-2.5 flex items-center gap-2.5"
      [class.label-filter-info-active]="active">
      <div class="label-filter-info-icon">
        <span
          *ngIf="icon; else iconByType"
          [innerHTML]="icon | safeHtml"></span>
      </div>
      <p class="label-filter-text flex flex-wrap items-center gap-0.5">
        <ng-content></ng-content>
      </p>
    </div>
    <button
      (click)="removeFilter($event)"
      class="label-filter-remove flex items-center justify-center flex-shrink-0">
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.875 1.00625L7.99375 0.125L4.5 3.61875L1.00625 0.125L0.125 1.00625L3.61875 4.5L0.125 7.99375L1.00625 8.875L4.5 5.38125L7.99375 8.875L8.875 7.99375L5.38125 4.5L8.875 1.00625Z"
          fill="#1E2024" />
      </svg>
    </button>
  </div>
</ng-template>

<ng-template #iconByType>
  <ng-container [ngSwitch]="type">
    <svg
      *ngSwitchCase="Type.Cohort"
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4187 6.20625C11.275 6.7875 11.875 7.575 11.875 8.625V10.5H14.375V8.625C14.375 7.2625 12.1438 6.45625 10.4187 6.20625Z"
        fill="#2D4EFF" />
      <path
        d="M9.375 5.5C10.7563 5.5 11.875 4.38125 11.875 3C11.875 1.61875 10.7563 0.5 9.375 0.5C9.08125 0.5 8.80625 0.5625 8.54375 0.65C9.0625 1.29375 9.375 2.1125 9.375 3C9.375 3.8875 9.0625 4.70625 8.54375 5.35C8.80625 5.4375 9.08125 5.5 9.375 5.5Z"
        fill="#2D4EFF" />
      <path
        d="M5.625 5.5C7.00625 5.5 8.125 4.38125 8.125 3C8.125 1.61875 7.00625 0.5 5.625 0.5C4.24375 0.5 3.125 1.61875 3.125 3C3.125 4.38125 4.24375 5.5 5.625 5.5ZM5.625 1.75C6.3125 1.75 6.875 2.3125 6.875 3C6.875 3.6875 6.3125 4.25 5.625 4.25C4.9375 4.25 4.375 3.6875 4.375 3C4.375 2.3125 4.9375 1.75 5.625 1.75Z"
        fill="#2D4EFF" />
      <path
        d="M5.625 6.125C3.95625 6.125 0.625 6.9625 0.625 8.625V10.5H10.625V8.625C10.625 6.9625 7.29375 6.125 5.625 6.125ZM9.375 9.25H1.875V8.63125C2 8.18125 3.9375 7.375 5.625 7.375C7.3125 7.375 9.25 8.18125 9.375 8.625V9.25Z"
        fill="#2D4EFF" />
    </svg>
    <svg
      *ngSwitchCase="Type.Trait"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 1.75C6.1875 1.75 6.75 2.3125 6.75 3C6.75 3.6875 6.1875 4.25 5.5 4.25C4.8125 4.25 4.25 3.6875 4.25 3C4.25 2.3125 4.8125 1.75 5.5 1.75ZM5.5 8C7.1875 8 9.125 8.80625 9.25 9.25H1.75C1.89375 8.8 3.81875 8 5.5 8ZM5.5 0.5C4.11875 0.5 3 1.61875 3 3C3 4.38125 4.11875 5.5 5.5 5.5C6.88125 5.5 8 4.38125 8 3C8 1.61875 6.88125 0.5 5.5 0.5ZM5.5 6.75C3.83125 6.75 0.5 7.5875 0.5 9.25V10.5H10.5V9.25C10.5 7.5875 7.16875 6.75 5.5 6.75Z"
        fill="#1E2024" />
    </svg>
    <svg
      *ngSwitchCase="Type.EventProperty"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 1.75C6.1875 1.75 6.75 2.3125 6.75 3C6.75 3.6875 6.1875 4.25 5.5 4.25C4.8125 4.25 4.25 3.6875 4.25 3C4.25 2.3125 4.8125 1.75 5.5 1.75ZM5.5 8C7.1875 8 9.125 8.80625 9.25 9.25H1.75C1.89375 8.8 3.81875 8 5.5 8ZM5.5 0.5C4.11875 0.5 3 1.61875 3 3C3 4.38125 4.11875 5.5 5.5 5.5C6.88125 5.5 8 4.38125 8 3C8 1.61875 6.88125 0.5 5.5 0.5ZM5.5 6.75C3.83125 6.75 0.5 7.5875 0.5 9.25V10.5H10.5V9.25C10.5 7.5875 7.16875 6.75 5.5 6.75Z"
        fill="#1E2024" />
    </svg>
    <svg
      *ngSwitchCase="Type.Event"
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.87217 12.125H2.24717L2.87217 7.75H0.684674C0.134674 7.75 0.478424 7.28125 0.490924 7.2625C1.29717 5.8375 2.50967 3.7125 4.12842 0.875H4.75342L4.12842 5.25H6.32218C6.57218 5.25 6.70967 5.36875 6.57217 5.6625C4.10342 9.96875 2.87217 12.125 2.87217 12.125Z"
        fill="#1E2024" />
    </svg>
    <svg
      *ngSwitchCase="Type.Page"
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.875 0.5H2.125C1.43125 0.5 0.875 1.0625 0.875 1.75V9.25C0.875 9.9375 1.43125 10.5 2.125 10.5H10.875C11.5625 10.5 12.125 9.9375 12.125 9.25V1.75C12.125 1.0625 11.5688 0.5 10.875 0.5ZM10.875 9.25H2.125V3H10.875V9.25Z"
        fill="#1E2024" />
    </svg>
    <svg
      *ngSwitchCase="Type.PageProperty"
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.875 0.5H2.125C1.43125 0.5 0.875 1.0625 0.875 1.75V9.25C0.875 9.9375 1.43125 10.5 2.125 10.5H10.875C11.5625 10.5 12.125 9.9375 12.125 9.25V1.75C12.125 1.0625 11.5688 0.5 10.875 0.5ZM10.875 9.25H2.125V3H10.875V9.25Z"
        fill="#1E2024" />
    </svg>
  </ng-container>
</ng-template>
