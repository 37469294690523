import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  SimpleChanges,
  Injector,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { DataType, FilterCondition } from 'src/app/common/enums';
import { FILTER_CONDITIONS } from 'src/app/constants';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { ProjectService } from 'src/app/services/project/project.service';
import { SessionService } from 'src/app/services/session/session.service';

import { LabelFilterType } from '../../enums';
import { EventPropertyFilter } from '../../types';

@Component({
  selector: 'app-event-property-filter',
  templateUrl: './event-property-filter.component.html',
  styleUrls: ['./event-property-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPropertyFilterComponent
  extends BaseDirective
  implements OnChanges
{
  eventPropertyModel$: BehaviorSubject<EventPropertyFilter | null> =
    new BehaviorSubject(null);
  FILTER_CONDITIONS = FILTER_CONDITIONS;
  dropdownActive = false;
  DataType = DataType;
  LabelFilterType = LabelFilterType;
  FilterCondition = FilterCondition;
  properties: {
    property: string;
    data_type: DataType;
  }[] = [];
  answerValues: string[] = [];

  @Input() eventName?: string;
  @Input() eventProperty?: EventPropertyFilter | null = null;

  @Output() update: EventEmitter<EventPropertyFilter> = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() finish: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private projectService: ProjectService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnChanges({ eventProperty }: SimpleChanges) {
    if (eventProperty?.currentValue) {
      this.setTraitType();
      this.setAnswerValues();
    }
  }

  get isFilterInvalid() {
    if (this.eventProperty) {
      const { value, condition, answers = [] } = this.eventProperty;

      if (!value) {
        return true;
      }

      if (
        condition === FilterCondition.HasAnyValue ||
        condition === FilterCondition.IsUnknown
      ) {
        return false;
      }

      return answers.length === 0;
    }
    return true;
  }

  get conditionTitle() {
    if (this.eventProperty?.condition) {
      return FILTER_CONDITIONS[this.eventProperty.condition];
    }
    return '';
  }

  get valuePostfix() {
    if (
      this.eventProperty?.traitType === DataType.DATE &&
      this.eventProperty.answers?.length &&
      !Number.isNaN(+this.eventProperty.answers[0])
    ) {
      return ' days ago';
    }
    return '';
  }

  private setAnswerValues() {
    if (!this.eventPropertyModel$.value?.answers) {
      return;
    }

    const { answers = [] } = this.eventPropertyModel$.value;

    const answersLength = answers.length;
    const fewAnswers = answersLength > 1;

    if (fewAnswers) {
      this.answerValues = answers.map((answer, index) => {
        if (answersLength - 2 === index) {
          return answer;
        }
        if (answersLength - 1 === index) {
          return ` or ${answer}`;
        }
        return `${answer}, `;
      }, '');
    } else {
      this.answerValues = [...answers];
    }
  }

  private async setTraitType() {
    if (!this.eventProperty) {
      return;
    }

    this.eventPropertyModel$.next({ ...this.eventProperty });

    if (!this.properties.length) {
      const projectId = this.sessionService.getActiveProject();

      if (!projectId) {
        return;
      }

      this.properties = await this.projectService.getEventProperties({
        projectId,
        event_name: this.eventName,
        limit: 100,
        offset: 0,
      });
    }

    let { value, traitType } = this.eventProperty;

    if (!traitType) {
      const property = this.properties.find(p => p.property === value);

      if (property) {
        traitType = property.data_type;
      }
    }

    this.eventPropertyModel$.next({
      ...this.eventProperty,
      traitType,
    });
  }

  setEventProperty(model: {
    type: DataType;
    icon: string;
    label: string;
    value: string;
  }) {
    if (this.eventPropertyModel$.value) {
      this.update.emit({
        ...this.eventPropertyModel$.value,
        traitType: model.type,
        label: model.label,
        value: model.value,
        icon: model.icon,
        answers: [],
      });
    }
  }

  showDropdown() {
    this.dropdownActive = true;
  }

  hideDropdown() {
    this.dropdownActive = false;

    this.finish.emit(!this.isFilterInvalid);
  }

  setCondition(condition?: FilterCondition, answer?: string) {
    if (this.eventPropertyModel$.value) {
      this.update.emit({
        ...this.eventPropertyModel$.value,
        condition,
        answers: answer ? [answer] : [],
      });
    }
  }

  setAnswer(answer?: string) {
    if (this.eventPropertyModel$.value) {
      this.update.emit({
        ...this.eventPropertyModel$.value,
        answers: answer ? [answer] : [],
      });
    }
  }

  setAnswers(answers: string[]) {
    if (this.eventPropertyModel$.value) {
      this.update.emit({ ...this.eventPropertyModel$.value, answers });
    }
  }

  setDateValue(model: { condition?: FilterCondition; value?: string }) {
    if (this.eventPropertyModel$.value) {
      this.update.emit({
        ...this.eventPropertyModel$.value,
        condition: model.condition,
        answers: model.value ? [model.value] : [],
      });
    }
  }

  trackByAnswerValue(index: number, answer: string) {
    return `${index}-${answer}`;
  }
}
