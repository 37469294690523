<svg
  *ngIf="active; else inactive"
  width="28"
  height="18"
  viewBox="0 0 28 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M19 7.75C21.075 7.75 22.7375 6.075 22.7375 4C22.7375 1.925 21.075 0.25 19 0.25C16.925 0.25 15.25 1.925 15.25 4C15.25 6.075 16.925 7.75 19 7.75ZM9 7.75C11.075 7.75 12.7375 6.075 12.7375 4C12.7375 1.925 11.075 0.25 9 0.25C6.925 0.25 5.25 1.925 5.25 4C5.25 6.075 6.925 7.75 9 7.75ZM9 10.25C6.0875 10.25 0.25 11.7125 0.25 14.625V17.75H17.75V14.625C17.75 11.7125 11.9125 10.25 9 10.25ZM19 10.25C18.6375 10.25 18.225 10.275 17.7875 10.3125C19.2375 11.3625 20.25 12.775 20.25 14.625V17.75H27.75V14.625C27.75 11.7125 21.9125 10.25 19 10.25Z"
    fill="#2D4EFF" />
</svg>

<ng-template #inactive>
  <svg
    width="26"
    height="18"
    viewBox="0 0 26 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.25 11.1875C6.325 11.1875 0.5 12.65 0.5 15.5625V17.75H18V15.5625C18 12.65 12.175 11.1875 9.25 11.1875ZM3.425 15.25C4.475 14.525 7.0125 13.6875 9.25 13.6875C11.4875 13.6875 14.025 14.525 15.075 15.25H3.425ZM9.25 9C11.6625 9 13.625 7.0375 13.625 4.625C13.625 2.2125 11.6625 0.25 9.25 0.25C6.8375 0.25 4.875 2.2125 4.875 4.625C4.875 7.0375 6.8375 9 9.25 9ZM9.25 2.75C10.2875 2.75 11.125 3.5875 11.125 4.625C11.125 5.6625 10.2875 6.5 9.25 6.5C8.2125 6.5 7.375 5.6625 7.375 4.625C7.375 3.5875 8.2125 2.75 9.25 2.75ZM18.05 11.2625C19.5 12.3125 20.5 13.7125 20.5 15.5625V17.75H25.5V15.5625C25.5 13.0375 21.125 11.6 18.05 11.2625ZM16.75 9C19.1625 9 21.125 7.0375 21.125 4.625C21.125 2.2125 19.1625 0.25 16.75 0.25C16.075 0.25 15.45 0.4125 14.875 0.6875C15.6625 1.8 16.125 3.1625 16.125 4.625C16.125 6.0875 15.6625 7.45 14.875 8.5625C15.45 8.8375 16.075 9 16.75 9Z"
      fill="#454545" />
  </svg>
</ng-template>
