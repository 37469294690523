<app-dropdown
  placement="bottom-start"
  [appendToBody]="true"
  (closeDropdown)="hideDropdown()">
  <button
    button
    type="button"
    class="timing-options-picker-header flex gap-2 items-center justify-between">
    <span>{{ selectedLabel }}</span>
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.825 0.912598L5 4.72926L1.175 0.912598L0 2.0876L5 7.0876L10 2.0876L8.825 0.912598Z"
        fill="#2D4EFF" />
    </svg>
  </button>
  <div dropdown class="timing-options-picker-dropdown p-4 flex flex-col gap-4">
    <div *ngFor="let option of timingOptions; trackBy: trackByOption">
      <app-radio
        [active]="activeOption.type === option.value"
        (update)="setTimingOption(option.value)">
        {{ option.label }}
      </app-radio>
      <app-input
        *ngIf="
          activeOption.type === option.value &&
          option.value === TimingOption.SHOW_AFTER
        "
        class="block ml-6"
        placeholder="Enter value"
        inputType="number"
        [required]="true"
        [autofocus]="true"
        [(inputModel)]="activeOption.value">
        <p inputRight class="input-right-text">seconds</p>
      </app-input>
    </div>
  </div>
</app-dropdown>
