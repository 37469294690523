import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyComponent {
  copiedSub = new BehaviorSubject(false);

  @Input() text?: string;

  constructor() {}

  public async copy() {
    if (this.text) {
      await navigator.clipboard.writeText(this.text);

      this.copiedSub.next(true);
    }
  }

  public reset() {
    if (this.copiedSub.value) {
      setTimeout(() => {
        this.copiedSub.next(false);
      }, 300);
    }
  }
}
