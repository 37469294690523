<svg
  *ngIf="active; else inactive"
  width="24"
  height="24"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM7.71 13.29C7.32 13.68 6.69 13.68 6.3 13.29L2.71 9.7C2.32 9.31 2.32 8.68 2.71 8.29C3.1 7.9 3.73 7.9 4.12 8.29L7 11.17L13.88 4.29C14.27 3.9 14.9 3.9 15.29 4.29C15.68 4.68 15.68 5.31 15.29 5.7L7.71 13.29Z"
    fill="#2D4EFF" />
</svg>

<ng-template #inactive>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.75 0.75H3.25C1.875 0.75 0.75 1.875 0.75 3.25V20.75C0.75 22.125 1.875 23.25 3.25 23.25H20.75C22.125 23.25 23.25 22.125 23.25 20.75V3.25C23.25 1.875 22.125 0.75 20.75 0.75ZM20.75 20.75H3.25V3.25H20.75V20.75ZM19.4875 8.25L17.725 6.475L9.4875 14.7125L6.2625 11.5L4.4875 13.2625L9.4875 18.25L19.4875 8.25Z"
      fill="#454545" />
  </svg>
</ng-template>
