import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { Dropdown } from 'flowbite';

import { InputType } from 'src/app/types/survey';

@Component({
  selector: 'app-question-select',
  templateUrl: './question-select.component.html',
  styleUrls: ['./question-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionSelectComponent implements OnInit {
  InputType = InputType;

  @Output() add: EventEmitter<InputType> = new EventEmitter();
  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('button', { static: true })
  buttonElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('dropdown', { static: true })
  dropdownElement?: ElementRef<HTMLDivElement>;

  constructor() {}

  ngOnInit(): void {
    if (
      this.buttonElement?.nativeElement &&
      this.dropdownElement?.nativeElement
    ) {
      new Dropdown(
        this.dropdownElement.nativeElement,
        this.buttonElement.nativeElement,
        {
          placement: 'bottom',
          onShow: () => {
            this.toggle.emit(true);
          },
          onHide: () => {
            this.toggle.emit(false);
          },
        }
      );
    }
  }

  public handleSelect(type: InputType) {
    this.buttonElement?.nativeElement.click();
    this.add.emit(type);
  }
}
