import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { unix } from 'moment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { SubscriptionType, PlanType } from 'src/app/common/enums';
import { API_URL_GET_USER_DASHBOARD_PREFIX } from 'src/app/constants/constants';
import { Subscription } from 'src/app/types/projects';

export type ApiSubscription = {
  plans: ApiSubscriptionPlan[];
  _id: SubscriptionType;
};

export type ApiSubscriptionPlan = {
  amount: number;
  limit: number;
  order: string;
  created_at: number;
  currency: string;
  deleted: boolean;
  deleted_at: number | null;
  plan_id: string;
  plan_name: string;
  price_id: string;
  product_id: string;
  visible: boolean;
  subscription: 'monthly' | 'annual';
  __v: number;
  _id: string;
};

@Injectable({
  providedIn: 'root',
})
export class UserSubscriptionService extends ApiCommonHandler {
  private defaultPlanTypeSub: BehaviorSubject<PlanType | null> =
    new BehaviorSubject(null);
  private isUpdateModalOpenedSub: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  private subscriptionsSub: BehaviorSubject<ApiSubscription[]> =
    new BehaviorSubject([] as ApiSubscription[]);

  public defaultPlanType$ = this.defaultPlanTypeSub.asObservable();
  public isUpdateModalOpened$ = this.isUpdateModalOpenedSub.asObservable();
  public subscriptions$ = this.subscriptionsSub.asObservable();

  constructor(private http: HttpClient) {
    super();
  }

  getSubscriptonDetails(subscription: Subscription) {
    const currentPlan = this.getCurrentPlan(subscription.plan_id);
    return {
      mtuUsage: currentPlan?.plan_name,
      nextBillAmount: currentPlan?.amount,
      nextBillDate: unix(subscription.end_date).format('MMM Do,YYYY'),
    };
  }

  async loadSubscriptionPlansByProductId(id: string) {
    return lastValueFrom(
      this.http.get<{
        result: ApiSubscriptionPlan[];
      }>(this.getAPIFullUrlByName(`subscription/plans?product=${id}`))
    );
  }

  getBestPlan(totalUssage: number, type: SubscriptionType) {
    const plans = this.subscriptionsSub.value.find(s => s._id === type)?.plans;

    if (plans) {
      return plans
        .filter(plan => plan.visible)
        .sort((p1, p2) => p1.limit - p2.limit)
        .find(plan => plan.limit > totalUssage);
    }

    return;
  }

  getCurrentPlan(priceId: string) {
    const annualPlan = this.subscriptionsSub.value
      .find(s => s._id === SubscriptionType.Annual)
      ?.plans.find(plan => plan.price_id === priceId);

    if (annualPlan) {
      return annualPlan;
    }

    return this.subscriptionsSub.value
      .find(s => s._id === SubscriptionType.Monthly)
      ?.plans.find(plan => plan.price_id === priceId);
  }

  getUserDasboardUrl(accountId: string, returnUrl: string): Promise<any> {
    return lastValueFrom(
      this.http.get(
        this.getAPIFullUrlByName(
          `${API_URL_GET_USER_DASHBOARD_PREFIX}/${accountId}?return_url=${encodeURIComponent(
            returnUrl
          )}`
        )
      )
    );
  }

  async loadSubscriptions() {
    if (this.subscriptionsSub.value.length === 0) {
      const { result } = await lastValueFrom(
        this.http.get<{
          result: ApiSubscription[];
        }>(this.getAPIFullUrlByName(`subscription?all=true`))
      );

      this.subscriptionsSub.next(result);
    }
  }

  showUpdatePlanModal(usePro = true) {
    this.defaultPlanTypeSub.next(usePro ? PlanType.Pro : PlanType.Basic);
    this.isUpdateModalOpenedSub.next(true);
  }

  hideUpdatePlanModal() {
    this.defaultPlanTypeSub.next(null);
    this.isUpdateModalOpenedSub.next(false);
  }
}
