import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-error-generator',
  templateUrl: './error-generator.component.html',
  styleUrls: ['./error-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorGeneratorComponent {
  @Input() public errors: ValidationErrors | null = null;
  @Input() public key?: string | null = null;

  constructor() {}

  getKeys(data: { [key: string]: any }) {
    let [error] = Object.keys(data);

    return [error];
  }
}
