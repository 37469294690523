import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import avatar from 'animal-avatar-generator';

@Component({
  selector: 'app-random-avatar',
  templateUrl: './random-avatar.component.html',
  styleUrls: ['./random-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RandomAvatarComponent {
  @Input() id = 'default';
  @Input() size = 22;

  constructor() {}

  public generateAvatar() {
    return avatar(this.id, { size: this.size });
  }
}
