export const AUTH_TOKEN = '__session_one_flow_key';
export const ADMIN_AUTH_TOKEN = '__admin_session_one_flow_key';
export const REDIRECT_AFTER_LOGIN = '/';
export const REDIRECT_SIGNUP_SECOND_STEP = '/signup-two';
export const BEFORE_VALIDATE_STEP_ONE_TWO_RETURN_PATH = '/signup';
export const GET_PROJECT_API_KEY = 'web/keys';
export const MIN_QUESTION_TEXT_VALUE = 5;
export const MAX_QUESTION_TEXT_VALUE = 280;
export const NUMBER_OF_CHOICE_SHOW_IN_FIRSTTIME: number = 2;
export const DEFAULT_SURVEY_NAME = 'New survey';
export const SURVEY_PREVIEW_MODE = 'survey_preview_mode';
export const EMPTY_SURVEY_NAME = 'Untitled Microsurvey';
export const SIDEBAR_POSITION = 'sidebar_position';
export const SIDEBAR_FOCUSED = 'sidebar_focused';
export const DEFAULT_ANNOUNCEMENT_NAME = 'Untitled Announcement';

/*-----API ROUTES ----*/

export const DEFAULT_LOGIN_URL = '/login';
export const API_URL_EMAIL_SUBCRIPTION_PREFIX = 'subscription';
export const API_URL_SURVEY_RESPONSE_PREFIX = 'survey-response';
export const API_URL_PROJECT_SETTING_PREFIX = 'project/survey-setting/';
export const API_URL_PROJECT_WEBHOOK_PREFIX = 'project/webhook/';
export const API_URL_USER_PREFIX = 'user';
export const API_URL_USER_FORGET_EMAIL_PREFIX = 'forget';
export const API_URL_VALIDATE_PROJECT_KEY_NAME_PREFIX = 'web/keys';
export const API_URL_SURVEY_PREFIX = 'survey';
export const API_URL_SURVEY_CSV_PREFIX = 'export/csv';
export const API_URL_SURVEY_STATUS_PREFIX = 'status';
export const API_URL_PROJECTS_PREFIX = 'projects';
export const API_URL_CREATE_PROJECTS_PREFIX = 'project';
export const API_URL_ACCOUNTS_PREFIX = 'accounts';
export const API_URL_ACCOUNT_INFO_PREFIX = 'users';
export const API_URL_GET_USER_DASHBOARD_PREFIX = 'subscription/dashboard';
export const API_URL_ADD_UPDATE_SURVEY_KEYUP = 'add-screen';
export const API_URL_GET_USER_DETAILS = 'web/user';
export const API_URL_INVITE_TEAM_MEMBERS = 'invite';
export const API_URL_INVITE_TEAM_MEMBER_page = '/setting/invite';
export const API_URL_INVITE_TEAM_DETAILS_MEMBERS = 'invite/get/invite';
export const API_URL_INVITE_CHECK_TEAM_MEMBERS = '/valid';
export const API_URL_GET_PROJECT_BY_ACCOUNT_ID = 'get-project-id';
export const API_URL_GET_PROJECT_EVENTS = '/events';
export const API_URL_GET_PROJECT_COHORTS = 'cohorts/getCohorts';
export const API_UPDATE_SANDBOX_OPTIONS = 'project/sandbox';
/*------SURVEY PAGE VARIABLES-----*/

export const DEFAULT_COLOR = '2d4eff';
export const DEFAULT_COLOR_OPACITY = 100;
export const DEFAULT_COLOR_RGBA = 'rgba(45, 78, 255, 1)';
export const DEFAULT_BACKGROUND_COLOR = 'FFFFFF';
export const DEFAULT_BACKGROUND_COLOR_OPACITY = 100;
export const DEFAULT_BACKGROUND_COLOR_RGBA = 'rgba(255,255,255,1)';
export const DEFAULT_TEXT_COLOR = '000000';
export const DEFAULT_TEXT_COLOR_OPACITY = 100;
export const DEFAULT_TEXT_COLOR_RGBA = 'rgba(0,0,0,1)';

/*-----------------SNIPPET ------------------*/

export const INSTALLATION = "pod '1Flow'";
export const SDK_SWIFT_CONFIG = 'import _1Flow';
export const SDK_OBJECTIVE_C_CONFIG = '@import _1Flow';

/*---------------USER PERMISSON MEESAGE ---*/
export const REDIRECT_MESSAGE = 'Permission denied.';
export const NO_UPDATE_MESSAGE = 'Permission denied.';
export const CREATE_ACTION = 'create';
export const UPDATE_ACTION = 'update';
export const DELETE_ACTION = 'delete';
export const SURVEY_PUBLISH = 'survey_publish';

/*--------------DEFAULT SURVEY ANSWER COUNT  -------------*/
export const DEFAULT_SUVREY_RESPONSE = 3000;
