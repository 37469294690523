<a
  *ngIf="routerLink || href; else button"
  class="link flex items-center justify-center"
  [routerLink]="routerLink"
  [href]="href"
  [queryParams]="queryParams"
  [target]="routerLink ? '_self' : '_blank'">
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.00033 0.666664L5.06033 1.60666L8.78032 5.33333H0.666992V6.66666H8.78032L5.06033 10.3933L6.00033 11.3333L11.3337 6L6.00033 0.666664Z"
      fill="#2F54EB" />
  </svg>
</a>

<ng-template #button>
  <button
    type="button"
    class="link flex items-center justify-center"
    (click)="press.emit($event)">
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00033 0.666664L5.06033 1.60666L8.78032 5.33333H0.666992V6.66666H8.78032L5.06033 10.3933L6.00033 11.3333L11.3337 6L6.00033 0.666664Z"
        fill="#2F54EB" />
    </svg>
  </button>
</ng-template>
