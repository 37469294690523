import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { Dropdown } from 'flowbite';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements AfterViewInit, OnDestroy {
  private opened = false;
  private dropdown: Dropdown | null = null;
  private resizeObserver: ResizeObserver | null = null;
  private bodyDropdown: HTMLDivElement | null = null;

  @Input() placement: 'bottom-start' | 'bottom' | 'bottom-end' = 'bottom-end';
  @Input() closeDrowdownOnClick = false;
  @Input() appendToBody = false;
  @Input() useScrollToElement = false;

  @Output() openDropdown = new EventEmitter();
  @Output() closeDropdown = new EventEmitter();

  @ViewChild('button', { static: false })
  buttonElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('dropdown', { static: false })
  dropdownElement?: ElementRef<HTMLDivElement>;
  @ViewChild('templateRef', { read: TemplateRef, static: true })
  templateRef!: TemplateRef<any>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    this.init();

    if (this.useScrollToElement) {
      this.resizeObserver = new ResizeObserver(() => {
        if (this.opened && this.dropdown) {
          this.updatePosition();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  private updatePosition() {
    requestAnimationFrame(() => {
      this.buttonElement?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  private init() {
    if (this.appendToBody) {
      if (!this.bodyDropdown) {
        const element = this.viewContainerRef.createEmbeddedView(
          this.templateRef
        );

        element.detectChanges();

        this.bodyDropdown = element.rootNodes[0];

        if (this.bodyDropdown) {
          document.body.appendChild(this.bodyDropdown);

          if (this.buttonElement?.nativeElement) {
            this.initDropdown(this.bodyDropdown);
          }
        }
      }
    } else {
      if (this.dropdownElement?.nativeElement) {
        this.initDropdown(this.dropdownElement.nativeElement);
      }
    }
  }

  private initDropdown(element: HTMLDivElement) {
    if (this.buttonElement?.nativeElement) {
      this.dropdown = new Dropdown(element, this.buttonElement.nativeElement, {
        placement: this.placement,
        onShow: () => {
          this.opened = true;

          this.openDropdown.emit();

          if (this.buttonElement?.nativeElement) {
            this.resizeObserver?.observe(this.buttonElement.nativeElement);
          }
        },
        onHide: () => {
          this.opened = false;

          this.closeDropdown.emit();

          if (this.buttonElement?.nativeElement) {
            this.resizeObserver?.unobserve(this.buttonElement.nativeElement);
          }
        },
      });

      const button = this.buttonElement.nativeElement;

      // this overrides the default `_setupClickOutsideListener` method and prevents all nested drowdowns from closing on click outside
      this.dropdown._setupClickOutsideListener = function () {
        this._clickOutsideEventListener = ev => {
          if (
            ev.target instanceof Node &&
            !this._targetEl.contains(ev.target) &&
            !button.contains(ev.target)
          ) {
            ev.stopPropagation();
          }

          if (!this._targetEl.querySelector('app-dropdown .dropdown.block')) {
            this._handleClickOutside(ev, this._targetEl);
          }
        };

        document.body.addEventListener(
          'click',
          this._clickOutsideEventListener,
          true
        );
      };

      this.dropdown._removeClickOutsideListener = function () {
        document.body.removeEventListener(
          'click',
          this._clickOutsideEventListener,
          true
        );
      };
    }
  }

  private destroy() {
    this.buttonElement?.nativeElement.removeAllListeners?.('click');
    this.resizeObserver?.disconnect();

    if (this.bodyDropdown) {
      this.bodyDropdown.remove();

      this.bodyDropdown = null;
    }
  }

  handleDropdownClick() {
    if (this.closeDrowdownOnClick) {
      this.dropdown?.hide();
    }
  }
}
