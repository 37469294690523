import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ProjectCreateService } from 'src/app/services/project-create/project-create.service';
import { SessionService } from 'src/app/services/session/session.service';

@Directive({
  selector: '[appCheckAPIkeyName]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: CheckAPIkeyNameDirective,
      multi: true,
    },
  ],
})
export class CheckAPIkeyNameDirective {
  constructor(
    private projectService: ProjectCreateService,
    private sessionService: SessionService
  ) {}
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.projectService
      .validateProjectKeyName(
        this.sessionService.getActiveProject(),
        control.value
      )
      .pipe(
        map((res: any) => {
          return res
            ? { exits: true, message: 'API name already taken.' }
            : null;
        }),
        catchError(() => {
          return of(null);
        })
      );
  }
}
