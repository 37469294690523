<div class="label flex flex-wrap items-center cursor-pointer">
  <ng-container>
    <div
      *ngIf="svg; else iconByType"
      class="flex items-center w-max h-max mr-3"
      [innerHTML]="svg"></div>
  </ng-container>
  <b class="mr-0.5">{{ eventName }}</b>
  <span [innerHtml]="value"></span>

  <svg
    *ngIf="closeable"
    (click)="remove.emit()"
    class="close"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    rid="9">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.21959 1.22018C0.360236 1.07957 0.550967 1.00059 0.74984 1.00059C0.948713 1.00059 1.13944 1.07957 1.28009 1.22018L4.49984 4.43993L7.71959 1.22018C7.78878 1.14854 7.87153 1.09141 7.96304 1.0521C8.05454 1.01279 8.15296 0.992105 8.25254 0.991239C8.35212 0.990374 8.45088 1.00935 8.54306 1.04706C8.63523 1.08477 8.71897 1.14046 8.78939 1.21088C8.85981 1.2813 8.9155 1.36504 8.95321 1.45721C8.99092 1.54938 9.00989 1.64814 9.00903 1.74773C9.00816 1.84731 8.98747 1.94573 8.94817 2.03723C8.90886 2.12873 8.85172 2.21149 8.78009 2.28068L5.56034 5.50043L8.78009 8.72018C8.91671 8.86163 8.9923 9.05108 8.9906 9.24773C8.98889 9.44438 8.91001 9.63248 8.77095 9.77154C8.6319 9.9106 8.44379 9.98947 8.24714 9.99118C8.05049 9.99289 7.86104 9.9173 7.71959 9.78068L4.49984 6.56093L1.28009 9.78068C1.13864 9.9173 0.949186 9.99289 0.752539 9.99118C0.555891 9.98947 0.367782 9.9106 0.228726 9.77154C0.0896698 9.63248 0.0107929 9.44438 0.00908411 9.24773C0.00737529 9.05108 0.0829714 8.86163 0.21959 8.72018L3.43934 5.50043L0.21959 2.28068C0.0789867 2.14003 0 1.9493 0 1.75043C0 1.55155 0.0789867 1.36082 0.21959 1.22018Z"
      fill="#2D4EFF" />
  </svg>
</div>

<ng-template #iconByType>
  <div class="mr-3" [ngSwitch]="type">
    <app-icon-event *ngSwitchCase="'event'"></app-icon-event>
    <app-icon-link *ngSwitchCase="'link'"></app-icon-link>
    <app-icon-clock *ngSwitchCase="'clock'"></app-icon-clock>
  </div>
</ng-template>
