<ng-template #inputRightContent>
  <ng-content select="[inputRight]"></ng-content>
</ng-template>

<div>
  <div *ngIf="title" class="flex items-center justify-between mb-1">
    <h4 class="title">{{ title }}</h4>
    <ng-content select="[header]"></ng-content>
  </div>
  <div
    class="input flex items-center justify-between gap-3"
    [class.input-with-error]="hasError">
    <div
      class="input-color-box relative"
      [style.backgroundColor]="color"
      [style.opacity]="opacity / 100">
      <app-color-picker
        [useParentElementAsButton]="true"
        [colorPicker]="colorPicker"
        (handleEvents)="handleColorPicker($event)"></app-color-picker>
    </div>
    <input
      type="text"
      autocomplete="off"
      [placeholder]="placeholder"
      [value]="color?.replace('#', '')"
      [required]="required"
      (blur)="updateColor($event); handleBlur($event)" />
    <input
      type="text"
      autocomplete="off"
      suffix="%"
      mask="separator.0"
      [placeholder]="placeholder"
      [(ngModel)]="opacity"
      [required]="required"
      (blur)="updateOpacity($event); handleBlur($event)" />
  </div>
  <p *ngIf="description" class="description mt-1">
    <span [innerHTML]="description | safeHtml"></span>
  </p>
</div>
