import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-filter-tooltip',
  templateUrl: './filter-tooltip.component.html',
  styleUrls: ['./filter-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTooltipComponent {
  @Input() visible = false;
  @Input() tooltip?: string;

  constructor() {}
}
