import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-filter-where-label',
  templateUrl: './filter-where-label.component.html',
  styleUrls: ['./filter-where-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterWhereLabelComponent {
  constructor() {}
}
