<div class="relative w-full">
  <div *ngIf="title" class="flex items-center mb-1">
    <h4 class="select-title">{{ title }}</h4>
    <ng-content></ng-content>
  </div>
  <div
    #button
    *ngIf="selectedElements$ | async; let selectedElements"
    (click)="searchElement?.nativeElement?.focus()"
    class="select-header flex items-center justify-between cursor-pointer"
    [class.select-header-with-error]="
      required &&
      isVisited &&
      (useSearch && !multiple ? !searchString : !selectedElements.length)
    ">
    <div class="icon mr-1" *ngIf="timeIcon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none">
        <path
          d="M8.9915 0.666626C4.3915 0.666626 0.666504 4.39996 0.666504 8.99996C0.666504 13.6 4.3915 17.3333 8.9915 17.3333C13.5998 17.3333 17.3332 13.6 17.3332 8.99996C17.3332 4.39996 13.5998 0.666626 8.9915 0.666626ZM8.99984 15.6666C5.3165 15.6666 2.33317 12.6833 2.33317 8.99996C2.33317 5.31663 5.3165 2.33329 8.99984 2.33329C12.6832 2.33329 15.6665 5.31663 15.6665 8.99996C15.6665 12.6833 12.6832 15.6666 8.99984 15.6666ZM9.4165 4.83329H8.1665V9.83329L12.5415 12.4583L13.1665 11.4333L9.4165 9.20829V4.83329Z"
          fill="#636B75" />
      </svg>
    </div>
    <div
      *ngIf="selected.length; else placeholderBlock"
      class="select-header-content">
      <div
        *ngIf="multiple; else defaultHeader"
        #multipleHeader
        class="w-full h-full flex flex-wrap items-center gap-1"
        [class.cursor-text]="useSearch">
        <span
          class="select-header-label"
          *ngFor="let selectedElement of selectedElements">
          <span class="block truncate">{{ selectedElement.label }}</span>
          <app-icon-close
            class="cursor-pointer"
            (click)="deleteLabel($event, selectedElement)"
            size="8px"></app-icon-close>
        </span>
        <input
          #search
          *ngIf="useSearch"
          class="select-header-search select-header-text select-header-text-value"
          type="text"
          [(ngModel)]="searchString"
          (ngModelChange)="searchElements(); handleSearch.emit(searchString)"
          (keypress)="handleKeyPress.emit($event)" />
      </div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7" />
    </svg>
  </div>
  <ul
    #dropdown
    *ngIf="filteredElements$ | async; let filteredElements"
    class="select-dropdown hidden menu-cs absolute z-10 mt-1 mb-10 w-full bg-white max-h-60 overflow-auto py-1"
    tabindex="-1"
    (scroll)="handleScroll($event)">
    <app-spinner
      *ngIf="isLoading; else content"
      class="block py-8"></app-spinner>
    <ng-template #content>
      <ng-container *ngIf="isOpened$ | async">
        <li
          role="option"
          class="select-dropdown-element cursor-pointer select-dropdown-element-with-bottom-border block truncate">
          <ng-content select="[topElement]"></ng-content>
        </li>
        <ng-container
          *ngIf="
            searchString ? filteredElements.length : elements.length;
            else noData
          ">
          <virtual-scroller
            #scroll
            [style.height]="dropdownHeight"
            [items]="searchString ? filteredElements : elements"
            [parentScroll]="dropdown">
            <li
              *ngFor="let element of scroll.viewPortItems; index as i"
              [attr.value]="element.value"
              role="option"
              class="select-dropdown-element cursor-pointer"
              [class.select-dropdown-element-with-bottom-border]="
                element.useBottomBorder
              "
              (click)="updateSelected(element); $event.stopPropagation()">
              <div class="w-full">
                <div class="flex items-center gap-2.5">
                  <span
                    *ngIf="element.icon"
                    class="w-5 flex justify-center"
                    [innerHTML]="element.icon | safeHtml"></span>
                  <span class="block truncate">
                    {{ element.label }}
                  </span>
                </div>
                <p *ngIf="element.description" class="text-xs text-gray-400">
                  {{ element.description }}
                </p>
              </div>
              <svg
                *ngIf="selected.length && selected.includes(element.value)"
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#1E2024"
                aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd" />
              </svg>
            </li>

            <li *ngIf="loadMoreLoader">
              <app-spinner class="block py-8"></app-spinner>
            </li>
          </virtual-scroller>
        </ng-container>
      </ng-container>
    </ng-template>
  </ul>
</div>

<ng-template #placeholderBlock>
  <input
    #search
    *ngIf="useSearch; else placeholderText"
    class="select-header-search select-header-text select-header-text-value"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="searchString"
    (ngModelChange)="searchElements(); handleSearch.emit(searchString)"
    (keypress)="handleKeyPress.emit($event)" />
</ng-template>

<ng-template #placeholderText>
  <p class="select-header-text select-header-text-placeholder block truncate">
    {{ placeholder }}
  </p>
</ng-template>

<ng-template #defaultHeader>
  <input
    #search
    *ngIf="useSearch; else defaultHeaderText"
    class="w-full select-header-search select-header-text select-header-text-value"
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="searchString"
    (ngModelChange)="searchElements(); handleSearch.emit(searchString)"
    (keypress)="handleKeyPress.emit($event)" />
</ng-template>

<ng-template #defaultHeaderText>
  <div class="flex items-center gap-2">
    <span
      *ngIf="(selectedElements$ | async)?.[0]?.icon; let icon"
      class="select-header-text"
      [innerHTML]="icon | safeHtml"></span>
    <span class="select-header-text select-header-text-value block truncate">
      {{ (selectedElements$ | async)?.[0]?.label }}
    </span>
  </div>
</ng-template>

<ng-template #noData>
  <li class="select-dropdown-element select-dropdown-element-disabled">
    <p
      class="select-header-text select-header-text-placeholder w-full flex justify-center truncate py-4">
      {{ searchString ? 'No data matched your query' : 'No data' }}
    </p>
  </li>
</ng-template>
