import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import {
  API_UPDATE_SANDBOX_OPTIONS,
  API_URL_CREATE_PROJECTS_PREFIX,
  API_URL_GET_PROJECT_COHORTS,
} from 'src/app/constants/constants';
import {
  GET_PROJECTS,
  PROJECT_SETTING,
  PROJECT_WEBHOOK,
} from 'src/app/endpoints/endpoints';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { ThrottlingType } from 'src/app/types/project-setting';
import {
  ProjectPushNotification,
  ProjectPushNotificationInterface,
} from 'src/app/types/projects';

@Injectable({
  providedIn: 'root',
})
export class ProjectSettingService extends ApiCommonHandler {
  private pushNotificationConfigSub: BehaviorSubject<
    ProjectPushNotification[]
  > = new BehaviorSubject([]);

  private isLoadingSub = new BehaviorSubject(false);

  public pushNotificationConfig$ =
    this.pushNotificationConfigSub.asObservable();
  public isLoading$ = this.isLoadingSub.asObservable();

  private isConfigSavedSub: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isConfigSaved$ = this.isConfigSavedSub.asObservable();

  constructor(
    private http: HttpClient,
    private permissionService: PermissionService
  ) {
    super();
  }

  updateProjectSurveyAppearance(
    account_id: string | null,
    project_id: string | null,
    data: any
  ): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.post(
      this.getAPIFullUrlByName(
        `${GET_PROJECTS}${account_id}/${PROJECT_SETTING}${project_id}`
      ),
      data
    );
  }

  deleteProjectSettingsLogo(
    accountId: string,
    projectId: string
  ): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.delete(
      this.getAPIFullUrlByName(
        `${GET_PROJECTS}${accountId}/${PROJECT_SETTING}${projectId}/logo`
      )
    );
  }

  updateProjectSettingsLogo(
    accountId: string,
    projectId: string,
    formData: FormData
  ): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.put(
      this.getAPIFullUrlByName(
        `${GET_PROJECTS}${accountId}/${PROJECT_SETTING}${projectId}/logo`
      ),
      formData
    );
  }

  getProjectSurveyAppearance(
    account_id: string | null,
    project_id: string | null
  ): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(
        `${GET_PROJECTS}/${account_id}/${API_URL_CREATE_PROJECTS_PREFIX}/${project_id}`
      )
    );
  }

  getProjectUserCohorts(project_id: string | null): Observable<any> {
    return this.http.get(
      this.getAPIFullUrlByName(`${API_URL_GET_PROJECT_COHORTS}/${project_id}`)
    );
  }

  saveSurveyThrottling(
    projectId: string,
    data: { period: number; type: ThrottlingType }
  ) {
    this.permissionService.validateCreateAndUpdateOperation();

    return lastValueFrom(
      this.http.post<{ message: string; result: any }>(
        this.getAPIFullUrlByName(`project/survey/throttling/${projectId}`),
        data
      )
    );
  }

  saveWebhookSetting(
    account_id: string | null,
    project_id: string | null,
    data
  ): Observable<any> {
    this.permissionService.validateCreateAndUpdateOperation();

    return this.http.post(
      this.getAPIFullUrlByName(
        `${GET_PROJECTS}${account_id}/${PROJECT_WEBHOOK}${project_id}`
      ),
      data
    );
  }

  hexToRgba(hexa_code: string, opacity: number) {
    let c;
    hexa_code = hexa_code.length > 7 ? hexa_code.substring(0, 7) : hexa_code;
    try {
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexa_code)) {
        let default_color = hexa_code.replace('#', '');
        let default_opacity = opacity * 100;
        c = hexa_code.substring(1).split('');
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        let default_color_rgba =
          'rgba(' +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
          ',' +
          opacity +
          ')';

        return {
          default_color,
          default_opacity,
          default_color_rgba,
        };
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  public uploadFile(
    formData: FormData,
    isReport: boolean = true,
    type = 'file'
  ) {
    this.permissionService.validateCreateAndUpdateOperation();
    let ob = {};
    if (isReport) {
      ob = {
        observe: 'events',
        reportProgress: true,
      };
    }
    return this.http.post<{
      message: string;
      result: any;
      success: number;
    }>(
      this.getAPIFullUrlByName(`push-notification/upload/file?type=` + type),
      formData,
      ob
    );
  }

  async addOrUpdateConfigurations(
    configurations: ProjectPushNotificationInterface,
    project_id: string
  ) {
    this.permissionService.validateCreateAndUpdateOperation();

    const formData = new FormData();

    this.isConfigSavedSub.next(true);

    if (configurations.up_file && configurations.up_file !== null) {
      formData.append('file', configurations.up_file);

      const res = await this.uploadFile(formData, false).toPromise();
      if (res && res.result) {
        configurations.file = res.result.url;
        configurations.file_original_name = res.result.name;
        configurations.size = res.result.size;
      }

      configurations.up_file = null;
    }

    const res = await lastValueFrom(
      this.http.post<{ result: ProjectPushNotification; message: string }>(
        this.getAPIFullUrlByName(`push-notification/${project_id}`),
        {
          ...configurations,
        }
      )
    );
    configurations.up_file = null;
    if (configurations.notification_id === null) {
      this.pushNotificationConfigSub.next([
        ...this.pushNotificationConfigSub.value,
        res.result,
      ]);
    }
    this.isConfigSavedSub.next(false);
    return res;
  }

  async loadConfiguration(project_id: string) {
    this.isLoadingSub.next(true);
    const res = await lastValueFrom(
      this.http.get<{ result: [ProjectPushNotification]; message: string }>(
        this.getAPIFullUrlByName(`push-notification/${project_id}`)
      )
    );

    this.pushNotificationConfigSub.next(res.result);
    this.isLoadingSub.next(false);
  }

  async updateSandboxOption(
    option: boolean,
    project_id: string,
    account_id: string
  ) {
    const res = await lastValueFrom(
      this.http.post<{
        result: { sandbox: boolean };
        message: string;
      }>(
        this.getAPIFullUrlByName(
          `${GET_PROJECTS}${account_id}/${API_UPDATE_SANDBOX_OPTIONS}/${project_id}`
        ),
        {
          option,
        }
      )
    );

    return res;
  }
}
