export enum FilterCondition {
  Is = 'is',
  IsIn = 'is in',
  IsNotIn = 'is not in',
  IsNot = 'is not',
  Between = 'between',
  IsEqualTo = 'is equal to',
  IncludesOneOrMoreOf = 'includes one or more of',
  IncludesAllOf = 'includes all of',
  ExcludesOneOrMoreOf = 'excludes one or more of',
  ExcludesAllOf = 'excludes all of',
  IsNotEqualTo = 'is not equal to',
  IsLessThan = 'is less than',
  IsMoreThan = 'is more than',
  IsAtLeast = 'is at least',
  IsAtMost = 'is at most',
  Contains = 'contains',
  DoesNotContain = 'does not contain',
  StartsWith = 'starts with',
  EndsWith = 'ends with',
  Exactly = 'exactly',
  LessThan = 'less than',
  MoreThan = 'more than',
  Before = 'before',
  On = 'on',
  After = 'after',
  HasAnyValue = 'has any value',
  IsUnknown = 'is unknown',
}
