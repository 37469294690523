<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3 12H6"
    stroke="#454545"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M12 3V6"
    stroke="#454545"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M7.8001 7.79998L5.6001 5.59998"
    stroke="#454545"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M16.2002 7.79998L18.4002 5.59998"
    stroke="#454545"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M7.8001 16.2L5.6001 18.4"
    stroke="#454545"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
  <path
    d="M12 12L21 15L17 17L15 21L12 12Z"
    stroke="#454545"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
