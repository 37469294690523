export enum SubscriptionStatus {
  Active = 'active',
  Paid = 'paid',
  Open = 'open',
  Complete = 'complete',
  Incomplete = 'incomplete',
  PaymentFailed = 'payment_failed',
}

export enum PlanType {
  Basic = 'basic',
  Pro = 'pro',
  Custom = 'custom',
}

export enum SubscriptionType {
  Annual = 'annual',
  Monthly = 'monthly',
}
