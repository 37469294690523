<button
  (click)="update.emit(!checked)"
  [disabled]="disabled"
  type="button"
  class="switch flex {{
    disabled ? 'bg-gray-100 cursor-default' : 'cursor-pointer'
  }} relative inline-flex flex-shrink-0 focus:outline-none"
  [class.switch-active]="checked"
  [class.switch-inactive]="!checked"
  role="switch"
  aria-checked="false">
  <span
    aria-hidden="true"
    class="switch-circle pointer-events-none inline-block rounded-full transform ring-0"
    [class.switch-circle-active]="checked">
  </span>
</button>
