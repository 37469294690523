import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutofocusDirective } from 'src/app/directives/autofocus/autofocus.directive';
import { CheckAPIkeyNameDirective } from 'src/app/directives/check-APIkey-name/check-apikey-name.directive';
import { CopyClipboardDirective } from 'src/app/directives/copy-clipboard/copy-clipboard.directive';
import { CustomDatatableDirective } from 'src/app/directives/custom-datatable/custom-datatable.directive';
import { EmailServerValidateDirective } from 'src/app/directives/email/email-server-validate.directive';
import { InviteTeamEmailDirective } from 'src/app/directives/invite-team-email/invite-team-email.directive';
import { NumberValidationDirective } from 'src/app/directives/number-validation/number-validation.directive';
import { TextD3CloudDirective } from 'src/app/directives/text-d3-cloud/text-d3-cloud.directive';

@NgModule({
  declarations: [
    NumberValidationDirective,
    CopyClipboardDirective,
    TextD3CloudDirective,
    CustomDatatableDirective,
    CheckAPIkeyNameDirective,
    EmailServerValidateDirective,
    InviteTeamEmailDirective,
    AutofocusDirective,
  ],
  imports: [CommonModule],
  exports: [
    NumberValidationDirective,
    CopyClipboardDirective,
    TextD3CloudDirective,
    CustomDatatableDirective,
    CheckAPIkeyNameDirective,
    EmailServerValidateDirective,
    InviteTeamEmailDirective,
    AutofocusDirective,
  ],
})
export class DirectivesModule {}
