import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

const emojiesDictionary = {};

@Component({
  selector: 'app-emoji-by-name',
  templateUrl: './emoji-by-name.component.html',
  styleUrls: ['./emoji-by-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiByNameComponent implements OnChanges {
  emojiSub: BehaviorSubject<string | null> = new BehaviorSubject(null);

  @Input() name?: string;
  @Input() size?: string;

  constructor() {}

  ngOnChanges({ name }: SimpleChanges) {
    if (name?.currentValue) {
      if (Object.keys(emojiesDictionary).length) {
        this.emojiSub.next(emojiesDictionary[name.currentValue]);
      } else {
        import('emojilib').then(m => {
          Object.keys(m).forEach(emojiString => {
            emojiesDictionary[m[emojiString][0]] = emojiString;
          });
          this.emojiSub.next(emojiesDictionary[name.currentValue]);
        });
      }
    }
  }
}
