import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-integrations',
  templateUrl: './icon-integrations.component.html',
  styleUrls: ['./icon-integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconIntegrationsComponent {
  @Input() active = false;

  constructor() {}
}
