import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-vertical-list-element',
  templateUrl: './vertical-list-element.component.html',
  styleUrls: ['./vertical-list-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalListElementComponent {
  @Input() active = false;
  @Input() disabled = false;
  @Input() name?: string;

  @Output() press: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
