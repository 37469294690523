<button
  class="vertical-list-item flex items-center justify-between gap-1"
  [disabled]="disabled || active"
  [class.vertical-list-item-active]="active">
  <span
    (click)="!disabled && !active && press.emit()"
    class="w-full flex items-center gap-3">
    <div class="vertical-list-item-icon">
      <ng-content select="[icon]"></ng-content>
    </div>
    <span class="vertical-list-item-text break-all text-left">{{ name }}</span>
  </span>
  <div>
    <ng-content select="[actions]"></ng-content>
  </div>
</button>
