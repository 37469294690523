<div *ngIf="!isLoading; else spinner" class="form flex flex-col gap-8">
  <div class="flex items-center justify-between">
    <h2 class="form-title">
      {{
        upgrade
          ? 'Upgrade your plan'
          : accountsService.isSubscriptionActive
          ? 'Your current plan'
          : 'Select your plan'
      }}
    </h2>
    <a target="_blank" [href]="marketingLink">
      <app-button size="m" variant="link">
        <div class="flex items-center gap-2">
          See plan details
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z"
              fill="#2F54EB" />
          </svg>
        </div>
      </app-button>
    </a>
  </div>
  <ng-container *ngIf="!upgrade && accountPlan">
    <p
      *ngIf="accountsService.isSubscriptionActive"
      class="form-active-plan-details">
      This organization’s <b>{{ accountPlan.name }}</b> plan is set to
      <b *ngIf="accountPlanSubscriptionType"
        >{{
          accountPlan.price[accountPlanSubscriptionType]?.amount
            | currency: 'USD' : 'symbol' : '1.0-0'
        }}
        per
        {{
          accountPlanSubscriptionType === SubscriptionType.Monthly
            ? 'month'
            : 'year'
        }}</b
      >
      and will renew on
      <b>{{
        (account?.subscription_details?.period?.end ?? 0) * 1000
          | date: 'MMMM dd, YYYY'
      }}</b
      >.
    </p>
  </ng-container>
  <div
    *ngIf="upgrade || isNewPlanActive"
    class="grid grid-cols-1 lg:grid-cols-2">
    <button
      *ngFor="let plan of plans; let i = index; trackBy: trackByPlan"
      class="form-plan cursor-pointer"
      [disabled]="
        upgrade &&
        i < activePlanIndex &&
        (userSubscriptionService.defaultPlanType$ | async) !== plan.name
      "
      [class.form-plan-active]="selectedPlan?.id === plan.id"
      (click)="setPlan(plan)">
      <div class="flex items-center gap-4 mb-5">
        <app-checkbox
          [disabled]="
            upgrade &&
            i < activePlanIndex &&
            (userSubscriptionService.defaultPlanType$ | async) !== plan.name
          "
          [checked]="selectedPlan?.id === plan.id">
        </app-checkbox>
        <h3 class="form-plan-title">{{ plan.name }}</h3>
      </div>
      <p class="form-plan-description mb-5">
        {{ plan.description }}
      </p>
      <ng-container
        *ngIf="
          (plan.price[activeSubscriptionType]?.amount || 0) / 100;
          let activeSubscriptionAmount;
          else: emptyAmount
        ">
        <h4 class="form-plan-price mb-1">
          {{
            activeSubscriptionAmount | currency: 'USD' : 'symbol' : '1.0-0'
          }}/{{
            activeSubscriptionType === SubscriptionType.Annual
              ? 'year'
              : 'month'
          }}
        </h4>
        <p
          *ngIf="activeSubscriptionType === SubscriptionType.Annual; else empty"
          class="form-plan-price-description">
          That’s
          {{
            activeSubscriptionAmount / 12 | currency: 'USD' : 'symbol' : '1.0-0'
          }}
          /month
        </p>
      </ng-container>
      <ng-template #empty>
        <p class="form-plan-price-description"></p>
      </ng-template>
    </button>
  </div>
  <ng-container *ngIf="savedAmount">
    <ng-container
      *ngIf="activeSubscriptionType === SubscriptionType.Annual; else monthly">
      <div
        *ngIf="plan?.price?.[SubscriptionType.Monthly]"
        class="flex items-center justify-center gap-8">
        <div class="flex items-center gap-3">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM8.5 15L3.5 10L4.91 8.59L8.5 12.17L16.09 4.58L17.5 6L8.5 15Z"
              fill="#52C41A" />
          </svg>
          <p>
            You’re saving
            <b>{{ savedAmount | currency: 'USD' : 'symbol' : '1.0-0' }}/year</b>
            by paying annually.
          </p>
        </div>
        <app-button
          size="m"
          variant="link"
          (press)="togglePlanType(SubscriptionType.Monthly)"
          >Pay monthly instead</app-button
        >
      </div>
    </ng-container>
    <ng-template #monthly>
      <div
        *ngIf="plan?.price?.[SubscriptionType.Annual]"
        class="flex items-center justify-center gap-8">
        <div class="flex items-center gap-3">
          <svg
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.5 19.5H22.5L11.5 0.5L0.5 19.5ZM12.5 16.5H10.5V14.5H12.5V16.5ZM12.5 12.5H10.5V8.5H12.5V12.5Z"
              fill="#FFE58F" />
          </svg>
          <p>
            <b
              >You could save
              {{ savedAmount | currency: 'USD' : 'symbol' : '1.0-0' }}/year!</b
            >
          </p>
        </div>
        <app-button
          size="m"
          variant="link"
          (press)="togglePlanType(SubscriptionType.Annual)"
          >Pay annual and save</app-button
        >
      </div>
    </ng-template>
  </ng-container>
  <app-button
    *ngIf="isPaymentButtonVisible"
    class="block ml-auto mr-auto"
    size="m"
    variant="filled"
    [disabled]="!plan?.price?.[activeSubscriptionType]?.id"
    [showLoader]="isWaitingForPayment"
    (press)="pay()">
    <div class="flex items-center gap-1.5">
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.6667 0.333496H2.33334C1.40834 0.333496 0.675005 1.07516 0.675005 2.00016L0.666672 12.0002C0.666672 12.9252 1.40834 13.6668 2.33334 13.6668H15.6667C16.5917 13.6668 17.3333 12.9252 17.3333 12.0002V2.00016C17.3333 1.07516 16.5917 0.333496 15.6667 0.333496ZM15.6667 12.0002H2.33334V7.00016H15.6667V12.0002ZM15.6667 3.66683H2.33334V2.00016H15.6667V3.66683Z"
          fill="white" />
      </svg>
      {{
        upgrade
          ? 'Upgrade now'
          : accountsService.isSubscriptionActive
          ? 'Save changes'
          : 'Pay securely via Stripe'
      }}
    </div>
  </app-button>

  <div class="flex items-center justify-center gap-8">
    <p>Have questions or need more?</p>
    <app-button size="m" variant="link" (press)="showIntercom()">
      <div class="flex items-center gap-1.5">
        Chat with us
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.1667 0.666504H1.83333C0.916666 0.666504 0.166666 1.4165 0.166666 2.33317V17.3332L3.5 13.9998H15.1667C16.0833 13.9998 16.8333 13.2498 16.8333 12.3332V2.33317C16.8333 1.4165 16.0833 0.666504 15.1667 0.666504ZM15.1667 12.3332H3.5L1.83333 13.9998V2.33317H15.1667V12.3332Z"
            fill="#2F54EB" />
        </svg>
      </div>
    </app-button>
  </div>
  <div
    *ngIf="
      !upgrade && stripeService.customerId$ && account?.subscription_details
    "
    class="flex items-center justify-center gap-8">
    <p>Download invoices, update payment method, and manage subscription.</p>
    <app-button size="m" variant="link" (press)="getUserStripeDashboardUrl()">
      <div class="flex items-center gap-2">
        Go to billing portal
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.8333 13.8333H2.16667V2.16667H8V0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8H13.8333V13.8333ZM9.66667 0.5V2.16667H12.6583L4.46667 10.3583L5.64167 11.5333L13.8333 3.34167V6.33333H15.5V0.5H9.66667Z"
            fill="#2F54EB" />
        </svg>
      </div>
    </app-button>
  </div>
</div>

<ng-template #spinner>
  <div class="form-spinner-wrapper flex items-center justify-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>

<app-modal
  width="496px"
  modalTitle="Your payment was rejected"
  [opened]="isFailedModalOpened"
  (hide)="hideFailedModal()">
  <div class="flex flex-wrap gap-4">
    <p class="form-modal-message">
      Your payment was rejected. Please try again using a different payment
      method, or contact us for help.
    </p>
    <div class="w-full flex gap-2 justify-end">
      <app-button
        size="m"
        variant="filled"
        color="primary"
        (press)="hideFailedModal()">
        Cancel
      </app-button>
    </div>
  </div>
</app-modal>

<ng-template #emptyAmount>
  <h4 class="form-plan-price mb-1">N/A</h4>
</ng-template>
