<span (mouseleave)="reset()">
  <button
    class="copy flex items-center justify-center"
    type="button"
    [disabled]="(copiedSub | async) || false"
    (click)="copy()">
    <svg
      *ngIf="copiedSub | async; else copyIcon"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99967 0.333313C3.31967 0.333313 0.333008 3.31998 0.333008 6.99998C0.333008 10.68 3.31967 13.6666 6.99967 13.6666C10.6797 13.6666 13.6663 10.68 13.6663 6.99998C13.6663 3.31998 10.6797 0.333313 6.99967 0.333313ZM5.66634 10.3333L2.33301 6.99998L3.27301 6.05998L5.66634 8.44665L10.7263 3.38665L11.6663 4.33331L5.66634 10.3333Z"
        fill="#2F54EB" />
    </svg>
  </button>
</span>

<ng-template #copyIcon>
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0003 0.428558H2.00033C1.26699 0.428558 0.666992 1.02856 0.666992 1.76189V11.0952H2.00033V1.76189H10.0003V0.428558ZM12.0003 3.09522H4.66699C3.93366 3.09522 3.33366 3.69522 3.33366 4.42856V13.7619C3.33366 14.4952 3.93366 15.0952 4.66699 15.0952H12.0003C12.7337 15.0952 13.3337 14.4952 13.3337 13.7619V4.42856C13.3337 3.69522 12.7337 3.09522 12.0003 3.09522ZM12.0003 13.7619H4.66699V4.42856H12.0003V13.7619Z"
      fill="#2F54EB" />
  </svg>
</ng-template>
