import { Component } from '@angular/core';

@Component({
  selector: 'app-icon-refresh',
  templateUrl: './icon-refresh.component.html',
  styleUrls: ['./icon-refresh.component.scss'],
})
export class IconRefreshComponent {
  constructor() {}
}
