import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PromiseService {
  private promises: { [key: string]: Promise<any> } = {};

  public clear(key: string, duration = 0): void {
    setTimeout(() => {
      delete this.promises[key];
    }, duration * 1000);
  }

  public async share<T>(
    key: string,
    action: () => Promise<T>,
    duration?: number
  ): Promise<T> {
    if (key in this.promises) {
      return this.promises[key];
    }

    const promise = action();

    this.promises[key] = promise;

    try {
      const result = await promise;

      this.clear(key, duration);

      return result;
    } catch (err) {
      this.clear(key, 0);

      throw err;
    }
  }

  public get<T>(key: string): Promise<T> {
    return this.promises[key];
  }

  public clearAll() {
    this.promises = {};
  }
}
