<div
  *ngIf="isOpened"
  class="modal-overlay flex items-center justify-center"
  [@showHide]="animation ? 'show' : 'hide'"
  (click)="
    userSubscriptionService.hideUpdatePlanModal(); $event.stopPropagation()
  ">
  <div class="modal" (click)="handleClick($event)">
    <app-plans-form [upgrade]="true"></app-plans-form>
  </div>
</div>
