import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() placement:
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end' = 'bottom-end';
  @Input() width?: number;
  @Input() content?: string;
  @Input() visible = true;

  constructor() {}

  get tippyProps() {
    return {
      placement: this.placement,
      maxWidth: this.width ?? 'none',
      animation: 'shift-away',
    };
  }
}
