import {
  Directive,
  OnDestroy,
  ChangeDetectorRef,
  Injector,
  AfterViewChecked,
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';

@Directive()
export class BaseDirective implements AfterViewChecked, OnDestroy {
  protected cdr: ChangeDetectorRef | null = null;

  protected destroyed$: Subject<boolean> = new Subject();

  constructor(injector: Injector) {
    this.cdr = injector.get(ChangeDetectorRef);
  }

  private subs: Subscription[] = [];
  private intervals: number[] = [];
  private timeouts: number[] = [];

  protected addSubscription(sub: Subscription): void {
    this.subs.push(sub);
  }

  protected addInterval(action: Function, delay: number): any {
    const interval = setInterval(action, delay);

    this.intervals.push(interval);

    return interval;
  }

  protected addTimeout(action: Function, delay: number): any {
    const timeout = setTimeout(action, delay);

    this.timeouts.push(timeout);

    return timeout;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this.intervals.forEach(clearInterval);
    this.timeouts.forEach(clearTimeout);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewChecked() {
    /* explicit change detection to avoid "expression-has-changed-after-it-was-checked-error" */
    if (this.cdr) {
      this.cdr.detectChanges();
    }

    /* avoid recursive calls */
    this.cdr = null;
  }
}
