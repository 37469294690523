<svg
  width="11"
  height="15"
  viewBox="0 0 11 15"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.47533 0.784497C6.62759 0.832379 6.7606 0.927583 6.85502 1.05626C6.94944 1.18494 7.00034 1.34039 7.00033 1.5V5.25H10.0003C10.1375 5.24994 10.2721 5.28751 10.3894 5.35862C10.5067 5.42973 10.6023 5.53165 10.6657 5.6533C10.7291 5.77496 10.758 5.91167 10.7491 6.04857C10.7402 6.18547 10.6939 6.31731 10.6153 6.42975L5.36533 13.9297C5.27396 14.0607 5.14321 14.159 4.99209 14.2104C4.84097 14.2619 4.67738 14.2638 4.52511 14.2159C4.37284 14.1679 4.23983 14.0726 4.14545 13.9439C4.05107 13.8151 4.00023 13.6596 4.00033 13.5V9.75H1.00033C0.863145 9.75006 0.728567 9.71249 0.611247 9.64138C0.493926 9.57027 0.398358 9.46834 0.334941 9.34669C0.271524 9.22504 0.24269 9.08832 0.251575 8.95142C0.26046 8.81452 0.306725 8.68268 0.385334 8.57025L5.63533 1.07025C5.72684 0.939587 5.85763 0.841506 6.00868 0.790254C6.15974 0.739002 6.32321 0.737248 6.47533 0.785247V0.784497Z"
    fill="black" />
</svg>
