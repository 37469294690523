import { Component, Input, Output, EventEmitter } from '@angular/core';

type DownloadButtonItemsType = {
  name: string;
  id: string;
};

export enum DownloadCriteria {
  'ALL' = 'all',
  'WITHOUT_EVENTS' = 'withoutEvents',
}

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
})
export class DownloadButtonComponent {
  @Input() isDownloading = false;
  dropDownButtonItem: DownloadButtonItemsType[] = [
    {
      name: 'Full data with trigger events',
      id: DownloadCriteria.ALL,
    },
    {
      name: 'Responses only',
      id: DownloadCriteria.WITHOUT_EVENTS,
    },
  ];

  @Output() handleClick = new EventEmitter();

  constructor() {}
}
