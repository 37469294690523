import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { FilterCondition } from 'src/app/common/enums';
import { FILTER_CONDITIONS } from 'src/app/constants';

@Component({
  selector: 'app-date-filter-section',
  templateUrl: './date-filter-section.component.html',
  styleUrls: ['./date-filter-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterSectionComponent {
  FILTER_CONDITIONS = FILTER_CONDITIONS;
  FilterCondition = FilterCondition;

  @Input() condition?: FilterCondition;
  @Input() value?: string;

  @Output() update: EventEmitter<{
    condition?: FilterCondition;
    value?: string;
  }> = new EventEmitter();

  constructor() {}

  updateOperatorWithoutValue(condition: FilterCondition) {
    this.update.emit({ condition });
  }

  updateValue(value?: string) {
    this.update.emit({ value, condition: this.condition });
  }

  updateOperator(condition: FilterCondition) {
    this.update.emit({ value: this.value, condition });
  }
}
