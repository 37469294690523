<div class="relative">
  <div
    *ngIf="showActions"
    class="actions w-full h-full flex items-center gap-8">
    <ng-content select="[actions]"></ng-content>
  </div>
  <div class="table-wrapper flex-1 overflow-auto">
    <table class="table">
      <thead class="table-header">
        <ng-content select="[thead]"></ng-content>
      </thead>
      <tbody class="table-body">
        <tr *ngIf="isLoading; else content">
          <td [attr.colspan]="colspan">
            <app-spinner [height]="spinnerHeight"></app-spinner>
          </td>
        </tr>
        <ng-template #content>
          <ng-content select="[tbody]"></ng-content>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
