import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalenderComponent implements OnInit, OnChanges {
  MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  isOpened = false;
  month = new Date().getMonth();
  year = new Date().getFullYear();
  daysOfTheMonth: number[] = [];
  blankDays: number[] = [];

  @Input() public date?: Date | string | number = new Date();

  @Output() public update: EventEmitter<string> = new EventEmitter();

  constructor(private eRef: ElementRef) {}

  ngOnInit() {
    if (this.date) {
      this.setPickerData(this.date);
    }
  }

  ngOnChanges({ date }: SimpleChanges) {
    if (date?.currentValue) {
      this.setPickerData(date.currentValue);
    } else {
      const date = this.getFullDate(new Date().getDate());

      this.update.emit(date);
    }
  }

  get modifiedDate() {
    const day = this.date
      ? new Date(this.date).getDate()
      : new Date().getDate();

    return this.getFullDate(day);
  }

  @HostListener('document:click', ['$event'])
  private clickout(event) {
    this.isOpened = this.eRef.nativeElement.contains(event.target);
  }

  private setPickerData(date: Date | string | number) {
    const currentDate = new Date(date);

    this.month = currentDate.getMonth();
    this.year = currentDate.getFullYear();

    this.getNoOfDays();
  }

  private getFullDate(day: number) {
    return `${this.year}/${
      this.month + 1 < 10 ? `0${this.month + 1}` : this.month + 1
    }/${day < 10 ? `0${day}` : day}`;
  }

  isDateActive(day: number) {
    const currentDate = this.date ? new Date(this.date) : new Date();
    const date = new Date(this.year, this.month, day);

    return currentDate.toDateString() === date.toDateString();
  }

  getNoOfDays() {
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
    const dayOfWeek = new Date(this.year, this.month).getDay();
    const blankDays: number[] = [];
    const daysOfTheMonth: number[] = [];

    for (let i = 1; i <= dayOfWeek; i++) {
      blankDays.push(i);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysOfTheMonth.push(i);
    }

    this.blankDays = blankDays;
    this.daysOfTheMonth = daysOfTheMonth;
  }

  setDate(day: number) {
    const date = this.getFullDate(day);

    this.update.emit(date);

    this.isOpened = false;
  }

  addDays(month) {
    let currentMonth = month;

    if (this.month === 11) {
      this.year += 1;
      currentMonth -= 1;
    }

    this.month = currentMonth + 1;
    this.getNoOfDays();
  }

  subtractMonth(month) {
    let currentMonth = month;

    if (this.month === 0) {
      this.year -= 1;
      currentMonth = 12;
    }

    this.month = currentMonth - 1;
    this.getNoOfDays();
  }

  toggleDatepickerPopup() {
    this.isOpened = !this.isOpened;
  }
}
