import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';

import { Dropdown } from 'flowbite';
import { BehaviorSubject } from 'rxjs';

type Element = {
  label: string;
  description?: string;
  value: any;
  useBottomBorder?: boolean;
  icon?: string;
};

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements AfterViewInit, OnChanges, OnDestroy {
  private dropdownInstance: Dropdown | null = null;

  selectedElements$: BehaviorSubject<Element[]> = new BehaviorSubject([]);
  isOpened$ = new BehaviorSubject(false);
  isVisited = false;
  searchString?: string;
  filteredElements$: BehaviorSubject<Element[]> = new BehaviorSubject([]);

  @Input() timeIcon: boolean = false;
  @Input() title?: string;
  @Input() isLoading = false;
  @Input() loadMoreLoader = false;
  @Input() useSearch = false;
  @Input() required = false;
  @Input() placeholder?: string;
  @Input() dropdownPlacement: 'top' | 'bottom' = 'bottom';
  @Input() selected: any[] = [];
  @Input() elements: Element[] = [];
  @Input() multiple = false;

  @Output() update: EventEmitter<{
    label: string;
    value: any;
    icon?: string;
    description?: string;
  }> = new EventEmitter();
  @Output() handleSearch: EventEmitter<string> = new EventEmitter();
  @Output() handleKeyPress: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() loadMore = new EventEmitter();

  @ViewChild('search', { static: false })
  searchElement?: ElementRef<HTMLInputElement>;
  @ViewChild('button', { static: false })
  buttonElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('dropdown', { static: false })
  dropdownElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('multipleHeader', { static: false })
  multipleHeaderElement?: ElementRef<HTMLButtonElement>;

  constructor() {}

  get firstElementValue() {
    return this.elements[0]?.value;
  }

  get dropdownElements() {
    return this.searchString ? this.filteredElements$.value : this.elements;
  }

  get dropdownHeight() {
    if (this.dropdownElements.length) {
      /* 38 - element height, 8 - padding */
      return this.dropdownElements.length > 6
        ? '240px'
        : this.dropdownElements.length * 38 + 8 + 'px';
    }
    return undefined;
  }

  ngAfterViewInit() {
    if (
      this.buttonElement?.nativeElement &&
      this.dropdownElement?.nativeElement
    ) {
      this.dropdownInstance = new Dropdown(
        this.dropdownElement.nativeElement,
        this.buttonElement.nativeElement,
        {
          placement: this.dropdownPlacement,
          onShow: () => {
            this.isOpened$.next(true);
            this.isVisited = true;

            this.initSearchString();
          },
          onHide: () => {
            this.isOpened$.next(false);

            if (document.activeElement === this.searchElement?.nativeElement) {
              this.searchElement.nativeElement.click();
            }

            this.resetSearch();
          },
        }
      );
    }
  }

  ngOnChanges({ selected, elements }: SimpleChanges) {
    if (this.multiple && selected?.currentValue && !selected?.firstChange) {
      this.focusSearchElement();
    }

    if (selected?.currentValue || !elements?.previousValue.length) {
      this.selectedElements$.next(
        this.selected.length
          ? this.selected
              .map(
                value => this.elements.find(element => element.value === value)!
              )
              .filter(e => e)
          : []
      );
    }

    if (selected?.currentValue || elements?.currentValue) {
      this.initSearchString();
    }
  }

  ngOnDestroy(): void {
    this.dropdownInstance = null;
  }

  private resetSearch() {
    if (!this.useSearch) {
      return;
    }
    const oldSearch = this.searchString;

    const [selectedLabel] = this.selectedElements$.value;

    if (this.multiple) {
      this.searchString = undefined;
    } else {
      this.searchString = selectedLabel?.label;
    }

    if (oldSearch !== this.searchString) {
      this.handleSearch.emit(this.searchString);
    }
  }

  private initSearchString() {
    if (!this.useSearch) {
      return;
    }

    if (!this.multiple && !this.searchString) {
      const [selectedValue] = this.selected;

      const search = this.elements.find(e => e.value === selectedValue)?.label;

      if (search) {
        this.searchString = search;
      }
    }

    this.searchElements();
  }

  focusSearchElement() {
    requestAnimationFrame(() => {
      if (document.activeElement !== this.searchElement?.nativeElement) {
        this.searchElement?.nativeElement.focus();
      }
    });
  }

  hide() {
    setTimeout(() => {
      requestAnimationFrame(() => {
        this.searchElement?.nativeElement.blur();
        this.dropdownInstance?.hide();
      });
    }, 0);
  }

  updateSelected(model: {
    label: string;
    value: any;
    icon?: string;
    description?: string;
  }) {
    this.update.emit(model);

    if (this.useSearch) {
      if (!this.multiple && this.selected[0] !== model.value) {
        this.searchString = model.label;
      }

      this.searchElements();
    }

    if (this.multiple) {
      this.focusSearchElement();
    } else {
      this.hide();
    }
  }

  deleteLabel(e: MouseEvent, model: { label: string; value: string | number }) {
    e.stopPropagation();

    this.updateSelected(model);
  }

  searchElements() {
    if (this.searchString) {
      const search = this.searchString.trim().toLowerCase();

      const filteredElements = this.elements.filter(
        element =>
          element.label.trim().toLowerCase().includes(search) ||
          element.description?.trim().toLowerCase().includes(search)
      );

      this.filteredElements$.next(filteredElements);
    } else {
      this.filteredElements$.next([]);
    }
  }

  handleScroll(e: Event) {
    if (
      e.target instanceof HTMLUListElement &&
      e.target.scrollTop === e.target.scrollHeight - e.target.clientHeight
    ) {
      this.loadMore.emit();
    }
  }
}
