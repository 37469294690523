import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { FilterCondition } from 'src/app/common/enums';
import { FILTER_CONDITIONS, PAGE_FILTER_OPTIONS } from 'src/app/constants';

import { FilterType, LabelFilterType, FilterOperator } from '../../enums';
import {
  PageFilter,
  PageFilterWhere,
  PagePropertyFilter,
  PageFilterWhereFilterGroupFilter,
} from '../../types';

@Component({
  selector: 'app-page-filter',
  templateUrl: './page-filter.component.html',
  styleUrls: ['./page-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFilterComponent implements OnChanges {
  dropdownActive = false;
  FilterType = FilterType;
  LabelFilterType = LabelFilterType;
  pageConditions = PAGE_FILTER_OPTIONS;

  @Input() filter: PageFilter | null = null;

  @Output() update: EventEmitter<PageFilter> = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() finish: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnChanges({ pageCondition }: SimpleChanges) {
    if (pageCondition?.currentValue) {
      this.filter = { ...pageCondition.currentValue };
    }
  }

  get isFilterInvalid() {
    if (!this.filter?.value) {
      return true;
    }

    if (this.filter?.where?.filters?.length) {
      const isInvalid = this.filter.where.filters.some(f => {
        if (f.filterType === FilterType.FilterGroup) {
          return (f as PageFilterWhereFilterGroupFilter)?.filters?.some(
            this.validateFilter
          );
        } else {
          return this.validateFilter(f);
        }
      });

      return isInvalid;
    }

    return false;
  }

  private validateFilter(f: PagePropertyFilter) {
    const { value, condition, answers = [] } = f;

    if (!value) {
      return true;
    }

    if (
      condition === FilterCondition.HasAnyValue ||
      condition === FilterCondition.IsUnknown
    ) {
      return false;
    }

    return answers.length === 0;
  }

  getFilterConditionLabel(condition?: FilterCondition) {
    if (condition) {
      return FILTER_CONDITIONS[condition];
    }
    return;
  }

  setPageCondition(model: { value: any; label: string }) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        label: model.label,
        value: model.value,
        where: {
          operator: FilterOperator.AND,
          filters: [],
        },
      });
    }
  }

  showDropdown() {
    this.dropdownActive = true;
  }

  hideDropdown() {
    this.dropdownActive = false;

    this.finish.emit(!this.isFilterInvalid);
  }

  updateWhere(where: PageFilterWhere) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        where,
      });
    }
  }

  getPagePropertyLabel(filter: PagePropertyFilter) {
    const answersLength = filter.answers?.length ?? 0;
    const fewAnswers = answersLength > 1;

    if (fewAnswers) {
      return filter.answers?.reduce((result, answer, index) => {
        if (answersLength - 2 === index) {
          return `${result}${answer}`;
        }
        if (answersLength - 1 === index) {
          return `${result} or ${answer}`;
        }
        return `${result}${answer}, `;
      }, '');
    }

    return filter.answers?.[0];
  }

  toFilterGroupFilterType(
    filter: PagePropertyFilter | PageFilterWhereFilterGroupFilter
  ) {
    return filter as PageFilterWhereFilterGroupFilter;
  }

  toPagePropertyFilterType(
    filter: PagePropertyFilter | PageFilterWhereFilterGroupFilter
  ) {
    return filter as PagePropertyFilter;
  }
}
