import {
  animate,
  style,
  state,
  trigger,
  transition,
} from '@angular/animations';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      transition('show <=> hide', [animate('300ms')]),
    ]),
    trigger('openClose', [
      state(
        'open',
        style({
          right: 0,
        })
      ),
      state(
        'closed',
        style({
          right: '-100%',
        })
      ),
      transition('open <=> closed', [animate('300ms')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidepanelComponent implements OnChanges {
  private timeout: NodeJS.Timeout | null = null;

  public appearanceOpenSub = new BehaviorSubject(false);
  public animationOpenSub = new BehaviorSubject(false);

  @Input() isOpen = false;

  @Output() hide = new EventEmitter();

  constructor() {}

  ngOnChanges({ isOpen }: SimpleChanges) {
    if (isOpen?.currentValue !== undefined) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      if (isOpen.currentValue) {
        this.appearanceOpenSub.next(isOpen.currentValue);
      } else {
        this.timeout = setTimeout(() => {
          this.appearanceOpenSub.next(isOpen.currentValue);
        }, 400);
      }

      setTimeout(() => {
        this.animationOpenSub.next(isOpen.currentValue);
      }, 0);
    }
  }
}
