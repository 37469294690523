import {
  animate,
  style,
  state,
  trigger,
  transition,
} from '@angular/animations';
import { Component, Injector } from '@angular/core';

import { BaseDirective } from 'src/app/directives/base/base.directive';
import { UserSubscriptionService } from 'src/app/services/user-subscription/user-subscription.service';

@Component({
  selector: 'app-update-plan-modal',
  templateUrl: './update-plan-modal.component.html',
  styleUrls: ['./update-plan-modal.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      transition('* => show', [animate('300ms')]),
      transition('show <=> hide', [animate('300ms')]),
    ]),
  ],
})
export class UpdatePlanModalComponent extends BaseDirective {
  private timeout: NodeJS.Timeout | null = null;

  animation = false;
  isOpened = false;

  constructor(
    public userSubscriptionService: UserSubscriptionService,
    injector: Injector
  ) {
    super(injector);

    super.addSubscription(
      this.userSubscriptionService.isUpdateModalOpened$.subscribe(isOpened => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        if (isOpened) {
          this.isOpened = true;
        } else {
          this.timeout = setTimeout(() => {
            this.isOpened = false;
          }, 400);
        }

        setTimeout(() => {
          this.animation = isOpened;
        }, 0);
      })
    );
  }

  public handleClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
