<div class="filter-group flex gap-8 items-center justify-between gap-2.5">
  <div class="flex items-center justify-end">
    <div *ngIf="filterGroup" class="flex flex-wrap items-center gap-2.5">
      <ng-container
        *ngFor="
          let filter of filterGroup.filters ?? [];
          let i = index;
          trackBy: trackByFilter
        ">
        <app-page-property-filter
          [filter]="filter"
          (update)="updateFilter($event)"
          (remove)="removeFilter(filter.id, i)"
          (finish)="handleFinish(filter.id, $event)">
        </app-page-property-filter>
        <app-relationship-switcher
          *ngIf="
            filterGroup.filters.length === 1 ||
            i !== filterGroup.filters.length - 1
          "
          [active]="filterGroup?.operator"
          (toggle)="updateOperator($event)">
        </app-relationship-switcher>
      </ng-container>
      <app-add-filter-button
        dropdownTitle="user data"
        [filters]="allowedFilters"
        (add)="addFilter($event)"></app-add-filter-button>
    </div>
  </div>
  <button
    class="filter-group-remove"
    (click)="$event.stopPropagation(); remove.emit()">
    <app-icon-close></app-icon-close>
  </button>
</div>
