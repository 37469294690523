<div class="switcher">
  <button
    class="switcher-item"
    [class.switcher-item-active]="active === FilterOperator.AND"
    [disabled]="!active"
    (click)="toggle.emit(FilterOperator.AND)">
    and
  </button>
  <button
    class="switcher-item"
    [class.switcher-item-active]="active === FilterOperator.OR"
    [disabled]="!active"
    (click)="toggle.emit(FilterOperator.OR)">
    or
  </button>
</div>
