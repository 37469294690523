import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FilterCondition } from 'src/app/common/enums';
import { FILTER_CONDITIONS } from 'src/app/constants';

import { FilterType, LabelFilterType } from '../../enums';
import { PagePropertyFilter } from '../../types';

@Component({
  selector: 'app-page-property-filter',
  templateUrl: './page-property-filter.component.html',
  styleUrls: ['./page-property-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagePropertyFilterComponent implements OnChanges {
  FilterCondition = FilterCondition;
  FILTER_CONDITIONS = FILTER_CONDITIONS;
  pageProperties = [
    // {
    //   label: 'name',
    //   value: 'name',
    // },
    {
      label: 'url',
      value: 'url',
    },
    {
      label: 'path',
      value: 'path',
    },
    {
      label: 'referrer',
      value: 'referrer',
    },
    {
      label: 'search',
      value: 'search',
    },
    {
      label: 'title',
      value: 'title',
    },
  ];
  LabelFilterType = LabelFilterType;
  answerValues: string[] = [];
  dropdownActive = false;
  pagePropertyModel$: BehaviorSubject<PagePropertyFilter | null> =
    new BehaviorSubject(null);

  @Input() filter: PagePropertyFilter | null = null;

  @Output() update: EventEmitter<PagePropertyFilter> = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() finish: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnChanges({ filter }: SimpleChanges): void {
    if (filter?.currentValue) {
      this.pagePropertyModel$.next({
        ...filter.currentValue,
        answers: filter.currentValue.answers
          ? [...filter.currentValue.answers]
          : [],
      });

      this.setAnswerValues();
    }
  }

  get isFilterInvalid() {
    if (this.filter) {
      const { value, condition, answers = [] } = this.filter;

      if (!value) {
        return true;
      }

      if (
        condition === FilterCondition.HasAnyValue ||
        condition === FilterCondition.IsUnknown
      ) {
        return false;
      }

      return answers.length === 0;
    }
    return true;
  }

  get conditionTitle() {
    if (this.filter?.condition) {
      return FILTER_CONDITIONS[this.filter.condition];
    }
    return '';
  }

  private setAnswerValues() {
    if (!this.filter?.answers) {
      return;
    }

    const answersLength = this.filter.answers?.length ?? 0;
    const fewAnswers = answersLength > 1;

    if (fewAnswers) {
      this.answerValues =
        this.filter.answers.map((answer, index) => {
          if (answersLength - 2 === index) {
            return answer;
          }
          if (answersLength - 1 === index) {
            return ` or ${answer}`;
          }
          return `${answer}, `;
        }, '') ?? [];
    } else {
      this.answerValues = [...this.filter.answers];
    }
  }

  showDropdown() {
    this.dropdownActive = true;
  }

  hideDropdown() {
    this.dropdownActive = false;

    this.finish.emit(!this.isFilterInvalid);
  }

  trackByAnswerValue(index: number, answer: string) {
    return `${index}-${answer}`;
  }

  setPageProperty(model: { label: string; value: any }) {
    if (this.pagePropertyModel$.value) {
      this.update.emit({
        id: this.pagePropertyModel$.value.id,
        filterType: FilterType.PageProperty,
        label: model.label,
        value: model.value,
        answers: [],
      });
    }
  }

  setCondition(condition?: FilterCondition, answer?: string) {
    if (this.pagePropertyModel$.value) {
      this.update.emit({
        ...this.pagePropertyModel$.value,
        condition,
        answers: answer ? [answer] : [],
      });
    }
  }

  setAnswer(answer?: string) {
    if (this.pagePropertyModel$.value) {
      this.update.emit({
        ...this.pagePropertyModel$.value,
        answers: answer ? [answer] : [],
      });
    }
  }

  setAnswers(answers: string[]) {
    if (this.pagePropertyModel$.value) {
      this.update.emit({ ...this.pagePropertyModel$.value, answers });
    }
  }
}
