import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { SelectComponent } from 'src/app/components/select/select.component';
import { ProjectService } from 'src/app/services/project/project.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-event-property-values-select',
  templateUrl: './event-property-values-select.component.html',
  styleUrls: ['./event-property-values-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPropertyValuesSelectComponent implements OnChanges {
  private searchTimeout?: NodeJS.Timeout;

  allEventPropertyValuesLoaded = false;
  limit = 100;
  offset = 0;
  isLoading$ = new BehaviorSubject(false);
  searchString$: BehaviorSubject<string> = new BehaviorSubject('');
  values$: BehaviorSubject<{ label: string; value: any }[]> =
    new BehaviorSubject([]);

  @ViewChild(SelectComponent, { static: true })
  propertiesSelect?: SelectComponent;

  @Input() eventProperty?: string;
  @Input() selected: string[] = [];
  @Input() eventName?: string;

  @Output() update: EventEmitter<string[]> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private projectService: ProjectService
  ) {}

  ngOnChanges({ eventProperty }: SimpleChanges): void {
    if (eventProperty?.currentValue) {
      this.offset = 0;
      this.values$.next([]);

      const projectId = this.sessionService.getActiveProject();

      if (projectId) {
        this.initProperties(projectId);
      }
    }
  }

  private async initProperties(projectId: string) {
    this.isLoading$.next(true);

    await this.loadEventPropertyValues(projectId);

    this.isLoading$.next(false);
  }

  private async loadEventPropertyValues(projectId: string) {
    if (!this.eventProperty) {
      return;
    }

    try {
      const properties = await this.projectService.getEventPropertyValues({
        projectId,
        limit: this.limit,
        offset: this.offset,
        property: this.eventProperty,
        search: this.searchString$.value,
        eventName: this.eventName,
      });

      if (properties.length < this.limit) {
        this.allEventPropertyValuesLoaded = true;
      }

      const userProperties = properties.map(value => ({
        label: value.toString(),
        value: value.toString(),
      }));

      const allProperties = this.selected.reduce((result, selectedProperty) => {
        const property = selectedProperty.trim();
        if (
          !properties.includes(property) &&
          !this.values$.value.some(v => v.value === property)
        ) {
          result.push({
            label: property,
            value: property,
          });
        }
        return result;
      }, userProperties);

      this.values$.next([...this.values$.value, ...allProperties]);
    } catch {
      this.values$.next([]);
    }
  }

  handleSearch(searchString: string) {
    this.searchString$.next(searchString);

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.offset = 0;
      this.allEventPropertyValuesLoaded = false;
      this.values$.next([]);

      const projectId = this.sessionService.getActiveProject();

      if (!projectId) {
        return;
      }

      this.initProperties(projectId);
    }, 100);
  }

  handleUpdate(value: { label: string; value: any }) {
    let selected = [...this.selected];

    if (selected.includes(value.value)) {
      selected = selected.filter(item => item !== value.value);
    } else {
      selected.push(value.value);
    }

    this.searchString$.next('');

    this.update.emit(selected);
  }

  setNewProperty() {
    if (!this.searchString$.value) {
      return;
    }
    if (
      this.selected.includes(this.searchString$.value) ||
      this.values$.value.find(p => p.value === this.searchString$.value)
    ) {
      window.alert('Property already exists');
    } else {
      this.values$.next([
        ...this.values$.value,
        {
          label: this.searchString$.value,
          value: this.searchString$.value,
        },
      ]);
      this.update.emit([...this.selected, this.searchString$.value]);
      this.searchString$.next('');
    }
  }

  loadMoreEventPropertyValues() {
    this.offset += this.limit;

    const projectId = this.sessionService.getActiveProject();

    if (projectId) {
      this.loadEventPropertyValues(projectId);
    }
  }

  handleSelectKeyPress(event: KeyboardEvent) {
    event.stopPropagation();

    const search = this.searchString$.value;

    if (event.key === 'Enter' && search && !this.selected.includes(search)) {
      this.setNewProperty();

      this.propertiesSelect?.focusSearchElement();
    }
  }
}
