import { v4 as uuidv4 } from 'uuid';

import { DataType } from 'src/app/common/enums';
import {
  FilterOperator,
  FilterType,
  EventFilterType,
  EventCountTimeRange,
} from 'src/app/components/filters/enums';
import {
  Filter,
  TriggerFilter,
  EventFilter,
  CohortFilter,
  TraitFilter,
  EventPropertyFilter,
  FilterGroupFilter,
  TriggerEventFilter,
  PageFilter,
  PagePropertyFilter,
  WhereFilterGroupFilter,
  PageFilterWhereFilterGroupFilter,
} from 'src/app/components/filters/types';
import {
  PAGE_FILTER_OPTIONS,
  COHORT_ICONS,
  DATA_TYPE_ICONS,
} from 'src/app/constants';
import { Cohort } from 'src/app/services/users/users.service';
import {
  UserCohortFilter,
  UserEventFilter,
  UserEventPropertyFilter,
  UserTraitFilter,
  AllUserFilters,
  UserFilterGroupFilter,
  SurveyTriggerPageFilter,
  SurveyTriggerEventFilter,
  SurveyTriggerPagePropertyFilter,
  SurveyTriggerFilter,
  SurveyTriggerPagePropertyGroupFilter,
  SurveyTriggerEventPropertyGroupFilter,
} from 'src/app/types';

const getEventFilterFieldType = (filter: EventFilter) => {
  if (filter[EventFilterType.COUNT]) {
    return EventFilterType.COUNT;
  } else if (filter[EventFilterType.FIRST_OCCURRED]) {
    return EventFilterType.FIRST_OCCURRED;
  } else if (filter[EventFilterType.LAST_OCCURRED]) {
    return EventFilterType.LAST_OCCURRED;
  } else {
    return null;
  }
};

const mapCohortFilter = (filter: CohortFilter): UserCohortFilter | null => {
  if (!filter.condition) {
    return null;
  }

  return {
    type: FilterType.Cohort,
    condition: filter.condition,
    values: [filter.value],
  };
};

const mapEventFilter = (filter: EventFilter): UserEventFilter | null => {
  const fieldType = getEventFilterFieldType(filter);

  if (!fieldType || !filter.value || !filter.condition) {
    return null;
  }

  const countFilter = filter[EventFilterType.COUNT];
  const firstOccurredFilter = filter[EventFilterType.FIRST_OCCURRED];
  const lastOccurredFilter = filter[EventFilterType.LAST_OCCURRED];

  if (
    countFilter &&
    (!countFilter.eventTypeValues.filter(v => v.value).length ||
      (countFilter.timeRange !== EventCountTimeRange.ALL_TIME &&
        !countFilter.timeRangeValues.filter(v => v.value).length))
  ) {
    return null;
  }

  return {
    type: FilterType.Event,
    field: filter.value,
    condition: filter.condition,
    field_type: fieldType,
    values: countFilter
      ? countFilter.eventTypeValues.map(v => v.value)
      : [firstOccurredFilter?.value ?? lastOccurredFilter?.value],
    ...(countFilter && {
      time_range: {
        type: countFilter.timeRange!,
        values: countFilter.timeRangeValues.map(v => v.value),
        unit: countFilter.timeRangeFormat,
      },
    }),
    property_filters: filter.where
      ? mapFilterToBackendModel<UserEventPropertyFilter>(filter.where)
      : null,
  };
};

const mapPageFilter = (filter: PageFilter): SurveyTriggerPageFilter | null => {
  if (!filter.value || !filter.timingOption) {
    return null;
  }

  return {
    type: FilterType.Page,
    field: filter.value,
    timingOption: filter.timingOption,
    property_filters: filter.where
      ? {
          operator: filter.where.operator!,
          filters: mapSurveyTriggerPagePropertyFilterToBackendModel(
            filter.where.filters
          ),
        }
      : null,
  };
};

const modifyValuesByDataType = (value: any, type: DataType) => {
  switch (type) {
    case DataType.NUMBER:
      return +value;

    case DataType.BOOLEAN:
      return typeof value === 'boolean' ? value : value === 'true';

    default:
      return value;
  }
};

const mapTraitFilter = (filter: TraitFilter): UserTraitFilter | null => {
  if (!filter.value || !filter.condition || !filter.answers) {
    return null;
  }

  return {
    type: FilterType.Trait,
    field: filter.value,
    isDefaultTrait: filter.isDefaultTrait ?? false,
    data_type: filter.traitType ?? DataType.STRING,
    condition: filter.condition,
    values: filter.traitType
      ? filter.answers.map(answer =>
          modifyValuesByDataType(answer, filter.traitType!)
        )
      : filter.answers,
  };
};

const mapEventPropertyFilter = (
  filter: EventPropertyFilter
): UserEventPropertyFilter | null => {
  if (!filter.value || !filter.condition || !filter.answers) {
    return null;
  }

  return {
    type: FilterType.EventProperty,
    field: filter.value,
    data_type: filter.traitType ?? DataType.STRING,
    condition: filter.condition,
    values: filter.traitType
      ? filter.answers.map(answer =>
          modifyValuesByDataType(answer, filter.traitType!)
        )
      : filter.answers,
  };
};

const mapPagePropertyFilter = (
  filter: PagePropertyFilter
): SurveyTriggerPagePropertyFilter | null => {
  if (!filter.value || !filter.condition || !filter.answers) {
    return null;
  }

  return {
    type: FilterType.PageProperty,
    field: filter.value,
    condition: filter.condition,
    values: filter.answers,
  };
};

const validateFilter = (filter: Filter) => {
  switch (filter.filterType) {
    case FilterType.Cohort: {
      const cohortFilter = filter as CohortFilter;
      return !!(cohortFilter.condition && cohortFilter.value);
    }

    case FilterType.Trait: {
      const traitFilter = filter as TraitFilter;
      return !!(traitFilter.condition && traitFilter.value);
    }

    case FilterType.Event: {
      const eventFilter = filter as EventFilter;
      return !!(eventFilter.condition && eventFilter.value);
    }

    case FilterType.EventProperty: {
      const eventPropertyFilter = filter as EventPropertyFilter;
      return !!(eventPropertyFilter.condition && eventPropertyFilter.value);
    }

    case FilterType.FilterGroup: {
      const groupFilter = filter as FilterGroupFilter;
      return (
        !!groupFilter.filters?.length &&
        groupFilter.filters.every(validateFilter)
      );
    }

    default:
      return false;
  }
};

export const toOriginalSurveyTriggerFilter = (
  filters: (
    | SurveyTriggerEventFilter
    | UserEventPropertyFilter
    | SurveyTriggerPagePropertyFilter
    | SurveyTriggerPagePropertyGroupFilter
    | SurveyTriggerEventPropertyGroupFilter
    | SurveyTriggerPageFilter
  )[]
) => {
  return filters.reduce(
    (filters, filter) => {
      switch (filter.type) {
        case FilterType.Event:
          {
            const { timingOption, field, property_filters } =
              filter as SurveyTriggerEventFilter;

            filters.push({
              timingOption,
              id: uuidv4(),
              filterType: FilterType.Event,
              value: field,
              label: field,
              where: property_filters
                ? {
                    operator: property_filters.operator,
                    filters: toOriginalSurveyTriggerFilter(
                      property_filters.filters
                    ),
                  }
                : undefined,
            } as TriggerEventFilter);
          }
          break;

        case FilterType.EventProperty:
          {
            const eventPropertyFilter = filter as UserEventPropertyFilter;

            filters.push({
              id: uuidv4(),
              filterType: FilterType.EventProperty,
              label: eventPropertyFilter.field,
              traitType: eventPropertyFilter.data_type,
              icon: DATA_TYPE_ICONS[eventPropertyFilter.data_type],
              value: eventPropertyFilter.field,
              condition: eventPropertyFilter.condition,
              answers: eventPropertyFilter.values,
            } as EventPropertyFilter);
          }
          break;

        case FilterType.PageProperty:
          {
            const pagePropertyFilter =
              filter as SurveyTriggerPagePropertyFilter;

            filters.push({
              id: uuidv4(),
              filterType: FilterType.PageProperty,
              label: pagePropertyFilter.field,
              value: pagePropertyFilter.field,
              condition: pagePropertyFilter.condition,
              answers: pagePropertyFilter.values,
            } as PagePropertyFilter);
          }
          break;

        case FilterType.FilterGroup:
          {
            const filterGroupFilter =
              filter as SurveyTriggerPagePropertyGroupFilter;
            filters.push({
              id: uuidv4(),
              filterType: FilterType.FilterGroup,
              operator: filterGroupFilter.operator,
              filters: filterGroupFilter.filters
                ? toOriginalSurveyTriggerFilter(filterGroupFilter.filters)
                : [],
            } as FilterGroupFilter);
          }
          break;

        case FilterType.Page:
          {
            const pagePropertyFilter = filter as SurveyTriggerPageFilter;

            filters.push({
              id: uuidv4(),
              filterType: FilterType.Page,
              label: PAGE_FILTER_OPTIONS.find(
                o => o.value === pagePropertyFilter.field
              )?.label,
              value: pagePropertyFilter.field,
              timingOption: pagePropertyFilter.timingOption,
              where: {
                operator: pagePropertyFilter.property_filters?.operator,
                filters: pagePropertyFilter.property_filters?.filters
                  ? toOriginalSurveyTriggerFilter(
                      pagePropertyFilter.property_filters.filters
                    )
                  : [],
              },
            } as PageFilter);
          }
          break;

        default:
          break;
      }

      return filters;
    },
    [] as (
      | TriggerEventFilter
      | PageFilter
      | PagePropertyFilter
      | PageFilterWhereFilterGroupFilter
      | EventPropertyFilter
      | WhereFilterGroupFilter
    )[]
  );
};

export const toOriginalFilter = (
  filter: {
    operator: FilterOperator;
    filters: AllUserFilters[];
  },
  cohorts: Cohort[]
): {
  operator?: FilterOperator;
  filters: Filter[];
} => {
  return {
    operator: filter.operator,
    filters: filter.filters
      .map(filter => {
        switch (filter.type) {
          case FilterType.Cohort: {
            const cohortFilter = filter as UserCohortFilter;
            const cohort = cohorts.find(c => c._id === cohortFilter.values[0]);

            return {
              id: uuidv4(),
              cohortType: cohort?.source,
              filterType: FilterType.Cohort,
              icon: cohort?.source && COHORT_ICONS[cohort.source],
              label: cohort?.name,
              value: cohortFilter.values[0],
              condition: cohortFilter.condition,
            } as CohortFilter;
          }

          case FilterType.Event: {
            const {
              field_type,
              field,
              condition,
              values,
              time_range,
              property_filters,
            } = filter as UserEventFilter;

            const isCountFilter = field_type === EventFilterType.COUNT;
            const isFirstOccurredFilter =
              field_type === EventFilterType.FIRST_OCCURRED;
            const isLastOccuredFilter =
              field_type === EventFilterType.LAST_OCCURRED;

            return {
              id: uuidv4(),
              filterType: FilterType.Event,
              value: field,
              label: field,
              condition: condition,
              count: isCountFilter
                ? {
                    eventTypeValues: values.map(v => ({
                      value: v,
                    })),
                    timeRange: time_range?.type,
                    timeRangeValues: time_range?.values.map(v => ({
                      value: v,
                    })),
                    timeRangeFormat: time_range?.unit,
                  }
                : undefined,
              [EventFilterType.FIRST_OCCURRED]: isFirstOccurredFilter
                ? { value: values[0] }
                : undefined,
              [EventFilterType.LAST_OCCURRED]: isLastOccuredFilter
                ? { value: values[0] }
                : undefined,
              where: property_filters
                ? toOriginalFilter(property_filters, cohorts)
                : null,
            } as EventFilter;
          }

          case FilterType.EventProperty: {
            const eventPropertyFilter = filter as UserEventPropertyFilter;

            return {
              id: uuidv4(),
              filterType: FilterType.EventProperty,
              traitType: eventPropertyFilter.data_type,
              label: eventPropertyFilter.field,
              icon: DATA_TYPE_ICONS[eventPropertyFilter.data_type],
              value: eventPropertyFilter.field,
              condition: eventPropertyFilter.condition,
              answers: eventPropertyFilter.values,
            } as EventPropertyFilter;
          }

          case FilterType.Trait: {
            const traitFilter = filter as UserTraitFilter;

            return {
              id: uuidv4(),
              filterType: FilterType.Trait,
              traitType: traitFilter.data_type,
              isDefaultTrait: traitFilter.isDefaultTrait,
              label: traitFilter.field,
              value: traitFilter.field,
              condition: traitFilter.condition,
              answers: traitFilter.values,
            } as TraitFilter;
          }

          case FilterType.FilterGroup: {
            const filterGroupFilter = filter as UserFilterGroupFilter;
            return {
              id: uuidv4(),
              filterType: FilterType.FilterGroup,
              operator: filterGroupFilter.operator,
              filters:
                toOriginalFilter(filterGroupFilter, cohorts)?.filters ?? [],
            } as FilterGroupFilter;
          }

          default:
            return null;
        }
      })
      .filter(filter => filter !== null) as Filter[],
  };
};

const mapTriggerEventFilter = (
  filter: TriggerEventFilter
): SurveyTriggerEventFilter | null => {
  if (!filter.value || !filter.timingOption) {
    return null;
  }

  return {
    type: FilterType.Event,
    field: filter.value,
    timingOption: filter.timingOption,
    property_filters: filter.where
      ? mapFilterToBackendModel<UserEventPropertyFilter>(filter.where)
      : null,
  };
};

export const mapFilterToBackendModel = <T extends AllUserFilters>(filter: {
  operator?: FilterOperator;
  filters: Filter[];
}): { operator: FilterOperator; filters: T[] } => {
  return {
    operator: filter.operator ?? FilterOperator.AND,
    filters: filter.filters.filter(validateFilter).reduce((filters, filter) => {
      switch (filter.filterType) {
        case FilterType.Cohort:
          {
            const cohortFilter = mapCohortFilter(filter as CohortFilter);
            if (cohortFilter) {
              filters.push(cohortFilter as T);
            }
          }
          break;

        case FilterType.Event:
          {
            const eventFilter = mapEventFilter(filter as EventFilter);
            if (eventFilter) {
              filters.push(eventFilter as T);
            }
          }
          break;

        case FilterType.EventProperty:
          {
            const traitFilter = mapEventPropertyFilter(
              filter as EventPropertyFilter
            );
            if (traitFilter) {
              filters.push(traitFilter as T);
            }
          }
          break;

        case FilterType.Trait:
          {
            const traitFilter = mapTraitFilter(filter as TraitFilter);
            if (traitFilter) {
              filters.push(traitFilter as T);
            }
          }
          break;

        case FilterType.FilterGroup:
          {
            const groupFilter = mapFilterToBackendModel(
              filter as FilterGroupFilter
            );
            if (groupFilter.filters.length) {
              filters.push({
                ...groupFilter,
                type: FilterType.FilterGroup,
              } as T);
            }
          }
          break;

        default:
          break;
      }
      return filters;
    }, [] as T[]),
  };
};

export const mapSurveyTriggerFilterToBackendModel = (
  filters: TriggerFilter[]
) => {
  return filters.reduce((filters, filter) => {
    switch (filter.filterType) {
      case FilterType.Page:
        {
          const pageFilter = mapPageFilter(filter as PageFilter);

          if (pageFilter) {
            filters.push(pageFilter);
          }
        }
        break;

      case FilterType.Event:
        {
          const eventFilter = mapTriggerEventFilter(
            filter as TriggerEventFilter
          );

          if (eventFilter) {
            filters.push(eventFilter);
          }
        }
        break;
    }

    return filters;
  }, [] as SurveyTriggerFilter[]);
};

export const mapSurveyTriggerPagePropertyFilterToBackendModel = <
  T extends
    | SurveyTriggerPagePropertyFilter
    | SurveyTriggerPagePropertyGroupFilter,
>(
  filters: (PagePropertyFilter | PageFilterWhereFilterGroupFilter)[]
) => {
  return filters.reduce((filters, filter) => {
    switch (filter.filterType) {
      case FilterType.PageProperty:
        {
          const pagePropertyFilter = mapPagePropertyFilter(
            filter as PagePropertyFilter
          );

          if (pagePropertyFilter) {
            filters.push(pagePropertyFilter as T);
          }
        }
        break;

      case FilterType.FilterGroup:
        {
          const filterGroup = filter as PageFilterWhereFilterGroupFilter;

          if (filterGroup) {
            filters.push({
              type: FilterType.FilterGroup,
              operator: filterGroup.operator,
              filters:
                mapSurveyTriggerPagePropertyFilterToBackendModel<SurveyTriggerPagePropertyFilter>(
                  filterGroup.filters
                ),
            } as T);
          }
        }
        break;
    }

    return filters;
  }, [] as T[]);
};

export const openFilterById = (id: string) => {
  setTimeout(() => {
    const filter = document.getElementById(id);

    if (filter instanceof HTMLDivElement) {
      filter.click();
    }
  }, 0);
};
