import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input() href?: string;
  @Input() routerLink?: string;
  @Input() align: 'left' | 'center' | 'right' = 'left';
  @Input() variant: 'primary' | 'secondary' | 'inherit' = 'inherit';

  @Output() press: EventEmitter<Event> = new EventEmitter();

  constructor() {}
}
