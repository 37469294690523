import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { decode } from 'html-entities';

import { colors } from 'src/app/common/colors';
import { icons_custom } from 'src/app/common/icons';

@Component({
  selector: 'app-survey-avatar',
  templateUrl: './survey-avatar.component.html',
  styleUrls: ['./survey-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyAvatarComponent implements OnChanges {
  @Input() icon?: string | null;
  @Input() color?: string | null;

  constructor() {}

  ngOnChanges({ icon, color }: SimpleChanges) {
    if (icon && !icon.currentValue) {
      this.icon = this.getRandomIcon();
    }

    if (color && !color.currentValue) {
      this.color = this.getRandomColor();
    }
  }

  private getRandomColor() {
    return colors[Math.floor(Math.random() * 9)].color_code;
  }

  private getRandomIcon() {
    return decode(icons_custom[Math.floor(Math.random() * 1000) + 1], {
      level: 'html5',
    });
  }
}
