import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-filter-label',
  templateUrl: './filter-label.component.html',
  styleUrls: ['./filter-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterLabelComponent {
  @Input() svg?: string;
  @Input() type?: 'event' | 'link' | 'clock';
  @Input() value?: string;
  @Input() eventName?: string;
  @Input() closeable: boolean = true;

  @Output() remove = new EventEmitter();

  constructor() {}
}
