<app-select
  class="block ml-6"
  placeholder="Enter value"
  [multiple]="true"
  [required]="true"
  [useSearch]="true"
  [selected]="selected"
  [elements]="(values$ | async) || []"
  (handleSearch)="udpateSearchString($event)"
  (update)="handleUpdate($event)">
  <span
    *ngIf="searchString$ | async; let searchString"
    topElement
    (click)="setNewProperty($event)">
    Use: "{{ searchString }}"
  </span>
</app-select>
