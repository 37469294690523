import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { AccountsService } from 'src/app/services/accounts/accounts.service';

@Component({
  selector: 'app-pro-label',
  templateUrl: './pro-label.component.html',
  styleUrls: ['./pro-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProLabelComponent {
  @Input() key?: string;

  constructor(public accountsService: AccountsService) {}
}
