import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  Input,
} from '@angular/core';

import { Dropdown } from 'flowbite';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dots-menu',
  templateUrl: './dots-menu.component.html',
  styleUrls: ['./dots-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotsMenuComponent implements OnDestroy {
  private bodyDropdown: HTMLDivElement | null = null;

  opened$ = new BehaviorSubject(false);

  @Input() showButton = true;
  @Input() appendToBody = false;
  @Input() useVerticalDots = false;

  @ViewChild('templateRef', { read: TemplateRef, static: true })
  templateRef!: TemplateRef<any>;
  @ViewChild('button', { static: true })
  buttonElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('dropdown', { static: false })
  dropdownElement?: ElementRef<HTMLDivElement>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnDestroy() {
    this.hide();
  }

  private show() {
    this.opened$.next(true);
  }

  private hide() {
    this.opened$.next(false);

    this.destroy();
  }

  private destroy() {
    this.buttonElement?.nativeElement.removeAllListeners?.('click');

    if (this.bodyDropdown) {
      this.bodyDropdown.remove();

      this.bodyDropdown = null;
    }
  }

  private initDropdown(element: HTMLDivElement) {
    if (this.buttonElement) {
      new Dropdown(element, this.buttonElement.nativeElement, {
        placement: 'bottom-end',
        onShow: () => this.show(),
        onHide: () => this.hide(),
      });
    }
  }

  init() {
    if (this.showButton && !this.opened$.value) {
      if (this.appendToBody) {
        if (!this.bodyDropdown) {
          const element = this.viewContainerRef.createEmbeddedView(
            this.templateRef
          );

          element.detectChanges();

          this.bodyDropdown = element.rootNodes[0];

          if (this.bodyDropdown) {
            document.body.appendChild(this.bodyDropdown);

            if (this.buttonElement?.nativeElement) {
              this.initDropdown(this.bodyDropdown);
            }
          }
        }
      } else {
        if (this.dropdownElement?.nativeElement) {
          this.initDropdown(this.dropdownElement.nativeElement);
        }
      }
    }
  }
}
