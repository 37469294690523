import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-delete',
  templateUrl: './icon-delete.component.html',
  styleUrls: ['./icon-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDeleteComponent {
  @Input() color = '#A8071A';
  @Input() size = '16px';

  constructor() {}
}
