<svg
  width="18"
  height="20"
  viewBox="0 0 18 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.0083 6.61665L9.88332 3.49165L2.33332 11.0416V14.1666H5.45832L13.0083 6.61665ZM3.99999 12.5V11.7333L9.88332 5.84998L10.65 6.61665L4.76666 12.5H3.99999Z"
    [attr.fill]="color" />
  <path
    d="M15.425 4.19998C15.75 3.87498 15.75 3.34998 15.425 3.02498L13.475 1.07498C13.3083 0.908313 13.1 0.833313 12.8833 0.833313C12.675 0.833313 12.4583 0.916646 12.3 1.07498L10.775 2.59998L13.9 5.72498L15.425 4.19998Z"
    [attr.fill]="color" />
  <path
    d="M17.3333 15.8333H0.666656V19.1666H17.3333V15.8333Z"
    [attr.fill]="color" />
</svg>
