<div
  class="max-w-xl mx-auto max-h-screen bg-gray-50 flex h-screen justify-center items-center">
  <div class="flex justify-center ml-4 mr-4">
    <p class="text-5xl font-extrabold text-primary">404</p>
    <div class="border-l-2 border-fuchsia-600 mr-5 ml-5 h-24"></div>
    <div>
      <p class="text-5xl font-extrabold text-black">Page not found</p>
      <p class="text-gray-500 font-medium text-md mt-2 ml-1">
        Please check the URL in the address bar and try again.
      </p>
      <div class="flex mt-5 pt-4">
        <a
          [routerLink]="
            '/' + (sessionService.activeProjectId$ | async) + SURVEYS
          ">
          <button
            class="w-half mr-3 flex align-items-center bg-primary justify-center py-2 px-4 border border-transparent font-semibold rounded-md shadow-sm text-sm text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Go back home
          </button>
        </a>
        <button
          class="w-half flex align-items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-semibold bg-primary-50 text-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
          Contact Support
        </button>
      </div>
    </div>
  </div>
</div>
