import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Dropdown } from 'flowbite';
import * as moment from 'moment';
import { BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

import { SubscriptionStatus } from 'src/app/common/enums/plans.enum';
import { logout } from 'src/app/common/utils';
import {
  CREATE_PROJECT,
  SETTINGS_ORGANIZATION,
  SETTINGS_PLAN_AND_USAGE,
  LOGIN_URL,
} from 'src/app/constants/routes';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { OneflowStorageService } from 'src/app/services/oneflow-storage/oneflow-storage.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SocketClientService } from 'src/app/services/socket/socket-client.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TeamMemberService } from 'src/app/services/team-member/team-member.service';
import { UserService } from 'src/app/services/user/user.service';
import { Account } from 'src/app/types/projects';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy, AfterViewInit {
  public SubscriptionStatus = SubscriptionStatus;
  public accountsListSub: BehaviorSubject<Account[]> = new BehaviorSubject(
    [] as Account[]
  );
  public mobileMenuOpened = false;
  public projectNameSub = new BehaviorSubject('Processing...');
  public SETTINGS_PLAN_AND_USAGE = SETTINGS_PLAN_AND_USAGE;
  public CREATE_PROJECT = CREATE_PROJECT;
  public SETTINGS_ORGANIZATION = SETTINGS_ORGANIZATION;
  public PROJECT_SETTING = SETTINGS_PLAN_AND_USAGE;
  public inviteSent = false;
  public supportEmail = environment.support_email;
  public documentation_route = environment.documentation_route;
  public componentDestroyed$: Subject<boolean> = new Subject();
  public totalDays = 0;
  public activeProjectId: string | null = null;

  @ViewChild('helpMenuButton', { static: true })
  helpMenuButtonElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('settingsMenuButton', { static: true })
  settingsMenuButtonElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('projectMenuButton', { static: true })
  projectMenuButtonElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('mobileProjectMenuButton', { static: true })
  mobileProjectMenuButtonElement?: ElementRef<HTMLButtonElement>;

  @ViewChild('helpMenuDropdown', { static: true })
  helpMenuDropdownElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('settingsMenuDropdown', { static: true })
  settingsMenuDropdownElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('projectMenuDropdown', { static: true })
  projectMenuDropdownElement?: ElementRef<HTMLButtonElement>;
  @ViewChild('mobileProjectMenuDropdown', { static: true })
  mobileProjectMenuDropdownElement?: ElementRef<HTMLButtonElement>;

  constructor(
    public accountsService: AccountsService,
    private storage: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private teamMemberService: TeamMemberService,
    private oneflowStorageService: OneflowStorageService,
    private segmentService: SegmentService,
    private socket: SocketClientService,
    private sessionService: SessionService,
    private userService: UserService
  ) {
    this.sessionService.activeProjectId$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(activeProjectId => {
        this.activeProjectId = activeProjectId;
      });

    this.accountsService.accounts$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(async accounts => {
        this.accountsListSub.next(accounts);

        const { trial_start } = this.accountsService.account;

        if (trial_start) {
          const endDate = new Date(trial_start);

          endDate.setDate(endDate.getDate() + 15);

          this.totalDays = moment(endDate).utc().diff(new Date(), 'days');
        }

        const activeAccountId = await lastValueFrom(
          this.sessionService.activeAccountId$.pipe(take(1))
        );

        if (activeAccountId && this.activeProjectId) {
          this.setCurrentProjectName(activeAccountId, this.activeProjectId);
        }
      });
  }

  ngAfterViewInit() {
    if (
      this.projectMenuDropdownElement?.nativeElement &&
      this.projectMenuButtonElement?.nativeElement
    ) {
      new Dropdown(
        this.projectMenuDropdownElement.nativeElement,
        this.projectMenuButtonElement.nativeElement,
        {
          placement: 'bottom-end',
        }
      );
    }

    if (
      this.helpMenuDropdownElement?.nativeElement &&
      this.helpMenuButtonElement?.nativeElement
    ) {
      new Dropdown(
        this.helpMenuDropdownElement.nativeElement,
        this.helpMenuButtonElement.nativeElement,
        {
          placement: 'bottom-end',
        }
      );
    }

    if (
      this.settingsMenuDropdownElement?.nativeElement &&
      this.settingsMenuButtonElement?.nativeElement
    ) {
      new Dropdown(
        this.settingsMenuDropdownElement.nativeElement,
        this.settingsMenuButtonElement.nativeElement,
        {
          placement: 'bottom-end',
        }
      );
    }

    if (
      this.mobileProjectMenuDropdownElement?.nativeElement &&
      this.mobileProjectMenuButtonElement?.nativeElement
    ) {
      new Dropdown(
        this.mobileProjectMenuDropdownElement.nativeElement,
        this.mobileProjectMenuButtonElement.nativeElement,
        {
          placement: 'bottom-end',
        }
      );
    }

    if (this.storage.getSessionData('logout')) {
      this.storage.removeSessionData('logout');
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public onLogout() {
    logout(
      this.accountsService,
      this.storage,
      this.oneflowStorageService,
      this.segmentService,
      this.socket,
      this.sessionService,
      this.userService
    );

    this.router.navigateByUrl(LOGIN_URL);
  }

  public async setCurrentProjectName(accountId: string, projectId: string) {
    const account = this.accountsListSub.value.find(
      account => account.account_id === accountId
    );

    if (account) {
      const project = account.projects.find(
        project => project.project_id === projectId
      );

      if (project) {
        this.projectNameSub.next(project.name);

        if (!this.inviteSent) {
          this.teamMemberService.inviteAcceoptTrigger(accountId);

          this.inviteSent = true;
        }
      }
    }
  }

  public async changeProject(accountId: string, projectId: string) {
    const prevProjectId = this.sessionService.getActiveProject();

    if (prevProjectId) {
      await this.router.navigateByUrl(
        this.router.url.replace(prevProjectId, projectId)
      );

      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {},
      });

      this.accountsService.updateAccount({
        account_id: accountId,
        project_id: projectId,
      });

      this.setCurrentProjectName(accountId, projectId);

      this.closeProjectMenu();
    }
  }

  public closeSettingsMenu() {
    this.settingsMenuButtonElement?.nativeElement.click();
  }

  public closeHelpMenu() {
    this.helpMenuButtonElement?.nativeElement.click();
  }

  public closeProjectMenu() {
    this.projectMenuButtonElement?.nativeElement.click();
  }

  public openMobileNav() {
    this.mobileMenuOpened = true;
  }

  public closeMobileNav() {
    this.mobileMenuOpened = false;
  }
}
