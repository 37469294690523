export const LOGIN_URL = '/login';
export const SIGNUP = '/signup';
export const SIGNUP_FIRST_STEP = '/signup/steps/step-1';
export const SIGNUP_SECOND_STEP = '/signup/steps/step-2';
export const SIGNUP_THRID_STEP = '/signup/steps/step-3';
export const SETUP = '/setup';
export const SIGNUP_FOUTH_STEP = '/setup/invite-team';
export const UPGRADE_PAGE_ROUTE = '/upgrade';
export const SURVEY_LIST = '/survey';
export const CREATE_SURVEY = '/survey/new';
export const SURVEY_EDIT_ROUTE = '/survey/edit';
export const SURVEY_RESULT = '/survey/result/:survey_id/:view_type';
export const SURVEY_PRIVIEW = '/popup';
export const PROJECT = '/project';
export const CREATE_PROJECT = '/project/create';
export const SETTINGS = '/settings';
export const SETTINGS_PLAN_AND_USAGE = `${SETTINGS}/plan-and-usage`;
export const SETTINGS_ORGANIZATION = `${SETTINGS}/organization`;
export const SETTINGS_ACCOUNT = `${SETTINGS}/account`;
export const SETTINGS_PROJECT = `${SETTINGS}/project`;
export const PAYMENT_CANCEL = '/upgrade/cancel';
export const SURVEY_UPGRADE = '/upgrade';
export const COPY_SNIPPET = '/setup/install-sdk';
export const ACCOUNT = '/account';
export const FORGET_PASSWORD = `${ACCOUNT}/user/forget`;
export const SURVET_TEMPLATE_PREVIEW = '/survey/template-popup';
export const SDK_INTEGRATED = '/setup/install-sdk/test-connection';
export const SDK_TEAMMATES_AFTER_LOGIN = '/setup/invite-team';
export const SECRET_PAGE = '/secret';
export const ADMIN = `${SECRET_PAGE}/admin`;
export const ADMIN_LOGIN = `${SECRET_PAGE}/login`;
export const ADMIN_ACCOUNTS = `${ADMIN}/accounts`;
export const ADMIN_CATEGORIES = `${ADMIN}/categories`;
export const ADMIN_TEMPLATES = `${ADMIN}/templates`;
export const ADMIN_ENTITLEMENTS = `${ADMIN}/entitlements`;
export const USERS = '/users';
export const TEMPLATES = '/templates';
export const EDIT_ADMIN_TEMPLATE = `/edit-template/:templateId`;
export const INTEGRATIONS = `/integrations`;
export const SURVEYS = '/surveys';
export const ANNOUNCEMENTS = '/announcements';
export const AI_SURVEY = '/ai-survey';
export const GENERATE_AI_SURVEY = `${AI_SURVEY}/generate`;
