<div class="filter-group flex gap-8 items-center justify-between gap-2.5">
  <div class="flex items-center justify-end">
    <div *ngIf="filterGroup" class="flex flex-wrap items-center gap-2.5">
      <ng-container
        *ngFor="
          let filter of filterGroup.filters ?? [];
          let i = index;
          trackBy: trackByFilter
        ">
        <ng-container [ngSwitch]="filter.filterType">
          <app-cohort-filter
            *ngSwitchCase="FilterType.Cohort"
            [cohort]="toCohortFilterType(filter)"
            (update)="updateFilter($event)"
            (remove)="removeFilter(filter.id, i)"
            (finish)="handleFinish(filter.id, $event)"></app-cohort-filter>
          <app-event-filter
            *ngSwitchCase="FilterType.Event"
            [event]="toEventFilterType(filter)"
            (update)="updateFilter($event)"
            (remove)="removeFilter(filter.id, i)"
            (finish)="handleFinish(filter.id, $event)"></app-event-filter>
          <app-event-property-filter
            *ngSwitchCase="FilterType.EventProperty"
            [eventName]="eventName"
            [eventProperty]="toEventPropertyFilterType(filter)"
            (update)="updateFilter($event)"
            (remove)="removeFilter(filter.id, i)"
            (finish)="handleFinish(filter.id, $event)">
          </app-event-property-filter>
          <app-trait-filter
            *ngSwitchCase="FilterType.Trait"
            [trait]="toTraitFilterType(filter)"
            (update)="updateFilter($event)"
            (remove)="removeFilter(filter.id, i)"
            (finish)="handleFinish(filter.id, $event)"></app-trait-filter>
        </ng-container>
        <app-relationship-switcher
          *ngIf="
            filterGroup.filters.length === 1 ||
            i !== filterGroup.filters.length - 1
          "
          [active]="filterGroup?.operator"
          (toggle)="updateOperator($event)">
        </app-relationship-switcher>
      </ng-container>
      <app-add-filter-button
        dropdownTitle="user data"
        [filters]="allowedFilters"
        (add)="addFilter($event)"></app-add-filter-button>
    </div>
  </div>
  <button
    class="filter-group-remove"
    (click)="$event.stopPropagation(); remove.emit()">
    <app-icon-close></app-icon-close>
  </button>
</div>
