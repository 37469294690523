<app-dropdown
  *ngIf="pagePropertyModel$ | async; let pagePropertyModel"
  placement="bottom-start"
  [useScrollToElement]="true"
  (openDropdown)="showDropdown()"
  (closeDropdown)="hideDropdown()">
  <app-label-filter
    button
    [id]="pagePropertyModel?.id"
    [showError]="isFilterInvalid"
    [tooltip]="
      pagePropertyModel?.value
        ? 'Please enter the missing value'
        : 'Please select a page property'
    "
    [active]="dropdownActive"
    [icon]="pagePropertyModel?.icon"
    [type]="LabelFilterType.PageProperty"
    (remove)="remove.emit()">
    <ng-container *ngIf="pagePropertyModel?.label; else placeholder">
      <b>{{ pagePropertyModel?.label }}</b>
      {{ conditionTitle }}
      <b *ngFor="let answer of answerValues; trackBy: trackByAnswerValue">{{
        answer
      }}</b>
    </ng-container>
    <ng-template #placeholder>Select a page property</ng-template>
  </app-label-filter>
  <div dropdown class="dropdown flex flex-col gap-4">
    <app-select
      title="Page property"
      placeholder="Find or create a page property"
      [required]="true"
      [useSearch]="true"
      [selected]="pagePropertyModel ? [pagePropertyModel.value] : []"
      [elements]="pageProperties"
      (update)="setPageProperty($event)">
    </app-select>
    <ng-container *ngIf="pagePropertyModel?.value && pagePropertyModel.answers">
      <div class="flex flex-col gap-1">
        <ng-container>
          <app-radio
            [active]="pagePropertyModel.condition === FilterCondition.Is"
            (update)="setCondition(FilterCondition.Is)">
            {{ FILTER_CONDITIONS[FilterCondition.Is] }}
          </app-radio>
          <app-page-property-values-select
            *ngIf="pagePropertyModel.condition === FilterCondition.Is"
            [selected]="pagePropertyModel.answers"
            (update)="setAnswers($event)">
          </app-page-property-values-select>
        </ng-container>
        <ng-container>
          <app-radio
            [active]="pagePropertyModel.condition === FilterCondition.IsNot"
            (update)="setCondition(FilterCondition.IsNot)">
            {{ FILTER_CONDITIONS[FilterCondition.IsNot] }}
          </app-radio>
          <app-page-property-values-select
            *ngIf="pagePropertyModel.condition === FilterCondition.IsNot"
            [selected]="pagePropertyModel.answers"
            (update)="setAnswers($event)">
          </app-page-property-values-select>
        </ng-container>
        <ng-container>
          <app-radio
            [active]="pagePropertyModel.condition === FilterCondition.Contains"
            (update)="setCondition(FilterCondition.Contains)">
            {{ FILTER_CONDITIONS[FilterCondition.Contains] }}
          </app-radio>
          <app-input
            *ngIf="pagePropertyModel.condition === FilterCondition.Contains"
            class="block ml-6"
            placeholder="Enter value"
            [required]="true"
            [autofocus]="true"
            [(inputModel)]="pagePropertyModel.answers[0]"
            (focusOut)="setAnswer(pagePropertyModel?.answers?.[0])">
          </app-input>
        </ng-container>
        <ng-container>
          <app-radio
            [active]="
              pagePropertyModel.condition === FilterCondition.DoesNotContain
            "
            (update)="setCondition(FilterCondition.DoesNotContain)">
            {{ FILTER_CONDITIONS[FilterCondition.DoesNotContain] }}
          </app-radio>
          <app-input
            *ngIf="
              pagePropertyModel.condition === FilterCondition.DoesNotContain
            "
            class="block ml-6"
            placeholder="Enter value"
            [required]="true"
            [autofocus]="true"
            [(inputModel)]="pagePropertyModel.answers[0]"
            (focusOut)="setAnswer(pagePropertyModel?.answers?.[0])">
          </app-input>
        </ng-container>
        <ng-container>
          <app-radio
            [active]="
              pagePropertyModel.condition === FilterCondition.StartsWith
            "
            (update)="setCondition(FilterCondition.StartsWith)">
            {{ FILTER_CONDITIONS[FilterCondition.StartsWith] }}
          </app-radio>
          <app-input
            *ngIf="pagePropertyModel.condition === FilterCondition.StartsWith"
            class="block ml-6"
            placeholder="Enter value"
            [required]="true"
            [autofocus]="true"
            [(inputModel)]="pagePropertyModel.answers[0]"
            (focusOut)="setAnswer(pagePropertyModel?.answers?.[0])">
          </app-input>
        </ng-container>
        <ng-container>
          <app-radio
            [active]="pagePropertyModel.condition === FilterCondition.EndsWith"
            (update)="setCondition(FilterCondition.EndsWith)">
            {{ FILTER_CONDITIONS[FilterCondition.EndsWith] }}
          </app-radio>
          <app-input
            *ngIf="pagePropertyModel.condition === FilterCondition.EndsWith"
            class="block ml-6"
            placeholder="Enter value"
            [required]="true"
            [autofocus]="true"
            [(inputModel)]="pagePropertyModel.answers[0]"
            (focusOut)="setAnswer(pagePropertyModel?.answers?.[0])">
          </app-input>
        </ng-container>
        <app-radio
          [active]="pagePropertyModel.condition === FilterCondition.HasAnyValue"
          (update)="setCondition(FilterCondition.HasAnyValue)">
          {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
        </app-radio>
        <app-radio
          [active]="pagePropertyModel.condition === FilterCondition.IsUnknown"
          (update)="setCondition(FilterCondition.IsUnknown)">
          {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
        </app-radio>
      </div>
    </ng-container>
  </div>
</app-dropdown>
