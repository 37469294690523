import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-events',
  templateUrl: './icon-events.component.html',
  styleUrls: ['./icon-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconEventsComponent {
  constructor() {}
}
