<div (mouseenter)="init()" class="menu">
  <button
    #button
    class="menu-button"
    [class.menu-button-active]="showButton || (opened$ | async)"
    [class.menu-button-opened]="opened$ | async"
    [class.menu-button-vertical]="useVerticalDots">
    <app-icon-dots></app-icon-dots>
  </button>
</div>

<ng-container *ngIf="appendToBody; else templateRef"></ng-container>

<ng-template #templateRef>
  <div #dropdown (click)="button.click()" class="menu-dropdown pointer hidden">
    <ng-content></ng-content>
  </div>
</ng-template>
