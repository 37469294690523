import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-dots-menu-rename-remove',
  templateUrl: './dots-menu-rename-remove.component.html',
  styleUrls: ['./dots-menu-rename-remove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotsMenuRenameRemoveComponent {
  @Input() zIndex = 0;
  @Input() showButton = false;

  @Output() showRenameModal = new EventEmitter();
  @Output() showDeleteModal = new EventEmitter();

  constructor() {}
}
