<app-select
  class="block ml-6"
  placeholder="Enter value"
  [multiple]="true"
  [required]="true"
  [useSearch]="true"
  [isLoading]="isLoading"
  [selected]="selected"
  [elements]="(userProperties$ | async) || []"
  (handleSearch)="handleSearch($event)"
  (handleKeyPress)="handleSelectKeyPress($event)"
  (update)="handleUpdate($event)"
  (loadMore)="!allTraitPropertiesLoaded && loadMoreTraitProperties()">
  <span
    *ngIf="searchString$ | async; let searchString"
    topElement
    (click)="$event.stopPropagation(); setNewProperty()">
    Use: "{{ searchString }}"
  </span>
</app-select>
