<app-radio
  [active]="active"
  (update)="condition !== null && setCondition.emit(condition)">
  {{ filterCondition }}
</app-radio>

<div *ngIf="active" (click)="$event.stopPropagation()">
  <app-input
    *ngFor="let value of currentValues; let i = index; trackBy: trackByFn"
    class="block ml-6 mb-1"
    placeholder="Enter value"
    [required]="true"
    [autofocus]="i === 0"
    [(inputModel)]="currentValues[i]"
    (focusOut)="submit()">
    <button *ngIf="value" inputRight (click)="removeValue(i)">
      <app-icon-close color="#636B75"></app-icon-close>
    </button>
  </app-input>
  <app-input
    class="block ml-6"
    placeholder="Add another value"
    (handleKeypress)="handleKeypress($event)"
    (focusOut)="addValue($event)">
  </app-input>
</div>
