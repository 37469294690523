import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnChanges {
  timeout?: number;
  searchString?: string;

  @Input() searchValue?: string;
  @Input() useDebounce = false;
  @Input() placeholder = '';
  @Input() size: 'sm' | 'md' = 'sm';

  @Output() search: EventEmitter<string | undefined> = new EventEmitter();

  constructor() {}

  ngOnChanges({ searchValue }: SimpleChanges) {
    if (searchValue) {
      this.searchString = searchValue.currentValue;
    }
  }

  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
      event.target.blur();
      event.preventDefault();
    }
  }

  handleChnage(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.useDebounce) {
      this.timeout = window.setTimeout(() => {
        this.search.emit(this.searchString);
      }, 300);
    } else {
      this.search.emit(this.searchString);
    }
  }
}
