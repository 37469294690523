import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { openFilterById } from 'src/app/common/utils';

import { FilterType, FilterOperator } from '../../enums';
import {
  PageFilterWhere,
  PagePropertyFilter,
  PageFilterWhereFilterGroupFilter,
} from '../../types';

@Component({
  selector: 'app-page-filter-where',
  templateUrl: './page-filter-where.component.html',
  styleUrls: ['./page-filter-where.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFilterWhereComponent {
  FilterType = FilterType;
  allowedFilterGroupFilters = [
    {
      title: 'Page Property',
      type: FilterType.PageProperty,
    },
  ];
  allowedFilters = [
    {
      title: 'Page Property',
      type: FilterType.PageProperty,
    },
    {
      title: 'Filter Group',
      description: 'A group to nest more filters',
      type: FilterType.FilterGroup,
    },
  ];

  @Input() filter: PageFilterWhere | null = null;

  @Output() update: EventEmitter<PageFilterWhere> = new EventEmitter();

  constructor() {}

  trackByFilter(
    index: number,
    filter: PagePropertyFilter | PageFilterWhereFilterGroupFilter
  ) {
    return filter.id;
  }

  addFilter(filterType: FilterType) {
    if (this.filter) {
      const id = uuidv4();

      this.update.emit({
        ...this.filter,
        filters: [
          ...this.filter.filters,
          {
            id,
            filterType,
          },
        ],
      });

      openFilterById(id);
    }
  }

  removeFilter(index: number) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        filters: this.filter.filters.filter((_, i) => i !== index),
      });
    }
  }

  updateFilter(filter: PagePropertyFilter | PageFilterWhereFilterGroupFilter) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        filters: this.filter.filters.map(f =>
          f.id === filter.id ? filter : f
        ),
      });
    }
  }

  toggleRelationshipSwitcher(operator: FilterOperator) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        operator,
      });
    }
  }

  toFilterGroupFilterType(
    filter: PagePropertyFilter | PageFilterWhereFilterGroupFilter
  ) {
    return filter as PageFilterWhereFilterGroupFilter;
  }
}
