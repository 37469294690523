import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private activeAccountIdSub: BehaviorSubject<string | null> =
    new BehaviorSubject(null);
  private activeProjectIdSub: BehaviorSubject<string | null> =
    new BehaviorSubject(null);
  private mtuUsageGlobalSub: BehaviorSubject<any[]> = new BehaviorSubject([]);

  public activeAccountId$ = this.activeAccountIdSub.asObservable();
  public activeProjectId$ = this.activeProjectIdSub.asObservable();
  public mtuUsageGlobal$ = this.mtuUsageGlobalSub.asObservable();

  constructor() {
    const mtuData = sessionStorage.getItem('mtu_data');

    if (mtuData) {
      const parsedMtuData = JSON.parse(mtuData);

      if (parsedMtuData instanceof Array) {
        this.mtuUsageGlobalSub.next(parsedMtuData);
      }
    }
  }

  setActiveAccount(id: string | null) {
    if (id !== this.activeAccountIdSub.value) {
      this.activeAccountIdSub.next(id);
    }
  }

  getActiveAccount() {
    return this.activeAccountIdSub.value;
  }

  getActiveProject() {
    return this.activeProjectIdSub.value;
  }

  setActiveProject(id: string | null) {
    if (id !== this.activeProjectIdSub.value) {
      this.activeProjectIdSub.next(id);
    }
  }

  setMtuData(mtuData) {
    if (mtuData instanceof Array) {
      sessionStorage.setItem('mtu_data', JSON.stringify(mtuData));

      this.mtuUsageGlobalSub.next(mtuData);
    }
  }

  logout() {
    this.mtuUsageGlobalSub.next([]);
    this.setActiveAccount(null);
    this.setActiveProject(null);
    localStorage.clear();
  }
}
