import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { SelectComponent } from 'src/app/components/select/select.component';
import { ProjectService } from 'src/app/services/project/project.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-trait-properties-select',
  templateUrl: './trait-properties-select.component.html',
  styleUrls: ['./trait-properties-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TraitPropertiesSelectComponent implements OnChanges {
  private searchTimeout?: NodeJS.Timeout;

  allTraitPropertiesLoaded = false;
  limit = 100;
  offset = 0;
  isLoading = false;
  searchString$: BehaviorSubject<string> = new BehaviorSubject('');
  userProperties$: BehaviorSubject<{ label: string; value: any }[]> =
    new BehaviorSubject([]);

  @ViewChild(SelectComponent, { static: true })
  propertiesSelect?: SelectComponent;

  @Input() trait?: string;
  @Input() isDefaultTrait = false;
  @Input() selected: string[] = [];

  @Output() update: EventEmitter<string[]> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private projectService: ProjectService
  ) {}

  ngOnChanges({ trait, isDefaultTrait }: SimpleChanges): void {
    if (trait || isDefaultTrait) {
      this.offset = 0;
      this.userProperties$.next([]);

      this.initProperties();
    }
  }

  private async initProperties() {
    try {
      this.isLoading = true;
      this.allTraitPropertiesLoaded = false;

      this.userProperties$.next([]);

      await this.loadUserProperties();
    } finally {
      this.isLoading = false;
    }
  }

  private async loadUserProperties() {
    const projectId = this.sessionService.getActiveProject();

    if (!this.trait || !projectId) {
      return;
    }

    try {
      const properties = await this.projectService.getTraitValues({
        projectId,
        isDefaultTrait: this.isDefaultTrait,
        limit: this.limit,
        offset: this.offset,
        trait: this.trait,
        search: this.searchString$.value,
      });

      if (properties.length < this.limit) {
        this.allTraitPropertiesLoaded = true;
      }

      const userProperties = properties.map(value => ({
        label: value.toString(),
        value: value.toString(),
      }));

      const allProperties = this.selected.reduce((result, selectedProperty) => {
        const property = selectedProperty.trim();
        if (
          !properties.includes(property) &&
          !this.userProperties$.value.some(v => v.value === property)
        ) {
          result.push({
            label: property,
            value: property,
          });
        }
        return result;
      }, userProperties);

      this.userProperties$.next([
        ...this.userProperties$.value,
        ...allProperties,
      ]);
    } catch {
      this.userProperties$.next([]);
    }
  }

  handleSearch(searchString: string) {
    this.searchString$.next(searchString);

    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      this.offset = 0;

      this.initProperties();
    }, 100);
  }

  handleUpdate(value: { label: string; value: any }) {
    let selected = [...this.selected];

    if (selected.includes(value.value)) {
      selected = selected.filter(item => item !== value.value);
    } else {
      selected.push(value.value);
    }

    this.searchString$.next('');

    this.update.emit(selected);
  }

  setNewProperty() {
    if (!this.searchString$.value) {
      return;
    }
    if (
      this.selected.includes(this.searchString$.value) ||
      this.userProperties$.value.find(p => p.value === this.searchString$.value)
    ) {
      window.alert('Property already exists');
    } else {
      this.userProperties$.next([
        ...this.userProperties$.value,
        {
          label: this.searchString$.value,
          value: this.searchString$.value,
        },
      ]);
      this.update.emit([...this.selected, this.searchString$.value]);
      this.searchString$.next('');
    }
  }

  loadMoreTraitProperties() {
    this.offset += this.limit;

    this.loadUserProperties();
  }

  handleSelectKeyPress(event: KeyboardEvent) {
    event.stopPropagation();

    const search = this.searchString$.value;

    if (event.key === 'Enter' && search && !this.selected.includes(search)) {
      this.setNewProperty();

      this.propertiesSelect?.focusSearchElement();
    }
  }
}
