import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  id = uuidv4();

  @Input() required = false;
  @Input() disabled = false;
  @Input() checked?: boolean;
  @Input() name?: string;
  @Input() label?: string;
  @Input() labelColor = '#1e2024';

  @Output() update: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  public handleChange(e: Event): void {
    if (e && e.target instanceof HTMLInputElement) {
      this.update.emit(e.target.checked);
    }
  }
}
