import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { openFilterById } from 'src/app/common/utils';

import { FilterType, FilterOperator } from '../../enums';
import { Filter, EventFilterWhere, WhereFilterGroupFilter } from '../../types';

@Component({
  selector: 'app-event-filter-where',
  templateUrl: './event-filter-where.component.html',
  styleUrls: ['./event-filter-where.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFilterWhereComponent {
  allowedFilterGroupFilters = [
    {
      title: 'Event Property',
      type: FilterType.EventProperty,
    },
  ];
  allowedFilters = [
    {
      title: 'Event Property',
      type: FilterType.EventProperty,
    },
    {
      title: 'Filter Group',
      description: 'A group to nest more filters',
      type: FilterType.FilterGroup,
    },
  ];
  FilterType = FilterType;

  @Input() eventName?: string;
  @Input() filter: EventFilterWhere | null = null;

  @Output() update: EventEmitter<EventFilterWhere> = new EventEmitter();

  constructor() {}

  trackByFilter(index: number, filter: Filter) {
    return filter.id;
  }

  addFilter(filterType: FilterType) {
    if (this.filter) {
      const id = uuidv4();

      this.update.emit({
        ...this.filter,
        filters: [
          ...this.filter.filters,
          {
            id,
            filterType,
          },
        ],
      });

      openFilterById(id);
    }
  }

  toggleRelationshipSwitcher(operator: FilterOperator) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        operator,
      });
    }
  }

  removeFilter(index: number) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        filters: this.filter.filters.filter((_, i) => i !== index),
      });
    }
  }

  updateFilter(filter: Filter) {
    if (this.filter) {
      this.update.emit({
        ...this.filter,
        filters: this.filter.filters.map(f =>
          f.id === filter.id ? filter : f
        ),
      });
    }
  }

  toFilterGroupFilterType(filter: Filter) {
    return filter as WhereFilterGroupFilter;
  }
}
