import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { BaseDirective } from 'src/app/directives/base/base.directive';
import { ProjectSettingService } from 'src/app/services/project-setting/project-setting.service';
import { ToastService } from 'src/app/services/toast/toast.service';

type Media = {
  name: string;
  url: string;
};

export interface CurrentFile {
  name: string;
  url: string;
  size: string;
  up_file: File | null;
  base_64?: string;
}

const megabatyesToBytes = (value: number) => value * 1024 * 1024;

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent extends BaseDirective implements OnChanges {
  @Input() title: string | null = null;
  @Input() description: string | null = null;
  @Input() type: string[] = [];
  @Input() currentFile: CurrentFile | null = null;
  @Input() isSaved = true;
  @Input() limit = 2;
  @Input() label: string | null = null;
  @Input() triggerClick = false;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement> | undefined;

  isUploading = false;
  uploadedFile: File | null = null;
  uploadingProgress = 0;
  isProcessing = false;

  @Output() Error: EventEmitter<string> = new EventEmitter<string>();
  @Output() Success: EventEmitter<CurrentFile> =
    new EventEmitter<CurrentFile>();

  constructor(
    private toastService: ToastService,
    private projectSettingService: ProjectSettingService,
    injector: Injector
  ) {
    super(injector);
  }

  get uploadingFileSizeTitle() {
    if (this.uploadedFile) {
      if (this.uploadedFile.size / 1024 / 1024 > 1) {
        return `${(this.uploadedFile.size / 1024 / 1024).toFixed(1)}MB`;
      }
      return `${(this.uploadedFile.size / 1024).toFixed(1)}KB`;
    }
    return '0KB';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.triggerClick && changes.triggerClick.currentValue) {
      if (this.fileInput) {
        this.fileInput.nativeElement.click();
      }
    }

    if (changes.currentFile && changes.currentFile.currentValue) {
      this.uploadedFile = null;
    }
  }

  private handleUploadMediaEvents(event, callback) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        {
          this.uploadingProgress = Math.round(
            (event.loaded / (event.total || 1)) * 100
          );
          if (event.loaded === event.total) {
            setTimeout(() => {
              this.isProcessing = true;
            }, 300);
          }
        }
        break;

      case HttpEventType.Response:
        {
          this.uploadingProgress = 100;
          this.uploadedFile = null;
          this.isProcessing = false;
          this.isUploading = false;

          if (event.body) {
            this.currentFile = {
              name: event.body.result.name || '',
              url: event.body.result.url,
              size: event.body.result.size,
              up_file: null,
            };

            this.Success.emit(event.body.result);
          }

          callback(event.body ?? null);
        }
        break;
    }
  }

  private handleUploadMediaError(error, callback) {
    this.isUploading = false;

    this.toastService.showError(
      error instanceof HttpErrorResponse
        ? error.error.message
        : 'Internal error'
    );

    callback(error);
  }

  handleUploadOperation(event: Event) {
    const reader = new FileReader();

    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    let fileType = file.type;

    if (fileType === '') {
      fileType = `.${file.name
        .split('.')
        [file.name.split('.')?.length - 1].toLowerCase()}`;
    }

    if (this.type.includes(fileType) === false) {
      this.toastService.showError(
        `Invalid file type. Please upload ${this.type.join(', ')} file.`
      );

      return;
    }

    if (file.size > megabatyesToBytes(this.limit)) {
      event.target.files = null;

      this.toastService.showError(
        `The uploaded file size exceeds ${this.limit}MB limit.`
      );

      return;
    }

    this.isUploading = true;

    reader.readAsDataURL(file);

    this.uploadedFile = file;

    reader.onprogress = event => {
      setTimeout(() => {
        this.uploadingProgress = Math.round((event.loaded / event.total) * 100);
      }, 100);
    };

    reader.onerror = () => {
      this.Error.emit(`Cannot load the ${fileType}`);
    };

    reader.onload = async () => {
      const value = reader.result;

      if (!value) {
        return;
      }
      if (this.isSaved === true) {
        const res = await this.uploadMedia();

        if (res?.message) {
          this.toastService.showSuccess(res.message);
        }
      }

      this.isUploading = false;

      setTimeout(() => {
        this.uploadingProgress = 0;
        this.isUploading = false;

        if (this.isSaved === false) {
          this.currentFile = {
            name: this.uploadedFile?.name || '',
            url: 'notification.url',
            size: this.uploadingFileSizeTitle,
            up_file: this.uploadedFile,
            base_64: value.toString(),
          };

          this.Success.emit(this.currentFile);
        }
      }, 300);
    };
  }

  uploadMedia() {
    return new Promise<{
      result: Media;
      message: string;
    } | null>((resolve, reject) => {
      if (!this.uploadedFile) {
        return resolve(null);
      }

      const formData = new FormData();

      formData.append('file', this.uploadedFile);

      super.addSubscription(
        this.projectSettingService.uploadFile(formData).subscribe(
          event => this.handleUploadMediaEvents(event, resolve),
          error => this.handleUploadMediaError(error, reject)
        )
      );
    });
  }

  clearFileInput(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      event.target.files = null;
      event.target.value = '';
    }
  }
}
