import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { DataType, FilterCondition } from 'src/app/common/enums';
import { SelectComponent } from 'src/app/components/select/select.component';
import { FILTER_CONDITIONS } from 'src/app/constants';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { SessionService } from 'src/app/services/session/session.service';
import { SmartAudienceService } from 'src/app/services/smart-audience/smart-audience.service';

import {
  LabelFilterType,
  FilterType,
  FilterOperator,
  EventFilterType,
  EventCountTimeRange,
  EventCountTimeRangeFormat,
} from '../../enums';
import {
  WhereFilterGroupFilter,
  EventPropertyFilter,
  EventFilter,
  EventFilterWhere,
} from '../../types';

@Component({
  selector: 'app-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFilterComponent extends BaseDirective implements OnChanges {
  eventSearchString$: BehaviorSubject<string> = new BehaviorSubject('');
  dropdownActive = false;
  isLoading = false;
  FilterType = FilterType;
  LabelFilterType = LabelFilterType;
  EventFilterType = EventFilterType;
  events$: BehaviorSubject<{ label: string; value: any }[]> =
    new BehaviorSubject([]);
  eventTypes = [
    {
      label: 'Count',
      value: EventFilterType.COUNT,
    },
    {
      label: 'First Occurred',
      value: EventFilterType.FIRST_OCCURRED,
    },
    {
      label: 'Last Occurred',
      value: EventFilterType.LAST_OCCURRED,
    },
  ];
  countEventTypes = [
    {
      label: 'less than',
      value: FilterCondition.LessThan,
    },
    {
      label: 'exactly',
      value: FilterCondition.Exactly,
    },
    {
      label: 'more than',
      value: FilterCondition.MoreThan,
    },
    {
      label: 'between',
      value: FilterCondition.Between,
    },
  ];
  eventCountTimeRanges = [
    {
      label: 'all time',
      value: EventCountTimeRange.ALL_TIME,
    },
    {
      label: 'in the last',
      value: EventCountTimeRange.IN_THE_LAST,
    },
    {
      label: 'more than',
      value: EventCountTimeRange.MORE_THAN,
    },
    {
      label: 'between',
      value: EventCountTimeRange.BETWEEN,
    },
  ];
  eventModel: EventFilter | null = null;
  activeEventType: EventFilterType | null = null;
  eventCountTimeRangeFormats: {
    label: string;
    value: EventCountTimeRangeFormat;
  }[] = [];
  EventCountTimeRange = EventCountTimeRange;

  @ViewChild(SelectComponent, { static: true }) eventSelect?: SelectComponent;

  @Input() allowAddingNewEvents = false;
  @Input() hideEventFilterType = false;
  @Input() event: EventFilter | null = null;

  @Output() update: EventEmitter<EventFilter> = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() finish: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private smartAudienceService: SmartAudienceService,
    injector: Injector
  ) {
    super(injector);

    super.addSubscription(
      this.sessionService.activeProjectId$.subscribe(async activeProjectId => {
        if (!activeProjectId) {
          return;
        }

        this.loadEvents(activeProjectId);
      })
    );
  }

  ngOnChanges({ event }: SimpleChanges) {
    if (event?.currentValue) {
      let eventNewModel = JSON.parse(JSON.stringify(event.currentValue));

      if (!this.hideEventFilterType) {
        if (
          !event?.currentValue[EventFilterType.COUNT] &&
          !event?.currentValue[EventFilterType.FIRST_OCCURRED] &&
          !event?.currentValue[EventFilterType.LAST_OCCURRED]
        ) {
          eventNewModel = {
            ...eventNewModel,
            condition: FilterCondition.Exactly,
            [EventFilterType.COUNT]: {
              timeRange: EventCountTimeRange.ALL_TIME,
              eventTypeValues: [{}],
              timeRangeValues: [],
            },
            where: {
              operator: FilterOperator.AND,
              filters: [],
            },
          };
        }

        if (eventNewModel[EventFilterType.COUNT]?.timeRange !== undefined) {
          this.eventCountTimeRangeFormats = this.setEventCountTimeRangeFormats(
            eventNewModel[EventFilterType.COUNT].timeRange
          );
        }

        if (!this.activeEventType || !eventNewModel[this.activeEventType]) {
          this.activeEventType = this.getEventType(eventNewModel);
        }
      }

      this.eventModel = eventNewModel;
    }
  }

  get isFilterInvalid() {
    if (!this.event?.value) {
      return true;
    }

    if (this.event?.where?.filters?.length) {
      const isInvalid = this.event.where.filters.some(f => {
        if (f.filterType === FilterType.EventProperty) {
          const { value, condition, answers = [] } = f as EventPropertyFilter;

          if (!value) {
            return true;
          }

          if (
            condition === FilterCondition.HasAnyValue ||
            condition === FilterCondition.IsUnknown
          ) {
            return false;
          }

          return answers.length === 0;
        }
        return false;
      });

      if (isInvalid) {
        return isInvalid;
      }
    }

    switch (this.activeEventType) {
      case EventFilterType.COUNT: {
        if (!this.event.count) {
          return true;
        }
        const { eventTypeValues, timeRangeValues, timeRange } =
          this.event.count;
        return (
          !eventTypeValues.some(v => v.value ?? false) ||
          (timeRange !== EventCountTimeRange.ALL_TIME &&
            !timeRangeValues.some(v => v.value ?? false))
        );
      }

      case EventFilterType.FIRST_OCCURRED: {
        if (
          this.event.condition === FilterCondition.HasAnyValue ||
          this.event.condition === FilterCondition.IsUnknown
        ) {
          return false;
        }

        return !this.event[EventFilterType.FIRST_OCCURRED]?.value;
      }

      case EventFilterType.LAST_OCCURRED: {
        if (
          this.event.condition === FilterCondition.HasAnyValue ||
          this.event.condition === FilterCondition.IsUnknown
        ) {
          return false;
        }

        return !this.event[EventFilterType.LAST_OCCURRED]?.value;
      }

      default:
        return false;
    }
  }

  get eventTypeLabel() {
    const eventType = this.event ? this.getEventType(this.event) : null;
    return eventType
      ? this.eventTypes.find(e => e.value === eventType)?.label.toLowerCase()
      : '';
  }

  get conditionTitle() {
    const eventType = this.event ? this.getEventType(this.event) : null;

    if (eventType) {
      const condition = this.event?.condition;

      return (condition !== undefined && FILTER_CONDITIONS[condition]) || '';
    }

    return '';
  }

  get value() {
    const eventType = this.event ? this.getEventType(this.event) : null;

    switch (eventType) {
      case EventFilterType.COUNT: {
        const filter = this.event?.[EventFilterType.COUNT];
        return filter?.eventTypeValues.length
          ? `${filter.eventTypeValues
              .map(t => t.value)
              .join(
                ` ${FilterOperator.AND.toLowerCase()} `
              )} times ${this.eventCountTimeRanges.find(
              e => e.value === filter.timeRange
            )?.label} ${filter.timeRangeValues
              .map(t => t.value)
              .join(` ${FilterOperator.AND.toLowerCase()} `)} ${
              this.eventCountTimeRangeFormats.find(
                t => t.value === filter.timeRangeFormat
              )?.label ?? ''
            } `
          : '';
      }

      case EventFilterType.FIRST_OCCURRED: {
        const value = this.eventModel?.[EventFilterType.FIRST_OCCURRED]?.value;

        return `${value ?? ''} ${
          value !== undefined && !Number.isNaN(+value) ? 'days ago' : ''
        }`;
      }

      case EventFilterType.LAST_OCCURRED: {
        const value = this.eventModel?.[EventFilterType.LAST_OCCURRED]?.value;

        return `${value ?? ''} ${
          value !== undefined && !Number.isNaN(+value) ? 'days ago' : ''
        }`;
      }

      default:
        return '';
    }
  }

  private async loadEvents(activeProjectId: string) {
    try {
      this.isLoading = true;

      const events =
        await this.smartAudienceService.getAudienceDataEvents(activeProjectId);

      this.events$.next(
        events.map(event => ({
          label: event._id,
          value: event._id,
        }))
      );

      if (
        this.eventModel?.value &&
        !this.events$.value.some(e => e.value === this.eventModel!.value)
      ) {
        this.events$.next([
          ...this.events$.value,
          {
            label: this.eventModel.value,
            value: this.eventModel.value,
          },
        ]);
      }
    } finally {
      this.isLoading = false;
    }
  }

  private getEventType(event: EventFilter) {
    if (event[EventFilterType.COUNT]) {
      return EventFilterType.COUNT;
    } else if (event[EventFilterType.FIRST_OCCURRED]) {
      return EventFilterType.FIRST_OCCURRED;
    } else if (event[EventFilterType.LAST_OCCURRED]) {
      return EventFilterType.LAST_OCCURRED;
    }
    return null;
  }

  private getTypeValuesByCondition(operator: FilterCondition) {
    switch (operator) {
      case FilterCondition.Between:
        return [{}, {}];

      default:
        return [{}];
    }
  }

  private getTimeRangeValuesByTimeRange(timeRange: EventCountTimeRange) {
    switch (timeRange) {
      case EventCountTimeRange.ALL_TIME:
        return [];

      case EventCountTimeRange.IN_THE_LAST:
        return [{}];

      case EventCountTimeRange.MORE_THAN:
        return [{}];

      case EventCountTimeRange.BETWEEN:
        return [{}, {}];

      default:
        return [];
    }
  }

  getAdditionalFilterPostfix(filter: EventPropertyFilter) {
    switch (filter.filterType) {
      case FilterType.EventProperty:
        switch (filter.traitType) {
          case DataType.DATE:
            return filter.answers?.length && !Number.isNaN(+filter.answers[0])
              ? ' days ago'
              : '';

          default:
            return '';
        }
      default:
        return '';
    }
  }

  setEvent(model: { label: string; value: any }) {
    if (this.eventModel) {
      this.update.emit({
        id: this.eventModel.id,
        filterType: this.eventModel.filterType,
        label: model.label,
        value: model.value,
        where: {
          operator: FilterOperator.AND,
          filters: [],
        },
      });
      this.eventSearchString$.next('');
    }
  }

  showDropdown() {
    this.dropdownActive = true;
  }

  hideDropdown() {
    this.dropdownActive = false;

    this.finish.emit(!this.isFilterInvalid);
  }

  setEventType(eventType: EventFilterType) {
    this.activeEventType = eventType;

    if (this.eventModel && !this.eventModel[this.activeEventType]) {
      let event = { ...this.eventModel };

      switch (eventType) {
        case EventFilterType.COUNT:
          event = {
            ...event,
            condition: FilterCondition.Exactly,
            [EventFilterType.COUNT]: {
              timeRange: EventCountTimeRange.ALL_TIME,
              eventTypeValues: [{}],
              timeRangeValues: [],
            },
          };
          break;

        case EventFilterType.FIRST_OCCURRED:
          event[EventFilterType.FIRST_OCCURRED] = {};
          break;

        case EventFilterType.LAST_OCCURRED:
          event[EventFilterType.LAST_OCCURRED] = {};
          break;

        default:
          break;
      }

      this.eventModel = event;
    }
  }

  handleCountEventType(condition: string | number) {
    if (this.eventModel?.[EventFilterType.COUNT]) {
      this.update.emit({
        ...this.eventModel,
        condition: condition as FilterCondition,
        [EventFilterType.COUNT]: {
          ...this.eventModel[EventFilterType.COUNT]!,
          eventTypeValues: this.getTypeValuesByCondition(
            condition as FilterCondition
          ),
        },
        [EventFilterType.FIRST_OCCURRED]: undefined,
        [EventFilterType.LAST_OCCURRED]: undefined,
      });
    }
  }

  handleCountTimeRange(timeRange: string | number) {
    if (this.eventModel?.[EventFilterType.COUNT]) {
      this.update.emit({
        ...this.eventModel,
        [EventFilterType.COUNT]: {
          ...this.eventModel[EventFilterType.COUNT]!,
          timeRange: timeRange as EventCountTimeRange,
          timeRangeFormat:
            timeRange === EventCountTimeRange.ALL_TIME
              ? undefined
              : EventCountTimeRangeFormat.DAYS,
          timeRangeValues: this.getTimeRangeValuesByTimeRange(
            timeRange as EventCountTimeRange
          ),
        },
        [EventFilterType.FIRST_OCCURRED]: undefined,
        [EventFilterType.LAST_OCCURRED]: undefined,
      });

      this.eventCountTimeRangeFormats = this.setEventCountTimeRangeFormats(
        timeRange as EventCountTimeRange
      );
    }
  }

  handleCountTimeRangeFormat(timeRangeFormat?: string | number) {
    if (this.eventModel?.[EventFilterType.COUNT]) {
      this.update.emit({
        ...this.eventModel,
        [EventFilterType.COUNT]: {
          ...this.eventModel[EventFilterType.COUNT]!,
          timeRangeFormat: timeRangeFormat as EventCountTimeRangeFormat,
        },
        [EventFilterType.FIRST_OCCURRED]: undefined,
        [EventFilterType.LAST_OCCURRED]: undefined,
      });
    }
  }

  setFirstOccurred(model: { condition?: FilterCondition; value?: string }) {
    if (this.eventModel?.[EventFilterType.FIRST_OCCURRED]) {
      this.update.emit({
        ...this.eventModel,
        condition: model.condition,
        [EventFilterType.COUNT]: undefined,
        [EventFilterType.LAST_OCCURRED]: undefined,
        [EventFilterType.FIRST_OCCURRED]: {
          ...this.eventModel[EventFilterType.FIRST_OCCURRED],
          value: model.value,
        },
      });
    }
  }

  setLastOccurred(model: { condition?: FilterCondition; value?: string }) {
    if (this.eventModel?.[EventFilterType.LAST_OCCURRED]) {
      this.update.emit({
        ...this.eventModel,
        condition: model.condition,
        [EventFilterType.COUNT]: undefined,
        [EventFilterType.FIRST_OCCURRED]: undefined,
        [EventFilterType.LAST_OCCURRED]: {
          ...this.eventModel[EventFilterType.LAST_OCCURRED],
          value: model.value,
        },
      });
    }
  }

  setEventCountTimeRangeFormats(timeRange: EventCountTimeRange) {
    return [
      {
        label:
          timeRange === EventCountTimeRange.IN_THE_LAST ? 'days' : 'days ago',
        value: EventCountTimeRangeFormat.DAYS,
      },
      {
        label:
          timeRange === EventCountTimeRange.IN_THE_LAST ? 'hours' : 'hours ago',
        value: EventCountTimeRangeFormat.HOURS,
      },
      {
        label:
          timeRange === EventCountTimeRange.IN_THE_LAST
            ? 'minutes'
            : 'minutes ago',
        value: EventCountTimeRangeFormat.MINUTES,
      },
    ];
  }

  handleNumberInputBlur(event: FocusEvent) {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.value &&
      +event.target.value < 1
    ) {
      event.target.value = '';
    }

    if (this.eventModel) {
      this.update.emit({
        ...this.eventModel,
        value: this.eventModel.value || undefined,
        count: {
          ...this.eventModel.count!,
          eventTypeValues: this.eventModel.count!.eventTypeValues.map(v => ({
            value: v.value || undefined,
          })),
          timeRangeValues: this.eventModel.count!.timeRangeValues.map(v => ({
            value: v.value || undefined,
          })),
        },
        [EventFilterType.FIRST_OCCURRED]: undefined,
        [EventFilterType.LAST_OCCURRED]: undefined,
      });
    }
  }

  updateWhere(where: EventFilterWhere) {
    if (this.eventModel) {
      this.update.emit({
        ...this.eventModel,
        where,
      });
    }
  }

  getFilterConditionLabel(condition?: FilterCondition) {
    if (condition) {
      return FILTER_CONDITIONS[condition];
    }
    return;
  }

  toFilterGroupFilterType(
    filter: EventPropertyFilter | WhereFilterGroupFilter
  ) {
    return filter as WhereFilterGroupFilter;
  }

  toEventPropertyFilterType(
    filter: EventPropertyFilter | WhereFilterGroupFilter
  ) {
    return filter as EventPropertyFilter;
  }

  getEventPropertyLabel(filter: EventPropertyFilter) {
    const answersLength = filter.answers?.length ?? 0;
    const fewAnswers = answersLength > 1;

    if (fewAnswers) {
      return filter.answers?.reduce((result, answer, index) => {
        if (answersLength - 2 === index) {
          return `${result}${answer}`;
        }
        if (answersLength - 1 === index) {
          return `${result} or ${answer}`;
        }
        return `${result}${answer}, `;
      }, '');
    }

    return filter.answers?.[0];
  }

  trackByEventType(index: number, eventType: { value: EventFilterType }) {
    return eventType.value;
  }

  setEventSearchString(searchString: string) {
    this.eventSearchString$.next(searchString);
  }

  addNewEvent() {
    if (
      this.eventSearchString$.value &&
      !this.events$.value.some(e => e.value === this.eventSearchString$.value)
    ) {
      const newEvent = {
        value: this.eventSearchString$.value,
        label: this.eventSearchString$.value,
      };
      this.setEventSearchString('');

      this.events$.next([...this.events$.value, newEvent]);
      this.setEvent(newEvent);
    }
  }

  handleEventSelectKeyPress(event: KeyboardEvent) {
    event.stopPropagation();

    const search = this.eventSearchString$.value;

    if (event.key === 'Enter' && search && this.eventModel?.value !== search) {
      this.addNewEvent();

      this.eventSearchString$.next('');

      this.eventSelect?.hide();
    }
  }
}
