<router-outlet></router-outlet>

<app-modal
  width="496px"
  modalTitle="Access restricted"
  [opened]="(permissionService.isPermissionsModalOpened$ | async) || false"
  (hide)="permissionService.hidePermissionsModal()">
  <div class="flex flex-wrap gap-4">
    <p
      *ngIf="permissionService.userRole$ | async; let userRole"
      class="message">
      As {{ userRole === UserType.VIEWER ? 'a' : 'an' }} <b>{{ userRole }}</b
      >, you do not have the permission to perform this operation. Please
      contact your organization owner to update your role if required.
    </p>
    <div class="w-full flex gap-2 justify-end">
      <app-button variant="filled" color="secondary" (press)="contactSupport()">
        Contact support
      </app-button>
      <app-button
        variant="filled"
        color="primary"
        (press)="permissionService.hidePermissionsModal()">
        Acknowledge
      </app-button>
    </div>
  </div>
</app-modal>
