import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-chevron',
  templateUrl: './icon-chevron.component.html',
  styleUrls: ['./icon-chevron.component.scss'],
})
export class IconChevronComponent {
  @Input() color = '#94A3B8';
  @Input() side: 'left' | 'top' | 'right' | 'bottom' = 'bottom';

  constructor() {}
}
