import {
  Component,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-vertical-list',
  templateUrl: './vertical-list.component.html',
  styleUrls: ['./vertical-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalListComponent {
  @Input() listTitle?: string;
  @Input() tooltip?: string;

  @Output() add: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
