import {
  Directive,
  ElementRef,
  Input,
  Output,
  OnInit,
  EventEmitter,
} from '@angular/core';

type RotationFunction = () => number;

export type AgWordCloudOptions = {
  settings?: {
    minFontSize?: number;
    maxFontSize?: number;
    fontFace?: string;
    fontWeight?: string;
    spiral?: string;
    textRotation?: number | RotationFunction;
  };
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  labels?: boolean;
};

type AgWordCloudData = {
  text: string;
  size: number;
  color?: string;
};

@Directive({
  selector: '[appTextD3Cloud]',
  exportAs: 'ag-word-cloud',
})
export class TextD3CloudDirective implements OnInit {
  temp: Array<AgWordCloudData> = [];

  @Input() wordData!: AgWordCloudData[];
  @Input() color: string[] = [
    '#FAECA3',
    '#91ECC0',
    '#AFB0F3',
    '#76B3FC',
    '#FFB1D9',
    '#F09973',
    '#D2FC7A',
    '#FAE264',
    '#7485DF',
  ];

  @Input() options!: AgWordCloudOptions;
  @Input() width!: number;
  @Input() height!: number;
  @Output() wordClick = new EventEmitter();

  private old_min: number = 50;
  private old_max: number = 100;
  private svg: any;

  private element: ElementRef;

  public constructor(element: ElementRef) {
    this.element = element;
  }

  ngOnInit() {
    this.update();
  }

  private getTextRotation(): number | RotationFunction {
    return 0;
  }

  private roundNumber() {
    //size:   10 + Math.random() * 90
    // 10 + Math.random() * 90
    const temp = this.wordData.map(d => {
      if (d.color) {
        return { text: d.text, size: d.size, color: d.color };
      }
      return {
        text: d.text,
        size: d.size,
        color: this.color[Math.floor(Math.random() * this.color.length)],
      };
    });
    this.temp.length = 0;
    this.temp.push(...temp);
  }

  private updateMaxMinValues() {
    this.old_min = Number.MAX_VALUE;
    this.old_max = Number.MIN_VALUE;
    this.wordData.map(res => {
      if (res.size < this.old_min) {
        this.old_min = res.size;
      }
      if (res.size > this.old_max) {
        this.old_max = res.size;
      }
    });
  }

  private setup() {
    if (!this.width) {
      // @ts-ignore
      this.width = 500 - this.options.margin.right - this.options.margin.left;
    }
    if (!this.height) {
      // @ts-ignore
      this.height =
        this.width * 0.75 -
        this.options.margin!.top -
        this.options.margin!.bottom;
    }
  }

  private async buildSVG() {
    const D3 = await import('d3');

    this.svg = D3.select(this.element.nativeElement)
      .append('svg')
      .attr('class', 'wordcloud-svg') // @ts-ignore
      .attr(
        'width',
        this.width + this.options.margin!.left + this.options.margin!.right
      ) // @ts-ignore
      .attr(
        'height',
        this.height + this.options.margin!.top + this.options.margin!.bottom
      )
      .append('g')
      .attr(
        'transform',
        'translate(' + ~~(this.width / 2) + ',' + ~~(this.height / 2) + ')'
      );
  }

  private async populate() {
    if (this.svg) {
      this.svg.selectAll('*').remove();
    }
    this.updateMaxMinValues();
    this.roundNumber();
    // @ts-ignore
    const fontFace = this.options.settings?.fontFace ?? 'Roboto';
    const fontWeight = this.options.settings?.fontWeight ?? 'normal';
    const spiralType = this.options.settings!.spiral ?? 'archimedean';

    const D3Cloud = await import('d3-cloud');

    new D3Cloud.default()
      .size([this.width, this.height])
      .words(this.temp)
      .padding(5)
      .rotate(this.getTextRotation())
      .font(fontFace)
      .fontWeight(fontWeight)
      .fontSize(d => d.size)
      .spiral(spiralType)
      .on('end', () => {
        this.drawWordCloud(this.temp);
      })
      .start();
  }

  private async drawWordCloud(words) {
    const self = this;
    // @ts-ignore
    const fontWeight = this.options.settings?.fontWeight ?? 'normal';
    const fontFace = this.options.settings?.fontFace ?? 'Roboto';

    const D3 = await import('d3');

    D3.select(this.element.nativeElement)
      .append('div')
      .attr('class', 'wordcloud-tooltip')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'hidden')
      .text('a simple tooltip');

    this.svg
      .selectAll('text')
      .data(words)
      .enter()
      .append('text')
      .style('font-size', d => d.size + 'px')
      .style('font-weight', fontWeight)
      .style('font-family', fontFace)
      .style('fill', (d, i) => {
        return d.color;
      })
      .attr('mdTooltip', 'ddd')
      .attr('text-anchor', 'middle')
      .attr(
        'transform',
        d => 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')'
      )
      .attr('class', 'word-cloud')
      .on('mouseover', (d, i) => {
        // return this.options.labels ? tooltip.style('visibility', 'visible').text('Size: ' + self.getWordSize(d.text)) : tooltip.style('display', 'none');
      })
      .on('mouseout', function () {
        // return tooltip.style('visibility', 'hidden');
      })
      .on('click', function (d, i) {
        self.wordClick.emit(d.text);
      })
      .text(d => {
        return d.text;
      });
  }

  getWordSize(word: string): number {
    const indexOfWord = this.wordData.findIndex(i => i.text === word);
    if (indexOfWord === -1) return 0;
    return this.wordData[indexOfWord].size;
  }

  public update() {
    this.removeElementsByClassName('wordcloud-svg');
    this.removeElementsByClassName('wordcloud-tooltip');

    this.setup();
    this.buildSVG();
    this.populate();
  }

  removeElementsByClassName(classname: string) {
    const elements =
      this.element.nativeElement.getElementsByClassName(classname);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }
}
