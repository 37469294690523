<app-dropdown
  *ngIf="eventPropertyModel$ | async; let eventPropertyModel"
  placement="bottom-start"
  [useScrollToElement]="true"
  (openDropdown)="showDropdown()"
  (closeDropdown)="hideDropdown()">
  <app-label-filter
    button
    [id]="eventPropertyModel?.id"
    [showError]="isFilterInvalid"
    [tooltip]="
      eventPropertyModel?.value
        ? 'Please enter the missing value'
        : 'Please select an event property'
    "
    [active]="dropdownActive"
    [icon]="eventPropertyModel?.icon"
    [type]="LabelFilterType.EventProperty"
    (remove)="remove.emit()">
    <ng-container *ngIf="eventPropertyModel?.label; else placeholder">
      <b>{{ eventPropertyModel?.label }}</b>
      {{ conditionTitle }}
      <b *ngFor="let answer of answerValues; trackBy: trackByAnswerValue">{{
        answer
      }}</b>
      <b>{{ valuePostfix }}</b>
    </ng-container>
    <ng-template #placeholder>Select an event property</ng-template>
  </app-label-filter>
  <div dropdown class="dropdown flex flex-col gap-4">
    <app-event-property-select
      [eventName]="eventName"
      [selected]="eventPropertyModel?.value"
      (setPropertyType)="setEventProperty($event)"></app-event-property-select>
    <ng-container *ngIf="eventPropertyModel && eventPropertyModel.answers">
      <ng-container [ngSwitch]="eventPropertyModel.traitType">
        <div *ngSwitchCase="DataType.ARRAY" class="flex flex-col gap-1">
          <app-array-input
            [values]="eventPropertyModel.answers"
            [active]="
              eventPropertyModel.condition ===
              FilterCondition.IncludesOneOrMoreOf
            "
            [condition]="FilterCondition.IncludesOneOrMoreOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-array-input
            [values]="eventPropertyModel.answers"
            [active]="
              eventPropertyModel.condition === FilterCondition.IncludesAllOf
            "
            [condition]="FilterCondition.IncludesAllOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-array-input
            [values]="eventPropertyModel.answers"
            [active]="
              eventPropertyModel.condition ===
              FilterCondition.ExcludesOneOrMoreOf
            "
            [condition]="FilterCondition.ExcludesOneOrMoreOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-array-input
            [values]="eventPropertyModel.answers"
            [active]="
              eventPropertyModel.condition === FilterCondition.ExcludesAllOf
            "
            [condition]="FilterCondition.ExcludesAllOf"
            (setCondition)="setCondition($event)"
            (setValues)="setAnswers($event)">
          </app-array-input>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.HasAnyValue
            "
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.IsUnknown
            "
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <div *ngSwitchCase="DataType.BOOLEAN" class="flex flex-col gap-1">
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.Is &&
              eventPropertyModel?.answers?.[0]?.toString() === 'true'
            "
            (update)="setCondition(FilterCondition.Is, 'true')">
            {{ FILTER_CONDITIONS[FilterCondition.Is] }} true
          </app-radio>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.Is &&
              eventPropertyModel?.answers?.[0]?.toString() === 'false'
            "
            (update)="setCondition(FilterCondition.Is, 'false')">
            {{ FILTER_CONDITIONS[FilterCondition.Is] }} false
          </app-radio>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.HasAnyValue
            "
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.IsUnknown
            "
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <div *ngSwitchCase="DataType.STRING" class="flex flex-col gap-1">
          <ng-container>
            <app-radio
              [active]="eventPropertyModel.condition === FilterCondition.Is"
              (update)="setCondition(FilterCondition.Is)">
              {{ FILTER_CONDITIONS[FilterCondition.Is] }}
            </app-radio>
            <app-event-property-values-select
              *ngIf="eventPropertyModel.condition === FilterCondition.Is"
              [selected]="eventPropertyModel.answers"
              [eventProperty]="eventPropertyModel?.value"
              [eventName]="eventName"
              (update)="setAnswers($event)">
            </app-event-property-values-select>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="eventPropertyModel.condition === FilterCondition.IsNot"
              (update)="setCondition(FilterCondition.IsNot)">
              {{ FILTER_CONDITIONS[FilterCondition.IsNot] }}
            </app-radio>
            <app-event-property-values-select
              *ngIf="eventPropertyModel.condition === FilterCondition.IsNot"
              [selected]="eventPropertyModel.answers"
              [eventProperty]="eventPropertyModel?.value"
              [eventName]="eventName"
              (update)="setAnswers($event)">
            </app-event-property-values-select>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.Contains
              "
              (update)="setCondition(FilterCondition.Contains)">
              {{ FILTER_CONDITIONS[FilterCondition.Contains] }}
            </app-radio>
            <app-input
              *ngIf="eventPropertyModel.condition === FilterCondition.Contains"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.DoesNotContain
              "
              (update)="setCondition(FilterCondition.DoesNotContain)">
              {{ FILTER_CONDITIONS[FilterCondition.DoesNotContain] }}
            </app-radio>
            <app-input
              *ngIf="
                eventPropertyModel.condition === FilterCondition.DoesNotContain
              "
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.StartsWith
              "
              (update)="setCondition(FilterCondition.StartsWith)">
              {{ FILTER_CONDITIONS[FilterCondition.StartsWith] }}
            </app-radio>
            <app-input
              *ngIf="
                eventPropertyModel.condition === FilterCondition.StartsWith
              "
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.EndsWith
              "
              (update)="setCondition(FilterCondition.EndsWith)">
              {{ FILTER_CONDITIONS[FilterCondition.EndsWith] }}
            </app-radio>
            <app-input
              *ngIf="eventPropertyModel.condition === FilterCondition.EndsWith"
              class="block ml-6"
              placeholder="Enter value"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.HasAnyValue
            "
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.IsUnknown
            "
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <div *ngSwitchCase="DataType.NUMBER" class="flex flex-col gap-1">
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.IsEqualTo
              "
              (update)="setCondition(FilterCondition.IsEqualTo)">
              {{ FILTER_CONDITIONS[FilterCondition.IsEqualTo] }}
            </app-radio>
            <app-input
              *ngIf="eventPropertyModel.condition === FilterCondition.IsEqualTo"
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.IsNotEqualTo
              "
              (update)="setCondition(FilterCondition.IsNotEqualTo)">
              {{ FILTER_CONDITIONS[FilterCondition.IsNotEqualTo] }}
            </app-radio>
            <app-input
              *ngIf="
                eventPropertyModel.condition === FilterCondition.IsNotEqualTo
              "
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.IsLessThan
              "
              (update)="setCondition(FilterCondition.IsLessThan)">
              {{ FILTER_CONDITIONS[FilterCondition.IsLessThan] }}
            </app-radio>
            <app-input
              *ngIf="
                eventPropertyModel.condition === FilterCondition.IsLessThan
              "
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.IsMoreThan
              "
              (update)="setCondition(FilterCondition.IsMoreThan)">
              {{ FILTER_CONDITIONS[FilterCondition.IsMoreThan] }}
            </app-radio>
            <app-input
              *ngIf="
                eventPropertyModel.condition === FilterCondition.IsMoreThan
              "
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.IsAtLeast
              "
              (update)="setCondition(FilterCondition.IsAtLeast)">
              {{ FILTER_CONDITIONS[FilterCondition.IsAtLeast] }}
            </app-radio>
            <app-input
              *ngIf="eventPropertyModel.condition === FilterCondition.IsAtLeast"
              class="block ml-6"
              placeholder="Enter number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <ng-container>
            <app-radio
              [active]="
                eventPropertyModel.condition === FilterCondition.IsAtMost
              "
              (update)="setCondition(FilterCondition.IsAtMost)">
              {{ FILTER_CONDITIONS[FilterCondition.IsAtMost] }}
            </app-radio>
            <app-input
              *ngIf="eventPropertyModel.condition === FilterCondition.IsAtMost"
              class="block ml-6"
              placeholder="Enter number"
              inputType="number"
              [required]="true"
              [autofocus]="true"
              [(inputModel)]="eventPropertyModel.answers[0]"
              (focusOut)="setAnswer(eventPropertyModel?.answers?.[0])">
            </app-input>
          </ng-container>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.HasAnyValue
            "
            (update)="setCondition(FilterCondition.HasAnyValue)">
            {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
          </app-radio>
          <app-radio
            [active]="
              eventPropertyModel.condition === FilterCondition.IsUnknown
            "
            (update)="setCondition(FilterCondition.IsUnknown)">
            {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
          </app-radio>
        </div>
        <app-date-filter-section
          *ngSwitchCase="DataType.DATE"
          [value]="eventPropertyModel?.answers?.[0]"
          [condition]="eventPropertyModel.condition"
          (update)="setDateValue($event)">
        </app-date-filter-section>
      </ng-container>
    </ng-container>
  </div>
</app-dropdown>
