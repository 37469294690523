import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-square-arrow-link',
  templateUrl: './square-arrow-link.component.html',
  styleUrls: ['./square-arrow-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquareArrowLinkComponent {
  @Input() href?: string;
  @Input() routerLink?: string;
  @Input() queryParams?: { [key: string]: any } = {};

  @Output() press: EventEmitter<Event> = new EventEmitter();

  constructor() {}
}
