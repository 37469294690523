import * as ObjectId from 'bson-objectid';

import { SurveyQuestion } from 'src/app/common/enums';
import { AUTH_TOKEN } from 'src/app/constants/constants';
import { events } from 'src/app/constants/segment-event-list';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { OneflowStorageService } from 'src/app/services/oneflow-storage/oneflow-storage.service';
import { SegmentService } from 'src/app/services/segment/segment.service';
import { SessionService } from 'src/app/services/session/session.service';
import { SocketClientService } from 'src/app/services/socket/socket-client.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserService } from 'src/app/services/user/user.service';

declare const window: Window & {
  Intercom: any;
};

export const parseEmbedURL = (url: string) => {
  if (url.includes('youtube.com')) {
    const videoId = url.split('v=')[1];
    return `https://www.youtube.com/embed/${videoId}?rel=0`;
  } else if (url.includes('loom.com')) {
    const videoId = url.split('/v/')[1] ?? url.split('/share/')[1];
    return `https://www.loom.com/embed/${videoId}`;
  } else if (url.includes('vimeo.com')) {
    const videoId = url.split('/')[3];
    return `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`;
  } else {
    return null;
  }
};

export const getEmbedVideoPlatform = (url: string) => {
  if (url.includes('youtube.com')) {
    return `youtube`;
  } else if (url.includes('loom.com')) {
    return 'loom';
  } else if (url.includes('.com')) {
    return 'vimeo';
  } else {
    return null;
  }
};

export const hexToRgba = (
  hexaCode: string,
  opacity: number
): string | undefined => {
  let c;
  let rgba_color;
  hexaCode = hexaCode.length > 7 ? hexaCode.substring(0, 7) : hexaCode;
  try {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexaCode)) {
      c = hexaCode.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      rgba_color =
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        ',' +
        opacity +
        ')';

      return rgba_color;
    } else {
      return;
    }
  } catch (e: any) {
    return;
  }
};

export const rgbaToHex = orig => {
  var a,
    rgb = orig
      .replace(/\s/g, '')
      .match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = ((rgb && rgb[4]) || '').trim(),
    hex = rgb
      ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
        (rgb[2] | (1 << 8)).toString(16).slice(1) +
        (rgb[3] | (1 << 8)).toString(16).slice(1)
      : orig;

  if (alpha !== '') {
    a = alpha;
  } else {
    a = '01';
  }
  // multiply before convert to HEX
  a = ((a * 255) | (1 << 8)).toString(16).slice(1);
  hex = hex + a;
  return hex;
};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export const logout = (
  accountsService: AccountsService,
  storageService: StorageService,
  oneflowStorageService: OneflowStorageService,
  segmentService: SegmentService,
  socketClientService: SocketClientService,
  sessionService: SessionService,
  userService: UserService
) => {
  segmentService.addEvent({
    event_name: events.logged_out,
    event_data: {},
  });

  storageService.removeItem(AUTH_TOKEN);
  storageService.setSessionData('logout', true);

  sessionStorage.removeItem('user_trails_days');
  sessionStorage.removeItem('mtu_data');

  storageService.removeItem('ajs_group_properties');
  storageService.removeItem('ajs_user_id');
  storageService.removeItem('ajs_anonymous_id');
  storageService.removeItem('ajs_group_id');
  storageService.removeItem('ajs_user_traits');
  storageService.removeItem('analytics_session_id');
  storageService.removeItem('analytics_session_id.last_access');

  oneflowStorageService.DestoryStorageData();
  oneflowStorageService.DestoryStorageDataLogout();

  userService.clearUserDetails();

  localStorage.clear();

  if (window.Intercom) {
    window.Intercom('shutdown');
  }

  accountsService.clearAccountsData();

  deleteAllCookies();

  socketClientService.disconnect();

  sessionService.logout();
};

export const getSurveyQuestionTitleByType = (type: SurveyQuestion) => {
  switch (type) {
    case SurveyQuestion.WELCOME:
      return 'Welcome screen';

    case SurveyQuestion.ONE_FIVE_RATING:
      return '1-5 Rating';

    case SurveyQuestion.NPS:
      return 'NPS';

    case SurveyQuestion.MULTIPLE_CHOISE:
      return 'Multiple Choice';

    case SurveyQuestion.CHECKBOX:
      return 'Checkbox';

    case SurveyQuestion.FIVE_STAR_RATING:
      return '5-Star Rating';

    case SurveyQuestion.EMOJI_RATING:
      return 'Emoji Rating';

    case SurveyQuestion.SHORT_TEXT:
      return 'Short Text';

    case SurveyQuestion.LONG_TEXT:
      return 'Long Text';

    case SurveyQuestion.END:
      return 'End screen';
  }
};

export const getRandomKey = () => {
  // @ts-ignore
  let text: any = parseInt(Math.random() * (4 - 1) + 1);
  let possible = '1234567890abcdefghi';
  const lengthOfCode = 20;
  for (let i = 0; i < lengthOfCode; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  //@ts-ignore
  return ObjectId(new Date().valueOf()).toHexString();
};

export const createSurveyStyleFiles = (model: {
  progressBar: boolean;
  closeButton: boolean;
  colorCode: string;
  removeWatermark: boolean;
  backgroundColor?: string;
  textColor?: string;
  textColor30?: string;
  textColor50?: string;
  textColor60?: string;
  textColor80?: string;
  textColor05?: string;
}) => {
  $('style#survey_styling_css_style').remove();
  $('style#survey_popup_theming').remove();

  let style = `
    <style id="survey_styling_css_style">
          .h-43.other-input input{
            border:1px solid #fff !important;
          }
          .survey .gray.disabled{
            background-color:${model.colorCode}!important;
            opacity: 0.5;
            pointer-events:none;
          }
          .survey-popup textarea,.survey-popup input.short-text{
            background:transparent !important;
          }
           .survey  [type=checkbox]:hover,.survey  [type=checkbox]:checked, .survey  [type=radio]:hover,.survey  [type=radio]:checked{
               background-color:${model.colorCode}  !important;
          }
          .survey .w3-grey{
          background-color:${model.colorCode} !important;
          }
         .survey-popup .bg-primary, .survey .bg-primary{
          background-color:${model.colorCode}  !important;
          }
          .survey .focus\\:ring-offset-2:focus{
            --tw-ring-color: ${model.colorCode} !important;
          }
          .survey .checkedCheckBox, .survey-popup .checkedCheckBox{
              background-color:${model.colorCode} !important;
          }
          .survey [type='checkbox']:focus, .survey [type='radio']:focus{
              --tw-ring-color:${model.colorCode} !important;;
          }
          .survey [type='text']:focus, .survey [type='email']:focus,.survey [type='url']:focus,.survey [type='password']:focus,.survey [type='number']:focus,.survey [type='date']:focus,.survey [type='datetime-local']:focus,.survey [type='month']:focus,.survey [type='search']:focus,.survey [type='tel']:focus,.survey [type='time']:focus,.survey [type='week']:focus,.survey [multiple]:focus, .survey textarea:focus,.survey select:focus,
          .survey [type='text']:hover, .survey [type='email']:hover,.survey [type='url']:hover,.survey [type='password']:hover,.survey [type='number']:hover,.survey [type='date']:hover,.survey [type='datetime-local']:hover,.survey [type='month']:hover,.survey [type='search']:hover,.survey [type='tel']:hover,.survey [type='time']:hover,.survey [type='week']:hover,.survey [multiple]:hover, .survey textarea:hover,.survey select:hover,
          .survey textarea:hover,.survey .try:hover,.survey .try2:hover,.survey input:hover,app-survey-mobile-preview input.no-hover.take-input{
          border-color: ${model.colorCode} !important;
          border: 1px solid ${model.colorCode} !important;
          box-shadow: none !important;
          }
          .survey .primary{
          color:${model.colorCode} !important;
          }
          .survey .border-indigo-500{
             border-color: ${model.colorCode} !important;
          }
          .survey svg.active{
            stroke: ${model.colorCode}  !important;
          }
          .qus-container .add-active.active{
           border: 2px solid ${model.colorCode} !important;
          }
          .survey .checkedRadio{
           border: 6px solid ${model.colorCode} !important;
          }
          .survey-desktop-preview-desktop-container .hover\\:btn-primary-500:hover {
            background-color: ${model.colorCode}  !important;
           }
           .h-43:hover,.sp:hover  {
              background: ${model.colorCode}  !important;  
          }
          .selected{
            background: transparent  !important;  
          }

          input.no-hover.take-input.cursor-pointer.text-indigo-600 {
            font-size: 14px;
            padding-left: 25px;
            color: #000;
            font-weight: 500;
            
        }
        .take-input{
          width:calc(100% - 64px) !important;
        }
        
        .h-43.other-input:hover,.h-43.other-input {
          color: #000 !important;
          border: 1px solid ${model.colorCode};
        }
       
          .h-43.other-input:hover,.h-43.other-input{
            border:1px solid ${model.colorCode}  !important;
          }
          .survey-desktop-preview-desktop-container .w3-grey{
          background:  ${model.colorCode}  !important;
          }
          .survey .hover\\:fill:hover {
            fill:${model.colorCode}  !important;
        }
      
         .survey .bb{ 
         stroke:${model.colorCode}  !important;
         }
         .survey .hover\\:btn-primary-500:hover,.preview-option-bg-color:hover{
          background:  ${model.colorCode}  !important;
          border-color:  ${model.colorCode}  !important;
         }
         .survey-preview button.focus:ring-indigo-500:focus,.survey-preview button.focus:ring-2:focus{
          --tw-ring-color:${model.colorCode}  !important;
         }
         .h-43:hover,.mh-43.txt_cls:hover { background: ${
           model.colorCode
         } !important; color: #fff !important;}
         .selected { border: 1px solid ${model.colorCode} !important;}
         button.btn-enter { background: ${model.colorCode};}
         .survey-popup .main-qus-container,.survey-popup .bg_cls,.pop-full,
         .middle-screen.middle-bottom,.middle-screen.middle-top,.survey-popup .right-sidebar{
           background-color:#fff !important;
         }

       /*  app-checkbox-preview .main-qus-container .progress-bar {
           margin-top:${model.removeWatermark === true ? '13px' : 'inherit'} ; 
         }
         app-rating-emoji-preview .main-qus-container .progress-bar, app-rating-five-star-preview .main-qus-container .progress-bar, app-open-text-preview .main-qus-container .progress-bar , app-mcq-mobile .progress-bar,app-open-text-mobile .progress-bar,app-rating-five-star-mobile .progress-bar,app-rating-emoji-mobile .progress-bar{
            margin-top:${
              model.removeWatermark === true ? '20px !important' : 'inherit'
            } ;
        } 
        app-mcq-preview .main-qus-container .progress-bar,app-rating-numerical-preview .main-qus-container .progress-bar,app-rating-nps-preview .main-qus-container .progress-bar,app-rating-numerical-mobile .progress-bar {
            margin-top: ${
              model.removeWatermark === true ? '2px !important' : 'inherit'
            } ; 
        }
        .middle-bottom .main-qus-container .w-199:not(.rating_btn),.middle-bottom .main-qus-container .part-1,.middle-bottom .main-qus-container .new-mt-16,
        .middle-top .main-qus-container .w-199:not(.rating_btn),.middle-top .main-qus-container .part-1,.middle-top .main-qus-container .new-mt-16{ 
          margin-bottom:${model.removeWatermark === true ? '22px' : 'inherit'};
        }
        .middle-bottom .main-qus-container .new-mt-23:not(.w-199),
        .middle-top .main-qus-container .new-mt-23:not(.w-199){
          margin-bottom:${model.removeWatermark === true ? '13px' : 'inherit'};
        }*/

        .survey-popup .main-qus-container:not(.for-mob){
          margin-bottom:${model.removeWatermark === true ? '20px' : 'inherit'};
        }
        .survey-popup .mobile-main-container{
          margin-bottom:${model.removeWatermark === true ? '20px' : 'inherit'};
        }
        .survey-popup .watermark:not(.full-view){
          display: none !important;
          pointer-events: none;
        }
        .survey-popup button.close_svg:not(.full-view){
          opacity:0 !important;
          pointer-events:none;
        }
        .survey-popup svg{
          outline:unset !important; 
        }
        .survey-popup button{
          box-shadow:unset !important;
        }
        .survey-popup button.close_svg,.survey-popup .transparent{
          background-color:transparent !important;
        }
        ..survey-popup .rating_btn button:hover{
          --tw-bg-opacity:1 !important;
        }
  
         .survey-popup .main-qus-container::-webkit-scrollbar,.mobile-main-container::-webkit-scrollbar {
              width: 8px;
          }
          .survey-popup .main-qus-container::-webkit-scrollbar-thumb,.mobile-main-container::-webkit-scrollbar-thumb {
            background-color:#000 !important;
            border-radius: 20px;
            border: 3px solid #000 !important;
        }
        .survey-popup .main-qus-container::-webkit-scrollbar-track,.mobile-main-container::-webkit-scrollbar-track {
          background: #fff !important;
          margin-top: 8px;
       }
       
      
    </style>
  `;

  if (model.backgroundColor) {
    style += `<style id="survey_popup_theming">

    .survey-popup textarea,.survey-popup input.short-text {
      color: ${model.textColor};
    }
    .h-43.other-input:hover {
      color: ${model.textColor} !important;
  }
    
    .survey-popup .text-gray {
      color:${model.textColor60};
    }

    .survey-popup textarea::placeholder,.survey-popup input.short-text::placeholder,.take-input::placeholder{
      color:${model.textColor30} !important;
    }

    
    .survey-popup textarea::-webkit-input-placeholder,.survey-popup input.short-text::-webkit-input-placeholder,.take-input::-webkit-input-placeholder {
      color:${model.textColor30} !important;
    }

    .survey-popup textarea:-ms-input-placeholder,.survey-popup input.short-text:-ms-input-placeholder,.take-input::-ms-input-placeholder {
      color:${model.textColor30} !important;
    }

    
    :root{
      --1flow-logo-bg-hover:${model.textColor05};
      --1flow-text-color:${model.textColor};
      --1flow-text-color-05:${model.textColor05};
      --1flow-text-color-60:${model.textColor60};
      --1flow-text-color-30:${model.textColor30};
    }
    .survey-popup .main-qus-container:not(.for-mob){
      background-color:transparent !important;
    }
    .survey-popup .bg_cls,.pop-full,
    .middle-screen.middle-bottom,.middle-screen.middle-top,.survey-popup .right-sidebar,.survey-popup .main-qus-container.for-mob{
      background-color:${model.backgroundColor} !important;
    }
    .survey-popup .txt_cls{
      color:${model.textColor} !important;
    }
    .survey-popup .txt_cls_60{
      color:${model.textColor60} !important;
    }
    .survey-popup .txt_cls_80{
      color:${model.textColor80} !important;
    }
    .survey-popup .txt_cls_50{
      color:${model.textColor50} !important;
    }
    
    .survey-popup .progress-bar{
      opacity:${model.progressBar !== true ? '0 !important' : '1'};
      pointer-events:${model.progressBar !== true ? 'none' : 'all'};
    }
   
    .survey-popup button.close_svg.full-view svg{
              opacity:${model.closeButton !== true ? '0 !important' : '1'};
              pointer-events:${model.closeButton !== true ? 'none' : 'all'};
      }
    .survey-popup button[type='submit']:hover{
      color:#fff !important;
    }
   
    .survey-popup .main-qus-container::-webkit-scrollbar-track,.mobile-main-container::-webkit-scrollbar-track {
      background: ${model.backgroundColor} !important;;
    }
    </style>`;
  }

  $('body').append(style);
};

export const getPositionClasses = (position: string | null) => {
  if (position) {
    switch (position) {
      case 'top-left':
        return {
          desktop: 'top-un top-left',
          mobile: 'bottom-0 mob-top-full top-unset',
          mobile_flex: 'items-start',
        };

      case 'top-center':
        return {
          desktop: 'middle-screen top-only',
          mobile: 'bottom-0 mob-top-full top-unset',
          mobile_flex: 'items-start',
        };

      case 'top-right':
        return {
          desktop: 'top-un top-rgt',
          mobile: 'bottom-0 mob-top-full top-unset',
          mobile_flex: 'items-start',
        };

      case 'middle-left':
        return {
          desktop: 'top-un left-bottom top-center',
          mobile: 'mob-center',
          mobile_flex: 'items-center',
        };

      case 'middle-center':
        return {
          desktop: 'middle-screen',
          mobile: 'mob-center',
          mobile_flex: 'items-center',
        };

      case 'middle-right':
        return {
          desktop: 'top-un top-center',
          mobile: 'mob-center',
          mobile_flex: 'items-center',
        };

      case 'bottom-left':
        return {
          desktop: 'top-un left-bottom',
          mobile: 'bottom-0 top-end',
          mobile_flex: 'items-end',
        };

      case 'bottom-center':
        return {
          desktop: 'middle-screen bottom-only',
          mobile: 'bottom-0 top-unset',
          mobile_flex: 'items-end',
        };

      case 'bottom-right':
        return {
          desktop: 'top-un',
          mobile: 'bottom-0 top-unset',
          mobile_flex: 'items-end',
        };

      case 'bottom-banner':
        return {
          desktop: 'middle-screen middle-bottom',
          mobile: 'bottom-0	top-unset full-size',
          mobile_flex: 'items-end',
        };

      case 'fullscreen':
        return {
          desktop: 'middle-screen pop-full',
          mobile: 'bottom-unset full-size h-100 top-0',
          mobile_flex: 'items-start no-top',
        };

      case 'top-banner':
        return {
          desktop: 'middle-screen middle-top',
          mobile: 'bottom-0 full-size mob-top-full',
          mobile_flex: 'items-start',
        };

      default:
        return null;
    }
  }
  return null;
};
