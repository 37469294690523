<svg
  class="chevron"
  class="chevron-{{ side }}"
  width="11"
  height="8"
  viewBox="0 0 11 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.4 7.39998L0 1.99998L1.4 0.599976L5.4 4.59998L9.4 0.599976L10.8 1.99998L5.4 7.39998Z"
    [attr.fill]="color" />
</svg>
