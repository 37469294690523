<div class="text-center mx-auto">
  <div
    class="inline-block align-center bg-white rounded-lg w-456 px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm: sm: sm:p-6">
    <div>
      <div
        class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          viewBox="0 0 20 20"
          fill="#43936b">
          <path
            fill-rule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clip-rule="evenodd" />
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3
          class="text-lg leading-6 font-medium text-gray-900"
          id="modal-title">
          Survey locked
        </h3>
        <div class="mt-2">
          <p class="text-sm text-gray-500 px-6">
            This survey is locked because you don't have an active subscription.
            We are still collecting data in the background but your access is
            restricted. Subscribe with the link below to access your data again.
          </p>
        </div>
      </div>
    </div>
    <div class="w-max mt-5 ml-auto mr-auto mx-auto text-center sm:mt-6">
      <a [routerLink]="'/' + activeProjectId + SETTINGS_PLAN_AND_USAGE">
        <app-button size="m"> Manage my subscription </app-button>
      </a>
    </div>
  </div>
</div>
