<ng-container [ngSwitch]="icon">
  <app-icon-surveys
    *ngSwitchCase="'surveys'"
    [active]="active"></app-icon-surveys>

  <app-icon-documentation
    *ngSwitchCase="'documentation'"
    [active]="active"></app-icon-documentation>

  <app-icon-settings
    *ngSwitchCase="'settings'"
    [active]="active"></app-icon-settings>

  <app-icon-setup *ngSwitchCase="'setup'" [active]="active"></app-icon-setup>

  <app-icon-users *ngSwitchCase="'users'" [active]="active"></app-icon-users>

  <app-icon-events *ngSwitchCase="'events'"></app-icon-events>

  <app-icon-bulb *ngSwitchCase="'bulb'" [active]="active"></app-icon-bulb>

  <app-icon-integrations
    *ngSwitchCase="'integrations'"
    [active]="active"></app-icon-integrations>

  <app-icon-volume-up
    *ngSwitchCase="'volume-up'"
    [active]="active"></app-icon-volume-up>
</ng-container>
