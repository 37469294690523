import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  setItem = (key, value) => {
    localStorage.setItem(key, value);
    return true;
  };

  getItem = (key: string) => {
    return localStorage.getItem(key);
  };

  removeItem = (key: string) => {
    localStorage.removeItem(key);
    return true;
  };

  setSessionData = (key, value) => {
    sessionStorage.setItem(key, value);
  };

  getSessionData = key => {
    return sessionStorage.getItem(key);
  };

  removeSessionData = key => {
    sessionStorage.removeItem(key);
    return true;
  };

  public static setSessionData = (key, value) => {
    sessionStorage.setItem(key, value);
  };

  public static getSessionData = key => {
    return sessionStorage.getItem(key);
  };
}
