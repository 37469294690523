<ng-container *ngIf="errors && key">
  <div
    class="text-red-600 text-sm text-left mb-1"
    [ngClass]="errorKey"
    *ngFor="let errorKey of getKeys(errors)">
    <ng-container *ngIf="errorKey === 'required' && errors[errorKey]"
      >{{ key }} is required</ng-container
    >
    <ng-container *ngIf="errorKey === 'email' && errors[errorKey]"
      >{{ key }} must be valid email address</ng-container
    >
    <ng-container
      *ngIf="errorKey === 'serverEmailExists' && errors[errorKey]"
      >{{ errors.message }}</ng-container
    >
    <ng-container
      *ngIf="errorKey === 'inviteEmailExists' && errors[errorKey]"
      >{{ errors.message }}</ng-container
    >
    <ng-container *ngIf="errorKey === 'exits' && errors[errorKey]">{{
      errors.message
    }}</ng-container>
    <ng-container *ngIf="errorKey === 'pattern'">
      {{
        key === 'Email' ? 'Email must be valid email address' : 'Invalid URL'
      }}.</ng-container
    >
  </div>
</ng-container>
