import { DataType, CohortType } from 'src/app/common/enums';

export const DATA_TYPE_ICONS = {
  [DataType.ARRAY]:
    '<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 5.8335H2.16667V4.16683H0.5V5.8335ZM0.5 9.16683H2.16667V7.50016H0.5V9.16683ZM0.5 2.50016H2.16667V0.833496H0.5V2.50016ZM3.83333 5.8335H15.5V4.16683H3.83333V5.8335ZM3.83333 9.16683H15.5V7.50016H3.83333V9.16683ZM3.83333 0.833496V2.50016H15.5V0.833496H3.83333ZM0.5 5.8335H2.16667V4.16683H0.5V5.8335ZM0.5 9.16683H2.16667V7.50016H0.5V9.16683ZM0.5 2.50016H2.16667V0.833496H0.5V2.50016ZM3.83333 5.8335H15.5V4.16683H3.83333V5.8335ZM3.83333 9.16683H15.5V7.50016H3.83333V9.16683ZM3.83333 0.833496V2.50016H15.5V0.833496H3.83333Z" fill="#636B75"/></svg>',
  [DataType.BOOLEAN]:
    '<svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.1663 0H5.83301C3.07467 0 0.833008 2.24167 0.833008 5C0.833008 7.75833 3.07467 10 5.83301 10H14.1663C16.9247 10 19.1663 7.75833 19.1663 5C19.1663 2.24167 16.9247 0 14.1663 0ZM14.1663 8.33333H5.83301C3.99134 8.33333 2.49967 6.84167 2.49967 5C2.49967 3.15833 3.99134 1.66667 5.83301 1.66667H14.1663C16.008 1.66667 17.4997 3.15833 17.4997 5C17.4997 6.84167 16.008 8.33333 14.1663 8.33333ZM14.1663 2.5C12.783 2.5 11.6663 3.61667 11.6663 5C11.6663 6.38333 12.783 7.5 14.1663 7.5C15.5497 7.5 16.6663 6.38333 16.6663 5C16.6663 3.61667 15.5497 2.5 14.1663 2.5Z" fill="#636B75"/></svg>',
  [DataType.DATE]:
    '<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.8333 2.33334H13V0.666672H11.3333V2.33334H4.66667V0.666672H3V2.33334H2.16667C1.24167 2.33334 0.508333 3.08334 0.508333 4.00001L0.5 15.6667C0.5 16.5833 1.24167 17.3333 2.16667 17.3333H13.8333C14.75 17.3333 15.5 16.5833 15.5 15.6667V4.00001C15.5 3.08334 14.75 2.33334 13.8333 2.33334ZM13.8333 15.6667H2.16667V7.33334H13.8333V15.6667ZM13.8333 5.66667H2.16667V4.00001H13.8333V5.66667ZM5.5 10.6667H3.83333V9.00001H5.5V10.6667ZM8.83333 10.6667H7.16667V9.00001H8.83333V10.6667ZM12.1667 10.6667H10.5V9.00001H12.1667V10.6667ZM5.5 14H3.83333V12.3333H5.5V14ZM8.83333 14H7.16667V12.3333H8.83333V14ZM12.1667 14H10.5V12.3333H12.1667V14Z" fill="#636B75"/></svg>',
  [DataType.NUMBER]:
    '<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.0833 5.33333L15.5 3.66667H12.1667L13 0.333333H11.3333L10.5 3.66667H7.16667L8 0.333333H6.33333L5.5 3.66667H2.16667L1.75 5.33333H5.08333L4.25 8.66667H0.916667L0.5 10.3333H3.83333L3 13.6667H4.66667L5.5 10.3333H8.83333L8 13.6667H9.66667L10.5 10.3333H13.8333L14.25 8.66667H10.9167L11.75 5.33333H15.0833ZM9.25 8.66667H5.91667L6.75 5.33333H10.0833L9.25 8.66667Z" fill="#636B75"/></svg>',
  [DataType.STRING]:
    '<svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 2.16667H14.25V1.75H12.5833V4.25H14.25V3.83333H15.5V4.66667C15.5 5.125 15.125 5.5 14.6667 5.5H12.1667C11.7083 5.5 11.3333 5.125 11.3333 4.66667V1.33333C11.3333 0.875 11.7083 0.5 12.1667 0.5H14.6667C15.125 0.5 15.5 0.875 15.5 1.33333V2.16667ZM4.66667 1.33333V5.5H3.41667V4.25H1.75V5.5H0.5V1.33333C0.5 0.875 0.875 0.5 1.33333 0.5H3.83333C4.29167 0.5 4.66667 0.875 4.66667 1.33333ZM3.41667 1.75H1.75V3H3.41667V1.75ZM9.25 3C9.70833 3 10.0833 3.375 10.0833 3.83333V4.66667C10.0833 5.125 9.70833 5.5 9.25 5.5H5.91667V0.5H9.25C9.70833 0.5 10.0833 0.875 10.0833 1.33333V2.16667C10.0833 2.625 9.70833 3 9.25 3ZM7.16667 1.75V2.375H8.83333V1.75H7.16667ZM8.83333 3.625H7.16667V4.25H8.83333V3.625Z" fill="#636B75"/></svg>',
};

export const COHORT_ICONS = {
  [CohortType.MANUAL]:
    '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99967 6.66658C8.15801 6.66658 6.66634 8.15825 6.66634 9.99992C6.66634 11.8416 8.15801 13.3333 9.99967 13.3333C11.8413 13.3333 13.333 11.8416 13.333 9.99992C13.333 8.15825 11.8413 6.66658 9.99967 6.66658ZM17.4497 9.16658C17.0663 5.69158 14.308 2.93325 10.833 2.54992V0.833252H9.16634V2.54992C5.69134 2.93325 2.93301 5.69158 2.54967 9.16658H0.833008V10.8333H2.54967C2.93301 14.3083 5.69134 17.0666 9.16634 17.4499V19.1666H10.833V17.4499C14.308 17.0666 17.0663 14.3083 17.4497 10.8333H19.1663V9.16658H17.4497ZM9.99967 15.8333C6.77467 15.8333 4.16634 13.2249 4.16634 9.99992C4.16634 6.77492 6.77467 4.16658 9.99967 4.16658C13.2247 4.16658 15.833 6.77492 15.833 9.99992C15.833 13.2249 13.2247 15.8333 9.99967 15.8333Z" fill="#636B75"/></svg>',
  [CohortType.DYNAMIC]:
    '<svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.83666 3.74033C4.18332 5.46699 6.66999 8.66699 6.66999 8.66699V12.667C6.66999 13.0337 6.96999 13.3337 7.33666 13.3337H8.66999C9.03666 13.3337 9.33666 13.0337 9.33666 12.667V8.66699C9.33666 8.66699 11.8167 5.46699 13.1633 3.74033C13.5033 3.30033 13.19 2.66699 12.6367 2.66699H3.36332C2.80999 2.66699 2.49666 3.30033 2.83666 3.74033Z" fill="#636B75" /></svg>',
  [CohortType.AMPLITUDE]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.BRAZE]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.EMAIL]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.GOOGLE_SHEET]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.HEAP]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.HUB_SPOT]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.ITERABLE]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.MIXPANEL]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.SEGMENT_DES]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
  [CohortType.SEGMENT_SOURCE]:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="icon/sync"><g id="Vector"><path d="M12 4V1L8 5L12 9V6C15.31 6 18 8.69 18 12C18 13.01 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 7.58 16.42 4 12 4ZM12 18C8.69 18 6 15.31 6 12C6 10.99 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 16.42 7.58 20 12 20V23L16 19L12 15V18Z" fill="#636B75"/></g></g></svg>',
};
