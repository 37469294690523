<app-dots-menu [style.zIndex]="zIndex" [showButton]="showButton" class="menu">
  <div class="menu-dropdown">
    <button
      (click)="showRenameModal.emit()"
      class="menu-dropdown-item menu-dropdown-item-default flex items-center gap-2.5">
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.0830078 0.75V3.25H4.24967V13.25H6.74967V3.25H10.9163V0.75H0.0830078ZM15.9163 4.91667H8.41634V7.41667H10.9163V13.25H13.4163V7.41667H15.9163V4.91667Z"
          fill="#636B75" />
      </svg>
      Rename
    </button>
    <div class="menu-dropdown-divider mt-1 mb-1"></div>
    <button
      (click)="showDeleteModal.emit()"
      class="menu-dropdown-item menu-dropdown-item-danger flex items-center gap-2.5">
      <app-icon-delete color="#CF1322"></app-icon-delete>
      Delete
    </button>
  </div>
</app-dots-menu>
