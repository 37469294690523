import { Component } from '@angular/core';

@Component({
  selector: 'app-icon-clock',
  templateUrl: './icon-clock.component.html',
  styleUrls: ['./icon-clock.component.scss'],
})
export class IconClockComponent {
  constructor() {}
}
