import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { DataType } from 'src/app/common/enums';
import { DATA_TYPE_ICONS } from 'src/app/constants';
import { ProjectService } from 'src/app/services/project/project.service';
import { SessionService } from 'src/app/services/session/session.service';

type Property = {
  type: DataType;
  icon: string;
  label: string;
  value: string;
};

@Component({
  selector: 'app-event-property-select',
  templateUrl: './event-property-select.component.html',
  styleUrls: ['./event-property-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPropertySelectComponent implements OnInit {
  allEventPropertiesLoaded = false;
  limit = 100;
  offset = 0;
  isLoading = false;
  propertiesListSub: BehaviorSubject<Property[]> = new BehaviorSubject([]);

  @Input() selected?: string;
  @Input() eventName?: string;

  @Output() setPropertyType: EventEmitter<Property> = new EventEmitter();

  constructor(
    private projectService: ProjectService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.loadEventProperties();
  }

  private async loadEventProperties() {
    const projectId = this.sessionService.getActiveProject();

    if (projectId && this.propertiesListSub.value.length === 0) {
      this.isLoading = true;

      try {
        const properties = await this.projectService.getEventProperties({
          projectId,
          event_name: this.eventName,
          limit: this.limit,
          offset: this.offset,
        });

        if (properties.length < this.limit) {
          this.allEventPropertiesLoaded = true;
        }

        this.propertiesListSub.next([
          ...this.propertiesListSub.value,
          ...properties.map(property => {
            return {
              type: property.data_type,
              label: property.property,
              value: property.property,
              icon: DATA_TYPE_ICONS[property.data_type],
            };
          }),
        ]);
      } catch {
        this.propertiesListSub.next([]);
      } finally {
        this.isLoading = false;
      }
    }
  }

  select(model: { value: any }) {
    this.setPropertyType.emit(
      this.propertiesListSub.value.find(
        property => property.value === model.value
      )
    );
  }

  loadMoreEventProperties() {
    this.offset += this.limit;

    this.loadEventProperties();
  }
}
