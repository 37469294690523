import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiCommonHandler } from 'src/app/common/ApiCommonHandler';
import { VALIDATE_EMAIL_INVITE } from 'src/app/endpoints/endpoints';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiCommonHandler {
  constructor(private http: HttpClient) {
    super();
  }

  validateEmail(email: string): Observable<any> {
    return this.http.get(
      `${this.getAPIFullUrlByName('user/email')}/${email}/validate`
    );
  }

  validateInviteEmail(email, user_id): Observable<any> {
    return this.http.get(
      `${this.getAPIFullUrlByName(VALIDATE_EMAIL_INVITE)}/${email}/${user_id}`
    );
  }
}
