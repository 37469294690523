<svg
  class="arrow arrow-{{ color }} arrow-{{ side }}"
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 20 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M16.6663 9.66683H6.52467L11.183 5.0085L9.99967 3.8335L3.33301 10.5002L9.99967 17.1668L11.1747 15.9918L6.52467 11.3335H16.6663V9.66683Z"
    [attr.fill]="color" />
</svg>
