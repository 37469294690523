import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusLabelComponent {
  @Input() status?: 'draft' | 'in-progress' | 'completed';

  constructor() {}

  getTitle() {
    switch (this.status) {
      case 'completed':
        return 'Stopped';

      case 'in-progress':
        return 'Active';

      case 'draft':
        return 'Draft';

      default:
        return '';
    }
  }
}
