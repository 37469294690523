<div class="w-full flex flex-col gap-1">
  <div *ngIf="title" class="flex items-center justify-between">
    <h4 class="title">{{ title }}</h4>
    <ng-content></ng-content>
  </div>
  <textarea
    #input="ngModel"
    class="textarea"
    autocomplete="off"
    [class.textarea-with-border]="useBorder"
    [rows]="rows"
    [(ngModel)]="inputModel"
    [placeholder]="placeholder"
    [ngModelOptions]="ngModelOptions"
    [name]="name"
    [required]="required"
    (blur)="focusOut.emit($event)"
    (focusin)="focusIn.emit($event)"
    (ngModelChange)="inputModelChange.emit(inputModel)">
  </textarea>
  <div *ngIf="title && required && input.invalid && input.touched">
    <app-error-generator
      [errors]="input.errors"
      [key]="title"></app-error-generator>
  </div>
  <p *ngIf="description" class="description1">
    <span [innerHTML]="description | safeHtml"></span>
  </p>
  <ng-content select="[bottomContent]"></ng-content>
</div>
