import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { FilterCondition } from 'src/app/common/enums';
import { FILTER_CONDITIONS } from 'src/app/constants';

@Component({
  selector: 'app-array-input',
  templateUrl: './array-input.component.html',
  styleUrls: ['./array-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrayInputComponent implements OnChanges {
  currentValues: string[] = [];
  FilterCondition = FilterCondition;

  @Input() values: string[] = [];
  @Input() active: boolean = false;
  @Input() condition: FilterCondition | null = null;

  @Output() setCondition: EventEmitter<FilterCondition> = new EventEmitter();
  @Output() setValues: EventEmitter<string[]> = new EventEmitter();

  constructor() {}

  get filterCondition() {
    return this.condition !== null ? FILTER_CONDITIONS[this.condition] : '';
  }

  ngOnChanges({ values }: SimpleChanges) {
    if (values.currentValue.length === 0) {
      this.currentValues = [''];
    } else {
      this.currentValues = [...values.currentValue];
    }
  }

  submit() {
    this.setValues.emit(this.currentValues);
  }

  addValue(event: FocusEvent) {
    if (event.target instanceof HTMLInputElement && event.target.value) {
      this.currentValues.push(event.target.value);

      this.submit();

      event.target.value = '';
    }
  }

  removeValue(index: number) {
    this.currentValues.splice(index, 1);

    this.submit();

    if (this.currentValues.length === 0) {
      this.currentValues.push('');
    }
  }

  handleKeypress(event: KeyboardEvent) {
    if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
      event.target.blur();

      requestAnimationFrame(() => {
        if (event.target instanceof HTMLInputElement) {
          event.target.focus();
        }
      });
    }
  }

  trackByFn(index: number) {
    return index;
  }
}
