import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { v4 as uuidv4 } from 'uuid';

import { TimingOption } from 'src/app/common/enums';
import { openFilterById } from 'src/app/common/utils';

import { FilterType } from '../enums';
import { TriggerFilter, TriggerEventFilter } from '../types';

@Component({
  selector: 'app-trigger-filter',
  templateUrl: './trigger-filter.component.html',
  styleUrls: ['./trigger-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriggerFilterComponent implements OnChanges {
  private invalidFilters: { [key: string]: boolean } = {};

  triggerFilters: TriggerFilter[] = [];
  FilterType = FilterType;
  allowedFilters = [
    {
      title: 'Event',
      description: 'Tracked in code',
      type: FilterType.Event,
    },
    {
      title: 'Page',
      description: 'URL pattern',
      type: FilterType.Page,
    },
  ];

  @Input() filters: TriggerFilter[] = [];

  @Output() updateValidationStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() update: EventEmitter<TriggerFilter[]> = new EventEmitter();

  constructor() {}

  ngOnChanges({ filters }: SimpleChanges): void {
    if (filters?.currentValue) {
      this.triggerFilters = [...filters.currentValue];
    }
  }

  private updateValidation() {
    this.updateValidationStatus.emit(
      Object.keys(this.invalidFilters).every(key => !this.invalidFilters[key])
    );
  }

  addFilter(filterType: FilterType) {
    const id = uuidv4();

    this.triggerFilters = [
      ...this.triggerFilters,
      {
        id,
        filterType,
        timingOption: {
          type: TimingOption.SHOW_IMMEDIATELY,
        },
      },
    ];

    openFilterById(id);
  }

  updateFilter(updatedFilter: Omit<TriggerFilter, 'timingOption'>) {
    this.triggerFilters = this.triggerFilters.map(filter =>
      filter.id === updatedFilter.id
        ? { ...updatedFilter, timingOption: filter.timingOption }
        : filter
    );
  }

  removeFilter(id: string, index: number) {
    delete this.invalidFilters[id];

    this.triggerFilters = this.triggerFilters.filter((_, i) => i !== index);

    this.update.emit(this.triggerFilters);
    this.updateValidation();
  }

  trackByFilter(index: number, filter: TriggerFilter) {
    return filter.id;
  }

  setTimingOption(
    timingOption: { type: TimingOption; value?: number },
    index: number
  ) {
    const filters = this.triggerFilters.map((f, i) =>
      i === index ? { ...f, timingOption } : f
    );

    this.update.emit(filters);
  }

  toEventFilterType(filter: TriggerFilter) {
    return filter as TriggerEventFilter;
  }

  applyFilter(id: string, isFilterValid: boolean) {
    this.invalidFilters[id] = !isFilterValid;

    this.update.emit(this.triggerFilters);
    this.updateValidation();
  }
}
