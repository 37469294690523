<div class="vertical">
  <div class="flex items-center justify-between mb-4">
    <h4 class="vertical-title">{{ listTitle }}</h4>
    <ng-container *ngIf="add.observers.length">
      <app-tooltip
        *ngIf="tooltip; else button"
        [content]="tooltip"
        placement="top"
        [width]="180">
        <ng-template [ngTemplateOutlet]="button"></ng-template>
      </app-tooltip>
    </ng-container>
  </div>
  <div class="vertical-content flex flex-col gap-1 overflow-auto">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #button>
  <app-button variant="link" (press)="add.emit()">
    <div class="flex items-center gap-2">
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.66671 5.66668H5.66671V9.66668H4.33337V5.66668H0.333374V4.33334H4.33337V0.333344H5.66671V4.33334H9.66671V5.66668Z"
          fill="#2F54EB" />
      </svg>
      New
    </div>
  </app-button>
</ng-template>
