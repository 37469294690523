<div
  *ngIf="appearanceOpenSub | async"
  (click)="handleClose()"
  [@showHide]="(animationOpenSub | async) ? 'show' : 'hide'"
  class="modal-overlay flex items-center justify-center">
  <div (click)="handleClick($event)" class="modal" [style.max-width]="width">
    <div
      *ngIf="modalTitle"
      class="modal-header flex items-center justify-between">
      <h3 class="modal-header-title">{{ modalTitle }}</h3>
      <div (click)="handleClose()" class="modal-header-close">
        <app-icon-close></app-icon-close>
      </div>
    </div>
    <div class="h-full" [class.p-6]="usePadding">
      <ng-content></ng-content>
    </div>
  </div>
</div>
