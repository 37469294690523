<svg
  [attr.width]="size"
  [attr.height]="size"
  viewBox="0 0 12 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.76666 6.725L5.99999 8.49167L4.22499 6.725L3.04999 7.9L4.82499 9.66667L3.05832 11.4333L4.23332 12.6083L5.99999 10.8417L7.76666 12.6083L8.94166 11.4333L7.17499 9.66667L8.94166 7.9L7.76666 6.725ZM8.91666 1.33333L8.08332 0.5H3.91666L3.08332 1.33333H0.166656V3H11.8333V1.33333H8.91666ZM0.99999 13.8333C0.99999 14.75 1.74999 15.5 2.66666 15.5H9.33332C10.25 15.5 11 14.75 11 13.8333V3.83333H0.99999V13.8333ZM2.66666 5.5H9.33332V13.8333H2.66666V5.5Z"
    [attr.fill]="color" />
</svg>
