import { Component } from '@angular/core';

@Component({
  selector: 'app-icon-delete-alert',
  templateUrl: './icon-delete-alert.component.html',
  styleUrls: ['./icon-delete-alert.component.scss'],
})
export class IconDeleteAlertComponent {
  constructor() {}
}
