<div class="flex flex-wrap items-center justify-start gap-2.5">
  <ng-container
    *ngFor="let filter of filters; let i = index; trackBy: trackByFilter">
    <ng-container [ngSwitch]="filter.filterType">
      <app-cohort-filter
        *ngSwitchCase="FilterType.Cohort"
        [cohort]="toCohortFilterType(filter)"
        (update)="updateFilter($event)"
        (remove)="removeFilter(filter.id, i)"
        (finish)="activateFilters(filter.id, $event)"></app-cohort-filter>
      <app-event-filter
        *ngSwitchCase="FilterType.Event"
        [event]="toEventFilterType(filter)"
        (update)="updateFilter($event)"
        (remove)="removeFilter(filter.id, i)"
        (finish)="activateFilters(filter.id, $event)"></app-event-filter>
      <app-event-property-filter
        *ngSwitchCase="FilterType.EventProperty"
        [eventProperty]="toEventPropertyFilterType(filter)"
        (update)="updateFilter($event)"
        (remove)="removeFilter(filter.id, i)"
        (finish)="activateFilters(filter.id, $event)">
      </app-event-property-filter>
      <app-trait-filter
        *ngSwitchCase="FilterType.Trait"
        [trait]="toTraitFilterType(filter)"
        (update)="updateFilter($event)"
        (remove)="removeFilter(filter.id, i)"
        (finish)="activateFilters(filter.id, $event)"></app-trait-filter>
      <app-filter-group
        *ngSwitchCase="FilterType.FilterGroup"
        class="block w-full"
        [filterGroup]="toFilterGroupFilterType(filter)"
        [allowedFilters]="allowedFilterGroupFilters"
        (update)="updateFilter($event)"
        (remove)="removeFilter(filter.id, i)"
        (activateFilters)="
          activateFilters(filter.id, $event)
        "></app-filter-group>
    </ng-container>
    <app-relationship-switcher
      *ngIf="filters.length === 1 || i !== filters.length - 1"
      [active]="operator"
      (toggle)="updateOperator($event)">
    </app-relationship-switcher>
  </ng-container>
  <app-add-filter-button
    dropdownTitle="user data"
    [filters]="allowedFilters"
    (add)="addFilter($event)"></app-add-filter-button>
</div>
