<svg
  width="18"
  height="16"
  viewBox="0 0 18 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path d="M12 12L8 16H18V12H12Z" fill="#636B75" />
  <path
    d="M9.06 3.19L0 12.25V16H3.75L12.81 6.94L9.06 3.19ZM2.92 14H2V13.08L9.06 6.02L9.98 6.94L2.92 14Z"
    fill="#636B75" />
  <path
    d="M15.71 4.04C16.1 3.65 16.1 3.02 15.71 2.63L13.37 0.29C13.17 0.09 12.92 0 12.66 0C12.41 0 12.15 0.1 11.96 0.29L10.13 2.12L13.88 5.87L15.71 4.04Z"
    fill="#636B75" />
</svg>
