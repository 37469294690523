import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent {
  @Input() required = false;
  @Input() name = uuidv4();
  @Input() rows = 4;
  @Input() ngModelOptions: { updateOn?: 'blur' } = {};
  @Input() useBorder = true;
  @Input() inputModel?: string | number | null;
  @Input() placeholder?: string;
  @Input() title?: string;
  @Input() description?: string;

  @Output() inputModelChange = new EventEmitter();
  @Output() focusOut = new EventEmitter();
  @Output() focusIn = new EventEmitter();

  constructor() {}
}
