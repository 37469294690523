import { Component } from '@angular/core';

@Component({
  selector: 'app-icon-danger-circle',
  templateUrl: './icon-danger-circle.component.html',
  styleUrls: ['./icon-danger-circle.component.scss'],
})
export class IconDangerCircleComponent {
  constructor() {}
}
