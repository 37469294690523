import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  Optional,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer, NgForm } from '@angular/forms';

import { v4 as uuidv4 } from 'uuid';

export function controlContainerFactory(controlContainer?: ControlContainer) {
  return controlContainer;
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: controlContainerFactory,
      deps: [[new Optional(), NgForm]],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {
  hasError = false;

  @Input() autofocus = false;
  @Input() required = false;
  @Input() readonly = false;
  @Input() name = uuidv4();
  @Input() ngModelOptions: { updateOn?: 'blur' } = {};
  @Input() inputModel?: string | number | null;
  @Input() min?: number;
  @Input() max?: number;
  @Input() value?: string | number | null = '';
  @Input() placeholder?: string = '';
  @Input() inputType?: 'text' | 'number' | 'password' | 'email' = 'text';
  @Input() errorTitle?: string;
  @Input() title?: string;
  @Input() description?: string;

  @Output() inputModelChange = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() focusOut: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() handleKeypress: EventEmitter<KeyboardEvent> = new EventEmitter();

  constructor() {}

  handleBlur(event: FocusEvent) {
    if (
      this.required &&
      event.target instanceof HTMLInputElement &&
      !event.target.value
    ) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }
    this.focusOut.emit(event);
  }
}
