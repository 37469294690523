<div class="search w-full">
  <label for="search" class="sr-only">Search</label>
  <div class="relative">
    <div
      class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 14C3.14 14 0 10.86 0 7C0 3.14 3.14 0 7 0C10.86 0 14 3.14 14 7C14 10.86 10.86 14 7 14ZM7 2C4.243 2 2 4.243 2 7C2 9.757 4.243 12 7 12C9.757 12 12 9.757 12 7C12 4.243 9.757 2 7 2ZM13.314 11.9L15.707 14.293C16.098 14.684 16.098 15.316 15.707 15.707C15.512 15.902 15.256 16 15 16C14.744 16 14.488 15.902 14.293 15.707L11.9 13.314C12.429 12.903 12.903 12.429 13.314 11.9Z"
          fill="#94A3B8" />
      </svg>
    </div>
    <input
      type="search"
      class="search-input search-input-{{
        size
      }} block w-full bg-white border border-gray-300 rounded-md py-2 pl-9 pr-3 pl-9 text-sm focus:outline-none focus:text-gray-900 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      [(ngModel)]="searchString"
      [placeholder]="placeholder"
      (ngModelChange)="handleChnage()"
      (keypress)="handleKeyPress($event)" />
  </div>
</div>
