export const generateAnswersAsLables = (answers?: string[]) => {
  const answersLength = answers?.length ?? 0;

  if (!answers) {
    return [];
  }

  if (answersLength > 1) {
    return answers.map((answer, index) => {
      if (answersLength - 2 === index) {
        return answer;
      }
      if (answersLength - 1 === index) {
        return ` or ${answer}`;
      }
      return `${answer}, `;
    }, '');
  }
  return answers;
};
