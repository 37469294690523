<app-dropdown
  placement="bottom-start"
  [appendToBody]="true"
  [useScrollToElement]="true"
  (openDropdown)="showDropdown()"
  (closeDropdown)="hideDropdown()">
  <app-label-filter
    button
    [id]="event?.id"
    [showError]="isFilterInvalid"
    [tooltip]="
      event?.value ? 'Please enter the missing value' : 'Please select an event'
    "
    [active]="dropdownActive"
    [type]="LabelFilterType.Event"
    (remove)="remove.emit()">
    <ng-container *ngIf="eventModel?.label; else placeholder">
      <b>{{ event?.label }} {{ eventTypeLabel }}</b>
      {{ conditionTitle }}
      <b>{{ value }}</b>
      <ng-container *ngIf="event?.where?.filters?.length">
        where
        <ng-container
          *ngFor="let filter of event?.where?.filters; let i = index">
          <ng-container
            *ngIf="
              filter.filterType === FilterType.FilterGroup &&
                toFilterGroupFilterType(filter);
              let filterGroupFilter;
              else: defaultWhereLabel
            ">
            (<ng-container
              *ngFor="
                let f of filterGroupFilter.filters;
                let filterGroupFilterIndex = index
              ">
              <b>{{ f.label }}</b>
              {{ getFilterConditionLabel(f.condition) }}
              <ng-container *ngIf="getEventPropertyLabel(f); let propertyLabel">
                <b>
                  {{ propertyLabel }}
                  <ng-container
                    *ngIf="getAdditionalFilterPostfix(f); let filterPostfix">
                    {{ filterPostfix }}
                  </ng-container>
                </b>
              </ng-container>
              <ng-container
                *ngIf="
                  filterGroupFilterIndex < filterGroupFilter.filters.length - 1
                ">
                {{ filterGroupFilter.operator?.toLowerCase() }}
              </ng-container> </ng-container
            >)
          </ng-container>
          <ng-template #defaultWhereLabel>
            <ng-container
              *ngIf="
                toEventPropertyFilterType(filter);
                let eventPropertyFilter
              ">
              <b>{{ eventPropertyFilter.label }}</b>
              {{ getFilterConditionLabel(eventPropertyFilter.condition) }}
              <b
                >{{ getEventPropertyLabel(filter) }}
                {{ getAdditionalFilterPostfix(eventPropertyFilter) }}</b
              >
            </ng-container>
          </ng-template>
          <ng-container *ngIf="i < (event?.where?.filters?.length ?? 0) - 1">
            {{ event?.where?.operator?.toLowerCase() }}
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #placeholder>Select an event</ng-template>
  </app-label-filter>
  <div dropdown class="dropdown flex flex-col gap-4">
    <app-select
      #eventSelect
      title="Event"
      placeholder="Find or create an event"
      [required]="true"
      [useSearch]="true"
      [isLoading]="isLoading"
      [selected]="eventModel ? [eventModel.value] : []"
      [elements]="(events$ | async) || []"
      (handleSearch)="allowAddingNewEvents && setEventSearchString($event)"
      (handleKeyPress)="
        allowAddingNewEvents && handleEventSelectKeyPress($event)
      "
      (update)="setEvent($event)">
      <ng-container
        topElement
        *ngIf="
          allowAddingNewEvents && (eventSearchString$ | async);
          let eventSearchString
        ">
        <span
          *ngIf="eventSearchString !== eventModel?.value"
          (click)="addNewEvent()">
          Use: "{{ eventSearchString }}"
        </span>
      </ng-container>
    </app-select>
    <ng-container *ngIf="eventModel && eventModel.value">
      <ng-container *ngIf="!hideEventFilterType">
        <app-segment *ngIf="activeEventType">
          <app-segment-element
            *ngFor="let eventType of eventTypes; trackBy: trackByEventType"
            [active]="activeEventType === eventType.value"
            (click)="setEventType(eventType.value)">
            {{ eventType.label }}
          </app-segment-element>
        </app-segment>
        <ng-container [ngSwitch]="activeEventType">
          <ng-container *ngSwitchCase="EventFilterType.COUNT">
            <ng-container
              *ngIf="eventModel?.[EventFilterType.COUNT]; let countFilter">
              <div class="flex items-center gap-4">
                <app-simple-select
                  [value]="eventModel.condition"
                  [elements]="countEventTypes"
                  (update)="handleCountEventType($event)">
                </app-simple-select>
                <ng-container
                  *ngFor="
                    let input of countFilter.eventTypeValues;
                    let i = index
                  ">
                  <app-input
                    class="w-14"
                    inputType="number"
                    [(inputModel)]="input.value"
                    (focusOut)="handleNumberInputBlur($event)">
                  </app-input>
                  <p
                    *ngIf="countFilter.eventTypeValues.length - 1 !== i"
                    class="dropdown-text">
                    and
                  </p>
                </ng-container>
                <p class="dropdown-text">times</p>
              </div>
              <div class="flex flex-wrap items-center gap-4">
                <app-simple-select
                  [value]="countFilter.timeRange"
                  [elements]="eventCountTimeRanges"
                  (update)="handleCountTimeRange($event)">
                </app-simple-select>
                <ng-container
                  *ngIf="
                    countFilter.timeRange !== EventCountTimeRange.ALL_TIME
                  ">
                  <ng-container
                    *ngFor="
                      let input of countFilter.timeRangeValues;
                      let i = index
                    ">
                    <app-input
                      class="w-14 flex-shrink-0"
                      inputType="number"
                      [(inputModel)]="input.value"
                      (focusOut)="handleNumberInputBlur($event)">
                    </app-input>
                    <p
                      *ngIf="countFilter.timeRangeValues.length - 1 !== i"
                      class="dropdown-text">
                      and
                    </p>
                  </ng-container>
                  <app-simple-select
                    [value]="countFilter.timeRangeFormat"
                    [elements]="eventCountTimeRangeFormats"
                    (update)="handleCountTimeRangeFormat($event)">
                  </app-simple-select>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="EventFilterType.FIRST_OCCURRED">
            <app-date-filter-section
              *ngIf="
                eventModel?.[EventFilterType.FIRST_OCCURRED];
                let firstOccurredFilter
              "
              [value]="firstOccurredFilter.value"
              [condition]="eventModel.condition"
              (update)="setFirstOccurred($event)">
            </app-date-filter-section>
          </ng-container>
          <ng-container *ngSwitchCase="EventFilterType.LAST_OCCURRED">
            <app-date-filter-section
              *ngIf="
                eventModel?.[EventFilterType.LAST_OCCURRED];
                let lastOccurredFilter
              "
              [value]="lastOccurredFilter.value"
              [condition]="eventModel.condition"
              (update)="setLastOccurred($event)">
            </app-date-filter-section>
          </ng-container>
        </ng-container>
      </ng-container>
      <app-event-filter-where
        [eventName]="event?.label"
        [filter]="eventModel?.where ?? null"
        (update)="updateWhere($event)"></app-event-filter-where>
    </ng-container>
  </div>
</app-dropdown>
