import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

import { Tooltip } from 'flowbite';

@Component({
  selector: 'app-inline-tooltip',
  templateUrl: './inline-tooltip.component.html',
  styleUrls: ['./inline-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineTooltipComponent implements AfterViewInit {
  @Input() placement:
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end' = 'bottom-end';
  @Input() width?: string;
  @Input() title?: string;

  @ViewChild('icon', { static: false })
  iconElement?: ElementRef<HTMLElement>;
  @ViewChild('tooltip', { static: false })
  tooltipElement?: ElementRef<HTMLDivElement>;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.iconElement?.nativeElement && this.tooltipElement?.nativeElement) {
      new Tooltip(
        this.tooltipElement.nativeElement,
        this.iconElement.nativeElement,
        {
          placement: this.placement,
        }
      );
    }
  }
}
