<app-select
  class="block ml-6"
  placeholder="Enter value"
  [multiple]="true"
  [required]="true"
  [useSearch]="true"
  [selected]="selected"
  [elements]="(values$ | async) || []"
  [isLoading]="(isLoading$ | async) || false"
  (handleSearch)="handleSearch($event)"
  (handleKeyPress)="handleSelectKeyPress($event)"
  (update)="handleUpdate($event)"
  (loadMore)="!allEventPropertyValuesLoaded && loadMoreEventPropertyValues()">
  <span
    *ngIf="searchString$ | async; let searchString"
    topElement
    (click)="$event.stopPropagation(); setNewProperty()">
    Use: "{{ searchString }}"
  </span>
</app-select>
