<svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M8.99999 0.666687C4.39999 0.666687 0.666656 4.40002 0.666656 9.00002C0.666656 13.6 4.39999 17.3334 8.99999 17.3334C13.6 17.3334 17.3333 13.6 17.3333 9.00002C17.3333 4.40002 13.6 0.666687 8.99999 0.666687ZM9.83332 13.1667H8.16666V11.5H9.83332V13.1667ZM9.83332 9.83335H8.16666V4.83335H9.83332V9.83335Z"
    fill="#F5222D" />
</svg>
