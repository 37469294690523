import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-icon-setup',
  templateUrl: './icon-setup.component.html',
  styleUrls: ['./icon-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSetupComponent {
  @Input() active = false;

  constructor() {}
}
