import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { AUTH_TOKEN, REDIRECT_AFTER_LOGIN } from 'src/app/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean | UrlTree {
    const user = localStorage.getItem(AUTH_TOKEN);

    if (user) {
      const stage = localStorage.getItem('ONE_FLOW_USER_ID');

      if (stage) {
        return this.router.createUrlTree([REDIRECT_AFTER_LOGIN]);
      }

      return true;
    } else {
      return true;
    }
  }
}
