import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  AfterViewChecked,
} from '@angular/core';

import { EMAIL_VALIDATION_REGEXP } from 'src/app/constants/regexp';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-labels-picker',
  templateUrl: './labels-picker.component.html',
  styleUrls: ['./labels-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelsPickerComponent implements AfterViewChecked {
  public searchString?: string;

  @Input() valueType: 'text' | 'email' = 'text';
  @Input() title?: string;
  @Input() selected: string[] = [];
  @Input() placeholder = '';
  @Input() required = false;
  @Input() autofocus = false;

  @Output() update: EventEmitter<string[]> = new EventEmitter();

  @ViewChild('search', { static: false })
  searchElement?: ElementRef<HTMLInputElement>;
  @ViewChild('pickerHeader', { static: false })
  pickerHeaderElement?: ElementRef<HTMLInputElement>;

  constructor(private toastService: ToastService) {}

  ngAfterViewChecked() {
    if (this.pickerHeaderElement?.nativeElement) {
      const elementWidth = this.pickerHeaderElement.nativeElement.clientWidth;
      let width = 0;

      for (
        let i = this.pickerHeaderElement.nativeElement.childNodes.length - 1;
        i !== 0;
        i--
      ) {
        const node = this.pickerHeaderElement.nativeElement.childNodes[i];

        if (node instanceof HTMLElement && node.nodeName === 'SPAN') {
          if (width + node.clientWidth < elementWidth) {
            width += node.clientWidth;
          } else {
            break;
          }
        }
      }

      if (
        width &&
        elementWidth - width >= 40 &&
        this.searchElement?.nativeElement
      ) {
        this.searchElement.nativeElement.style.width = `calc(100% - ${width}px)`;
      } else {
        this.searchElement?.nativeElement.style.removeProperty('width');
      }
    }
  }

  private validate(value: string) {
    switch (this.valueType) {
      case 'email':
        return EMAIL_VALIDATION_REGEXP.test(value);

      default:
        return true;
    }
  }

  public handleDelete(e: MouseEvent, element: string) {
    e.stopPropagation();

    this.update.emit(this.selected.filter(s => s !== element));
  }

  public handleChange({ code }: KeyboardEvent) {
    if (
      ['Comma', 'Enter', 'Semicolon', 'Space'].includes(code) &&
      this.searchString
    ) {
      const value = ['Comma', 'Semicolon'].includes(code)
        ? this.searchString.substr(0, this.searchString.length - 1)
        : this.searchString.trim();
      const valid = this.validate(value);

      if (valid) {
        this.update.emit([...this.selected, value]);

        this.searchString = undefined;

        setTimeout(() => {
          this.searchElement?.nativeElement.focus();
        }, 0);
      } else {
        this.toastService.showError('This format is not valid');
      }
    }
  }

  public handleBlur() {
    if (this.searchString) {
      const valid = this.validate(this.searchString);

      if (valid) {
        this.update.emit([...this.selected, this.searchString!]);

        this.searchString = undefined;
      } else {
        this.toastService.showError('This format is not valid');
      }
    }
  }
}
