<svg
  width="24"
  height="22"
  viewBox="0 0 24 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M0.75 7.25001V14.75H5.75L12 21V1.00001L5.75 7.25001H0.75ZM17.625 11C17.625 8.78751 16.35 6.88751 14.5 5.96251V16.025C16.35 15.1125 17.625 13.2125 17.625 11ZM14.5 0.0375061V2.61251C18.1125 3.68751 20.75 7.03751 20.75 11C20.75 14.9625 18.1125 18.3125 14.5 19.3875V21.9625C19.5125 20.825 23.25 16.35 23.25 11C23.25 5.65001 19.5125 1.17501 14.5 0.0375061Z"
    [attr.fill]="active ? '#2D4EFF' : '#454545'" />
</svg>
