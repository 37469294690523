<div *ngIf="filter" class="py-4 flex flex-wrap items-center gap-2.5">
  <app-filter-where-label></app-filter-where-label>
  <ng-container
    *ngFor="
      let filterModel of filter.filters;
      let i = index;
      trackBy: trackByFilter
    ">
    <ng-container [ngSwitch]="filterModel.filterType">
      <app-page-property-filter
        *ngSwitchCase="FilterType.PageProperty"
        [filter]="filterModel"
        (update)="updateFilter($event)"
        (remove)="removeFilter(i)">
      </app-page-property-filter>
      <app-page-filter-group
        *ngSwitchCase="FilterType.FilterGroup"
        class="block w-full"
        [filterGroup]="toFilterGroupFilterType(filterModel)"
        (update)="updateFilter($event)"
        (remove)="removeFilter(i)">
      </app-page-filter-group>
    </ng-container>
    <app-relationship-switcher
      *ngIf="i !== filter.filters.length - 1"
      [active]="filter.operator"
      (toggle)="toggleRelationshipSwitcher($event)">
    </app-relationship-switcher>
  </ng-container>
  <app-add-filter-button
    dropdownTitle="user data"
    [filters]="allowedFilters"
    (add)="addFilter($event)">
  </app-add-filter-button>
</div>
