import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() showLoader = false;
  @Input() disabled = false;
  @Input() color: 'primary' | 'secondary' | 'danger' | 'transparent' =
    'primary';
  @Input() variant: 'filled' | 'outlined' | 'link' = 'filled';
  @Input() buttonType: 'button' | 'submit' = 'button';
  @Input() width?: string;
  @Input() size: 'sm' | 'm' = 'sm';

  @Output() press = new EventEmitter();

  constructor() {}
}
