<div class="flex flex-col gap-1">
  <p class="label mb-2">Relative</p>
  <ng-container>
    <app-radio
      [active]="condition === FilterCondition.LessThan"
      (update)="updateOperator(FilterCondition.LessThan)">
      {{ FILTER_CONDITIONS[FilterCondition.LessThan] }}
    </app-radio>
    <app-input
      *ngIf="condition === FilterCondition.LessThan"
      class="block ml-6"
      placeholder="Enter number"
      inputType="number"
      [required]="true"
      [autofocus]="true"
      [value]="value"
      (update)="updateValue($event.target.value)">
      <p inputRight class="input-right-text">days ago</p>
    </app-input>
  </ng-container>
  <ng-container>
    <app-radio
      [active]="condition === FilterCondition.Exactly"
      (update)="updateOperator(FilterCondition.Exactly)">
      {{ FILTER_CONDITIONS[FilterCondition.Exactly] }}
    </app-radio>
    <app-input
      *ngIf="condition === FilterCondition.Exactly"
      class="block ml-6"
      placeholder="Enter number"
      inputType="number"
      [required]="true"
      [autofocus]="true"
      [value]="value"
      (update)="updateValue($event.target.value)">
      <p inputRight class="input-right-text">days ago</p>
    </app-input>
  </ng-container>
  <ng-container>
    <app-radio
      [active]="condition === FilterCondition.MoreThan"
      (update)="updateOperator(FilterCondition.MoreThan)">
      {{ FILTER_CONDITIONS[FilterCondition.MoreThan] }}
    </app-radio>
    <app-input
      *ngIf="condition === FilterCondition.MoreThan"
      class="block ml-6"
      placeholder="Enter number"
      inputType="number"
      [required]="true"
      [autofocus]="true"
      [value]="value"
      (update)="updateValue($event.target.value)">
      <p inputRight class="input-right-text">days ago</p>
    </app-input>
  </ng-container>
  <p class="label mb-2 mt-2">Absolute</p>
  <ng-container>
    <app-radio
      [active]="condition === FilterCondition.Before"
      (update)="updateOperatorWithoutValue(FilterCondition.Before)">
      {{ FILTER_CONDITIONS[FilterCondition.Before] }}
    </app-radio>
    <app-calendar
      *ngIf="condition === FilterCondition.Before"
      class="block ml-6"
      [date]="value"
      (update)="updateValue($event)"></app-calendar>
  </ng-container>
  <ng-container>
    <app-radio
      [active]="condition === FilterCondition.On"
      (update)="updateOperatorWithoutValue(FilterCondition.On)">
      {{ FILTER_CONDITIONS[FilterCondition.On] }}
    </app-radio>
    <app-calendar
      *ngIf="condition === FilterCondition.On"
      class="block ml-6"
      [date]="value"
      (update)="updateValue($event)"></app-calendar>
  </ng-container>
  <ng-container>
    <app-radio
      [active]="condition === FilterCondition.After"
      (update)="updateOperatorWithoutValue(FilterCondition.After)">
      {{ FILTER_CONDITIONS[FilterCondition.After] }}
    </app-radio>
    <app-calendar
      *ngIf="condition === FilterCondition.After"
      class="block ml-6"
      [date]="value"
      (update)="updateValue($event)"></app-calendar>
  </ng-container>
  <app-radio
    [active]="condition === FilterCondition.HasAnyValue"
    (update)="updateOperatorWithoutValue(FilterCondition.HasAnyValue)">
    {{ FILTER_CONDITIONS[FilterCondition.HasAnyValue] }}
  </app-radio>
  <app-radio
    [active]="condition === FilterCondition.IsUnknown"
    (update)="updateOperatorWithoutValue(FilterCondition.IsUnknown)">
    {{ FILTER_CONDITIONS[FilterCondition.IsUnknown] }}
  </app-radio>
</div>
