import { Component, Input } from '@angular/core';

import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { UserSubscriptionService } from 'src/app/services/user-subscription/user-subscription.service';

@Component({
  selector: 'app-feature-access-wrapper',
  templateUrl: './feature-access-wrapper.component.html',
  styleUrls: ['./feature-access-wrapper.component.scss'],
})
export class FeatureAccessWrapperComponent {
  @Input() key?: string;
  @Input() additionalRule = true;
  @Input() usePro = true;

  constructor(
    public accountsService: AccountsService,
    private userSubscriptionService: UserSubscriptionService
  ) {}

  handleClick(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.userSubscriptionService.showUpdatePlanModal(this.usePro);
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.code === 'Space') {
      e.stopPropagation();
      e.preventDefault();

      this.userSubscriptionService.showUpdatePlanModal(this.usePro);
    }
  }
}
