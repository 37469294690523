import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-no-table-data-placeholder',
  templateUrl: './no-table-data-placeholder.component.html',
  styleUrls: ['./no-table-data-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoTableDataPlaceholderComponent {
  @Input() title?: string;
  @Input() description?: string;
  @Input() addButton?: {
    title: string;
    link: string;
    queryParams?: { [key: string]: string };
  };

  constructor() {}
}
