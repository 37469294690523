import { Input, Directive } from '@angular/core';
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
  AsyncValidator,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api/api.service';

@Directive({
  selector: '[appValidateEmail]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: EmailServerValidateDirective,
      multi: true,
    },
  ],
})
export class EmailServerValidateDirective implements AsyncValidator {
  @Input() appValidateEmail = false;

  constructor(private api: ApiService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!this.appValidateEmail) {
      return of(null);
    }
    return this.api.validateEmail(control.value).pipe(
      map(() => {
        return null;
      }),
      catchError(err => {
        return of({
          serverEmailExists: true,
          message: err.error?.message ?? 'Internal error',
        });
      })
    );
  }
}
