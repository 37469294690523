<ng-template #inputRightContent>
  <ng-content select="[inputRight]"></ng-content>
</ng-template>

<div>
  <div *ngIf="title" class="flex items-center justify-between mb-1">
    <h4 class="title">{{ title }}</h4>
    <ng-content select="[header]"></ng-content>
  </div>
  <ng-container *ngIf="inputModelChange.observers.length; else defaultInput">
    <div
      class="input flex items-center gap-1"
      [class.input-with-error]="hasError">
      <input
        #input="ngModel"
        autocomplete="off"
        [autofocus]="autofocus"
        [(ngModel)]="inputModel"
        [placeholder]="placeholder"
        [ngModelOptions]="ngModelOptions"
        [min]="min"
        [max]="max"
        [type]="inputType"
        [name]="name"
        [readonly]="readonly"
        [required]="required"
        (blur)="handleBlur($event)"
        (keypress)="handleKeypress.emit($event)"
        (ngModelChange)="inputModelChange.emit(inputModel)" />
      <div
        class="flex items-center justify-center"
        (click)="$event.stopPropagation()">
        <ng-template [ngTemplateOutlet]="inputRightContent"></ng-template>
      </div>
    </div>
    <app-error-generator
      *ngIf="
        (title || errorTitle) && required && input.invalid && input.touched
      "
      class="block mt-1"
      [errors]="input.errors"
      [key]="errorTitle || title">
    </app-error-generator>
  </ng-container>
  <ng-template #defaultInput>
    <div
      class="input flex items-center gap-1"
      [class.input-with-error]="hasError">
      <input
        autocomplete="off"
        [autofocus]="autofocus"
        [placeholder]="placeholder"
        [min]="min"
        [max]="max"
        [value]="value"
        [type]="inputType"
        [name]="name"
        [readonly]="readonly"
        [required]="required"
        (blur)="focusOut.emit($event)"
        (keypress)="handleKeypress.emit($event)"
        (change)="update.emit($event)" />
      <div
        class="flex items-center justify-center"
        (click)="$event.stopPropagation()">
        <ng-template [ngTemplateOutlet]="inputRightContent"></ng-template>
      </div>
    </div>
  </ng-template>
  <p *ngIf="description" class="description mt-1">
    <span [innerHTML]="description | safeHtml"></span>
  </p>
</div>
