export enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DataType {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  DATE = 'date',
  NUMBER = 'number',
  STRING = 'string',
}

export enum TimingOption {
  SHOW_IMMEDIATELY = 'show_immediately',
  SHOW_AFTER = 'show_after',
}
