import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  Injector,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { CohortType } from 'src/app/common/enums';
import { COHORT_ICONS } from 'src/app/constants';
import { BaseDirective } from 'src/app/directives/base/base.directive';
import { SessionService } from 'src/app/services/session/session.service';
import { UsersService } from 'src/app/services/users/users.service';

type Cohort = {
  type: CohortType;
  icon: string;
  label: string;
  value: string;
};

@Component({
  selector: 'app-cohort-select',
  templateUrl: './cohort-select.component.html',
  styleUrls: ['./cohort-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CohortSelectComponent extends BaseDirective {
  isLoading = false;
  cohortsList$: BehaviorSubject<Cohort[]> = new BehaviorSubject([]);

  @Input() selected?: string | null;

  @Output() setCohort: EventEmitter<Cohort> = new EventEmitter();

  constructor(
    private sessionService: SessionService,
    private usersService: UsersService,
    injector: Injector
  ) {
    super(injector);

    super.addSubscription(
      this.sessionService.activeProjectId$.subscribe(projectId => {
        if (projectId) {
          const cohorts = this.usersService.getAllCohorts();

          if (!cohorts.length) {
            this.loadCohorts(projectId);
          }
        }
      })
    );

    super.addSubscription(
      this.usersService.cohorts$.subscribe(cohorts => {
        this.cohortsList$.next(
          cohorts.map(cohort => ({
            type: cohort.source,
            icon: COHORT_ICONS[cohort.source],
            label: cohort.name,
            value: cohort._id,
          }))
        );
      })
    );
  }

  private async loadCohorts(projectId: string) {
    this.isLoading = true;

    try {
      await this.usersService.loadCohorts(projectId);
    } finally {
      this.isLoading = false;
    }
  }

  select(model: { value: any }) {
    this.setCohort.emit(
      this.cohortsList$.value.find(trait => trait.value === model.value)
    );
  }
}
