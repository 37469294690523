import { Directive } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appBaseForm]',
})
export class BaseFormDirective {
  constructor() {}

  public addErrorClass(control: NgModel) {
    return control.errors && control.touched ? 'border-red-600' : 'no-errors';
  }
}
