<div class="flex items-center gap-5">
  <app-survey-avatar [icon]="icon" [color]="color"></app-survey-avatar>
  <input
    *ngIf="editModeActive; else readonlyName"
    [autofocus]="true"
    class="edit-field"
    type="text"
    [value]="name"
    (blur)="handleBlur($event)" />
  <ng-template #readonlyName>
    <h5 class="name text-left">
      <app-link variant="secondary" (press)="pressLink.emit()">
        <span class="ellipsis">{{ name || defaultName }}</span>
      </app-link>
    </h5>
  </ng-template>
</div>
