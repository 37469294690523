import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

import { Tooltip } from 'flowbite';

@Component({
  selector: 'app-data-logic-tooltip',
  templateUrl: './data-logic-tooltip.component.html',
  styleUrls: ['./data-logic-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLogicTooltipComponent implements AfterViewInit {
  @Input() placement:
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end' = 'bottom-end';
  @Input() width?: string;
  @Input() title?: string;
  @Input() icon?: boolean;
  @Input() element?: string;
  @Input() update?: any;

  @ViewChild('icon', { static: false })
  iconElement?: ElementRef<HTMLElement>;
  @ViewChild('tooltip', { static: false })
  tooltipElement?: ElementRef<HTMLDivElement>;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.icon === false) {
      let element = document.getElementById(this.element ? this.element : '');

      if (element) {
        new Tooltip(this.tooltipElement?.nativeElement, element, {
          placement: this.placement,
        });
      }
      if (this.iconElement?.nativeElement) {
        this.iconElement.nativeElement.style.display = 'none';
      }
    } else if (
      this.iconElement?.nativeElement &&
      this.tooltipElement?.nativeElement
    ) {
      new Tooltip(
        this.tooltipElement.nativeElement,
        this.iconElement.nativeElement,
        {
          placement: this.placement,
        }
      );
    }
  }
}
