import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-page-property-values-select',
  templateUrl: './page-property-values-select.component.html',
  styleUrls: ['./page-property-values-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagePropertyValuesSelectComponent implements OnChanges {
  searchString$: BehaviorSubject<string> = new BehaviorSubject('');
  values$: BehaviorSubject<{ label: string; value: any }[]> =
    new BehaviorSubject([]);

  @Input() selected: string[] = [];

  @Output() update: EventEmitter<string[]> = new EventEmitter();

  constructor() {}

  ngOnChanges({ selected }: SimpleChanges): void {
    if (selected?.currentValue) {
      const values = new Set([
        ...this.values$.value.map(v => v.value),
        ...selected.currentValue,
      ]);

      this.values$.next(Array.from(values).map(v => ({ value: v, label: v })));
    }
  }

  udpateSearchString(searchString: string) {
    this.searchString$.next(searchString);
  }

  handleUpdate(value: { label: string; value: any }) {
    let selected = [...this.selected];

    if (selected.includes(value.value)) {
      selected = selected.filter(item => item !== value.value);
    } else {
      selected.push(value.value);
    }

    this.udpateSearchString('');

    this.update.emit(selected);
  }

  setNewProperty(e: MouseEvent) {
    e.stopPropagation();

    if (!this.searchString$.value) {
      return;
    }
    if (
      this.selected.includes(this.searchString$.value) ||
      this.values$.value.find(p => p.value === this.searchString$.value)
    ) {
      window.alert('Property already exists');
    } else {
      this.values$.next([
        ...this.values$.value,
        {
          label: this.searchString$.value,
          value: this.searchString$.value,
        },
      ]);
      this.update.emit([...this.selected, this.searchString$.value]);
      this.udpateSearchString('');
    }
  }
}
