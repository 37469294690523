import * as ObjectId from 'bson-objectid';

import {
  SurveyMediaContentType,
  SurveyMediaSourceType,
  SurveyMediaAction,
} from 'src/app/common/enums';
import { FilterOperator } from 'src/app/components/filters/enums';
import {
  DEFAULT_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_BACKGROUND_COLOR_OPACITY,
  DEFAULT_TEXT_COLOR,
  DEFAULT_TEXT_COLOR_OPACITY,
  MAX_QUESTION_TEXT_VALUE,
  MIN_QUESTION_TEXT_VALUE,
} from 'src/app/constants/constants';
import {
  SurveyTriggerFilter,
  UserCohortFilter,
  UserTraitFilter,
  UserEventFilter,
  UserFilterGroupFilter,
} from 'src/app/types';

import { FilterData } from './smart-audience';

export enum InputType {
  RATING_NUMERICAL = 'rating-numerical',
  TEXT = 'text',
  MCQ = 'mcq',
  RATING = 'rating',
  RATING_FIVE_STAR = 'rating-5-star',
  RATING_EMOJI = 'rating-emojis',
  CHECKBOX = 'checkbox',
  NPS = 'nps',
  THANK_YOU = 'thankyou',
  SHORT_TEXT = 'short-text',
  EMPTY_DIV = 'empty_div',
  WELCOME = 'welcome-screen',
  END = 'end-screen',
}

export type RatingStringType = '0' | '1' | '2' | '3' | '4' | '5';
export const TIME: Array<any> = [
  '12:00 AM',
  '01:00 AM',
  '02:00 AM',
  '03:00 AM',
  '04:00 AM',
  '05:00 AM',
  '06:00 AM',
  '07:00 AM',
  '08:00 AM',
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
  '04:00 PM',
  '05:00 PM',
  '06:00 PM',
  '07:00 PM',
  '08:00 PM',
  '09:00 PM',
  '10:00 PM',
  '11:00 PM',
];

export const SURVEY_MIN_VERSION: string = '2022-08-29'; //LAST VERSION 2022-07-22
export interface ChoiceInterface {
  _id?: string | null;
  title: string | null;
  option: number;
}

export enum RatingTextType {
  ZeroSecond = '0',
  OneSecond = '1',
  TwoSecond = '2',
  ThreeSecond = '3',
  FourSecond = '4',
  FiveSecond = '5',
}

export class Choice implements ChoiceInterface {
  _id?: string | null;
  title: string | null;
  other_option: Boolean | null;
  option: number;

  constructor() {
    //@ts-ignore
    this._id = ObjectId(new Date().valueOf()).toHexString();
    this.title = '';
    this.option = 0;
    this.other_option = null;
  }
}

export interface InputInterface {
  placeholder_text?: null | string;
  min_val?: number | null;
  max_val?: number | null;
  emoji?: boolean | null;
  stars?: boolean | null;
  emojis?: any[];
  star_fill_color?: null | string;
  min_chars?: number | null;
  max_chars?: number | null;
  _id?: string;
  choices?: Choice[];
  number_of_choices?: number | null;
  input_type: InputType;
  rating_min_text: string | null;
  rating_max_text: string | null;
}

export class Input implements InputInterface {
  randomize_choice_order?: boolean;
  placeholder_text?: null | string;
  min_val?: number | null;
  max_val?: number | null;
  emoji?: boolean | null;
  stars?: boolean | null;
  emojis?: any[];
  star_fill_color?: null | string;
  min_chars?: number | null;
  max_chars?: number | null;
  _id?: string;
  choices?: Choice[];
  number_of_choices?: number | null;
  input_type: InputType;
  rating_min_text: string | null;
  rating_max_text: string | null;
  other_option_id: string | null;
  rating_text: { [key in RatingTextType]: string } | null;

  constructor(
    type: InputType,
    rating_text: { [key in RatingTextType]: string },
    rating_min_text: string,
    rating_max_text: string
  ) {
    this.randomize_choice_order = false;
    this.input_type = type;
    this.min_val = null;
    this.max_val = null;
    this.emojis = [];
    this.emoji = false;
    this.stars = false;
    this.star_fill_color = null;
    this.min_chars = MIN_QUESTION_TEXT_VALUE;
    this.max_chars = MAX_QUESTION_TEXT_VALUE;
    this.choices = [];
    this.number_of_choices = null;
    this.rating_max_text = rating_max_text;
    this.rating_min_text = rating_min_text;
    this.other_option_id = '';
    this.rating_text = rating_text;
  }
}

export interface StyleInterface {
  display_mode: string;
  font: string;
  _id?: string;
  primary_color: string;
  corner_radius: number;
  color_opacity: number;
  change_trigger: boolean;
}

export class Style implements StyleInterface {
  display_mode: string;
  font: string;
  _id?: string;
  primary_color: string;
  corner_radius: number;
  color_opacity: number;
  change_trigger: boolean;
  constructor() {
    this.display_mode = 'light';
    this.font = 'arial';
    this.primary_color = DEFAULT_COLOR;
    this.corner_radius = 10;
    this.color_opacity = 100;
    this.change_trigger = false;
  }
}
export interface ButtonInterface {
  button_type: PrimaryColor;
  _id?: string;
  action: string;
  title: string;
}

export class Button implements ButtonInterface {
  button_type: PrimaryColor;
  _id?: string;
  action: string;
  title: string;

  constructor(title: string) {
    this.button_type = PrimaryColor.Primary;
    this.action = 'button_action_submit';
    this.title = title;
  }
}

export enum PrimaryColor {
  Done = 'done',
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface ScreenInterface {
  title: string;
  message: null | string;
  media: {
    contentType: SurveyMediaContentType;
    sourceType: SurveyMediaSourceType;
    url: string;
    _id: string;
    internalUrl?: string;
  } | null;
  _id?: string;
  input: Input;
  buttons: Button[];
  rules: SurveyDataLogic;
}

export class Screen implements ScreenInterface {
  title: string;
  message: null | string;
  _id?: string;
  input: Input;
  buttons: Button[];
  rules: SurveyDataLogic;
  media: {
    contentType: SurveyMediaContentType;
    sourceType: SurveyMediaSourceType;
    url: string;
    _id: string;
    internalUrl?: string;
  } | null = null;

  constructor(
    type: InputType,
    title: string,
    message: string,
    buttonTitle: string,
    ratingText: { [key in RatingTextType]: string },
    ratingMinText: string,
    ratingMaxText: string,
    id?: string
  ) {
    //@ts-ignore-next-line
    this._id = id || ObjectId(new Date().valueOf()).toHexString();
    this.title = title;
    this.message = message;
    this.buttons = [new Button(buttonTitle)];
    this.input = new Input(type, ratingText, ratingMinText, ratingMaxText);
    this.rules = new SurveyDataLogic(type);
  }
}

interface RetakeSurveyStructure {
  retake_input_value: string | null;
  retake_select_value: string | null;
}

export class RetakeSurvey implements RetakeSurveyStructure {
  retake_input_value: string | null;
  retake_select_value: string | null;

  constructor() {
    this.retake_input_value = '0';
    this.retake_select_value = 'hours';
  }
}

interface FrequencyCappingStructure {
  enabled: boolean;
  frequency_capping_select_value: string | null;
  frequency_capping_input_value: string | null;
  time_per_user: string | null;
}

class FrequencyCapping implements FrequencyCappingStructure {
  enabled: boolean;
  frequency_capping_select_value: string | null;
  frequency_capping_input_value: string | null;
  time_per_user: string | null;

  constructor() {
    this.enabled = true;
    this.frequency_capping_input_value = '';
    this.frequency_capping_select_value = 'hours';
    this.time_per_user = '';
  }
}
interface SettingStructure {
  type: string;
  value: string;
  time: string;
}
interface FrequencySchedulingStructure {
  start_sending: SettingStructure;
  stop_sending: SettingStructure;
}

export class FrequencyScheduling implements FrequencySchedulingStructure {
  start_sending: SettingStructure;
  stop_sending: SettingStructure;
  constructor() {
    this.start_sending = {
      type: 'string',
      value: 'immediately',
      time: '',
    };
    this.stop_sending = {
      type: 'string',
      value: 'never',
      time: '',
    };
  }
}
interface PageRuleSurveyStructure {
  url_type: string | null;
  relation: string | null;
  url: string | null;
  time_spend_type: string | null;
  time_spend_relation: string | null;
  time_spend: string | null;
}

export type PageRuleRelation =
  | 'is'
  | 'is not'
  | 'starts with'
  | 'ends with'
  | 'contains'
  | 'does not contain';

export class PageRuleSurvey implements PageRuleSurveyStructure {
  url_type: string | null;
  relation: PageRuleRelation;
  url: string | null;
  time_spend_type: string | null;
  time_spend_relation: string | null;
  time_spend: string | null;

  constructor() {
    this.url_type = 'Current page URL';
    this.relation = 'is';
    this.url = '';
    this.time_spend_type = 'Time on current page';
    this.time_spend_relation = 'is equal to';
    this.time_spend = null;
  }
}

interface SurveySettingStructure {
  trigger_event: string[] | [];
  show_watermark: boolean;
  resurvey_option?: boolean;
  retake_survey: RetakeSurvey;
  audience_rules: SurveyAudience[];
  page_rules: PageRuleSurvey;
  event_rules: string[] | [];
  frequency_capping: FrequencyCapping;
  frequencyAndScheduling: FrequencyScheduling;
}

export interface CustomWidget {
  is_enable: boolean;
  mobile: string;
  desktop: string;
}
export interface SDKThemingInterface {
  background_color: string;
  background_color_opacity: number;
  text_color: string;
  text_color_opacity: number;
  remove_watermark: boolean;
  close_button: boolean;
  dark_overlay: boolean;
  progress_bar: boolean;
  widget_position: string | null;
}

export class SDKThemimg implements SDKThemingInterface {
  background_color: string;
  background_color_opacity: number;
  text_color: string;
  text_color_opacity: number;
  remove_watermark: boolean;
  close_button: boolean;
  dark_overlay: boolean;
  progress_bar: boolean;
  widget_position: string | null;

  constructor() {
    this.background_color = DEFAULT_BACKGROUND_COLOR;
    this.background_color_opacity = DEFAULT_BACKGROUND_COLOR_OPACITY;
    this.text_color = DEFAULT_TEXT_COLOR;
    this.text_color_opacity = DEFAULT_TEXT_COLOR_OPACITY;
    this.remove_watermark = false;
    this.close_button = true;
    this.dark_overlay = true;
    this.progress_bar = true;
    this.widget_position = null;
  }
}
export class SurveySetting implements SurveySettingStructure {
  trigger_event: string[] | [];
  show_watermark: boolean;
  resurvey_option: boolean;
  frequency_capping: FrequencyCapping;
  retake_survey: RetakeSurvey;
  audience_rules: SurveyAudience[];
  page_rules: PageRuleSurvey;
  event_rules: string[] | [];
  frequencyAndScheduling: FrequencyScheduling;
  closed_as_finished: boolean;
  sdk_theme: SDKThemingInterface;
  override_global_throttling: boolean;
  audience_sampling: number;
  target_filter?: {
    operator: FilterOperator;
    filters: ((
      | UserCohortFilter
      | UserTraitFilter
      | UserEventFilter
      | UserFilterGroupFilter
    ) & {
      _id?: string;
    })[];
    _id?: string;
  };
  trigger_filters?: (SurveyTriggerFilter & {
    _id?: string;
  })[];

  constructor() {
    this.audience_sampling = 100;
    this.trigger_event = [];
    this.show_watermark = true;
    this.resurvey_option = false;
    this.frequency_capping = new FrequencyCapping();
    this.retake_survey = new RetakeSurvey();
    this.audience_rules = [];
    this.page_rules = new PageRuleSurvey();
    this.event_rules = [];
    this.frequencyAndScheduling = new FrequencyScheduling();
    this.closed_as_finished = false;
    this.sdk_theme = new SDKThemimg();
    this.override_global_throttling = false;
  }
}
export interface SurveyAudienceInterface {
  event_name: string;
  event_value: Object | null;
}

export class SurveyAudience implements SurveyAudienceInterface {
  event_name: string;
  event_value: FilterData | null;
  constructor(data) {
    this.event_name = data.name ? data.name : '';
    this.event_value = data.event_value ? data.event_value : '';
  }
}
export interface SurveyDataLogicStructure {
  dataLogic: ruleStructure | {};
  userProperty: string;
}

export interface ruleStructure {
  condition: string;
  values: string;
  type: string;
  action: string | null;
  full_string: string | null;
}
export interface DismissBehaviorInterface {
  fades_away: Boolean;
  delay_in_seconds: number;
}

export class DismissBehavior implements DismissBehaviorInterface {
  fades_away: Boolean;
  delay_in_seconds: number;
  constructor() {
    this.fades_away = true;
    this.delay_in_seconds = 0;
  }
}
export class SurveyDataLogic implements SurveyDataLogicStructure {
  dataLogic: Array<ruleStructure> | [];
  userProperty: string;
  dismiss_behavior: DismissBehaviorInterface | null;
  constructor(type: InputType) {
    this.dataLogic = [];
    this.userProperty = '';
    this.dismiss_behavior =
      type === 'end-screen' ? new DismissBehavior() : null;
  }
}

export interface SurveyInterface {
  name: string;
  description: string;
  num_responses?: null;
  end_date?: null;
  live?: boolean;
  platforms: string[];
  deleted?: boolean;
  deleted_on?: null;
  schema_version?: number;
  _id?: string;
  project_id: {
    _id: string;
  } | null;
  style?: StyleInterface;
  screens: Screen[];
  trigger_event_name: string;
  start_date?: number;
  created_on?: number;
  updated_on?: number;
  __v?: number;
  min_version: string | null;
}

export class Survey implements SurveyInterface {
  public name: string;
  public description: string;
  public num_responses?: null;
  public end_date?: null;
  public live?: boolean;
  public platforms: string[];
  public deleted?: boolean;
  public deleted_on?: null;
  public schema_version?: number;
  public _id?: string;
  public project_id: {
    _id: string;
  } | null = null;
  public style?: StyleInterface;
  public screens: Screen[];
  public trigger_event_name: string;
  public start_date?: number;
  public created_on?: number;
  public updated_on?: number;
  public __v?: number;
  public color_theme?: string;
  public color_opacity?: string;
  public bg_color?: string;
  public bg_opacity?: string;
  public txt_color?: string;
  public txt_opacity?: string;
  public icon: string;
  public color: string;
  public status: string;
  public created_by: string;
  public updated_by: string;
  public created_email: string;
  public min_version: string | null;
  /* Survey Settings */
  public survey_settings: SurveySetting;
  public localized: {
    [language: string]: {
      [screenId: string]: {
        [key: string]: any;
      };
    };
  } | null;
  public languages_order: {
    [language: string]: number;
  } | null;

  constructor(project_id, id = '', min_version: null | string = null) {
    this._id = id;
    this.name = '';
    this.description = 'Survey Description';
    this.platforms = [];
    this.project_id = {
      _id: project_id,
    };
    this.screens = [];
    this.trigger_event_name = '';
    this.survey_settings = new SurveySetting();
    this.style = new Style();
    this.icon = '';
    (this.color = ''), (this.status = 'draft');
    this.created_by = '';
    this.updated_by = '';
    this.created_email = '';
    this.min_version = min_version;
    this.localized = null;
    this.languages_order = null;
  }

  /*
  @purpuse
  @return SET  API DATA
   */
  setColorTheme(color, opacity, type = 'brand') {
    switch (type) {
      case 'brand':
        this.color_theme = color;
        this.color_opacity = opacity;
        if (this.style) {
          this.style.primary_color = color;
          this.style.color_opacity = opacity;
        }
        break;
      case 'bg_color':
        this.bg_color = color;
        this.bg_opacity = opacity;
        this.survey_settings.sdk_theme.background_color = color;
        this.survey_settings.sdk_theme.background_color_opacity = opacity;
        break;
      case 'txt_color':
        this.txt_color = color;
        this.txt_opacity = opacity;
        this.survey_settings.sdk_theme.text_color = color;
        this.survey_settings.sdk_theme.text_color_opacity = opacity;
        break;
    }
  }
  setNameAndDescription(name: string, description: string) {
    this.name = name;
    this.description = description || '';
  }

  async set(survey: Survey) {
    this.name = survey.name;
    this.description = survey.description;
    this.num_responses = survey.num_responses;
    this.end_date = survey.end_date;
    this.live = survey.live;
    this.platforms = survey.platforms;
    this.deleted = survey.deleted;
    this.deleted_on = survey.deleted_on;
    this.schema_version = survey.schema_version;
    this._id = survey._id;
    this.project_id = survey.project_id;
    this.localized = survey.localized;
    this.screens = this.setScreens(survey.screens);
    this.trigger_event_name = survey.trigger_event_name;
    this.start_date = survey.start_date;
    this.created_on = survey.created_on;
    this.updated_on = survey.updated_on;
    this.survey_settings = survey.survey_settings
      ? this.setSurveySetting(survey.survey_settings)
      : new SurveySetting();
    this.__v = survey.__v;
    this.icon = survey.icon;
    this.color = survey.color;
    this.status = survey.status;
    this.created_by = survey.created_by ? survey.created_by : '';
    this.created_email = survey.created_email ? survey.created_email : '';
    this.updated_by = survey.updated_by ? survey.updated_by : '';
    this.min_version = survey.min_version ? survey.min_version : null;
    if (!survey.style) {
      this.style = new Style();
    } else {
      if (survey.style.primary_color === '') {
        survey.style.primary_color = DEFAULT_COLOR;
      }
      this.style = survey.style;
    }
  }

  setScreens(screens: Screen[] = []) {
    let new_screen: Array<Screen> = [];
    if (screens.length > 0) {
      screens.map((screen: Screen) => {
        if (screen.input.randomize_choice_order === undefined) {
          screen.input.randomize_choice_order = false;
        }

        if (!screen.input.rating_text) {
          screen.input.rating_text = {
            '0': 'Please select an option',
            '1': 'Very dissatisfied',
            '2': 'Somewhat dissatisfied',
            '3': 'Not dissatisfied nor satisfied',
            '4': 'Somewhat satisfied',
            '5': 'Very satisfied',
          };
        } else {
          let keys = Object.keys(screen.input.rating_text).length;
          if (keys === 5) {
            screen.input.rating_text = {
              '0': 'Please select an option',
              '1': 'Very dissatisfied',
              '2': 'Somewhat dissatisfied',
              '3': 'Not dissatisfied nor satisfied',
              '4': 'Somewhat satisfied',
              '5': 'Very satisfied',
            };
          }
        }
        if (screen.input.rating_min_text === '') {
          screen.input.rating_min_text = 'Not likely at all';
        }
        if (screen.input.rating_max_text === '') {
          screen.input.rating_max_text = 'Extremely likely';
        }

        if (!screen.rules) {
          screen.rules = new SurveyDataLogic(screen.input.input_type);
        }
        new_screen.push(screen);
      });
    }
    return new_screen;
  }
  setSurveySetting(setting: SurveySetting) {
    this.survey_settings.audience_rules = setting.audience_rules
      ? setting.audience_rules
      : [];
    this.survey_settings.event_rules = setting.event_rules
      ? setting.event_rules
      : [];
    this.survey_settings.page_rules = setting.page_rules
      ? setting.page_rules
      : new PageRuleSurvey();
    this.survey_settings.trigger_event = [];
    this.survey_settings.audience_sampling = setting.audience_sampling || 100;
    this.survey_settings.closed_as_finished = setting.closed_as_finished
      ? setting.closed_as_finished
      : false;
    this.survey_settings.show_watermark = setting.show_watermark
      ? setting.show_watermark
      : true;
    this.survey_settings.resurvey_option = setting.resurvey_option
      ? setting.resurvey_option
      : false;
    this.survey_settings.frequency_capping = setting.frequency_capping
      ? setting.frequency_capping
      : new FrequencyCapping();
    this.survey_settings.retake_survey = setting.retake_survey
      ? setting.retake_survey
      : new RetakeSurvey();
    this.survey_settings.frequencyAndScheduling = setting.frequencyAndScheduling
      ? setting.frequencyAndScheduling
      : new FrequencyScheduling();
    this.survey_settings.retake_survey.retake_input_value = setting
      .retake_survey?.retake_input_value
      ? setting.retake_survey.retake_input_value
      : '0';
    this.survey_settings.sdk_theme = setting.sdk_theme
      ? this.setSurveySDKTheme(setting.sdk_theme)
      : new SDKThemimg();
    this.survey_settings.override_global_throttling =
      setting.override_global_throttling ?? false;
    this.survey_settings.target_filter = setting.target_filter;
    this.survey_settings.trigger_filters = setting.trigger_filters;

    return this.survey_settings;
  }

  setSurveySDKTheme(theme: SDKThemingInterface) {
    return {
      background_color:
        theme.background_color !== null && theme.background_color !== undefined
          ? theme.background_color
          : DEFAULT_BACKGROUND_COLOR,
      background_color_opacity:
        theme.background_color_opacity !== null &&
        theme.background_color_opacity !== undefined
          ? theme.background_color_opacity
          : DEFAULT_BACKGROUND_COLOR_OPACITY,
      text_color:
        theme.text_color !== null && theme.text_color !== undefined
          ? theme.text_color
          : DEFAULT_TEXT_COLOR,
      text_color_opacity:
        theme.text_color_opacity !== null &&
        theme.text_color_opacity !== undefined
          ? theme.text_color_opacity
          : DEFAULT_TEXT_COLOR_OPACITY,
      remove_watermark:
        theme.remove_watermark !== null && theme.remove_watermark !== undefined
          ? theme.remove_watermark
          : false,
      close_button:
        theme.close_button !== null && theme.close_button !== undefined
          ? theme.close_button
          : true,
      dark_overlay:
        theme.dark_overlay !== null && theme.dark_overlay !== undefined
          ? theme.dark_overlay
          : true,
      progress_bar:
        theme.progress_bar !== null && theme.progress_bar !== undefined
          ? theme.progress_bar
          : true,
      widget_position:
        theme.widget_position !== null && theme.widget_position !== undefined
          ? theme.widget_position
          : null,
    };
  }

  setSurveyIds(suvery) {
    this._id = suvery._id;
  }

  /*
   @purpuse
   @return ADD SURVEY API DATA
    */

  getCurrentScreen = (screen: Screen) => {
    let screen_val: Screen;
    let input!: Input;
    const currentScreen = this.screens.find(s => s._id === screen._id);

    if (!currentScreen) {
      return screen;
    }

    switch (screen.input.input_type) {
      case InputType.RATING_NUMERICAL:
        input = {
          input_type: InputType.RATING_NUMERICAL,
          min_val: screen.input.min_val,
          max_val: screen.input.max_val,
          rating_max_text: currentScreen.input.rating_max_text,
          rating_min_text: currentScreen.input.rating_min_text,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
      case InputType.NPS:
        input = {
          input_type: InputType.NPS,
          min_val: screen.input.min_val,
          max_val: screen.input.max_val,
          rating_max_text: currentScreen.input.rating_max_text,
          rating_min_text: currentScreen.input.rating_min_text,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
      case InputType.RATING_FIVE_STAR:
        input = {
          input_type: InputType.RATING_FIVE_STAR,
          min_val: 1,
          max_val: 5,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: currentScreen.input.rating_text,
        };
        break;
      case InputType.RATING_EMOJI:
        input = {
          input_type: InputType.RATING_EMOJI,
          stars: false,
          emoji: true,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
      case InputType.TEXT:
        input = {
          input_type: screen.input.input_type,
          min_chars: screen.input.min_chars,
          max_chars: screen.input.max_chars,
          placeholder_text: screen.input.placeholder_text,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
      case InputType.SHORT_TEXT:
        input = {
          input_type: screen.input.input_type,
          min_chars: screen.input.min_chars,
          max_chars: screen.input.max_chars,
          placeholder_text: screen.input.placeholder_text,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
      case InputType.MCQ:
        input = {
          randomize_choice_order: screen.input.randomize_choice_order,
          input_type: screen.input.input_type,
          choices: screen.input.choices?.map((choice: Choice) => ({
            _id: choice._id,
            title:
              currentScreen.input.choices?.find(c => c._id === choice._id)
                ?.title || choice.title,
            option: choice.option,
            other_option: choice.other_option,
          })),
          number_of_choices: screen.input.number_of_choices,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
      case InputType.CHECKBOX:
        input = {
          input_type: screen.input.input_type,
          randomize_choice_order: screen.input.randomize_choice_order,
          choices: screen.input.choices?.map((choice: Choice) => ({
            _id: choice._id,
            title:
              currentScreen.input.choices?.find(c => c._id === choice._id)
                ?.title || choice.title,
            option: choice.option,
            other_option: choice.other_option,
          })),
          number_of_choices: screen.input.number_of_choices,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
      case 'welcome-screen':
        input = {
          input_type: screen.input.input_type,
          min_chars: screen.input.min_chars,
          max_chars: screen.input.max_chars,
          placeholder_text: screen.input.placeholder_text,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;

      case 'end-screen':
        input = {
          input_type: screen.input.input_type,
          min_chars: screen.input.min_chars,
          max_chars: screen.input.max_chars,
          placeholder_text: screen.input.placeholder_text,
          rating_max_text: null,
          rating_min_text: null,
          other_option_id: screen.input.other_option_id,
          rating_text: null,
        };
        break;
    }

    screen_val = {
      title: currentScreen?.title || '',
      message: currentScreen?.message || '',
      media: currentScreen.media,
      input: input,
      buttons: currentScreen.buttons,
      rules: currentScreen.rules,
    };

    if (screen.input.star_fill_color)
      screen_val.input.star_fill_color = screen.input.star_fill_color;

    if (screen_val.input && screen.input)
      screen_val.input._id = screen.input._id;

    if (screen._id) screen_val._id = screen._id;

    return screen_val;
  };

  get() {
    return {
      name: this.name,
      description: this.description,
      platforms: this.platforms,
      languages_order: this.languages_order,
      localized: this.localized,
      project_id: this.project_id,
      screens: this.screens?.map((screen: Screen) => {
        let screen_val: Screen;
        let input!: Input;

        switch (screen.input.input_type) {
          case InputType.RATING:
            input = {
              input_type: InputType.RATING_NUMERICAL,
              min_val: screen.input.min_val,
              max_val: screen.input.max_val,
              stars: false,
              emoji: false,
              randomize_choice_order: false,
              rating_max_text: screen.input.rating_max_text,
              rating_min_text: screen.input.rating_min_text,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          case InputType.RATING_NUMERICAL:
            input = {
              input_type: InputType.RATING_NUMERICAL,
              min_val: screen.input.min_val,
              max_val: screen.input.max_val,
              stars: false,
              emoji: false,
              randomize_choice_order: false,
              rating_max_text: screen.input.rating_max_text,
              rating_min_text: screen.input.rating_min_text,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          case InputType.RATING_FIVE_STAR:
            input = {
              input_type: InputType.RATING_FIVE_STAR,
              min_val: 1,
              max_val: 5,
              stars: true,
              emoji: false,
              randomize_choice_order: false,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: screen.input.rating_text,
            };
            break;
          case InputType.NPS:
            input = {
              input_type: InputType.NPS,
              min_val: 0,
              max_val: 10,
              stars: true,
              emoji: false,
              randomize_choice_order: false,
              rating_max_text: screen.input.rating_max_text,
              rating_min_text: screen.input.rating_min_text,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          case InputType.RATING_EMOJI:
            input = {
              input_type: InputType.RATING_EMOJI,
              stars: false,
              emoji: true,
              randomize_choice_order: false,
              min_val: 1,
              max_val: 5,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          case InputType.TEXT:
            input = {
              input_type: screen.input.input_type,
              min_chars: screen.input.min_chars,
              max_chars: screen.input.max_chars,
              randomize_choice_order: false,
              placeholder_text: screen.input.placeholder_text,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          case InputType.SHORT_TEXT:
            input = {
              input_type: screen.input.input_type,
              min_chars: screen.input.min_chars,
              max_chars: screen.input.max_chars,
              placeholder_text: screen.input.placeholder_text,
              randomize_choice_order: false,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          case InputType.MCQ:
            input = {
              input_type: screen.input.input_type,
              choices: screen.input.choices?.map((choice: Choice) => {
                //@ts-ignore
                if (!choice._id)
                  //@ts-ignore
                  choice._id = ObjectId(new Date().valueOf()).toHexString();
                return {
                  _id: choice._id,
                  title: choice.title,
                  option: choice.option,
                  other_option: choice.other_option,
                };
              }),
              randomize_choice_order: screen.input.randomize_choice_order,
              number_of_choices: 1,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          case InputType.CHECKBOX: {
            let limit_choice = 0;
            input = {
              input_type: screen.input.input_type,
              choices: screen.input.choices?.map((choice: Choice) => {
                if (choice.option === 1) ++limit_choice; //@ts-ignore
                if (!choice._id)
                  //@ts-ignore
                  choice._id = ObjectId(new Date().valueOf()).toHexString();
                return {
                  _id: choice._id,
                  title: choice.title,
                  option: choice.option,
                  other_option: choice.other_option,
                };
              }),
              randomize_choice_order: screen.input.randomize_choice_order,
              number_of_choices: limit_choice,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
          }

          case 'welcome-screen':
            input = {
              input_type: screen.input.input_type,
              min_chars: screen.input.min_chars,
              max_chars: screen.input.max_chars,
              randomize_choice_order: false,
              placeholder_text: screen.input.placeholder_text,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;

          case 'end-screen':
            input = {
              input_type: screen.input.input_type,
              min_chars: screen.input.min_chars,
              max_chars: screen.input.max_chars,
              randomize_choice_order: false,
              placeholder_text: screen.input.placeholder_text,
              rating_max_text: null,
              rating_min_text: null,
              other_option_id: screen.input.other_option_id,
              rating_text: null,
            };
            break;
        }
        screen_val = {
          title: screen.title,
          message: screen.message,
          media: screen.media,
          input: input,
          buttons: screen.buttons,
          rules: screen.rules,
        };

        if (screen.input.star_fill_color)
          screen_val.input.star_fill_color = screen.input.star_fill_color;

        if (screen.input?._id) screen_val.input._id = screen.input._id;

        if (screen._id) screen_val._id = screen._id;

        return screen_val;
      }),
      trigger_event_name: this.trigger_event_name,
      survey_settings: this.survey_settings,
      style: this.style,
      color: this.color,
      icon: this.icon,
      min_version: this.min_version,
    };
  }

  validateDismissRule(survey: Survey, rule) {
    if (
      rule === false &&
      survey.survey_settings.sdk_theme &&
      survey.survey_settings.sdk_theme.close_button === true
    ) {
      return false;
    } else {
      return true;
    }
  }
}

export interface SurveySingleResponseInterface {
  success: number;
  message: string;
  result: Survey;
}

export type SurveyScreenMediaOperation =
  | {
      action: SurveyMediaAction.UPLOAD;
      content: {
        type: SurveyMediaContentType;
        url: string;
        file: File;
      };
    }
  | {
      action: SurveyMediaAction.LINK;
      content: {
        type: SurveyMediaContentType;
        url: string;
      };
    }
  | {
      action: SurveyMediaAction.DELETE;
    };
